import React, { useState } from "react";
import {
    Container,
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    CardActions,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Slide,
  } from "@mui/material";

// Example mock data for day off requests
const mockData = [
    {
      id: 1,
      requested_by: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      send_to: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      day_off_start: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      day_off_end: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      status: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      paid_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      location_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      excuse_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id: 2,
      requested_by: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      send_to: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      day_off_start: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      day_off_end: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      status: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      paid_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      location_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      excuse_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id: 3,
      requested_by: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      send_to: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      day_off_start: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      day_off_end: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      status: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      paid_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      location_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      excuse_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      id: 4,
      requested_by: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      send_to: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      day_off_start: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      day_off_end: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      status: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      paid_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      location_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      excuse_type: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];

const PurchasingForms = () => {
  // Example data for day off requests (you should replace this with your actual data)
    const [purchasingRequests, setPurchasingRequests] = useState(mockData);
    // State to store day off requests
  
    // State for controlling the visibility of the view details dialog
    const [openDialog, setOpenDialog] = useState(false);

    // State for storing the currently viewed request
    const [currentRequest, setCurrentRequest] = useState(null);

    // Function to handle reviewing a day off request
    const handleReviewPurchasingRequest = (requestId) => {
        // Implement your logic for reviewing the day off request here
        // You can use the requestId to identify the specific request to review
    };

  // Function to handle viewing a day off request
  const handleViewPurchasingRequest = (request) => {
    setCurrentRequest(request);
    setOpenDialog(true);
  };

  // Function to close the view details dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentRequest(null);
  };
  
    const cardStyle = {
      backgroundColor: "#f5f5f5",
      marginBottom: "16px", // Adding spacing between cards
      padding: "16px", // Adding padding within each card
    };
  
    const parameterTitleStyle = {
      color: "#007bff",
      fontWeight: "bold",
    };
  
    const parameterValueStyle = {
      color: "#333",
    };
  
    return (
      <Container maxWidth="md">
        <Typography variant="h5" gutterBottom>
          Purchasing Forms
        </Typography>
        <div>
          {/* Add filtering and sorting buttons here */}
          {/* For example: */}
          <Button variant="outlined">Filter</Button>
          <Button variant="outlined">Sort</Button>
        </div>
        <Grid container spacing={2}>
          {purchasingRequests.map((request) => (
            <Grid item xs={12} sm={6} md={4} key={request.id}>
              <Card style={cardStyle}>
                <CardContent>
                  <Typography variant="h6" gutterBottom style={parameterTitleStyle}>
                    Requested By:
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={parameterValueStyle}>
                    {request.requested_by}
                  </Typography>
  
                  <Typography variant="h6" gutterBottom style={parameterTitleStyle}>
                    Start Date:
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={parameterValueStyle}>
                    {request.day_off_start}
                  </Typography>
  
                  <Typography variant="h6" gutterBottom style={parameterTitleStyle}>
                    End Date:
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={parameterValueStyle}>
                    {request.day_off_end}
                  </Typography>
                  <Typography variant="h6" gutterBottom style={parameterTitleStyle}>
                    Status:
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={parameterValueStyle}>
                    {request.status}
                  </Typography>
  
                  {/* Add more parameters here */}
                </CardContent>
                <Divider />
                <CardActions style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                    variant="outlined"
                    onClick={() => handleViewPurchasingRequest(request)}
                    >
                    View
                    </Button>
                {request.status === "Pending" && (
                    <>
                    <div style={{
                        justifyContent: "space-between",
                        display: "flex",
                    }}>

                        <Button
                            variant="outlined"
                            sx={{
                                color: "#4caf50",
                                borderColor: "#4caf50",
                                marginRight: "4px",
                            }}
                            onClick={() => handleReviewPurchasingRequest(request.id)}
                        >
                            Approve
                        </Button>
                        <Button
                            sx={{
                                color: "#f44336",
                                borderColor: "#f44336",                                
                                }}
                            variant="outlined"
                            onClick={() => handleReviewPurchasingRequest(request.id)}
                        >
                            Reject
                        </Button>
                    </div>

                    </>
                )}
                </CardActions>

              </Card>
            </Grid>
          ))}
        </Grid>

        {/* View Details Dialog */}
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            TransitionComponent={Slide}
          >
            <DialogTitle>View Details</DialogTitle>
            <DialogContent>
              {currentRequest && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Requested By:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentRequest.requested_by}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Description:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentRequest.description}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Send To:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentRequest.send_to}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Start Date:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentRequest.day_off_start}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    End Date:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentRequest.day_off_end}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Status:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentRequest.status}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Paid Type:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentRequest.paid_type}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Location Type:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentRequest.location_type}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Excuse Type:
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentRequest.excuse_type}
                  </Typography>
                  {/* Add more details here */}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

      </Container>
    );
  };
  
  export default PurchasingForms;