import { Box, Button, Typography } from '@mui/material';
import '../../../../styles.css';

export const FormConfirm = (props) => {

	return (
		<form autoComplete="off" noValidate hidden={props.hidden}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center"
				}}
				pt={4} pb={3}
			>
				<Box mb={2}>
					<Typography>
						Please Confirm the Process
					</Typography>
				</Box>
				<Box mb={2}>
					<Button onClick={() => props.setConfirmationComplete(true)}>
						Confirm
					</Button>
				</Box>
			</Box>
		</form>
	);
};
