import React, { useEffect, useState, useMemo } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import axios from "../../../axios/index"
import authorsTableData from "components/rekrom/EmployeeTable/data/authorsTableData";

import {
  Card,
  Grid,
  CardContent,
  Typography,
  Box,
  Paper,
  Divider,
  Avatar,
  
} from "@mui/material";
import MDBadge from "components/MDBadge";

import { DataGrid, GridToolbar, GridPagination } from "@mui/x-data-grid";

const PAGE_SIZE = 10;

function getEmployeeStatus(employee_type)
{
  if(employee_type === 0)
  {
    return "Former";
  }
  else if(employee_type === 1)
  {
    return "Full Time";
  }
  else if(employee_type === 2)
  {
    return "Part Time";
  }
  else if(employee_type === 3)
  {
    return "Intern";
  }
  else if(employee_type === 4)
  {
    return "Candidate Engineer";
  }
  else
  {
    return "Unknown";
  }
}

function getEmployeeStatusColor(employee_type)
{
  if(employee_type === 0)
  {
    return "error";
  }
  else if(employee_type === 1)
  {
    return "success";
  }
  else if(employee_type === 2)
  {
    return "warning";
  }
  else if(employee_type === 3)
  {
    return "info";
  }
  else if(employee_type === 4)
  {
    return "primary";
  }
  else
  {
    return "error";
  }
}


function EmployeeTable ({  toggleFilter, showOnlyActive }) {

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

    /////////////////////////////////////////////////
    const [employees, setEmployee] = useState([]);
  
    let token = sessionStorage.getItem("token")

    useEffect(() => {
      //TODO: check this api
      const getEmployees = async () => {
        await axios.get("/api/users/get-all-employees", {
          headers: {
            "Authorization": token
          },
          params: {
            //is_only_active : false,
            //employee_type: "FULL_TIME"
          }
        })
        .then((res) => {
          if (res && res.data) {
            setEmployee(res.data);
          }
        })
        .catch((err) => {
          if (err && err.response) {
            //console.log("Error:", err)
          }
        })
      }
      
      getEmployees()
    }, [])

    const filteredEmployees = showOnlyActive
  ? employees.filter((employee) => employee.is_active)
  : employees;
  //console.log(filteredEmployees)

  const orderedEmployees = filteredEmployees.map((employee, index) => ({
    ...employee,
    order: index + 1
  }));

  return (
    <>
    <Box>
      <Paper elevation={3} sx={{ padding: 2, maxWidth: "100%" }}>
        <Card sx={{ position: "relative" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Employees ({filteredEmployees.length} total)
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <button
                      onClick={toggleFilter}
                      style={{
                        marginLeft: '25px', // Move button to right
                        padding: '12px 12px', // Smaller padding
                        fontSize: '14px',    // Smaller font size
                        background: showOnlyActive ? '#007bff' : 'transparent',
                        color: showOnlyActive ? '#fff' : '#007bff',
                        border: '1px solid #007bff',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        transition: 'background 0.3s, color 0.3s',
                      }}
                    >
                      {showOnlyActive ? "Show All" : "Show Active"}
                    </button>
            </div>
            <Divider sx={{ marginY: 1 }} />
              <DataGrid
                rows={orderedEmployees}
                columns={[
                  { field: "order", headerName: "No", flex: 1 },
                  { field: "customId", headerName: "ID", flex: 1 },
                  {
                    field: 'profile_image',
                    headerName: 'Avatar',
                    flex: 1,
                    renderCell: (params) => (
                      <>
                        <a href={`/profile/${params.row.id}`} style={{ color: 'black', textDecoration: 'none' }}>
                          <Avatar
                            alt={ params.row.username}
                            src={axios.defaults.baseURL +params.row.profile_image}  // Assuming avatarUrl is the field name for avatar URLs
                          />
                        </a>
                      </>
                    ),
                  },
                  { field: "username", headerName: "Name", flex: 2, renderCell: (params) => (
                        <MDTypography display="block" variant="button" fontWeight="medium">
                            <a href={`/profile/${params.row.id}`} style={{ color: 'black', textDecoration: 'none' }}>
                              {params.row.first_name + " " + params.row.last_name}
                            </a>
                        </MDTypography>
                  ) },
                  { field: "title", headerName: "Title", flex: 3 },
                  { field: "employee_type", headerName: "Working Status", flex: 2 , renderCell: (params) => (
                        <MDBox ml={-1}>
                          <MDBadge badgeContent={getEmployeeStatus(params.row.employee_type)} color={getEmployeeStatusColor(params.row.employee_type)} border={true}  variant="gradient" size="sm" circular={true}/>
                        </MDBox>
                  )},
                  { field: "date_of_birth", headerName: "Date of Birth", flex: 2 },
                  { field: "admission_date", headerName: "Admission Date", flex: 2 },
                ]}
                sx={{ height: "410px", width: "100%" }}
                page={currentPage}
                pageSize={PAGE_SIZE}
                pageSizeOptions={[PAGE_SIZE]}
                
                rowSelection={false}
                onCellClick={(params, event) => {
                  if (params.field === "avatarUrl" || params.field === "username") {
                    if (event.ctrlKey) {
                      // Ctrl + Click
                      window.open(`/profile/${params.row.id}`, "_blank");
                    } else {
                      // Normal click
                      window.location.href = `/profile/${params.row.id}`;
                    }
                  }
                }
                }

                components={{
                  Toolbar: GridToolbar,
                }}

                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: PAGE_SIZE,
                    },
                  },
                }}
                onPageChange={(params) => {
                  handlePageChange(params.page);
                }}
              />
          </CardContent>
        </Card>
      </Paper>
    </Box>
    </>
  );
};

export { EmployeeTable };