import {
	Animation,
	EventTracker,
	SelectionState,
} from '@devexpress/dx-react-chart';
import {
	Chart,
	Legend,
	PieSeries,
} from '@devexpress/dx-react-chart-material-ui';
import {
	Box,
	Button,
	Card,
	CardHeader,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { EmployeeList } from 'components/rekrom/Admin/lists/EmployeeList';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const ViewWorkPackage = () => {
	const navigate = useNavigate();

	const { id } = useParams();
	const location = useLocation();
	const workPackages = location.state;
	const workPackage = workPackages[id - 1];
	//console.log(workPackage.workPackageEmployees);

	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [workPackageTitle, setWorkPackageTitle] = useState(
		workPackage.workPackageName
	);
	const [totalTime, setTotalTime] = useState();
	const [employee, setEmployee] = useState('');

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const goProjectPanel = () => {
		navigate('/projects');
	};

	const clickHandler = ({ targets }) => {
		const target = targets[0];
		if (target) {
			//console.log(workPackage.employees[target.point]);
			setTitle(workPackage.employees[target.point].name);
			setTotalTime(workPackage.employees[target.point].employeeWorkTime);
			setEmployee(
				workPackage.employees[target.point].firstName +
					' ' +
					workPackage.employees[target.point].lastName
			);
		}
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleNextWorkPackage = () => {
		if (id < location.state.length) {
			setWorkPackageTitle(workPackages[id].workPackageName);
			navigate(`/projects/viewproject/viewworkpackage/${parseInt(id) + 1}`, {
				state: workPackages,
			});
		} else {
			alert('There is no next work package!');
		}
	};

	const handlePrevWorkPackage = () => {
		if (id > 1) {
			setWorkPackageTitle(workPackages[id - 2].workPackageName);
			navigate(`/projects/viewproject/viewworkpackage/${id - 1}`, {
				state: workPackages,
			});
		} else {
			alert('There is no previous work package!');
		}
	};

	return (
		<>
			<title>Work Package View</title>

			<Box
				component="main"
				sx={{
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					width: '100%',
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container>
					<Grid container spacing={3}>
						<Grid item lg={12} md={12} xl={15} xs={12}>
							<EmployeeList employees={workPackage.workPackageEmployees} />
						</Grid>
						<Grid item lg={12} md={12} xl={15} xs={12}>
							<Card
								sx={{
									maxWidth: 600,
									maxHeight: 400,
								}}
							>
								<Grid
									container
									spacing={3}
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Grid item>
										<Button onClick={handlePrevWorkPackage} size="large">
											{'<'}
										</Button>
									</Grid>
									<Grid item>
										<CardHeader title={workPackageTitle} />
									</Grid>
									<Grid item>
										<Button onClick={handleNextWorkPackage} size="large">
											{'>'}
										</Button>
									</Grid>
								</Grid>
								<Chart data={workPackage.workPackageEmployees}>
									{workPackage.workPackageEmployees.map((employee, index) => (
										<PieSeries
											key={index}
											name={
												employee.employeeFirstName +
												' ' +
												employee.employeeLastName +
												' (' +
												(
													(employee.employeeWorkTime /
														workPackage.workPackageWorkTime) *
													100
												).toFixed(1) +
												' %)'
											}
											valueField="employeeWorkTime"
											argumentField="employeeFirstName"
										/>
									))}
									{/*<Title text="Work time per work package"/>*/}
									<Legend position="left" />
									<EventTracker onClick={clickHandler} />
									<SelectionState />
									<Animation />
								</Chart>
								<Dialog
									fullScreen={fullScreen}
									open={open}
									onClose={handleClose}
									aria-labelledby="responsive-dialog-title"
								>
									<DialogTitle id="responsive-dialog-title">
										{title}
									</DialogTitle>
									<DialogContent>
										<DialogContentText>
											{employee + "'s Total Work Time: " + totalTime + ' hours'}
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button autoFocus onClick={handleClose}>
											Close
										</Button>
									</DialogActions>
								</Dialog>
							</Card>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
};

export default ViewWorkPackage;
