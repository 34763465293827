import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Rating,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  fetchSurveys,
  fetchSurveyAnswers,
  fetchAnonymousSurveyAnswers,
  fetchEmployees,
} from "../../apis/apis/index";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useTheme } from "@mui/material/styles";

const Answers = ({ onBack, surveyId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [answers, setAnswers] = useState([]);
  const [groupedAnswers, setGroupedAnswers] = useState({});
  const [selectedSurvey, setSelectedSurvey] = useState(surveyId || "");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [surveys, setSurveys] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [optionMappings, setOptionMappings] = useState({});
  const [noAnswersFound, setNoAnswersFound] = useState(false);
  const [isSurveyAnonymous, setIsSurveyAnonymous] = useState(false);

  const token = sessionStorage.getItem("token");
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => setOpenDialog(true);

  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const theme = useTheme();

  const fetchSurveysData = async () => {
    try {
      const response = await fetchSurveys(
        token,
        setErrorMessage,
        handleOpenDialog
      );
      setSurveys(response || []);

      const mappings = {};
      response.forEach((survey) => {
        survey.questions.forEach((question) => {
          const optionMapping = {};
          question.options.forEach((option) => {
            optionMapping[option.id] = option.text;
          });
          mappings[question.id] = optionMapping;
        });
      });
      setOptionMappings(mappings);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const fetchEmployeeData = async () => {
    try {
      const response = await fetchEmployees(
        token,
        setErrorMessage,
        handleOpenDialog
      );
      const activeEmployees = response.filter((employee) => employee.is_active);
      setEmployees(activeEmployees || []);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setErrorMessage(error.message);
    }
  };

  const initializeSurvey = async (surveyId) => {
    const survey = surveys.find((s) => s.id === surveyId);
    if (survey) {
      setSelectedSurvey(surveyId);
      setIsSurveyAnonymous(survey.is_anonymous);
      if (survey.is_anonymous) {
        setSelectedEmployee("");
      }
    }
  };

  useEffect(() => {
    if (token) {
      fetchSurveysData();
      fetchEmployeeData();
    } else {
      setErrorMessage("Token is missing. Please log in.");
    }
  }, [token]);

  useEffect(() => {
    if (surveyId && surveys.length > 0) {
      initializeSurvey(surveyId);
    }
  }, [surveyId, surveys]);

  const handleSurveyChange = (event) => {
    const surveyId = event.target.value;
    initializeSurvey(surveyId);
  };

  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
  };

  const handleDisplayClick = async () => {
    setError(""); // Reset any previous errors

    if (!isSurveyAnonymous && !selectedEmployee) {
      setError("Please select an employee.");
      return;
    }

    setLoading(true);
    setAnswers([]);
    setNoAnswersFound(false);

    try {
      let response;
      if (isSurveyAnonymous) {
        response = await fetchAnonymousSurveyAnswers(
          token,
          selectedSurvey,
          setError,
          handleOpenDialog
        );
      } else {
        response = await fetchSurveyAnswers(
          token,
          selectedSurvey,
          selectedEmployee,
          setError,
          handleOpenDialog
        );
      }

      if (response.length === 0) {
        setNoAnswersFound(true);
      } else {
        setAnswers(response);
        const grouped = response.reduce((acc, answer) => {
          const { anonymous_identifier } = answer;
          if (!acc[anonymous_identifier]) {
            acc[anonymous_identifier] = [];
          }
          acc[anonymous_identifier].push(answer);
          return acc;
        }, {});
        setGroupedAnswers(grouped);
      }
    } catch (error) {
      console.error("Error fetching survey answers:", error);
      setError("Failed to load answers.");
    } finally {
      setLoading(false);
    }
  };

  const mapOptionIdToText = (questionId, optionId) => {
    return optionMappings[questionId]
      ? optionMappings[questionId][optionId] || optionId
      : optionId;
  };

  const renderAnswer = (answer) => {
    switch (answer.question_type) {
      case 1: // Multiple Choice
        return (
          <Typography
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
              mt: 2,
            }}
            variant="body1"
          >
            {answer.text_answer}
          </Typography>
        );
      case 2: // Checkboxes
        return (
          <Box sx={{ mt: 2 }}>
            {answer.selected_options.map((optionId, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox checked readOnly />}
                label={
                  <Typography
                    sx={{
                      color:
                        theme.palette.mode === "light" ? light.text : dark.text,
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    {mapOptionIdToText(answer.related_question, optionId)}
                  </Typography>
                }
                sx={{ display: "flex", alignItems: "center" }} // Ensure overall alignment
              />
            ))}
          </Box>
        );
      case 3: // Numeric Rating
        return (
          <Rating
            sx={{
              mt: 2,
            }}
            value={parseInt(answer.numeric_answer)}
            readOnly
            max={5}
          />
        );
      case 4: // Text Input
        return (
          <Typography
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
              mt: 2,
            }}
            variant="body1"
          >
            {answer.text_answer}
          </Typography>
        );
      case 5: // File Upload
        return (
          <Button
            component="a"
            href={answer.file_answer}
            target="_blank"
            sx={{
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.buttonBackground
                  : dark.buttonBackground,
              color:
                theme.palette.mode === "light"
                  ? light.buttonIcon
                  : dark.buttonIcon,

              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : dark.border
              }`,
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? light.buttonBackgroundHover
                    : dark.buttonBackgroundHover,
                color:
                  theme.palette.mode === "light"
                    ? light.buttonIconHover
                    : dark.buttonIconHover,
              },
              mt: 2,
            }}
          >
            Download File
          </Button>
        );
      default:
        return (
          <Typography variant="body1">Unsupported answer type.</Typography>
        );
    }
  };

  return (
    <Box sx={{ pb: 2 }}>
      <Button
        sx={{
          backgroundColor:
            theme.palette.mode === "light"
              ? light.buttonBackground
              : dark.buttonBackground,
          color:
            theme.palette.mode === "light" ? light.buttonIcon : dark.buttonIcon,
          border: `1px solid ${
            theme.palette.mode === "light" ? light.border : dark.border
          }`,
          "&:hover": {
            backgroundColor:
              theme.palette.mode === "light"
                ? light.buttonBackgroundHover
                : dark.buttonBackgroundHover,
            color:
              theme.palette.mode === "light"
                ? light.buttonIconHover
                : dark.buttonIconHover,
            border: `1px solid ${
              theme.palette.mode === "light"
                ? light.borderHover
                : dark.borderHover
            }`,
          },
          marginBottom: "20px",
        }}
        onClick={onBack}
      >
        Back to Surveys
      </Button>

      <Box
        sx={{
          display: "flex",
          mb: 2,
        }}
      >
        <FormControl sx={{ width: "45%", height: "40px", mr: 2 }}>
          <InputLabel id="survey-select-label">Select a survey</InputLabel>
          <Select
            labelId="survey-select-label"
            value={selectedSurvey}
            label="Select a survey"
            onChange={handleSurveyChange}
            sx={{
              height: "40px",
              "& .MuiSelect-select": {
                padding: "16px",
                color: theme.palette.mode === "light" ? light.text : dark.text,
              },
            }}
          >
            {surveys.map((survey) => (
              <MenuItem key={survey.id} value={survey.id}>
                {survey.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{ width: "28%", height: "40px", mr: 2 }}
          disabled={isSurveyAnonymous} // Disable if the survey is anonymous
        >
          <InputLabel id="employee-select-label">Select an employee</InputLabel>
          <Select
            labelId="employee-select-label"
            value={selectedEmployee}
            label="Select an employee"
            onChange={handleEmployeeChange}
            sx={{
              height: "40px",
              "& .MuiSelect-select": {
                padding: "16px",
                color: theme.palette.mode === "light" ? light.text : dark.text,
              },
            }}
          >
            {employees.map((employee) => (
              <MenuItem key={employee.id} value={employee.id}>
                {employee.first_name} {employee.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? light.buttonBackground
                : dark.buttonBackground,
            color:
              theme.palette.mode === "light"
                ? light.buttonIcon
                : dark.buttonIcon,
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : dark.border
            }`,
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.buttonBackgroundHover
                  : dark.buttonBackgroundHover,
              color:
                theme.palette.mode === "light"
                  ? light.buttonIconHover
                  : dark.buttonIconHover,
              border: `1px solid ${
                theme.palette.mode === "light"
                  ? light.borderHover
                  : dark.borderHover
              }`,
            },
            height: "40px",
          }}
          onClick={handleDisplayClick}
        >
          Display Answers
        </Button>
      </Box>

      <Box
        sx={{
          p: 2,
          height: "600px",
          overflowY: "auto",
          backgroundColor:
            theme.palette.mode === "light" ? light.primary : dark.primary,
          borderRadius: "5px",
          width: "105%",
          position: "relative",
          left: "-3%",
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        {loading ? (
          <CircularProgress />
        ) : noAnswersFound ? (
          <Typography
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
              mt: 2,
              textAlign: "center",
            }}
            variant="body1"
          >
            No answers found.
          </Typography>
        ) : (
          Object.keys(groupedAnswers).map((identifier, index) => (
            <Box key={index}>
              <Card
                sx={{
                  mb: 2,
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.primary
                      : dark.primary,
                  borderRadius: "5px",
                }}
              >
                <CardContent
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.secondary
                        : dark.secondary,
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  {isSurveyAnonymous && (
                    <Typography
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? light.text
                            : dark.text,
                        mt: 2,
                        mb: 3,
                      }}
                      variant="h6"
                    >
                      Anonymous {index + 1}
                    </Typography>
                  )}
                  {groupedAnswers[identifier].map((answer, answerIndex) => (
                    <Box key={answerIndex} sx={{ mb: 2 }}>
                      <Box
                        sx={{
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? light.primary
                              : dark.primary,
                          borderRadius: "5px",
                          p: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text,
                            mt: 2,
                          }}
                          variant="h6"
                        >
                          {answer.question_text}
                        </Typography>
                        {renderAnswer(answer)}
                      </Box>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default Answers;
