import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Avatar,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Rating,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  fetchSurveyById,
  submitSurveyAnswers,
  submitSurveyAnswersAnonymously,
} from "../../apis/apis/index";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useTheme } from "@mui/material/styles";
import SHA256 from "crypto-js/sha256";

const ViewSurvey = ({ surveyId, onBack, initialAnswers }) => {
  const [survey, setSurvey] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [answers, setAnswers] = useState(initialAnswers || {});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem("token");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [showMandatoryError, setShowMandatoryError] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");

  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const theme = useTheme();

  useEffect(() => {
    if (initialAnswers) {
      setAnswers(initialAnswers);
    }
  }, [initialAnswers]);

  console.log(
    "respondent's identifier",
    SHA256(sessionStorage.getItem("username")).toString()
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSurveyById(token, surveyId, setErrorMessage);
        if (data && data.survey) {
          setSurvey(data.survey);
          if (data.survey.questions && data.survey.questions.length > 0) {
            const initialAnswers = {};
            data.existing_answers.forEach((answer) => {
              const questionIndex = data.survey.questions.findIndex(
                (question) => question.id === answer.related_question
              );
              if (questionIndex !== -1) {
                initialAnswers[questionIndex] = {
                  text: answer.text_answer,
                  numeric: answer.numeric_answer,
                  file: answer.file_answer,
                  options: answer.selected_options.map((option) => option.text),
                };
              }
            });
            setAnswers(initialAnswers);
          } else {
            setErrorMessage("No questions found for this survey.");
          }
        } else {
          setErrorMessage("Error fetching survey data.");
        }
        setLoading(false);
        setInfoDialogOpen(true); // Open the dialog when survey data is loaded
      } catch (error) {
        console.error("Error fetching survey:", error);
        setErrorMessage("Error fetching survey data.");
        setLoading(false);
      }
    };
    fetchData();
  }, [surveyId, token]);

  const handleNext = () => {
    if (!survey || !survey.questions) {
      setSnackbarMessage("Survey or questions data is missing.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const currentQuestionData = survey.questions[currentQuestion];
    const currentAnswer = answers[currentQuestion];

    if (currentQuestionData.is_mandatory) {
      let isAnswered = true;
      switch (currentQuestionData.type) {
        case 1:
          isAnswered = currentAnswer && currentAnswer.text;
          break;
        case 2:
          isAnswered =
            currentAnswer &&
            currentAnswer.options &&
            currentAnswer.options.length > 0;
          break;
        case 3:
          isAnswered =
            currentAnswer &&
            currentAnswer.numeric !== undefined &&
            currentAnswer.numeric !== null;
          break;
        case 4:
          isAnswered = currentAnswer && currentAnswer.text;
          break;
        case 5:
          isAnswered = currentAnswer && currentAnswer.file;
          break;
        default:
          break;
      }

      if (!isAnswered) {
        setShowMandatoryError(true);
        setSnackbarMessage("This question is required to answer.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      } else {
        setShowMandatoryError(false);
      }
    }

    setCurrentQuestion(currentQuestion + 1);
    setShowMandatoryError(false);
  };

  const handlePrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleChange = (e) => {
    setAnswers({
      ...answers,
      [currentQuestion]: {
        ...answers[currentQuestion],
        [e.target.name]: e.target.value,
      },
    });
    setUnsavedChanges(true);
  };

  const handleCheckboxChange = (e, optionText) => {
    const newAnswers = answers[currentQuestion]?.options || [];
    if (e.target.checked) {
      newAnswers.push(optionText);
    } else {
      const optionIndex = newAnswers.indexOf(optionText);
      if (optionIndex > -1) {
        newAnswers.splice(optionIndex, 1);
      }
    }
    setAnswers({
      ...answers,
      [currentQuestion]: {
        ...answers[currentQuestion],
        options: newAnswers,
      },
    });
    setUnsavedChanges(true);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!survey || !survey.questions) {
      setSnackbarMessage("Survey or questions data is missing.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
  
    const preparedAnswers = survey.questions.map((question, index) => {
      const answer = answers[index] || {};
      const data = {
        related_question: question.id.toString(),
        selected_options: [],
        text_answer: answer.text || null,
        numeric_answer: answer.numeric || null,
        file_answer: answer.file || null,
      };
  
      switch (question.type) {
        case 1: // Multiple Choice
          if (answer.text) {
            const selectedOption = question.options.find(
              (option) => option.text === answer.text
            );
            if (selectedOption) {
              data.selected_options = [selectedOption.id.toString()];
            }
          }
          break;
        case 2:
          if (answer.options) {
            data.selected_options = answer.options
              .map((optionText) => {
                const selectedOption = question.options.find(
                  (option) => option.text === optionText
                );
                return selectedOption ? selectedOption.id.toString() : null;
              })
              .filter(Boolean);
          }
          break;
        default:
          break;
      }
  
      return data;
    });  

    if (survey.is_anonymous) {
      const anonymousIdentifier = SHA256(
        sessionStorage.getItem("username")
      ).toString();

      try {
        const response = await submitSurveyAnswersAnonymously(
          token,
          survey.id,
          preparedAnswers,
          anonymousIdentifier
        );
        console.log("response:", response);
        console.log("answers:", preparedAnswers);
        console.log("identifier:", anonymousIdentifier);
        setSubmissionStatus("success");
        setSnackbarMessage("Answers submitted successfully.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        const resetAnswers = {};
        survey.questions.forEach((_, index) => {
          resetAnswers[index] = {
            text: "",
            numeric: null,
            file: null,
            options: [],
          };
        });
        setAnswers(resetAnswers);
        setCurrentQuestion(0);
        setUnsavedChanges(false);

        setTimeout(() => {
          onBack();
        }, 3000);
      } catch (error) {
        console.error("Error submitting anonymous answers:", error);
        setSubmissionStatus("error");
        setSnackbarMessage("Error submitting answers.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } else {
      const submissionPayload = {
        answers: preparedAnswers,
      };
      try {
        const response = await submitSurveyAnswers(token, survey.id, {
          answers: preparedAnswers,
        });
        console.log("Named answers:", response);
        setSubmissionStatus("success");
        setSnackbarMessage("Answers submitted successfully.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        // Reset answers
        const resetAnswers = {};
        survey.questions.forEach((_, index) => {
          resetAnswers[index] = {
            text: "",
            numeric: null,
            file: null,
            options: [],
          };
        });
        setAnswers(resetAnswers);
        setCurrentQuestion(0);
        setUnsavedChanges(false);

        setTimeout(() => {
          onBack();
        }, 3000);
      } catch (error) {
        console.error("Error submitting named answers:", error);
        setSubmissionStatus("error");
        setSnackbarMessage("Error submitting answers.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const handleBackClick = () => {
    if (unsavedChanges) {
      setConfirmDialogOpen(true);
    } else {
      onBack();
    }
  };

  const handleConfirmBack = () => {
    setConfirmDialogOpen(false);
    onBack();
  };

  const handleCancelBack = () => {
    setConfirmDialogOpen(false);
  };

  const renderQuestion = (question, index) => {
    const optionTextColor =
      theme.palette.mode === "light" ? light.text : dark.text;
    const currentAnswer = answers[index] || {};

    switch (question.type) {
      case 1:
        return (
          <RadioGroup
            name="text"
            value={currentAnswer.text || ""}
            onChange={handleChange}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {question.options.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.text}
                control={<Radio />}
                label={
                  <Typography
                    sx={{
                      color: optionTextColor,
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    {option.text}
                  </Typography>
                }
                sx={{ display: "flex", alignItems: "center" }}
              />
            ))}
          </RadioGroup>
        );
      case 2:
        return (
          <>
            {question.options.map((option) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    checked={
                      currentAnswer.options?.includes(option.text) || false
                    }
                    onChange={(e) => handleCheckboxChange(e, option.text)}
                  />
                }
                label={
                  <Typography
                    sx={{
                      color: optionTextColor,
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    {option.text}
                  </Typography>
                }
                sx={{ display: "flex", alignItems: "center" }}
              />
            ))}
          </>
        );
      case 3:
        return (
          <Rating
            name="numeric"
            value={parseInt(currentAnswer.numeric) || 0}
            onChange={(e, value) =>
              setAnswers({
                ...answers,
                [index]: {
                  ...currentAnswer,
                  numeric: value,
                },
              })
            }
            max={5}
          />
        );
      case 4:
        return (
          <TextField
            fullWidth
            name="text"
            variant="outlined"
            value={currentAnswer.text || ""}
            onChange={handleChange}
            sx={{
              "& .MuiInputBase-input": {
                color: theme.palette.mode === "light" ? light.text : dark.text,
              },
            }}
          />
        );
      case 5: // File Upload
        return (
          <Box>
            <Button
              component="label"
              sx={{
                backgroundColor:
                  theme.palette.mode === "light"
                    ? light.buttonBackground
                    : dark.buttonBackground,
                color:
                  theme.palette.mode === "light"
                    ? light.buttonIcon
                    : dark.buttonIcon,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.buttonBackgroundHover
                      : dark.buttonBackgroundHover,
                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIconHover
                      : dark.buttonIconHover,
                  border: `1px solid ${
                    theme.palette.mode === "light"
                      ? light.borderHover
                      : dark.borderHover
                  }`,
                },
              }}
            >
              Upload File
              <input
                type="file"
                hidden
                onChange={(e) => handleFileChange(e, index)}
              />
            </Button>
            {currentAnswer.file && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Selected file: {currentAnswer.file.name}
              </Typography>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  const handleFileChange = (e, questionIndex) => {
    setAnswers({
      ...answers,
      [questionIndex]: {
        ...answers[questionIndex],
        file: e.target.files[0],
      },
    });
    setUnsavedChanges(true);
  };

  const handleDialogContinue = () => {
    setInfoDialogOpen(false);
  };

  const handleDialogGoBack = () => {
    setInfoDialogOpen(false);
    onBack();
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (submissionStatus === "success") {
    return (
      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Typography variant="h5" color="success.main">
            Your answers have been successfully submitted!
          </Typography>
        </Box>
      </Container>
    );
  }

  if (submissionStatus === "error") {
    return (
      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Typography variant="h5" color="error.main">
            There was an error submitting your answers. Please try again.
          </Typography>
        </Box>
      </Container>
    );
  }

  if (!survey || !survey.questions || survey.questions.length === 0) {
    return (
      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Typography variant="h5" color="textSecondary">
            No questions found for this survey.
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box display="flex">
        <Button
          sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? light.buttonBackground
                : dark.buttonBackground,
            color:
              theme.palette.mode === "light"
                ? light.buttonIcon
                : dark.buttonIcon,
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : dark.border
            }`,
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.buttonBackgroundHover
                  : dark.buttonBackgroundHover,
              color:
                theme.palette.mode === "light"
                  ? light.buttonIconHover
                  : dark.buttonIconHover,
              border: `1px solid ${
                theme.palette.mode === "light"
                  ? light.borderHover
                  : dark.borderHover
              }`,
            },
            marginBottom: "20px",
          }}
          onClick={handleBackClick}
        >
          Back to Surveys
        </Button>
      </Box>
      {errorMessage && (
        <Typography color="error" variant="body2">
          {errorMessage}
        </Typography>
      )}
      {survey && survey.questions && (
        <>
          <Box display="flex" justifyContent="center">
            <Card
              sx={{
                backgroundColor:
                  theme.palette.mode === "light" ? light.primary : dark.primary,
                width: "100%",
                maxWidth: "800px",
                margin: "0 auto",
                borderRadius: "12px",
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center" mb={3}>
                  <Avatar
                    alt={survey.publisher.first_name}
                    src={survey.publisher.profile_image}
                  />
                  <Box ml={2}>
                    <Typography
                      variant="h6"
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? light.text
                            : dark.text,
                      }}
                    >
                      {survey.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? light.text
                            : dark.text,
                      }}
                    >
                      {survey.description}
                    </Typography>
                  </Box>
                </Box>
                <Box mb={2}>
                  <Typography
                    variant="body1"
                    sx={{
                      color:
                        theme.palette.mode === "light" ? light.text : dark.text,
                    }}
                  >
                    {currentQuestion + 1}.{" "}
                    {survey.questions[currentQuestion].text}
                  </Typography>
                  <Box mt={2}>
                    {renderQuestion(
                      survey.questions[currentQuestion],
                      currentQuestion
                    )}
                  </Box>
                  {showMandatoryError &&
                    survey.questions[currentQuestion].is_mandatory && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? light.error
                              : dark.error,
                        }}
                      >
                        This question is required to answer.
                      </Typography>
                    )}
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={3}
            width="100%"
            maxWidth="800px"
            margin="0 auto"
          >
            <Button
              sx={{
                backgroundColor:
                  theme.palette.mode === "light"
                    ? light.buttonBackground
                    : dark.buttonBackground,
                color:
                  theme.palette.mode === "light"
                    ? light.buttonIcon
                    : dark.buttonIcon,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.buttonBackgroundHover
                      : dark.buttonBackgroundHover,
                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIconHover
                      : dark.buttonIconHover,
                  border: `1px solid ${
                    theme.palette.mode === "light"
                      ? light.borderHover
                      : dark.borderHover
                  }`,
                },
                mt: 2,
              }}
              onClick={handlePrev}
              disabled={currentQuestion === 0}
            >
              Previous
            </Button>
            {currentQuestion === survey.questions.length - 1 ? (
              <Button
                sx={{
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.buttonBackground
                      : dark.buttonBackground,
                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIcon
                      : dark.buttonIcon,
                  border: `1px solid ${
                    theme.palette.mode === "light" ? light.border : dark.border
                  }`,
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.buttonBackgroundHover
                        : dark.buttonBackgroundHover,
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIconHover
                        : dark.buttonIconHover,
                    border: `1px solid ${
                      theme.palette.mode === "light"
                        ? light.borderHover
                        : dark.borderHover
                    }`,
                  },
                  mt: 2,
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.buttonBackground
                      : dark.buttonBackground,
                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIcon
                      : dark.buttonIcon,
                  border: `1px solid ${
                    theme.palette.mode === "light" ? light.border : dark.border
                  }`,
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.buttonBackgroundHover
                        : dark.buttonBackgroundHover,
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIconHover
                        : dark.buttonIconHover,
                    border: `1px solid ${
                      theme.palette.mode === "light"
                        ? light.borderHover
                        : dark.borderHover
                    }`,
                  },
                  mt: 2,
                }}
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </Box>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={confirmDialogOpen} onClose={handleCancelBack}>
        <DialogTitle
          sx={{
            color: theme.palette.mode === "light" ? light.text : dark.text,
          }}
        >
          {"Unsaved Changes"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            You have unsaved changes. If you go back now, your progress will be
            lost. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
            onClick={handleCancelBack}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
            onClick={handleConfirmBack}
            color="secondary"
          >
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={infoDialogOpen}>
        <DialogTitle
          sx={{
            color: theme.palette.mode === "light" ? light.text : dark.text,
          }}
        >
          Important Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            {survey && survey.is_anonymous
              ? "This survey requires you to answer anonymously."
              : "This survey requires you to answer with your name."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogGoBack}
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            Go Back
          </Button>
          <Button onClick={handleDialogContinue} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ViewSurvey;
