import React, { useState } from "react";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
//import {Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
//import { MuiTelInput } from 'mui-tel-input'
//import PhoneInput from 'react-phone-input-2'
//import 'react-phone-input-2/lib/style.css'
//import PhoneInput from 'react-phone-number-input'
//import MuiPhoneNumber from 'mui-phone-number';

const Employees = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  /*const [value, setValue] = useState("")

    const handleOnChange = (value) => {
        setValue(value)
    }*/

  //console.log("wow")

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid
        container
        direction="column"
        spacing={1}
        p={3}
        m={10}
        sx={{
          // backgroundColor: "#EB6440",
          maxWidth: "65%",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "10px",
        }}
      >
        <Grid item>
          <Typography variant="h4">New Employee Details</Typography>
          <Divider dark fullWidth />
        </Grid>
        <Grid item>
          <Typography variant="h5">Personal Information</Typography>
          <Divider dark fullWidth />
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h6">Name</Typography>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                sx={{
                  mt: "2px",
                }}
              >
                <Grid item xs={6}>
                  <TextField variant="outlined" label="first name">
                    first name
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField variant="outlined" label="last name">
                    last name
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <Typography variant="h6">Birth Date</Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  mt: "2px",
                }}
              >
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="birth date"
                      name="birthDate"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e)}
                      textField={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          style={
                            {
                              // backgroundColor: "#FFF"
                            }
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            marginTop: "20px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginTop: "5px",
              marginBottom: "15px",
            }}
          >
            Address
          </Typography>
          <TextField variant="outlined" label="street address" fullWidth>
            Street Address
          </TextField>
        </Grid>
        <Grid
          item
          sx={{
            mt: "10px",
          }}
        >
          <TextField
            variant="outlined"
            label="street address continue"
            fullWidth
          >
            Street Address
          </TextField>
        </Grid>
        <Grid
          item
          sx={{
            marginTop: "10px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item xs={6}>
              <Grid container justifyContent="flex-start">
                <TextField
                  variant="outlined"
                  label="city"
                  fullWidth
                ></TextField>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <TextField
                  variant="outlined"
                  label="post code"
                  fullWidth
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            sx={{
              marginTop: "10px",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h6">Phone Number</Typography>
              {/*<MuiPhoneNumber
                                variant="outlined"
                                defaultCountry={'tr'}
                                onChange={handleOnChange}
                    />*/}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">E-mail</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Employees;
