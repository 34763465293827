import React, { useState, useEffect } from "react";
import { IoAddOutline, IoCheckmarkOutline } from "react-icons/io5"; // Import the icons
import { X } from "react-feather";
import "./Editable.css";
import { Box } from "@mui/material"; // Import Box from MUI
import { useTheme } from "@mui/material/styles"; // Import useTheme to access the theme
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

const Editable = (props) => {
  const [show, setShow] = useState(props?.handler || false);
  const [text, setText] = useState(props.defaultValue || "");
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (text && props.onSubmit) {
      setText("");
      props.onSubmit(text);
    }
    setShow(false);
  };

  const addNote = () => {
    setShow(true);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.code === "Enter" && show) {
        handleOnSubmit(e);
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [show, text]);

  return (
    <div className={`editable ${props.parentClass}`}>
      {show ? (
        <form onSubmit={handleOnSubmit}>
          <div className={`editable__input ${props.class}`}>
            <textarea
              placeholder={props.placeholder}
              autoFocus={true}
              id="edit-input"
              type="text"
              onChange={(e) => setText(e.target.value)}
              value={text} // Keep the value in sync with the state
            />
            <div>
              <button
                type="submit"
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
              >
                <IoCheckmarkOutline
                  style={{
                    fontSize: "24px",
                    backgroundColor:
                      theme.palette.mode === "light" ? "green" : "green",
                    color: theme.palette.mode === "light" ? "white" : "white",
                    borderRadius: "10%",
                    padding: "0.25rem",
                  }}
                />
              </button>
              <X
                className="close"
                onClick={() => {
                  setShow(false);
                  if (props?.setHandler) {
                    props.setHandler(false);
                  }
                }}
                style={{
                  marginLeft: "0.5rem",
                  fontSize: "20px",
                  backgroundColor:
                    theme.palette.mode === "light" ? "red" : "red",
                  color: theme.palette.mode === "light" ? "white" : "white",
                  borderRadius: "10%",
                  padding: "0.25rem",
                }}
              />
            </div>
          </div>
        </form>
      ) : (
        <Box
          onClick={addNote}
          sx={{
            borderRadius: "10%",
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : dark.border
            }`,
            
            color: theme.palette.mode === "light" ? light.buttonIcon : dark.buttonIcon,
            backgroundColor: theme.palette.mode === "light" ? light.buttonBackground : dark.buttonBackground,
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.buttonBackgroundHover
                  : dark.buttonBackgroundHover,
              cursor: "pointer",
              color: theme.palette.mode === "light" ? light.buttonIconHover : dark.buttonIconHover,
            },
            width: "fit-content",
            height: "fit-content",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "0",
            padding: "0.25rem",
            marginLeft: "0.5rem",
          }}
        >
          <span style={{ fontSize: "24px" }}>
            <IoAddOutline />
          </span>
        </Box>
      )}
    </div>
  );
};

export default Editable;
