import React, { useState } from "react";
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  Checkbox,
  Switch,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Alert,
  Tabs,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, Upload, Delete, Clear } from "@mui/icons-material";
import Rating from "@mui/material/Rating";
import { createSurvey } from "../../apis/apis/index";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useTheme } from "@mui/material/styles";
import { IoAddOutline } from "react-icons/io5";
import PersonIcon from "@mui/icons-material/Person";
import MdiIcon from "@mdi/react";
import { mdiIncognito } from "@mdi/js";
import Icon from "@mui/material/Icon";

const SurveyCreator = () => {
  const [questions, setQuestions] = useState([
    { type: "multiple-choice", options: [""] },
  ]);
  const [surveyName, setSurveyName] = useState("");
  const [surveyDescription, setSurveyDescription] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [errors, setErrors] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setIsAnonymous(newValue === 1);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { type: "multiple-choice", options: [""] }]);
  };

  const handleDeleteQuestion = (index) => {
    const newQuestions = questions.filter((_, qIndex) => qIndex !== index);
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex] = value;
    setQuestions(newQuestions);
  };

  const handleAddOption = (qIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.push("");
    setQuestions(newQuestions);
  };

  const handleDeleteOption = (qIndex, oIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options = newQuestions[qIndex].options.filter(
      (_, index) => index !== oIndex
    );
    setQuestions(newQuestions);
  };

  const handleTypeChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].type = value;
    newQuestions[index].options = [""];
    setQuestions(newQuestions);
  };

  const handleMandatoryChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].mandatory = value;
    setQuestions(newQuestions);
  };

  const SurveyQuestionType = {
    "multiple-choice": 1,
    checkboxes: 2,
    "numeric-rating": 3,
    "text-input": 4,
    "upload-file": 5,
  };

  const validateSurvey = () => {
    const newErrors = {};
    if (!surveyName) newErrors.surveyName = "Enter a title";
    if (!surveyDescription) newErrors.surveyDescription = "Enter a description";

    questions.forEach((q, index) => {
      if (!q.question) newErrors[`question-${index}`] = "Enter a question";
      if (q.type === "multiple-choice" || q.type === "checkboxes") {
        if (q.options.length < 2 || q.options.some((option) => !option)) {
          newErrors[`options-${index}`] =
            "A question should have at least two options";
        }
        const uniqueOptions = new Set(q.options.map(option => option.toLowerCase()));
        if (uniqueOptions.size !== q.options.length) {
          newErrors[`options-${index}`] = "Duplicate options are not allowed";
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateSurvey()) {
      setSnackbarMessage("Please fix the errors in the survey");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const values = {
      title: surveyName,
      description: surveyDescription,
      is_anonymous: isAnonymous,
      questions: questions.map((q) => ({
        text: q.question,
        type: SurveyQuestionType[q.type],
        is_mandatory: q.mandatory || false,
        options: q.options.map((option) => ({ text: option })),
      })),
    };

    console.log("Request Payload:", values);

    try {
      await createSurvey(token, (err) => setErrorMessage(err), () => {}, navigate, values);
      setSnackbarMessage("Survey submitted successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setSurveyName("");
      setSurveyDescription("");
      setQuestions([{ type: "multiple-choice", options: [""] }]);
      setErrors({});
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    } catch (error) {
      console.error("Error creating survey:", error);
      setSnackbarMessage("Error submitting survey. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      minHeight="500px"
      sx={{ paddingTop: 4 }}
      bgcolor={theme.palette.mode === "light" ? light.primary : dark.primary}
    >
      <Box maxWidth={600} width="100%">
        <Box mb={2}>
          <Box display="flex">
            <TextField
              fullWidth
              label="Survey Name"
              variant="outlined"
              value={surveyName}
              onChange={(e) => setSurveyName(e.target.value)}
              error={!!errors.surveyName}
              helperText={errors.surveyName}
              sx={{
                "& .MuiInputBase-input": {
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                },
              }}
            />
            <Box ml={2}>
              <Tabs
                sx={{
                  height: "45px",
                  width: "200px",
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.secondary
                      : dark.secondary,
                  borderRadius: "10px",
                  border: `1px solid ${
                    theme.palette.mode === "light" ? light.border : dark.border
                  }`,
                }}
                onChange={handleTabChange}
                centered
                value={selectedTab}
              >
                <Tab icon={<PersonIcon />} />
                <Tab icon={<MdiIcon path={mdiIncognito} size={1} />} />
              </Tabs>
            </Box>
          </Box>
          <TextField
            fullWidth
            label="Survey Description"
            variant="outlined"
            value={surveyDescription}
            onChange={(e) => setSurveyDescription(e.target.value)}
            error={!!errors.surveyDescription}
            helperText={errors.surveyDescription}
            sx={{
              mt: 2,
              "& .MuiInputBase-input": {
                color: theme.palette.mode === "light" ? light.text : dark.text,
              },
            }}
          />
        </Box>
        {questions.map((q, qIndex) => (
          <Box
            key={qIndex}
            mb={2}
            p={2}
            border={1}
            borderRadius={2}
            borderColor="grey.300"
            sx={{ backgroundColor: "white" }}
          >
            <Box display="flex" alignItems="center" mb={2}>
              <TextField
                fullWidth
                placeholder="Question"
                variant="standard"
                value={q.question || ""}
                onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
                error={!!errors[`question-${qIndex}`]}
                helperText={errors[`question-${qIndex}`]}
                sx={{
                  mr: 2,
                  "& .MuiInputBase-input::placeholder": {
                    color: theme.palette.mode === "light" ? "#000" : dark.text,
                  },
                  "& .MuiInputBase-input": {
                    color: theme.palette.mode === "light" ? "#000" : dark.text,
                  },
                }}
              />
              <Select
                value={q.type}
                onChange={(e) => handleTypeChange(qIndex, e.target.value)}
                displayEmpty
                sx={{
                  minWidth: 120,
                  minHeight: 30,
                  "& .MuiSelect-select": {
                    color: theme.palette.mode === "light" ? "#000" : dark.text,
                  },
                }}
              >
                <MenuItem value="multiple-choice">Multiple Choice</MenuItem>
                <MenuItem value="checkboxes">Checkboxes</MenuItem>
                <MenuItem value="numeric-rating">Numeric Rating</MenuItem>
                <MenuItem value="text-input">Text Input</MenuItem>
                <MenuItem value="upload-file">File Upload</MenuItem>
              </Select>
            </Box>
            {q.type === "multiple-choice" && (
              <RadioGroup name={`question-${qIndex}`} value="">
                {q.options.map((option, oIndex) => (
                  <Box
                    key={oIndex}
                    display="flex"
                    alignItems="center"
                    mb={1}
                    ml={-1}
                  >
                    <Radio disabled sx={{ padding: "0 8px" }} />
                    <TextField
                      variant="standard"
                      value={option}
                      placeholder={`${oIndex + 1}. option`}
                      onChange={(e) =>
                        handleOptionChange(qIndex, oIndex, e.target.value)
                      }
                      error={!!errors[`options-${qIndex}`]}
                      sx={{
                        width: "50%",
                        "& .MuiInputBase-input::placeholder": {
                          color:
                            theme.palette.mode === "light" ? "#000" : dark.text,
                        },
                        "& .MuiInputBase-input": {
                          color:
                            theme.palette.mode === "light" ? "#000" : dark.text,
                        },
                      }}
                    />
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteOption(qIndex, oIndex)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                ))}
                <Typography variant="caption" color="error" sx={{ ml: 2 }}>
                  {errors[`options-${qIndex}`]}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ mt: 1, ml: -1 }}
                  onClick={() => handleAddOption(qIndex)}
                >
                  <Radio
                    disabled
                    sx={{ padding: "0 8px", cursor: "pointer" }}
                  />
                  <Typography
                    sx={{
                      color:
                        theme.palette.mode === "light" ? light.text : "#adadad",
                      cursor: "pointer",
                      fontSize: "0.875rem",
                      fontWeight: 100,
                    }}
                  >
                    New Option
                  </Typography>
                </Box>
              </RadioGroup>
            )}
            {q.type === "checkboxes" && (
              <Box>
                {q.options.map((option, oIndex) => (
                  <Box
                    key={oIndex}
                    display="flex"
                    alignItems="center"
                    mb={1}
                    ml={-1}
                  >
                    <Checkbox disabled sx={{ padding: "0 8px" }} />
                    <TextField
                      variant="standard"
                      value={option}
                      placeholder={`${oIndex + 1}. option`}
                      onChange={(e) =>
                        handleOptionChange(qIndex, oIndex, e.target.value)
                      }
                      error={!!errors[`options-${qIndex}`]}
                      sx={{
                        width: "50%",
                        "& .MuiInputBase-input::placeholder": {
                          color:
                            theme.palette.mode === "light" ? "#000" : dark.text,
                        },
                        "& .MuiInputBase-input": {
                          color:
                            theme.palette.mode === "light" ? "#000" : dark.text,
                        },
                      }}
                    />
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteOption(qIndex, oIndex)}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                ))}
                <Typography variant="caption" color="error" sx={{ ml: 2 }}>
                  {errors[`options-${qIndex}`]}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ mt: -3, ml: -1 }}
                  onClick={() => handleAddOption(qIndex)}
                >
                  <Checkbox
                    disabled
                    sx={{ padding: "0 8px", cursor: "pointer" }}
                  />
                  <Typography
                    sx={{
                      color:
                        theme.palette.mode === "light" ? light.text : "#adadad",
                      cursor: "pointer",
                      fontSize: "0.875rem",
                      fontWeight: 100,
                    }}
                  >
                    New Option
                  </Typography>
                </Box>
              </Box>
            )}
            {q.type === "numeric-rating" && (
              <Rating
                sx={{
                  "& .MuiRating-iconEmpty": {
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                  },
                }}
                name="no-value"
                disabled
                max={5}
              />
            )}
            {q.type === "text-input" && (
              <TextField
                fullWidth
                placeholder="Answer"
                variant="standard"
                sx={{
                  mt: 2,
                  "& .MuiInputBase-input::placeholder": {
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                  },
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
            {q.type === "upload-file" && (
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<Upload />}
                  disabled
                >
                  Upload File
                  <input type="file" hidden />
                </Button>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              sx={{
                marginLeft: "350px",
                marginTop: "10px",
                paddingTop: "10px",
              }}
            >
              <Icon
                sx={{
                  ml: 5,
                  mr: 1,
                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIcon
                      : dark.buttonIcon,
                  cursor: "pointer",
                  width: "22px",
                  height: "22px",
                  fontSize: "15px",
                  "&:hover": {
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIconHover
                        : "#000000",
                    borderRadius: "20%",
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.buttonBackgroundHover
                        : dark.buttonBackgroundHover,
                  },
                }}
                onClick={() => handleDeleteQuestion(qIndex)}
              >
                delete
              </Icon>
              <Typography
                sx={{
                  fontSize: "20px",
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                }}
              >
                |
              </Typography>

              <Box display="flex" alignItems="center" sx={{ ml: 2 }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                  }}
                >
                  Mandatory
                </Typography>
                <Switch
                  checked={q.mandatory || false}
                  onChange={(e) =>
                    handleMandatoryChange(qIndex, e.target.checked)
                  }
                />
              </Box>
            </Box>
          </Box>
        ))}
        <Box
          sx={{
            borderRadius: "10%",
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : dark.border
            }`,
            color:
              theme.palette.mode === "light"
                ? light.buttonIcon
                : dark.buttonIcon,
            backgroundColor:
              theme.palette.mode === "light"
                ? light.primary
                : dark.buttonBackground,
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.buttonBackgroundHover
                  : dark.buttonBackgroundHover,
              cursor: "pointer",
              color:
                theme.palette.mode === "light"
                  ? light.buttonIconHover
                  : dark.buttonIconHover,
            },
            width: "fit-content",
            height: "fit-content",
            display: "inline-block",
            textAlign: "center",
            lineHeight: "0",
            padding: "0.25rem",
          }}
        >
          <span style={{ fontSize: "32px" }} onClick={handleAddQuestion}>
            <IoAddOutline />
          </span>
        </Box>
        <Box display="flex" justifyContent="center" mt={4} mb={1}>
          <Button
            sx={{
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.buttonBackground
                  : dark.buttonBackground,

              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? light.buttonBackgroundHover
                    : dark.buttonBackgroundHover,
                color: theme.palette.mode === "light" ? dark.text : light.text,
              },
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : dark.border
              }`,
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default SurveyCreator;
