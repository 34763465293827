import {
  Card,
  Box,
  Container,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Button,
  CircularProgress,
  Paper,
  InputBase,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import { useTheme } from "@mui/material/styles";
import { ProjectList } from "components/rekrom/Admin/lists/ProjectList";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../axios/index";
import Fuse from 'fuse.js';

const statusList = [
  {
    name: "ONGOING",
    label: 1,
  },
  {
    name: "DEVELOPING",
    label: 2,
  },
  {
    name: "DONE",
    label: 3,
  },
  {
    name: "PENDING",
    label: 4,
  },
];

const Projects = () => {
  const [projectLists, setProjectList] = useState([]);
  const [renderProjectList, setRenderProjectList] = useState([]);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState([]);
  const [projectsTotalTime, setProjectsTotalTime] = useState();
  const [chartData, setChartData] = useState();
  const [chartDataFetched, setChartDataFetched] = useState(false);

  const navigate = useNavigate("");

  const [title, setTitle] = useState("");
  const [totalTime, setTotalTime] = useState();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  var array = [];

  const token = sessionStorage.getItem("token");

  const deleteProject = async (projectId) => {
    //console.log(projectId)
    const values = {
      projectId: projectId,
    };
    if (projectId < 0) {
      return;
    }
    await axios
      .post("/api/project/delete-project", values, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (res && res.data) {
          //console.log(res.data)
          window.location.reload();
        }
      })
      .catch((err) => {
        if (err) {
          //console.log("Error: " + err);
        }
      });
  };

  useEffect(() => {
    const getProjectDetail = async () => {
      await axios
        .get("/api/project/get-custom-project-detail", {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          if (res && res.data) {
            //console.log(res.data)
            setProjectsTotalTime(res.data.totalWorkTime);
            var i;
            for (i = 0; i < res.data.projects.length; i++) {
              array.push({
                candidateList: res.data.projects[i].candidateList,
                projectAbbr: res.data.projects[i].projectAbbr,
                projectDescription: res.data.projects[i].projectDescription,
                projectEstimatedEndDate:
                  res.data.projects[i].projectEstimatedEndDate,
                projectId: res.data.projects[i].projectId,
                projectLead: res.data.projects[i].projectLead,
                projectLeadID: res.data.projects[i].projectLeadID,
                projectName: res.data.projects[i].projectName,
                projectOwner: res.data.projects[i].projectOwner,
                projectOwnerID: res.data.projects[i].projectOwnerID,
                projectStartDate: res.data.projects[i].projectStartDate,
                projectStatus: statusList.find(
                  (status) =>
                    res.data.projects[i].projectStatus === status.label
                ).name,
                projectType: res.data.projects[i].projectType,
                projectWorkTime: res.data.projects[i].projectWorkTime,
                workPackages: res.data.projects[i].workPackages,
              });
              //console.log(res.data.projects[i].projectLeadID)
            }
            const chartData = array.map((item) => ({
              projectName: item.projectName,
              projectWorkTime: item.projectWorkTime,
            }));
            setChartData(chartData);
            setChartDataFetched(true);

            setProjectList(array);
            setRenderProjectList(array);
            setFlag(true);
          }
        })
        .catch((err) => {
          if (err) {
            //console.log("Error: " + err);
          }
        });
    };
    getProjectDetail();
  }, []);

  const clickHandler = ({ targets }) => {
    const target = targets[0];
    if (target) {
      setTitle(chartData[target.point].projectName);
      setTotalTime(chartData[target.point].projectWorkTime);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.trim(); // Trim whitespace from search input
    const fuseOptions = {
      keys: ['projectName'], // Specify which object keys to search in
      includeScore: true, // Include score for result ranking
      threshold: 0.4, // Adjust threshold for matching (lower is more permissive)
    };

    if (!searchValue) {
      // If search value is empty, show all projects
      setRenderProjectList([...projectLists]);
      return;
    }

    const fuse = new Fuse(projectLists, fuseOptions);
    const result = fuse.search(searchValue);

    // Map search results to get only the project items (excluding scores)
    const filteredProjects = result.map((item) => item.item);

    setRenderProjectList([...filteredProjects]);
  };

  return (
    <>
      <title>Project List</title>
      {/* TODO: continue to add search button */}
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 400,
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Project"
          inputProps={{ "aria-label": "search project" }}
          onChange={handleSearchChange}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      {chartDataFetched ? (
        <>
          <Box
            component="main"
            sx={{
              display: "flex",
              flex: "1 1 auto",
              flexDirection: "column",
              width: "100%",
              flexGrow: 1,
              py: 8,
            }}
          >
            <Container>
              <Grid container spacing={3}>
                <Grid item lg={12} md={15} xl={15} xs={15}>
                  <ProjectList
                    deleteProject={deleteProject}
                    projects={renderProjectList}
                  />
                </Grid>
                {/* <Grid
                            item
                            lg={12}
                            md={12}
                            xl={15}
                            xs={12}
                        >
                            <Card
                                sx={{
                                    maxWidth: 600,
                                    maxHeight: 400
                                }}
                            >
                                <Chart
                                    data={chartData}
                                >   
                                <PieSeries
                                    valueField="projectWorkTime"
                                    argumentField="projectName"
                                />
                                    <Title text="Project Total Work Time"/>
                                    <Legend position="left"/>
                                    <EventTracker onClick={clickHandler}/>
                                    <SelectionState selection={selection}/>
                                </Chart>
                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                >
                                    <DialogTitle id="responsive-dialog-title">
                                        {title}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            {"Total Project Work Time: " + totalTime + " hours"}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button autoFocus onClick={handleClose}>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Card>
                        </Grid> */}
              </Grid>
            </Container>
          </Box>
        </>
      ) : (
        <div>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
          >
            <CircularProgress />
          </Box>
        </div>
      )}
    </>
  );
};

export default Projects;
