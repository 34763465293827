import { Box, Button, Typography } from '@mui/material';
import AuthCode from 'react-auth-code-input';
import '../../../../styles.css';

export const FormMail = (props) => {
	return (
		<form autoComplete="off" noValidate hidden={props.hidden}>
			<Box className="App">
				<Box pt={4} pb={3} px={3}>
					<Typography variant="b2">
						{'Please verify the TFA code that is send to your ' +
							props.formik.values.email +
							' mail'}
					</Typography>
					<AuthCode
						allowedCharacters="numeric"
						onChange={props.handleOnAuthChange(props.result)}
						length={6}
						inputClassName="input"
						containerClassName="container"
					/>
					<Button disabled={!props.isCodeComplete} onClick={props.confirmMail}>
						Verify Mail Address
					</Button>
				</Box>
			</Box>
		</form>
	);
};
