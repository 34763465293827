import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Paper,
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { getFeedbacks, getFeedbackTypes } from "apis/apis";
import { markFeedbackAsNoted, deleteFeedback } from "apis/apis";
import { useNavigate } from "react-router-dom";
import { lighten, darken } from "@mui/system";
import Masonry from "react-masonry-css";
import "./masonry.css";

const DevFeedback = () => {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeType, setActiveType] = useState("");
  const [sortOrder, setSortOrder] = useState("newest");
  const [openDialog, setOpenDialog] = useState(false);
  const [feedbackToDelete, setFeedbackToDelete] = useState(null);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    fetchFeedbacks();
    fetchFeedbackTypes();
  }, []);

  const fetchFeedbacks = async () => {
    const data = await getFeedbacks(
      token,
      setErrorMessage,
      () => setLoading(false),
      navigate
    );

    if (data) {
      setFeedbacks(data);
    }
    setLoading(false);
  };

  const fetchFeedbackTypes = async () => {
    try {
      const types = await getFeedbackTypes(token, setErrorMessage, navigate);
      setFeedbackTypes(types);
    } catch (error) {
      console.error("Failed to fetch feedback types:", error);
    }
  };

  const getColorStyles = (color) => {
    if (!color)
      return { color: "#333", borderColor: "#333", backgroundColor: "#E0E0E0" };

    const textColor =
      theme.palette.mode === "light" ? darken(color, 0.3) : lighten(color, 0.1);
    const borderColor =
      theme.palette.mode === "light" ? darken(color, 0.3) : lighten(color, 0.1);
    const backgroundColor =
      theme.palette.mode === "light" ? lighten(color, 0.4) : darken(color, 0.4);

    return {
      color: textColor,
      borderColor: borderColor,
      backgroundColor: backgroundColor,
    };
  };

  const handleTypeClick = (type) => {
    setActiveType(type === activeType ? "" : type);
  };

  const handleSortOrderChange = () => {
    setSortOrder((prevOrder) => (prevOrder === "newest" ? "oldest" : "newest"));
  };

  const handleDelete = async (id) => {
    await deleteFeedback(id, token, setErrorMessage, () => {}, navigate);
    fetchFeedbacks();
    handleCloseDialog();
  };

  const handleOpenDialog = (id) => {
    setFeedbackToDelete(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFeedbackToDelete(null);
  };

  const handleMarkAsNoted = async (id) => {
    await markFeedbackAsNoted(id, token, setErrorMessage, () => {}, navigate);
    fetchFeedbacks();
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const filteredFeedbacks = activeType
    ? feedbacks.filter((feedback) => feedback.feedback_type === activeType)
    : feedbacks;

  const sortedFeedbacks = filteredFeedbacks.sort((a, b) => {
    if (sortOrder === "newest") {
      return new Date(b.created_at) - new Date(a.created_at);
    } else {
      return new Date(a.created_at) - new Date(b.created_at);
    }
  });

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
  };

  return (
    <Box sx={{ maxWidth: "100%", margin: "0", padding: "16px" }}>
      <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
        {feedbackTypes.map((type) => {
          const isActive = activeType === type.value;
          const colorStyles = getColorStyles(type.color);

          const backgroundColor = isActive
            ? colorStyles.backgroundColor
            : theme.palette.mode === "light"
            ? lighten(colorStyles.backgroundColor, 0.5)
            : darken(colorStyles.backgroundColor, 0.6);

          const textColor = isActive
            ? colorStyles.color
            : theme.palette.mode === "light"
            ? darken(colorStyles.color, 0.2)
            : lighten(colorStyles.color, 0.7);

          return (
            <Button
              key={type.value}
              onClick={() => handleTypeClick(type.value)}
              sx={{
                borderRadius: "4px",
                padding: "4px 8px",
                fontSize: "14px",
                fontWeight: "bold",
                color: textColor,
                border: `2px solid ${colorStyles.borderColor}`,
                backgroundColor: backgroundColor,
                display: "inline-block",
                marginBottom: "0.5rem",
                "&:hover": {
                  color: colorStyles.color,
                  border: `2px solid ${colorStyles.borderColor}`,
                  backgroundColor: colorStyles.backgroundColor,
                },
                opacity: isActive ? 1 : 0.5,
              }}
            >
              {type.display_name}
            </Button>
          );
        })}
        <Button
          onClick={handleSortOrderChange}
          sx={{
            marginLeft: "auto",
            marginBottom: "0.5rem",
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
            color: theme.palette.mode === "light" ? light.text : dark.text,
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : dark.border
            }`,
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.secondary
                  : dark.secondary,
              color: theme.palette.mode === "light" ? light.text : dark.text,
            },
          }}
        >
          {sortOrder === "newest" ? "Newest to Oldest" : "Oldest to Newest"}
        </Button>
      </Box>

      {sortedFeedbacks.length === 0 ? (
        <Typography
          align="center"
          sx={{
            color: theme.palette.mode === "light" ? light.text : dark.text,
            marginTop: "20px",
          }}
        >
          No feedback found.
        </Typography>
      ) : (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {sortedFeedbacks.map((feedback, index) => (
            <Paper
              key={index}
              elevation={2}
              sx={{
                padding: "20px",
                backgroundColor:
                  theme.palette.mode === "light" ? light.primary : dark.primary,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
                marginBottom: "2rem",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  flexWrap: "nowrap",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                    fontSize: "1.2rem",
                    flexGrow: 1,
                    wordBreak: "break-word",
                  }}
                >
                  {feedback.subject}
                </Typography>
                <Box
                  sx={{
                    borderRadius: "4px",
                    padding: "4px 16px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: getColorStyles(feedback.color, true).color,
                    border: `2px solid ${
                      getColorStyles(feedback.color, true).borderColor
                    }`,
                    backgroundColor: getColorStyles(feedback.color, true)
                      .backgroundColor,
                    whiteSpace: "nowrap",
                    overflow: "visible",
                  }}
                >
                  {feedback.feedback_type}
                </Box>
              </Box>

              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                  fontSize: "1rem",
                  marginTop: "1.5rem",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                  wordBreak: "break-word",
                }}
              >
                {feedback.description}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1.5rem",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.text2 : dark.text2,
                    fontSize: "0.8rem",
                  }}
                >
                  Reported by <strong>{feedback.employee_full_name}</strong> at{" "}
                  <strong>
                    {new Date(feedback.created_at).toLocaleDateString()}
                  </strong>
                </Typography>

                <Box sx={{ display: "flex", gap: "8px" }}>
                  <Button
                    onClick={() => handleMarkAsNoted(feedback.id)}
                    variant="contained"
                    startIcon={feedback.is_noted ? <CheckIcon /> : null}
                    sx={{
                      backgroundColor: feedback.is_noted ? "green" : "blue",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: feedback.is_noted
                          ? "darkgreen"
                          : "darkblue",
                      },
                    }}
                  >
                    {feedback.is_noted ? "Noted" : "Mark as Noted"}
                  </Button>
                  <IconButton
                    onClick={() => handleOpenDialog(feedback.id)}
                    color="error"
                    size="medium"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          ))}
        </Masonry>
      )}

      {/* Confirmation Dialog for Deletion */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
            color: theme.palette.mode === "light" ? light.text : dark.text,
          }}
        >
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            Are you sure you want to delete this feedback? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
          }}
        >
          <Button
            onClick={handleCloseDialog}
            color="primary"
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : dark.border
              }`,
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(feedbackToDelete)}
            color="error"
            autoFocus
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : dark.border
              }`,
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DevFeedback;
