import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Box, Divider } from "@mui/material";
import { DataGrid, GridToolbar, GridPagination } from "@mui/x-data-grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import { List, ListItem, ListItemText } from "@mui/material";
import formatDate from "utils";

import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";

const PAGE_SIZE = 5;
const TaskLister = ({ tasks, is_assigner, is_assignee }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [blink, setBlink] = useState(false); // State to toggle blinking effect

  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const theme = useTheme();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // Toggle the blinking effect every 500ms
    const interval = setInterval(() => {
      setBlink((prev) => !prev);
    }, 750);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []); // Run effect only once on component mount

  const modifiedTasks = tasks.map((task, index) => ({
    ...task,
    order: index + 1, // Increment the ID
    project_name:
      task.related_kanban_card.related_work_space.name.split(" ")[0],
    assigner: task.assigner
      ? task.assigner.first_name + " " + task.assigner.last_name
      : "",
    assignee: task.assignee
      ? task.assignee.first_name + " " + task.assignee.last_name
      : "",
    start_date: task.start_date,
    estimated_end_date: task.estimated_end_date,
  }));

  const handleRowClick = (params) => {
    setSelectedTask(params.row);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedTask(null);
  };

  const filteredTasks = modifiedTasks.filter((task) => {
    return {
      ...task,
      assigner: task.assigner ? task.assigner.first_name : "",
    };
  });

  const initialColumns = [
    {
      field: "order",
      headerName: "ID",
      flex: 1,
      editable: true,
      headerAlign: "center",
    },
    {
      field: "project_name",
      headerName: "Project",
      flex: 2,
      editable: true,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      editable: true,
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      editable: true,
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 3,
      editable: true,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div className={`status ${params.value.toLowerCase()}`}>
            <span className="status-indicator"></span>
          </div>
        </Tooltip>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 3,
      editable: true,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div className={`type ${params.value.toLowerCase()}`}>
            <span className="type-indicator"></span>
          </div>
        </Tooltip>
      ),
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 3,
      editable: true,
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div className={`priority ${params.value.toLowerCase()}`}>
            <span className="priority-indicator"></span>
          </div>
        </Tooltip>
      ),
    },
    {
      field: "start_date",
      headerName: "Start Date",
      flex: 3,
      editable: true,
      headerAlign: "center",
      renderCell: (params) => {
        const dateString = params.value;
        if(dateString === null) return null;

        // Parse the date string 'DD/MM/YYYY' into a Date object
        const startDate = new Date(dateString);
        // Check if startDate is a valid Date object
        if (isNaN(startDate.getTime())) {
          console.error(`Invalid date format for: ${dateString}`);
          return null; // Return null or placeholder for invalid date
        }
        return formatDate(params.value);
      },
    },
    {
      field: "estimated_end_date",
      headerName: "Due Date",
      flex: 3,
      editable: true,
      headerAlign: "center",
      renderCell: (params) => {
        const dateString = params.value;
        if(dateString === null) return null;

        const currentDate = new Date(); // Current date
        const dueDate = new Date(dateString);

        // Check if dueDate is a valid Date object
        if (isNaN(dueDate.getTime())) {
          console.error(`Invalid date format for: ${dateString}`);
          return null; // Return null or placeholder for invalid date
        }

        // Calculate time difference in milliseconds
        const timeDifference = dueDate.getTime() - currentDate.getTime();
        // Determine which icon to render based on the time difference
        let iconComponent;
        let tooltipTitle;
        let customStyle = {
          color: "inherit",
          display: "flex",
          alignItems: "center",
        };
        if (timeDifference < 0) {
          // Date has passed (less than 0)
          iconComponent = (
            <HourglassFullIcon sx={{ color: blink ? "red" : "inherit" }} />
          );
          tooltipTitle = "Date has passed!";
          customStyle = {
            color: blink ? "#ff6e6e" : "inherit",
            display: "flex",
            alignItems: "center",
          };
        } else if (timeDifference < 86400000 * 1) {
          // Less than 3 days remaining (less than 3 days in milliseconds)
          iconComponent = <HourglassBottomIcon sx={{ color: "orange" }} />;
          tooltipTitle = "Less than 1 day remaining";
          customStyle = {
            color: "#ff6e6e",
            display: "flex",
            alignItems: "center",
          };
        } else if (timeDifference < 86400000 * 3) {
          // Less than 3 days remaining (less than 3 days in milliseconds)
          iconComponent = <HourglassBottomIcon sx={{ color: "orange" }} />;
          tooltipTitle = "Less than 3 days remaining";
        } else {
          // More than 3 days remaining
          iconComponent = <HourglassEmptyIcon sx={{ color: "green" }} />;
          tooltipTitle = "More than 3 days remaining";
        }
        // Render the icon component wrapped in a Tooltip
        return (
          <Tooltip title={tooltipTitle}>
            <div style={customStyle}>
              <div>{formatDate(params.value)}</div>
              {iconComponent}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  if (is_assigner) {
    initialColumns.push({
      field: "assigner",
      headerName: "Assigner",
      flex: 3,
      editable: true,
      headerAlign: "center",
    });
  }

  if (is_assignee) {
    initialColumns.push({
      field: "assignee",
      headerName: "Assignee",
      flex: 3,
      editable: true,
      headerAlign: "center",
    });
  }

  return (
    <Box>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="task-detail-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="task-detail-dialog-title">
          {selectedTask?.name}
        </DialogTitle>
        <DialogContent dividers>
          <ListItem>
            <ListItemText
              primary="Related Project"
              secondary={
                selectedTask?.related_kanban_card.related_work_space.name.split(
                  " "
                )[0] || "N/A"
              }
            />
          </ListItem>

          {selectedTask?.description && (
            <>
              <Typography
                gutterBottom
                variant="subtitle1"
                sx={{
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                }}
              >
                Description:
              </Typography>
              <Typography
                paragraph
                variant="body2"
                color="textSecondary"
                sx={{
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                }}
              >
                {selectedTask?.description}
              </Typography>
              <Divider />
            </>
          )}

          {/* Example: Adding more content */}
          <List
            sx={{
              maxHeight: 300,
            }}
            dense
          >
            <Divider />
            <ListItem>
              <ListItemText
                primary="Start Date"
                secondary={formatDate(selectedTask?.start_date) || "N/A"}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Estimated End Date"
                secondary={
                  formatDate(selectedTask?.estimated_end_date) || "N/A"
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Priority"
                secondary={selectedTask?.priority || "Normal"}
              />
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemText
                primary="Status"
                secondary={selectedTask?.status || "N/A"}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Type"
                secondary={selectedTask?.type || "N/A"}
              />
            </ListItem>
            <Divider />

            {/* Add more list items as needed */}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "light" ? light.primary : dark.primary,
          padding: 2,
          maxWidth: "100%",
          position: "relative",
          height: "100%",
        }}
      >
        <CardContent>
          {is_assigner && (
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: theme.palette.mode === "light" ? light.text : dark.text,
              }}
            >
              Your Tasks
            </Typography>
          )}

          {is_assignee && (
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: theme.palette.mode === "light" ? light.text : dark.text,
              }}
            >
              Tasks You Have Assigned to Others
            </Typography>
          )}

          <Divider sx={{ marginY: 2 }} />
          <Box
            sx={{
              "& .MuiDataGrid-root": {
                borderColor:
                  theme.palette.mode === "light" ? light.border : dark.border,
              },
              "& .MuiDataGrid-cell": {
                borderColor:
                  theme.palette.mode === "light" ? light.border : dark.border,
              },
              "& .MuiDataGrid-columnHeaders": {
                borderColor:
                  theme.palette.mode === "light" ? light.border : dark.border,
              },
              "& .MuiDataGrid-footerContainer": {
                borderColor:
                  theme.palette.mode === "light" ? light.border : dark.border,
              },
              "& .MuiTablePagination-toolbar": {
                alignItems: "baseline",
                marginTop: 2,
              },
              // Status styles
              ".status, .type , .priority": {
                display: "flex",
                justifyContent: "center",
                width: "100%",
              },

              ".status-indicator, .type-indicator, .priority-indicator": {
                width: 15,
                height: 15,
                borderRadius: "20%", // Circular shape
              },

              "& .status.approved .status-indicator": {
                backgroundColor: "#4CAF50 ",
              },
              "& .status.todo .status-indicator": {
                backgroundColor: "#2196F3 ",
              },
              "& .status.done .status-indicator": {
                backgroundColor: "#9E9E9E ",
              },
              "& .status.in_progress .status-indicator": {
                backgroundColor: "#FF9800 ",
              },

              "& .type.design .type-indicator": {
                backgroundColor: "#9C27B0 ",
              },
              "& .type.feature .type-indicator": {
                backgroundColor: "#00BCD4 ",
              },
              "& .type.revision .type-indicator": {
                backgroundColor: "#E91E63 ",
              },

              "& .priority.low .priority-indicator": {
                backgroundColor: "#E0E0E0",
              },
              "& .priority.medium .priority-indicator": {
                backgroundColor: "#FFEB3B ",
              },
              "& .priority.high .priority-indicator": {
                backgroundColor: "#F44336",
              },
            }}
          >
            <DataGrid
              sx={{
                height: "900px",
              }}
              rows={modifiedTasks}
              columns={initialColumns}
              autoHeight
              page={currentPage}
              pageSize={PAGE_SIZE}
              showCellVerticalBorder={true}
              components={{
                Toolbar: GridToolbar,
                Pagination: GridPagination,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                filter: {
                  ...modifiedTasks.initialState?.filter,
                  filterModel: {
                    items: [
                      {
                        field: "status",
                        operator: "isAnyOf",
                        value: ["TODO", "DONE", "IN_PROCESS"],
                      },
                    ],
                  },
                },
              }}
              onPageChange={(params) => {
                handlePageChange(params.page);
              }}
              pageSizeOptions={[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3]}
              onRowClick={handleRowClick}
              isCellEditable={(params) => params.row.status === "APPROVED"}
              getCellClassName={(params) => {
                if (params.field === "status") {
                  if (params.value === "APPROVED") {
                    return "status approved";
                  } else if (params.value === "TODO") {
                    return "status todo";
                  } else if (params.value === "DONE") {
                    return "status done";
                  } else if (params.value === "IN_PROCESS") {
                    return "status in_progress";
                  }
                }
                if (params.field === "type") {
                  if (params.value === "DESIGN") {
                    return "type design";
                  } else if (params.value === "FEATURE") {
                    return "type feature";
                  } else if (params.value === "REVISION") {
                    return "type revision";
                  }
                }
                if (params.field === "priority") {
                  if (params.value === "Low") {
                    return "priority low";
                  } else if (params.value === "Medium") {
                    return "priority medium";
                  } else if (params.value === "High") {
                    return "priority high";
                  }
                }
                if (params.field === "estimated_end_date") {
                  const currentDate = new Date();
                  const dueDate = new Date(params.value);
                  if (dueDate < currentDate) {
                    return "past-due";
                  }
                }
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export { TaskLister };
