// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Overview page components
import Header from "layouts/profile/components/Header";
import axios from "../../axios/index";
import {
  Box,
  Button,
  InputProps,
  Typography,
  TextField,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import PieChart, {
  Series,
  Label,
  Connector,
  SmallValuesGrouping,
  Legend,
} from "devextreme-react/pie-chart";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DateRangePicker } from "react-date-range";
import FilterDialog from "../../components/Report/Dialog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import { handleTokenExpiration } from "../../axios/tokenUtils";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material/";
import { useNavigate } from "react-router-dom";

import { TaskLister } from "components/rekrom/TaskLister/"
import { DayoffLister } from "components/rekrom/DayoffLister/"
import { ProfileInfo } from "components/rekrom/ProfileInfo/"

import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";


const mockdata = 
[
  {
    "id": 58,
    "status": "APPROVED",
    "paid_type": "PAID",
    "location_type": "HOMETOWN",
    "excuse_type": "ANNUAL",
    "day_off_start": "10-10-2023",
    "day_off_end": "15-10-2023",
    "requested_by": {
        "id": 4,
        "employee_name": "Ecrin Yıldız"
    },
    "description": "qwe",
    "send_to": null
  },
  {
    "id": 59,
    "status": "APPROVED",
    "paid_type": "PAID",
    "location_type": "HOMETOWN",
    "excuse_type": "ANNUAL",
    "day_off_start": "10-10-2023",
    "day_off_end": "15-10-2023",
    "requested_by": {
        "id": 4,
        "employee_name": "Ecrin Yıldız"
    },
    "description": "qwe",
    "send_to": null
  },
]

function Overview() 
{
  const navigate = useNavigate();

  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const theme = useTheme();

  const [openDialog, setOpenDialog] = useState(false); // State for dialog open/close
  const [errorMessage, setErrorMessage] = useState(null);
  
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { id } = useParams();
  //const [date, setDate] = useState(1665047001);

  const [resOpen, setResOpen] = useState(false);

  const [profileInformation, setProfileInformation] = useState({});
  const [employeeDataFetched, setEmployeeDataFetched] = useState(false);
  
  const token = sessionStorage.getItem("token");
  const [data, setData] = useState([]);

  const [pieData, setPieData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [totalLoad, setTotalLoad] = useState(0);

  const [expanded, setExpanded] = useState(false);
  const [searchButton, setSearchButton] = useState(false);

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const now = new Date();

  const [state, setState] = useState([
    {
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleButtonClicked = () => {
    setExpanded(!expanded);
    setSearchButton(!searchButton);
    setIsButtonClicked(true); // Set to true when button is clicked
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [tasks, setTasks] = useState([])
  
  const [dayoffs, setDayoffs] = useState(mockdata)

  useEffect(() => {
    const getEmployeeDetail = async (employeeId) => {
      let url = "api/project/get-custom-employee-detail";
      if (employeeId) {
        url += `/${employeeId}`;
      }
      try {
        const res = await axios.post(
          url,
          { startDate: state[0].startDate, endDate: state[0].endDate },
          { headers: { Authorization: token } }
        );
        if (res && res.data) {
          setTotalLoad((totalLoad) => res.data.totalHour);
          setData((prevData) => [...prevData, res.data]);
          for (let i = 0; i < res.data.projects.length; i++) {
            setPieData((oldData) => [
              ...oldData,
              {
                projectName: res.data.projects[i].name,
                totalHour:
                  (res.data.projects[i].totalHour / res.data.totalHour) * 100,
              },
            ]);
            let project = res.data.projects[i];

            for (let j = 0; j < project.workPackages.length; j++) {
              if(project.workPackages[j].totalHour !== 0)
              {
                setTableData((oldTable) => [
                  ...oldTable,
                  {
                    id: project.workPackages[j].id,
                    projectName: project.name,
                    workPackageName: project.workPackages[j].name,
                    totalHour: project.workPackages[j].totalHour,
                  },
                ]);
              }
            }
          }
        }
      } catch (error) {
        handleTokenExpiration(
          error,
          setErrorMessage,
          handleOpenDialog,
          navigate
        );
      }
    };
    const getEmployeeInfo = async (employeeId = null) => {
      let url = "api/users/get-employee-info";
      if (employeeId) {
        url += `/${employeeId}`;
      }
      try {
        const res = await axios.get(url, { headers: { Authorization: token } });
        if (res && res.data) {
          //console.log(res.data)
          setProfileInformation({
            ...profileInformation,
            id : res.data.id,
            firstName: res.data.first_name,
            title: res.data.title,
            lastName: res.data.last_name,
            phoneNumber: res.data.phone_number,
            email: res.data.email,
            remainingDayOff: res.data.remaining_day_off,
            image: axios.defaults.baseURL + res.data.profile_image,
            admission_date: res.data.admission_date,
            graduated_university: res.data.graduated_university,
            graduation_date: res.data.graduation_date,
          });
          setEmployeeDataFetched(true);
        }
      } catch (error) {
        handleTokenExpiration(
          error,
          setErrorMessage,
          handleOpenDialog,
          navigate
        );
      }
    };
    const getTasks = async (employeeId) => {
      try
      {
        let url = "api/project/fetch-user-assigned-tasks"
        if(employeeId)
        {
          url += `/${employeeId}`;
        }
        
          const res =  await axios.get(url, 
          {
              headers: {
                  Authorization: token,
              },
          })
          if (res && res.data) {
              setTasks(res.data);
          }
      }
      catch (error) 
      {
          handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
      }
  }
    const getDayoffs = async (employeeId) => {
      try
      {
        let url = "api/users/fetch-user-day-offs"
        if(employeeId)
        {
          url += `/${employeeId}`;
        }
        const res =  await axios.get(url, 
        {
            headers: {
                Authorization: token,
            },
        })
        if (res && res.data) {
            setDayoffs(res.data);
        }
      }
      catch (error) 
      {
          handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
      }
  }

    const fetchEmployeeData = async (employeeId) => {
      setData([]);
      setPieData([]);
      setTableData([]);

      await Promise.all([
        getEmployeeInfo(employeeId),
        getEmployeeDetail(employeeId),
        getTasks(employeeId),
        getDayoffs(employeeId),
      ]);
    };

    if (id && id.localeCompare(":id")) {
      fetchEmployeeData(id);
    } else {
      fetchEmployeeData();
    }
  }, [id, searchButton]);

  const [selectedSlice, setSelectedSlice] = useState("");
  //const [viewAvailable, setViewAvailable] = useState(false);

  const onPointClick = ({ target: point }) => {
    point.select();
    setSelectedSlice((selectedSlice) => point.data.name);
  };

  const columns = [
    {
      field: "projectName",
      headerName: "Project",
      width: 200,
      editable: false,
    },
    {
      field: "workPackageName",
      headerName: "Work Package",
      width: 200,
      editable: false,
    },
    {
      field: "totalHour",
      headerName: "Total Hour",
      type: "number",
      width: 160,
      editable: false,
    },
  ];

  /*
  const showOnlyNonZeros = () => 
    {
        return true;
    }
  */

  const formatLabel = (arg) => {
    return `${arg.argumentText}: ${arg.valueText}%`;
  };

  /*const useLinkClickHandler = (e) => {
        e.preventDefault()
        
    }*/

  const [tab, setTab] = useState(1);

  const handleTabClick = (value) => {
    setTab(value);
  };

  return (
    <>
      {/* Your other JSX components */}
      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Error</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {employeeDataFetched && (
        <>
          <Header
            image={profileInformation.image}
            tabClick={handleTabClick}
            name={profileInformation.firstName}
            lastName={profileInformation.lastName}
            title={profileInformation.title}
          />
            <MDBox mt={5} mb={3} sx={{maxWidth:"95%", marginLeft:"48px", 
                                      backgroundColor: theme.palette.mode === 'light' ? light.secondary : dark.secondary}}>
              {tab === 0 ? (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center", // This centers the content horizontally
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 2,
                      }}
                    >
                      <Box sx={{}}>
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChange("panel1")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Grid
                              container
                              spacing={2}
                              xs={12}
                              md={12}
                              lg={12}
                              direction="column"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center", // This centers the content horizontally
                              }}
                            >
                              <Grid
                                item
                                sx={{
                                  marginBottom: "-20px",
                                }}
                              >
                                <Typography sx={{ width: "100%", flexShrink: 0 }}>
                                  Choose another time interval
                                </Typography>
                              </Grid>
                              <Grid item sx={{}}>
                                <Typography
                                  sx={{
                                    fontWeight: "300",
                                  }}
                                >
                                  ({state[0].startDate.toLocaleDateString()} -{" "}
                                  {state[0].endDate.toLocaleDateString()})
                                </Typography>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            {/*<form>*/}
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item>
                                <DateRangePicker
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={2}
                                  ranges={state}
                                  label="username"
                                  name="username"
                                  variant="outlined"
                                  onChange={(item) => setState([item.selection])}
                                  value={state}
                                  direction="horizontal"
                                />
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  sx={{
                                    color: "#fff",
                                  }}
                                  onClick={handleButtonClicked}
                                >
                                  Search
                                </Button>
                              </Grid>
                            </Grid>
                            {/*</form>*/}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                      <Box>{/*<FilterDialog/>*/}</Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center", // This centers the content horizontally
                    }}
                  ></Grid>
                  <Grid item xs={12} md={4} xl={6} sx={{ display: "flex" }}>
                    <Divider orientation="vertical" sx={{ mx: 2 }} />
                    <Grid container spacing={1}>
                      <Grid item>
                        <PieChart
                          id="pie"
                          dataSource={pieData}
                          palette="Bright"
                          title="Your total workload"
                          height="440px"
                          onPointClick={onPointClick}
                        >
                          <Series
                            argumentField="projectName"
                            valueField="totalHour"
                          >
                            <Label
                              visible={true}
                              customizeText={formatLabel}
                              format="fixedPoint"
                            >
                              <Connector visible={true} width={0.5} />
                            </Label>
                            <SmallValuesGrouping
                              threshold={1}
                              mode="smallValueThreshold"
                            />
                          </Series>
                          <Legend
                            visible={false}
                            orientation="row"
                            verticalAlignment="bottom"
                          />
                        </PieChart>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Divider orientation="vertical" sx={{ mx: 2 }} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={8} xl={6}>
                    <Box sx={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={tableData}
                        columns={columns}
                        pageSize={5}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                      />
                    </Box>
                  </Grid>
                
                </Grid>
                
              ) : (
                <>
                    <Grid>
                          <ProfileInfo profileInformation={profileInformation} />
                    </Grid>
                    <Grid style={{marginTop:"10px", padding:"20px", 
                          background: theme.palette.mode === 'light' ? light.secondary : dark.secondary}}>
                      <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor={'primary'}
                        textColor={'primary'}
                        centered
                        aria-label="secondary tabs example"
                      >
                        <Tab label="Tasks" />
                        <Tab label="Day offs" />
                      </Tabs>
                      {selectedTab === 0 && <TaskLister tasks={tasks}/>} {/* Render DayOffForms component when selectedTab is 0 */}
                      {selectedTab === 1 && <DayoffLister dayoffs={dayoffs} />} {/* Render PurchasingForms component when selectedTab is 1 */}
                    </Grid>
                </>
              )}
            </MDBox>
        </>
      )
      }
    </>
  );
}

export default Overview;
