import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  ViewDirective,
  ViewsDirective,
  TimelineViews,
  
} from "@syncfusion/ej2-react-schedule";
import {
  DateTimePickerComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  TextareaAutosize
} from "@mui/material";

import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "../../../axios";

import { handleTokenExpiration } from "../../../axios/tokenUtils";

const CustomSchedule = (props) => {
  const navigate = useNavigate();
  const scheduleRef = useRef(null);
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Set the selected date to 10th July 2023
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [dialogTimeOpen, setDialogTimeOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // State for dialog open/close
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProjectsDataFetched, setIsProjectsDataFetched] = useState(false);
  const [isUserSlotsDataFetched, setIsUserSlotsDataFetched] = useState(false);
  const token = sessionStorage.getItem("token");

  const getUserSlots = async () => {
    try {
      const res = await axios.get("api/project/get-user-slots", {
        headers: {
          Authorization: token,
        },
      });
      if (res && res.data) {
        setIsUserSlotsDataFetched(true);
        setData([]);
        for (let i = 0; i < res.data.length; i++) {
          setData((oldData) => [
            ...oldData,
            {
              Id: res.data[i].id,
              IsBlock: res.data[i].IsBlock,
              Subject: res.data[i].projectWorkPackage,
              StartTime: res.data[i].startDate,
              EndTime: res.data[i].endDate,
              description: res.data[i].description,
              totalhour: res.data[i].totalhour,
            },
          ]);
        }
      }
    } catch (error) {
      handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
  };
  const getWorkPackages = async () => {
    try {
      const res = await axios.get(
        "api/users/get-employee-project-work-package",
        {
          headers: {
            Authorization: token,
          },
          params: {
            is_only_active: true,
          },
        }
      );
      setIsProjectsDataFetched(true);
      if (res && res.data) {
        
        setProjects(res.data);
      }
    } catch (error) {
      handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
  };

  useEffect(() => {
    getUserSlots();
    getWorkPackages();
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const onNavigate = (args) => {
    setSelectedDate(args.currentDate);
  };

  const getProjectIdByName = (combinedName) => {
    if (typeof combinedName === "string") {
      const [projectName, workPackageName] = combinedName.split(" - ");
      const project = projects.find(
        (project) => project.name === projectName.trim()
      );
      let workPackage = {};
      if (project) {
        project.work_packages.forEach((wp) => {
          if (wp.name === combinedName) {
            workPackage = wp;
          }
        });
        return workPackage ? workPackage.id : "";
      }
      return "";
    } else return combinedName;
  };

  const getProjectNameById = (id) => {
    if (typeof id === "number") {
      const project = projects.find((project) => project.id === id);
      return project ? project.name : "";
    } else return id;
  };

  const onActionBegin = (args) => {
    if (args.data) {
      let data;
      if (args.requestType === "eventCreate") {
        data = args.data[0];
        const related_workpackage_id = getProjectIdByName(data.Subject).slice(
          0,
          -1
        );

        const timeSlot = {
          id: data.Id,
          startDate: data.StartTime,
          endDate: data.EndTime,
          related_workpackage_id: related_workpackage_id,
          description: data.Description,
        };

        if (!data.Description || data.Description.trim() === "") {
          args.cancel = true;
          setErrorDialogOpen(true);
        } else if (data.StartTime > data.EndTime) {
          args.cancel = true;
          setDialogTimeOpen(true);
        } else {
          const isSlotAvailable = scheduleRef.current.isSlotAvailable(
            timeSlot.startDate,
            timeSlot.endDate
          );

          if (isSlotAvailable) {
            saveTimeSlot(timeSlot);
          } else {
            args.cancel = true;
            setDialogOpen(true);
          }
        }
      } else if (args.requestType === "eventRemove") {
        data = args.data[0];
        const deletedTimeSlot = {
          id: data.Id,
        };
        deleteTimeSlot(deletedTimeSlot);
      } else if (args.requestType === "eventChange") {
        data = args.data;
        const updatedTimeSlot = {
          id: data.Id,
          startDate: data.StartTime,
          endDate: data.EndTime,
          related_workpackage: getProjectIdByName(data.Subject).slice(0, -1),
          description: data.Description,
        };
        if (!data.Description || data.Description.trim() === "") {
          args.cancel = true;
          setErrorDialogOpen(true);
        } else if (data.StartTime > data.EndTime) {
          args.cancel = true;
          setDialogTimeOpen(true);
        } else {
          const isSlotAvailable = scheduleRef.current.isSlotAvailable(
            updatedTimeSlot.startDate,
            updatedTimeSlot.endDate
          );

          if (isSlotAvailable) {
            updateTimeSlot(updatedTimeSlot);
          } else {
            args.cancel = true;
            setDialogOpen(true);
          }
        }
      }
    }
  };
  const dataBound = () => {
    if (scheduleRef && scheduleRef.current) {
      const currentHour = new Date().getHours();
      const formattedHour =
        currentHour < 10 ? `0${currentHour}:00` : `${currentHour}:00`;
      scheduleRef.current.scrollTo(formattedHour);
    }
  };
  const DateHeaderTemplate = (props) => {
    const { date } = props;

    let workloadHours = 0;

    const targetDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .substring(0, 10);
    for (let i = 0; i < data.length; i++) {
      let datadate = new Date(data[i].StartTime);
      // Compare formatted strings instead of Date objects
      let formattedDataDate = datadate.toISOString().substring(0, 10);
      if (formattedDataDate === targetDate) {
        workloadHours += data[i].totalhour;
      }
    }
    return (
      <div>
        <div>
          {getDateHeaderDate(date)} - {getDateHeaderDay(date)}
        </div>
        <div className="date-text">Total: {workloadHours.toFixed(1)} hours</div>
      </div>
    );
  };
  function getDateHeaderDay(value) {
    return new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(value);
  }

  function getDateHeaderDate(value) {
    return new Intl.DateTimeFormat("en-US", { day: "2-digit" }).format(value);
  }

  function editorTemplate(props) {
    
    let slotId = 0;

    if (props.Id) {
      slotId = props.Id;
    }

    const result = data.filter((slot) => {
      return slot.Id === slotId;
    });

    if (result[0]) {
      let subject = result[0].Subject;
      subject = subject.replaceAll(":", "-");
      for (let i = 0; i < projects.length; i++) {
        let el = projects[i];
        for (let j = 0; j < el.work_packages.length; j++) {
          let el2 = el.work_packages[j];
          if (el2.name === subject) {
            slotId = el2.id;
            break;
          }
        }
      }
    }
    let fields = {
      dataSource: projects,
      value: "id",
      text: "name",
      child: "work_packages",
    };
    const dropdownTreeRef = React.createRef();

    const onChange = (args) => {
      const selectedNode = dropdownTreeRef.current.treeObj.selectedNodes;
      if (selectedNode.length > 0) {
        const selectedNodeFull = dropdownTreeRef.current.treeObj.getNode(
          selectedNode[0]
        );
        
        if (selectedNodeFull.parentID === null) {
          dropdownTreeRef.current.treeObj.expandAll([selectedNode[0]]);
          dropdownTreeRef.current.value = null;
          dropdownTreeRef.current.hidePopup();
          alert("Please expand the project to select a work package");
          // setOpenTreeDialog(true); // Open the error dialog
          setTimeout(() => {
            dropdownTreeRef.current.showPopup();
          }, 0);
        }
      }
    };

    return props !== undefined ? (
      <>
      <table
        className="schedule-dialog"
        style={{ width: "100%" }}
        cellPadding={5}
      >
        <tbody>
          <tr>
            <td className="e-textlabel">Project</td>
            <td colSpan={4}>
              <DropDownTreeComponent
                id="Project"
                ref={dropdownTreeRef}
                placeholder="Çalıştığın Proje"
                data-name="Subject"
                className="e-field"
                style={{ width: "100%" }}
                enabled="false"
                expandOn={true}
                fields={fields}
                showDropDownIcon="false"
                ensureVisible={true}
                change={onChange}
                value={(() => {
                  return [slotId.toString()];
                })()}
              ></DropDownTreeComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Start Time</td>
            <td colSpan={4}>
              <DateTimePickerComponent
                id="StartTime"
                format="dd/MM/yy hh:mm a"
                data-name="StartTime"
                value={new Date(props.startTime || props.StartTime)}
                className="e-field"
              ></DateTimePickerComponent>
            </td>
          </tr>

          <tr>
            <td className="e-textlabel">End Time</td>
            <td colSpan={4}>
            <DateTimePickerComponent
              id="EndTime"
              format="dd/MM/yy hh:mm a"
              data-name="EndTime"
              value={new Date(props.endTime || props.EndTime)}
              className="e-field"
            ></DateTimePickerComponent>
            </td>
          </tr>

          <tr>
            <td className="e-textlabel">Description</td>
            <td colSpan={4}>
              <textarea
                id="Description"
                className="e-field e-input"
                name="Description"
                data-name="Description"
                placeholder="Bugün Rekrom için ne yaptın?"
                rows={3}
                cols={50}
                style={{
                  width: "100%",
                  height: "60px !important",
                  resize: "vertical",
                }}
              >
                {props.description}
              </textarea>
            </td>
          </tr>
        </tbody>
      </table>
      </>
    ) : (
      <div></div>
    );
  }

  const eventTemplate = (props) => {
    const projectName = getProjectNameById(props.Subject);
    return (
      <div className="custom-event">
        <div className="custom-event-subject">{projectName}</div>
        <div className="custom-event-time">
          {new Intl.DateTimeFormat(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          }).format(props.StartTime)}{" "}
          -{" "}
          {new Intl.DateTimeFormat(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          }).format(props.EndTime)}
        </div>
      </div>
    );
  };

  const popUpHeader = (props) => {
    return (
      <div>
        {props.elementType === "cell" ? (
          <div className="e-cell-header">
            <div className="e-header-icon-wrapper">
              <button className="e-close" title="Close"></button>
            </div>
          </div>
        ) : (
          <Card>
            <Card>
              <CardHeader title={getProjectNameById(props.Subject)} />
            </Card>
          </Card>
        )}
      </div>
    );
  };

  const popUpContent = (props) => {
    
    let fields = {
      dataSource: projects,
      value: "id",
      text: "name",
      child: "work_packages",
    };
    const dropdownTreeRef = React.createRef();

    const onChange = (args) => {
      const selectedNode = dropdownTreeRef.current.treeObj.selectedNodes;
      if (selectedNode.length > 0) {
        const selectedNodeFull = dropdownTreeRef.current.treeObj.getNode(
          selectedNode[0]
        );
        
        if (selectedNodeFull.parentID === null) {
          
          dropdownTreeRef.current.treeObj.expandAll([selectedNode[0]]);
          dropdownTreeRef.current.value = null;
          dropdownTreeRef.current.hidePopup();
          alert("Please expand the project to select a work package");
          // setOpenTreeDialog(true); // Open the error dialog
          setTimeout(() => {
            dropdownTreeRef.current.showPopup();
          }, 0);

          requestAnimationFrame(() => {
            dropdownTreeRef.current.showPopup();
          });
        }
      }
    };
    return (
      <div>
        {props.elementType === "cell" ? (
          <div className="e-cell-content">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DropDownTreeComponent
                  id="Project"
                  ref={dropdownTreeRef}
                  placeholder="Çalıştığın Proje"
                  data-name="Subject"
                  className="e-field"
                  style={{ width: "100%" }}
                  enabled="false"
                  expandOn={true}
                  fields={fields}
                  showDropDownIcon="false"
                  ensureVisible={true}
                  change={onChange}
                ></DropDownTreeComponent>
              </Grid>
              <Grid item xs={12}>
                <textarea
                  required
                  id="Description"
                  className="e-field e-input"
                  name="Description"
                  data-name="Description"
                  placeholder="Bugün Rekrom için ne yaptın?"
                  rows={3}
                  cols={50}
                  style={{
                    width: "100%",
                    height: "60px !important",
                    resize: "vertical",
                  }}
                ></textarea>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Card
              style={{
                width: "100%",
                height: "30px !important",
                resize: "vertical",
              }}
            >
              <CardHeader
                subheader={`Total Hour: ${(
                  (props.EndTime - props.StartTime) /
                  3600000
                ).toFixed(1)}`}
              />
              <CardContent>
                <Typography variant="body1" component="p">
                  Description:{" "}
                  {props.description
                    ? props.description
                    : "No description provided."}
                </Typography>
              </CardContent>
            </Card>
            <Button onClick={() => copyTimeSlot(props)}>Copy This Slot</Button>
          </Box>
        )}
      </div>
    );
  };


    function addDays(date, days) 
    {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
    }


    const saveTimeSlot = async (timeSlot) => {
    try
    {
        const res = await axios
        .post("api/project/submit-slot", timeSlot, {
            headers: {
                Authorization: token,
            },
        })
            if (res && res.data) {
                getUserSlots();
        }
    }
    catch(error)
    {
        handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
    };

    const updateTimeSlot = async (updatedTimeSlot) => {
        try
        {
            const res =  await axios
            .post("api/project/update-slot", updatedTimeSlot, {
                headers: {
                    Authorization: token,
                },
            })
                if (res && res.data) {
                    
                    getUserSlots();
                }
        }
        catch(error)
        {
            handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
        }
    };

    const deleteTimeSlot = async (deleteTimeSlot) => {
        try
        {
            const res = await axios
            .post("api/project/delete-slot", deleteTimeSlot, {
                headers: {
                    Authorization: token,
                },
            })
            if (res && res.data) 
            {
                    getUserSlots();
            }
        }
        catch(error)
        {
            handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
        }
    };

    const copyTimeSlot = async (props) => {
        const copyTimeSlot =
        {
            endDate: props.EndTime,
            startDate: props.StartTime,
            related_workpackage_id: getProjectIdByName(props.Subject).slice(0, -1),
            description: props.description,
        };
        
        copyTimeSlot.startDate = addDays(props.StartTime, 1)
        copyTimeSlot.endDate = addDays(props.EndTime, 1)
        const isSlotAvailable = scheduleRef.current.isSlotAvailable(
            copyTimeSlot.startDate,
            copyTimeSlot.endDate
        );
        
        if (isSlotAvailable) {
            saveTimeSlot(copyTimeSlot);
        }
    }

    

    const handleDialogClose = () => {
      setDialogOpen(false);
  };

  const handleTimeDialogClose= () => {
      setDialogTimeOpen(false);
  };

  const handleErrorDialogClose = () => {
      setErrorDialogOpen(false);
  };

  return (
    
    <>
    {isProjectsDataFetched && isUserSlotsDataFetched ? (
      <>

    <ScheduleComponent
      ref={scheduleRef}
      eventRendered={(args) => props.onEventRendered(args)}
      width="100%"
      height="85vh"
      selectedDate={selectedDate}
      currentView="Week"
      navigating={onNavigate.bind(this)}
      actionBegin={onActionBegin.bind(this)}
      dataBound={dataBound.bind(this)}
      startHour="00:00"
      endHour="24:00"
      minDate={new Date(2019, 2, 8)}
      firstDayOfWeek={1}
      showWeekNumber={true}
      dateHeaderTemplate={DateHeaderTemplate.bind(this)}
      editorTemplate={editorTemplate.bind(this)}
      eventTemplate={eventTemplate.bind(this)}
      eventSettings={{
        resourceColorField: "red",
        enableTooltip: true,
        template: eventTemplate,
        dataSource: data,
        fields: {
          id: "Id",
          subject: { name: "Subject", title: "whattt" },
          startTime: "StartTime",
          endTime: "EndTime",
          description: { name: "description", title: "Açıklama" },
          workpackage: { name: "Subject", title: "Work Package" }, // Modify this line to map the workpackage field to the name property
        },
      }}
      workDays={[1, 2, 3, 4, 5]}
      workHours={{
        start: "07:00",
        end: "23:00",
        highlight: true,
      }}
      workingHour={{
        start: "08:00",
        end: "18:00",
        isWorking: false,
      }}
      quickInfoTemplates={{
        header: popUpHeader.bind(this),
        content: popUpContent.bind(this),
      }}
      showTimeIndicator={true}
      quickInfoOnSelectionEnd={true}
      allowKeyboardInteraction={true}
    >
      <ViewsDirective>
        <ViewDirective option="Day" />
        <ViewDirective option="Week" />
        <ViewDirective option="WorkWeek" />
        <ViewDirective option="Month" />
        <ViewDirective option="Agenda" />
      </ViewsDirective>
      <Inject services={[Day, Week, WorkWeek, Month, Agenda, TimelineViews]} />
    </ScheduleComponent>
    <Dialog open={errorDialogOpen} onClose={handleErrorDialogClose}>
                        <DialogTitle>{"Error"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please provide a description for the event.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleErrorDialogClose} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={dialogOpen} onClose={handleDialogClose}>
                        <DialogTitle>{"Time Slot Unavailable"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                The selected time slot is already occupied. Please choose another time.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={dialogTimeOpen} onClose={handleTimeDialogClose}>
                        <DialogTitle>{"Invalid time interval"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                start time cannot be later than end time
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleTimeDialogClose} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
    </>
    )
    :
    <div>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
        >
        <CircularProgress/>
        </Box>
    </div>
    }
    </>
  );
};

export { CustomSchedule };
