import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab, Box, CircularProgress } from "@mui/material";
import Changelog from "./ChangeLog/index";
import UserFeedback from "./Feedback/UserFeedback/index";
import DevFeedback from "./Feedback/DeveloperFeedback/index";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { validatePublisherGroup } from "../../apis/apis/index";

const ChangeLogAndFeedback = () => {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const [selectedTab, setSelectedTab] = useState(0);
  const [isMemberOfERPDevTeam, setIsMemberOfERPDevTeam] = useState(false);
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem("token");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const checkUserGroup = async () => {
      if (!token) {
        console.log("No token found");
        setLoading(false);
        return;
      }

      try {
        const response = await validatePublisherGroup(
          token,
          () => {},
          () => {}
        );
        if (response?.detail === "Publisher belongs to 'ERP Dev Team' group.") {
          setIsMemberOfERPDevTeam(true);
          console.log("User is a member of the ERP Dev Team.");
        }
      } catch (error) {
        console.error("Error validating publisher group:", error);
      } finally {
        setLoading(false);
      }
    };

    checkUserGroup();
  }, [token]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box mt={3}>
      <Container maxWidth="md">
        <Tabs
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? light.secondary : dark.secondary,
          }}
          value={selectedTab}
          onChange={handleTabChange}
          centered
        >
          <Tab label="ChangeLog" />
          <Tab label="Feedback" />
        </Tabs>

        <Box mt={3}>
          {selectedTab === 0 && (
            <div>
              <Changelog isMemberOfERPDevTeam={isMemberOfERPDevTeam} />{" "}
              {/* Pass the prop correctly */}
            </div>
          )}
          {selectedTab === 1 && (
            <div>
              {isMemberOfERPDevTeam ? <DevFeedback /> : <UserFeedback />}
            </div>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ChangeLogAndFeedback;
