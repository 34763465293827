import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import ApexCharts from "react-apexcharts";
import {
  fetchSurveys,
  fetchAllSurveyAnswers,
  fetchEmployees,
} from "../../apis/apis/index";

const Summary = ({ onBack, surveyId, surveyTitle }) => {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const [errorMessage, setErrorMessage] = useState("");
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(surveyId || "");
  const [surveySummary, setSurveySummary] = useState(null);
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const token = sessionStorage.getItem("token");

  const processSurveyData = (surveyData) => {
    if (!surveyData || Object.keys(surveyData).length === 0) {
      return {
        totalSubmissions: 0,
        questionSummaries: {},
      };
    }

    const summary = {
      totalSubmissions: surveyData.total_submissions || 0,
      questionSummaries: {},
    };

    Object.entries(surveyData.questions).forEach(([questionId, data]) => {
      summary.questionSummaries[questionId] = {
        questionText: data.question_text,
        totalAnswers: data.total_answers,
        options: data.options,
        type: data.type,
        averageRating: data.average_rating || null,
        textAnswers: data.text_answers || [],
      };
    });

    return summary;
  };

  const handleSurveyChange = (event) => {
    setSelectedSurvey(event.target.value);
  };

  const handleGenerateSummary = async () => {
    if (!selectedSurvey) {
      setErrorMessage("Please select a survey.");
      return;
    }
    try {
      const surveyData = await fetchAllSurveyAnswers(
        token,
        selectedSurvey,
        setErrorMessage
      );
      setIsAnonymous(surveyData.is_anonymous);
      const summary = processSurveyData(surveyData);
      setSurveySummary(summary);
    } catch (error) {
      setErrorMessage("Error fetching survey data: " + error.message);
    }
  };

  console.log("surveySummary", surveySummary);

  const fetchEmployeeData = async () => {
    try {
      const response = await fetchEmployees(token, setErrorMessage, () => {});
      const activeEmployees = response.filter((employee) => employee.is_active);
      setActiveEmployees(activeEmployees || []);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    const fetchSurveysData = async () => {
      try {
        const surveysData = await fetchSurveys(
          token,
          setErrorMessage,
          () => {}
        );
        setSurveys(surveysData);
      } catch (error) {
        setErrorMessage("Error fetching surveys: " + error.message);
      }
    };

    fetchSurveysData();
    fetchEmployeeData();
  }, [token]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Button
          sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? light.buttonBackground
                : dark.buttonBackground,
            color:
              theme.palette.mode === "light"
                ? light.buttonIcon
                : dark.buttonIcon,
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : dark.border
            }`,
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.buttonBackgroundHover
                  : dark.buttonBackgroundHover,
              color:
                theme.palette.mode === "light"
                  ? light.buttonIconHover
                  : dark.buttonIconHover,
              border: `1px solid ${
                theme.palette.mode === "light"
                  ? light.borderHover
                  : dark.borderHover
              }`,
            },
            marginBottom: "20px",
            mr: 1,
          }}
          onClick={onBack}
        >
          Back to Surveys
        </Button>
        <FormControl sx={{ width: "51%", height: "40px" }}>
          <InputLabel id="survey-select-label">Select a survey</InputLabel>
          <Select
            labelId="survey-select-label"
            value={selectedSurvey}
            label="Select a survey"
            onChange={handleSurveyChange}
            sx={{
              height: "40px",
              "& .MuiSelect-select": {
                padding: "16px",
                color: theme.palette.mode === "light" ? light.text : dark.text,
              },
            }}
          >
            {surveys.map((survey) => (
              <MenuItem key={survey.id} value={survey.id}>
                {survey.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          sx={{
            backgroundColor:
              theme.palette.mode === "light"
                ? light.buttonBackground
                : dark.buttonBackground,
            color:
              theme.palette.mode === "light"
                ? light.buttonIcon
                : dark.buttonIcon,
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : dark.border
            }`,
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.buttonBackgroundHover
                  : dark.buttonBackgroundHover,
              color:
                theme.palette.mode === "light"
                  ? light.buttonIconHover
                  : dark.buttonIconHover,
              border: `1px solid ${
                theme.palette.mode === "light"
                  ? light.borderHover
                  : dark.borderHover
              }`,
            },
            height: "40px",
            ml: 1,
          }}
          onClick={handleGenerateSummary}
        >
          Generate Summary
        </Button>
      </Box>

      {errorMessage && (
        <Box sx={{ color: light.error, mb: 2 }}>{errorMessage}</Box>
      )}

      {surveySummary && (
        <Box sx={{ pt: 3 }}>
          {Object.entries(surveySummary.questionSummaries).map(
            ([questionId, data]) => {
              if (data.type === 1 || data.type === 2) {
                const optionCounts = Object.values(data.options).map(
                  (opt) => opt.count
                );
                const maxCountIndex = optionCounts.indexOf(
                  Math.max(...optionCounts)
                );

                const colors = optionCounts.map((count, index) =>
                  index === maxCountIndex ? "#0000FF" : "#0000FF"
                );

                const options = {
                  chart: {
                    type: "bar",
                    horizontal: true,
                    background:
                      theme.palette.mode === "light"
                        ? light.secondary
                        : dark.secondary,
                    toolbar: {
                      show: false,
                    },
                  },
                  xaxis: {
                    categories: Object.keys(data.options),
                    min: 0,
                    max: activeEmployees.length,
                    labels: {
                      style: {
                        colors:
                          theme.palette.mode === "light"
                            ? light.text
                            : dark.text,
                        fontSize: "10px",
                      },
                    },
                  },
                  yaxis: {
                    labels: {
                      style: {
                        colors:
                          theme.palette.mode === "light"
                            ? light.text
                            : dark.text,
                        fontSize: "12px",
                      },
                    },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: true,
                      borderRadius: 2,
                      dataLabels: {
                        position: "top",
                      },
                    },
                  },
                  tooltip: {
                    theme: theme.palette.mode,
                    style: {
                      background:
                        theme.palette.mode === "light"
                          ? light.buttonBackground
                          : dark.buttonBackground,
                      color:
                        theme.palette.mode === "light" ? light.text : dark.text,
                    },
                    custom: function ({
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                    }) {
                      const category = w.globals.labels[dataPointIndex];
                      const employees =
                        data.options[category].employees.join("<br />");
                      return `<div class="tooltip-content" style="
                          color: ${
                            theme.palette.mode === "light"
                              ? "#000000"
                              : dark.text
                          }; 
                          background: ${
                            theme.palette.mode === "light"
                              ? "#ffffff"
                              : dark.buttonBackground
                          };
                          padding: 10px; 
                          font-size: 14px; 
                          border-radius: 8px; 
                          max-width: 300px;
                          white-space: normal;
                        ">
                          <strong>Employees:</strong><br /> ${employees}
                        </div>`;
                    },
                  },
                  colors: colors,
                  dataLabels: {
                    enabled: false,
                  },
                  grid: {
                    xaxis: {
                      lines: {
                        show: true,
                        style: {
                          colors:
                            theme.palette.mode === "light"
                              ? light.border
                              : "#9a9a9a",
                        },
                      },
                    },
                    yaxis: {
                      lines: {
                        show: true,
                        style: {
                          colors:
                            theme.palette.mode === "light"
                              ? light.border
                              : "#9a9a9a",
                        },
                      },
                    },
                    borderColor:
                      theme.palette.mode === "light" ? light.border : "#9a9a9a",
                  },
                };

                const series = [
                  {
                    data: Object.values(data.options).map((opt) => opt.count),
                  },
                ];

                return (
                  <Card
                    key={questionId}
                    sx={{
                      mb: 3,
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? light.secondary
                          : dark.secondary,
                    }}
                  >
                    <CardContent
                      sx={{
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? light.secondary
                            : dark.secondary,
                        borderRadius: 4,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? "#000000"
                              : dark.text,
                        }}
                        gutterBottom
                      >
                        {data.questionText}
                      </Typography>
                      <ApexCharts
                        options={options}
                        series={series}
                        type="bar"
                        className="apex-chart"
                      />
                    </CardContent>
                  </Card>
                );
              } else if (data.type === 3) {
                const averageRating =
                  data.averageRating !== null
                    ? parseFloat(data.averageRating.toFixed(1))
                    : 0;
                return (
                  <Card
                    key={questionId}
                    sx={{
                      mb: 3,
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? light.tertiary
                          : dark.tertiary,
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? "#000000"
                              : dark.text,
                          mb: 2,
                        }}
                        gutterBottom
                      >
                        {data.questionText}
                      </Typography>
                      <Rating value={averageRating} precision={0.1} readOnly />
                      <Box mt={2}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          Average Rating:{" "}
                          {data.averageRating !== null
                            ? data.averageRating.toFixed(1)
                            : "N/A"}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                );
              } else if (data.type === 4) {
                return (
                  <Card
                    key={questionId}
                    sx={{
                      mb: 3,
                      boxShadow: 3,
                      borderRadius: 4,
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? light.tertiary
                          : dark.tertiary,
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? "#000000"
                              : dark.text,
                          mb: 2,
                        }}
                        gutterBottom
                      >
                        {data.questionText}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          overflowX: "auto",
                          "&::-webkit-scrollbar": {
                            height: "6px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text,
                            borderRadius: "4px",
                          },
                        }}
                      >
                        {data.textAnswers.map((answer, index) => (
                          <Card
                            key={index}
                            sx={{
                              minWidth: 300,
                              mr: 2,
                              mb: 2,
                              boxShadow: 2,
                              borderRadius: 2,
                              backgroundColor:
                                theme.palette.mode === "light"
                                  ? light.primary
                                  : dark.primary,
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{
                                  color:
                                    theme.palette.mode === "light"
                                      ? "#000000"
                                      : dark.text,
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  pb: 4,
                                  pt: 1,
                                }}
                              >
                                {answer.employee_name}
                              </Typography>
                              <Typography
                                sx={{
                                  color:
                                    theme.palette.mode === "light"
                                      ? light.text
                                      : dark.text,
                                  mt: 1,
                                }}
                              >
                                {answer.text}
                              </Typography>
                            </CardContent>
                          </Card>
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                );
              }
              return null;
            }
          )}
        </Box>
      )}
    </Box>
  );
};

export default Summary;
