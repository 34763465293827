import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material/";
import { useNavigate } from "react-router-dom";
import { TaskLister } from "components/rekrom/TaskLister";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import FavoriteWorkSpaces from "./components/FavoriteWorkSpace/index";
import {
  fetchEmployeeProjectWorkPackages,
  fetchTopWorkSpaces,
  fetchWorkSpaces,
  fetchUserAssignedTasks,
  fetchTasksAssignedByUser,
} from "../../../src/apis/apis/index";

function Dashboard() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const token = sessionStorage.getItem("token");
  const [projects, setProjects] = useState([]);
  const [isProjectsDataFetched, setIsProjectsDataFetched] = useState(false);
  const [favoritedWorkspaceError, setFavoritedWorkspaceError] = useState(false);
  const [favoriteWorkSpaces, setFavoriteWorkSpaces] = useState([]);
  const [numberOfFavorites, setNumberOfFavorites] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [toAssignedTasks, setToAssignedTasks] = useState([]);
  const theme = useTheme();

  const [expandedProject, setExpandedProject] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isTaskDialogOpen, setIsTaskDialogOpen] = useState(false);

  const getProjects = () => {
    fetchEmployeeProjectWorkPackages(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate
    ).then((data) => {
      setIsProjectsDataFetched(true);
      setProjects(data);
    });
  };

  const getFavoriteWorkSpaces = () => {
    fetchTopWorkSpaces(token, setErrorMessage, handleOpenDialog, navigate)
      .then((data) => {
        setNumberOfFavorites(data.length);
        setFavoriteWorkSpaces(data);
      })
      .catch(() => {
        setFavoritedWorkspaceError(true);
      });
  };

  const getTasks = () => {
    fetchUserAssignedTasks(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate
    ).then((data) => {
      setTasks(data);
    });
  };

  const getToAssignedTasks = () => {
    fetchTasksAssignedByUser(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate
    ).then((data) => {
      setToAssignedTasks(data);
    });
  };

  const getWorkSpaces = () => {
    fetchWorkSpaces(token, setErrorMessage, handleOpenDialog, navigate)
      .then((data) => {
        const projectWorkSpaces = {};
        data.forEach((workspace) => {
          workspace.work_packages.forEach((workPackage) => {
            const projectId = workPackage.project_id;
            if (!projectWorkSpaces[projectId]) {
              projectWorkSpaces[projectId] = [];
            }
            projectWorkSpaces[projectId].push(workspace);
          });
        });

        setProjects((prevProjects) =>
          prevProjects.map((project) => ({
            ...project,
            workspaces: projectWorkSpaces[project.id] || [],
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching workspaces", error);
      });
  };

  useEffect(() => {
    getProjects();
    getFavoriteWorkSpaces();
    getTasks();
    getToAssignedTasks();
    getWorkSpaces();
  }, []);

  const handleProjectClick = (projectId) => {
    setExpandedProject(expandedProject === projectId ? null : projectId);
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setIsTaskDialogOpen(true);
  };

  const handleCloseTaskDialog = () => {
    setIsTaskDialogOpen(false);
    setSelectedTask(null);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {isProjectsDataFetched && (
        <MDBox py={3}>
          <FavoriteWorkSpaces
            favoritedWorkspaceError={favoritedWorkspaceError}
            favoriteWorkSpaces={favoriteWorkSpaces}
            numberOfFavorites={numberOfFavorites}
            expandedProject={expandedProject}
            setExpandedProject={setExpandedProject}
            handleProjectClick={handleProjectClick}
            token={token}
            setErrorMessage={setErrorMessage}
            handleOpenDialog={handleOpenDialog}
            navigate={navigate}
          />

          <Box mb={5}>
            <TaskLister
              tasks={tasks}
              onTaskClick={handleTaskClick}
              is_assignee={false}
              is_assigner={true}
            />
          </Box>

          <Box mb={5}>
            <TaskLister
              tasks={toAssignedTasks}
              is_assignee={true}
              is_assigner={false}
              onTaskClick={handleTaskClick}
            />
          </Box>

          

          <Dialog
            open={isTaskDialogOpen}
            onClose={handleCloseTaskDialog}
            aria-labelledby="task-dialog-title"
          >
            <DialogTitle id="task-dialog-title">
              {selectedTask?.name}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{selectedTask?.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTaskDialog}>Close</Button>
            </DialogActions>
          </Dialog>
        </MDBox>
      )}
    </>
  );
}

export default Dashboard;
