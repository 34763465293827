import { Box, TextField } from '@mui/material';

export const FormFirst = (props) => {
	return (
		<form autoComplete="off" noValidate hidden={props.hidden}>
			<Box>
				<Box pt={4} pb={3} px={3}>
					<Box mb={2}>
						<TextField
							type="text"
							label="First Name"
							name="firstName"
							variant="outlined"
							onBlur={props.formik.handleBlur}
							onChange={props.formik.handleChange}
							value={props.formik.values.firstName}
							error={Boolean(
								props.formik.touched.firstName && props.formik.errors.firstName
							)}
							helperText={
								props.formik.touched.firstName && props.formik.errors.firstName
							}
							fullWidth
						/>
					</Box>
					<Box mb={2}>
						<TextField
							type="text"
							label="Last Name"
							name="lastName"
							variant="outlined"
							onBlur={props.formik.handleBlur}
							onChange={props.formik.handleChange}
							value={props.formik.values.lastName}
							error={Boolean(
								props.formik.touched.lastName && props.formik.errors.lastName
							)}
							helperText={
								props.formik.touched.lastName && props.formik.errors.lastName
							}
							fullWidth
						/>
					</Box>
					<Box mb={2}>
						<TextField
							type="text"
							label="User Name"
							name="userName"
							variant="outlined"
							onBlur={props.formik.handleBlur}
							onChange={props.formik.handleChange}
							value={props.formik.values.userName}
							error={Boolean(
								props.formik.touched.userName && props.formik.errors.userName
							)}
							helperText={
								props.formik.touched.userName && props.formik.errors.userName
							}
							fullWidth
						/>
					</Box>
					<Box mb={2}>
						<TextField
							type="email"
							label="Email"
							name="email"
							variant="outlined"
							onBlur={props.formik.handleBlur}
							onChange={props.formik.handleChange}
							value={props.formik.values.email}
							error={Boolean(
								props.formik.touched.email && props.formik.errors.email
							)}
							helperText={
								props.formik.touched.email && props.formik.errors.email
							}
							fullWidth
						/>
					</Box>
					<Box mb={2}>
						<TextField
							type="password"
							label="Password"
							name="password"
							variant="outlined"
							onBlur={props.formik.handleBlur}
							onChange={props.formik.handleChange}
							value={props.formik.values.password}
							error={Boolean(
								props.formik.touched.password && props.formik.errors.password
							)}
							helperText={
								props.formik.touched.password && props.formik.errors.password
							}
							fullWidth
						/>
					</Box>
					<Box mb={2}>
						<TextField
							type="password"
							label="Password Again"
							name="passwordAgain"
							variant="outlined"
							onBlur={props.formik.handleBlur}
							onChange={props.formik.handleChange}
							value={props.formik.values.passwordAgain}
							error={Boolean(
								props.formik.touched.passwordAgain &&
									props.formik.errors.passwordAgain
							)}
							helperText={
								props.formik.touched.passwordAgain &&
								props.formik.errors.passwordAgain
							}
							fullWidth
						/>
					</Box>
				</Box>
			</Box>
		</form>
	);
};
