import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Container,
  Paper,
  FormLabel,
  FormControlLabel,
  MenuList,
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InputMask from "react-input-mask";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  getClients,
  createClient,
  updateClient,
  getProjects,
  deleteClient,
} from "../../apis/apis";
import Fuse from "fuse.js";

const phoneRegExp = /^\d{4}-\d{3}-\d{4}$/;

const ClientInfo = () => {
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [editClientId, setEditClientId] = useState(null);
  const [showCreateClientForm, setShowCreateClientForm] = useState(false);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [projectFilter, setProjectFilter] = useState("ALL");
  const token = sessionStorage.getItem("token");
  const [errorMessage, setErrorMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientData = await getClients(
          token,
          setErrorMessage,
          () => setOpenDialog(true),
          navigate
        );
        setClients(clientData);
        setFilteredClients(clientData);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }

      try {
        const projectData = await getProjects(
          token,
          setErrorMessage,
          () => setOpenDialog(true),
          navigate
        );
        setProjects(projectData);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchData();
  }, [token, navigate]);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      company: "",
      title: "",
      project: "",
      phone_number: "",
      email: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      company: Yup.string().nullable(),
      title: Yup.string().nullable(),
      project: Yup.string().nullable(),
      phone_number: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .nullable(),
      email: Yup.string().email("Invalid email address").nullable(),
    }),
    onSubmit: async (values, { resetForm }) => {
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => value)
      );

      try {
        const newClient = await createClient(
          filteredValues,
          token,
          setErrorMessage,
          () => setOpenDialog(true),
          navigate
        );
        setClients([...clients, newClient]);
        setFilteredClients([...clients, newClient]);
        resetForm();
        setShowCreateClientForm(false);

        // Reload the page after a successful creation
        window.location.reload();
      } catch (error) {
        console.error("Error creating client:", error);
      }
    },
  });

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    const updatedClients = clients.map((client) =>
      client.id === editClientId
        ? name === "project"
          ? {
              ...client,
              project: projects.find((project) => project.id === value),
            }
          : { ...client, [name]: value }
        : client
    );
    setClients(updatedClients);
  };

  const handleUpdateClient = async (clientId) => {
    try {
      const client = clients.find((client) => client.id === clientId);
      const updatedClientData = {
        ...client,
        project: client.project ? client.project.id : null, // Send project ID to server
      };

      const updatedClient = await updateClient(
        clientId,
        updatedClientData,
        token,
        setErrorMessage,
        () => setOpenDialog(true),
        navigate
      );

      // Replace project ID with full project object from projects list
      const updatedClientWithProject = {
        ...updatedClient,
        project: updatedClient.project
          ? projects.find((project) => project.id === updatedClient.project)
          : null,
      };

      const updatedClients = clients.map((client) =>
        client.id === clientId ? updatedClientWithProject : client
      );
      setClients(updatedClients);
      setFilteredClients(updatedClients);
      setEditClientId(null);

      // Reload the page after a successful update
      window.location.reload();
    } catch (error) {
      console.error("Error updating client:", error);
    }
  };

  const handleOpenDeleteDialog = (client) => {
    setClientToDelete(client);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setClientToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteClient = async () => {
    try {
      await deleteClient(
        clientToDelete.id,
        token,
        setErrorMessage,
        () => setOpenDialog(true),
        navigate
      );
      const updatedClients = clients.filter(
        (client) => client.id !== clientToDelete.id
      );
      setClients(updatedClients);
      setFilteredClients(updatedClients);
      handleCloseDeleteDialog();

      // Reload the page to reflect the changes
      window.location.reload();
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  const toggleCreateClientForm = () => {
    setShowCreateClientForm(!showCreateClientForm);
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleProjectFilterChange = (e) => {
    setProjectFilter(e.target.value);
  };

  const fuseOptions = {
    keys: [
      "first_name",
      "last_name",
      "company",
      "title",
      "project.name",
      "phone_number",
      "email",
    ],
    threshold: 0.3,
  };

  useEffect(() => {
    const fuse = new Fuse(clients, fuseOptions);
  }, [clients]);

  const applyFilters = () => {
    let filtered = clients;

    if (projectFilter !== "ALL") {
      filtered = filtered.filter(
        (client) =>
          client.project && client.project.id === Number(projectFilter)
      );
    }

    if (searchTerm) {
      const fuse = new Fuse(clients, fuseOptions);
      filtered = fuse.search(searchTerm).map((result) => result.item);
    }

    setFilteredClients(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [projectFilter, searchTerm, clients]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Client Information
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          marginBottom: 5,
        }}
      >
        {!showCreateClientForm && (
          <Button
            variant="contained"
            color="primary"
            onClick={toggleCreateClientForm}
          >
            Create Client
          </Button>
        )}

        {!showCreateClientForm && (
          <TextField
            label="Search Clients"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            sx={{ maxWidth: "600px", ml: 2 }}
          />
        )}
      </Box>
      {showCreateClientForm && (
        <Paper style={{ padding: 16, marginBottom: 50, position: "relative",borderRadius:15 }}>
          <MDBox
          mx={2}
          my={3}
          mt={-5}
          py={3}
          px={2}
          ml={0}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          display="flex"
          alignItems="center"
          sx={{ width: '99.5%' }}  // Adjust the width as needed

        >
          <MDTypography variant="h6" color="white">
            Create New Client
          </MDTypography>
          <Box flexGrow={1} />
          <IconButton
            onClick={toggleCreateClientForm}
            style={{ position: "absolute",  right: 35}}
          >
            <CloseIcon />
          </IconButton>
        </MDBox>
         
         
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.first_name &&
                    Boolean(formik.errors.first_name)
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Company"
                  name="company"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.company && Boolean(formik.errors.company)
                  }
                  helperText={formik.touched.company && formik.errors.company}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                  }}
                >
                  <InputLabel id="project-label">Related Project</InputLabel>
                  <Select
                    labelId="project-label"
                    name="project"
                    value={formik.values.project}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.project && Boolean(formik.errors.project)
                    }
                  >
                    {projects.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name} ({project.abbr})
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.project && formik.errors.project && (
                    <Typography color="error">
                      {formik.errors.project}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputMask
                  mask="9999-999-9999"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {() => (
                    <TextField
                      label="Phone Number"
                      name="phone_number"
                      error={
                        formik.touched.phone_number &&
                        Boolean(formik.errors.phone_number)
                      }
                      helperText={
                        formik.touched.phone_number &&
                        formik.errors.phone_number
                      }
                      fullWidth
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Create Client
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}

      <Card width="%100">
        <MDBox
          mx={2}
          mt={-3}
          py={2}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          display="flex"
          alignItems="center"
        >
          <MDTypography variant="h6" color="white">
            Client List
          </MDTypography>
          <Box flexGrow={1} />
          <Tooltip title="Filter">
            <IconButton onClick={handleOpenPopover}>
              <FilterAltIcon />
            </IconButton>
          </Tooltip>
        </MDBox>

        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead sx={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredClients.map((client) => (
                  <TableRow key={client.id}>
                    {editClientId === client.id ? (
                      <>
                        <TableCell>
                          <TextField
                            name="first_name"
                            label="First Name"
                            value={client.first_name}
                            onChange={handleEditInputChange}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="last_name"
                            label="Last Name"
                            value={client.last_name}
                            onChange={handleEditInputChange}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="title"
                            label="Title"
                            value={client.title}
                            onChange={handleEditInputChange}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="company"
                            label="Company"
                            value={client.company}
                            onChange={handleEditInputChange}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl
                            fullWidth
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 45,
                              },
                            }}
                          >
                            <InputLabel id="edit-project-label">
                              Project
                            </InputLabel>
                            <Select
                              labelId="edit-project-label"
                              name="project"
                              value={client.project ? client.project.id : ""}
                              onChange={(e) =>
                                handleEditInputChange({
                                  target: {
                                    name: "project",
                                    value: e.target.value,
                                  },
                                })
                              }
                            >
                              {projects.map((project) => (
                                <MenuItem key={project.id} value={project.id}>
                                  {project.name} ({project.abbr})
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="phone_number"
                            label="Phone Number"
                            value={client.phone_number}
                            onChange={handleEditInputChange}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="email"
                            label="Email"
                            value={client.email}
                            onChange={handleEditInputChange}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Save">
                            <IconButton
                              onClick={() => handleUpdateClient(client.id)}
                            >
                              <SaveIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel">
                            <IconButton onClick={() => setEditClientId(null)}>
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{client.first_name}</TableCell>
                        <TableCell>{client.last_name}</TableCell>
                        <TableCell>{client.title}</TableCell>
                        <TableCell>{client.company}</TableCell>
                        <TableCell>
                          {client.project
                            ? `${client.project.name} (${client.project.abbr})`
                            : "None"}
                        </TableCell>
                        <TableCell>{client.phone_number}</TableCell>
                        <TableCell>{client.email}</TableCell>
                        <TableCell align="right">
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => setEditClientId(client.id)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => handleOpenDeleteDialog(client)}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            py: 1.5,
            px: 2,
          }}
        >
          <Typography variant="overline">Filters</Typography>
          <Divider />
          <FormControl>
            <FormLabel id="filter-project-label">Project</FormLabel>
            <RadioGroup
              aria-labelledby="filter-project-label"
              name="project-filter"
              value={projectFilter}
              onChange={handleProjectFilterChange}
            >
              <FormControlLabel value="ALL" control={<Radio />} label="All" />
              {projects.map((project) => (
                <FormControlLabel
                  key={project.id}
                  value={project.id}
                  control={<Radio />}
                  label={project.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <MenuList
          disablePadding
          sx={{
            "& > *": {
              "&:first-of-type": {
                borderTopColor: "divider",
                borderTopStyle: "solid",
                borderTopWidth: "1px",
              },
              padding: "12px 16px",
            },
          }}
        >
          <MenuItem onClick={applyFilters}>Apply Filters</MenuItem>
        </MenuList>
      </Popover>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Client"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to permanently delete this client?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteClient} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ClientInfo;
