
import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";

import { useTheme } from "@mui/material/styles";

import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

const Header = ({  image, name, title, lastName, tabClick}) => {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const theme = useTheme();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
      setTabValue(newValue);
      tabClick(tabValue);
  }

  return (
    <MDBox position="relative" mb={5} >
      {/*<MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />*/}
      <Card
        sx={{
          position: "relative",
          mt: 4,
          mx: 3,
          py: 2,
          px: 2,
          backgroundColor: theme.palette.mode === "dark" ? dark.primary : light.primary
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item >
            <MDAvatar src={image} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item >
            <MDBox height="100%" mt={0.5} lineHeight={1} >
              <MDTypography variant="h5" fontWeight="medium" 
                            sx={{color: theme.palette.mode === "dark" ? dark.text : light.text}}>
                  {name} {lastName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                  {title}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}>
                <Tab
                  label="Me"
                  icon={
                    <PermIdentityIcon/>
                  }
                />
                
                <Tab
                  label="Performans"
                  icon={
                    <ShowChartIcon/>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
