
import './index.css';
import React, {useRef, useState,} from "react";
import Button from "@mui/material/Button";
import { Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from "@mui/material/";
import { CustomSchedule } from "../../components/rekrom/CustomSchedule"

const Scheduler = () => {
    const [openDialog, setOpenDialog] = useState(false); // State for dialog open/close
    const [errorMessage, setErrorMessage] = useState(null);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const changeEventColor = (args) => {
        if (args.data.Subject && args.data.Subject.includes("IZINLER")) {
          args.element.style.backgroundColor = 'red';
        }
      };


    return (
        <>
            {/* Your other JSX components */}
            <div>
                <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">Error</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                    OK
                    </Button>
                </DialogActions>
                </Dialog>
            </div>
            <CustomSchedule onEventRendered={changeEventColor}/>
        </>
    );
};

export default Scheduler;