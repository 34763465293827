import React, {useState} from 'react';
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Box } from '@mui/material';
import { styled } from '@mui/system';

const FormControlLabelCustom = styled(FormControlLabel)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '1rem'
});

const Options = ({ onSelect , selectedOption, onFilter, selectedValue}) => {


    const handleChange = (event) => {
        //console.log("Selected value:", event.target.value);
        onFilter(event.target.value);
        // onSelect(event.target.value);
    };

    return (
        <Box
            p={2}
        >
            <FormControl
                component="fieldset"
            >
                <RadioGroup
                    aria-label="Constraints"
                    // defaultValue="show-all-non-zero"
                    name="constraints"
                    sx={{
                        flexDirection: 'row',
                    }}
                    value={selectedValue}
                    onChange={handleChange}
                >
                    <FormControlLabelCustom value="show-all" control={<Radio />} label="show all employees"/>
                    <FormControlLabelCustom value="show-all-non-zero" control={<Radio />} label="show only those who enter working hours"/>
                    <FormControlLabelCustom value="show-all-zero" control={<Radio />} label="show only those who do not enter working hours"/>
                </RadioGroup>
            </FormControl>
        </Box>
    );
};

export default Options;
