import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React example components
import Footer from "components/Footer";
// Data
import axios from "../../axios/index";
import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { CompanyHierarchy } from 'components/rekrom/CompanyHierarchy';
import { EmployeeTable } from 'components/rekrom/EmployeeTable';

import { getHierarchyStructure } from "apis/apis/index";
import {
  CircularProgress,
} from "@mui/material";

function Tables() 
{
  let token = sessionStorage.getItem("token")

  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataHierarchyStructure, setHierarchyStructure] = useState([]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    };

  const [showOnlyActive, setShowOnlyActive] = useState(true); // State for filtering
 
  
  
  const toggleFilter = () => {
    setShowOnlyActive(!showOnlyActive);
  };


  const fetchHierarchyStructure = () => {
    setLoading(true);
    setError(null);
    // Replace getChangelog with your actual API function to fetch changelog data
    getHierarchyStructure(token, setErrorMessage, handleOpenDialog, useNavigate)
      .then((fetchedHierarchyStructure) => {
        setHierarchyStructure(fetchedHierarchyStructure);
      })
      .catch((error) => {
        setError(error.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (token) {
      fetchHierarchyStructure();
    }
  }, [token]);
  
  return (
    <>
      <EmployeeTable toggleFilter={toggleFilter} showOnlyActive={showOnlyActive} />
      
      {loading && <CircularProgress />}
      { dataHierarchyStructure.length > 0 && (
        <CompanyHierarchy treeData={dataHierarchyStructure} />
      )}
    <Footer />
    </>
  );
}

export default Tables;
