import { Box, Typography, Paper, Divider, Card, Button, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Input } from "rsuite";
import { changePassword } from "apis/apis";
import styles from "./style.js"
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

const ProfileInfo = ({ profileInformation }) => {
  const navigate = useNavigate();
  const [isChangePasswordButtonClicked, setIsChangePasswordButtonClicked] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPasword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem("token");

  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const theme = useTheme();

  const handleLeaveFormClick = () => {
    navigate('/create-day-off', { state: { profileInformation } });
  };

  const handlePasswordChangeClick = () => {
    if(isChangePasswordButtonClicked === null){
      setIsChangePasswordButtonClicked("clicked");
    }
    else{
      setMessage(null);
      setIsChangePasswordButtonClicked(null);
    }
  }

  const handleOldPasswordChange = (value) =>{
    setOldPassword(value);
  };
  
  const handleNewPasswordChange = (value) =>{
    setNewPassword(value);
  };
  
  const handleConfirmPasswordChange = (value) =>{
    setConfirmPassword(value);
  };

  const confirmButtonClicked = async () => {
    try{
      const passwordChangeData = await changePassword(
        token,
        oldPassword,
        newPassword,
        confirmPasword,
      );
  
      setMessage(passwordChangeData['message']);
      setError(false)
    }
    catch(error)
    {
      setMessage(error.response.data['error']);
      setError(true);
    }
  }

  return (
    <Box>
      <Paper style={styles.container} 
                           sx={{backgroundColor: theme.palette.mode === "light" ? light.secondary : dark.secondary}}>
        <Box display="flex">
          <Card style={isChangePasswordButtonClicked ? styles.cardDivided : styles.card} 
                sx={{backgroundColor: theme.palette.mode === "light" ? light.primary : dark.primary}}>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography variant="h6" style={styles.title}
               sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                Profile Information
              </Typography>
            </Box>
            <div style={{marginLeft: "10px"}}> 
              <Divider style={styles.divider}/>
              <div style={styles.fieldContainer}>
                <Typography style={styles.label}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  First Name:
                </Typography>
                <Typography style={styles.value}>
                  {profileInformation.firstName ? profileInformation.firstName : "-"}
                </Typography>
              </div>
              <div style={styles.fieldContainer}>
                <Typography style={styles.label}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  Last Name:
                </Typography>
                <Typography style={styles.value}>
                  {profileInformation.lastName ? profileInformation.lastName : "-"}
                </Typography>
              </div>
              <div style={styles.fieldContainer}>
                <Typography style={styles.label}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  Employee ID:
                </Typography>
                <Typography style={styles.value}>
                  {profileInformation.id ? profileInformation.id : "-"}
                </Typography>
              </div>
              <div style={styles.fieldContainer}>
                <Typography style={styles.label}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  Admission Date:
                </Typography>
                <Typography style={styles.value}>
                  {profileInformation.admission_date ? profileInformation.admission_date : "-"}
                </Typography>
              </div>
              <div style={styles.fieldContainer}>
                <Typography style={styles.label}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  Graduated University:
                </Typography>
                <Typography style={styles.value}>
                  {profileInformation.graduated_university ? profileInformation.graduated_university : "-"}
                </Typography>
              </div>
              <div style={styles.fieldContainer}>
                <Typography style={styles.label}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  Graduation Date:
                </Typography>
                <Typography style={styles.value}>
                  {profileInformation.graduation_date ? profileInformation.graduation_date : "-"}
                </Typography>
              </div>
              <div style={styles.fieldContainer}>
                <Typography style={styles.label}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  Phone Number:
                </Typography>
                <Typography style={styles.value}>
                  {profileInformation.phoneNumber ? profileInformation.phoneNumber : "-"}
                </Typography>
              </div>
              <div style={styles.fieldContainer}>
                <Typography style={styles.label}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  Email:
                </Typography>
                <Typography style={styles.value}>
                  {profileInformation.email ? profileInformation.email : "-"}
                </Typography>
              </div>
              <div style={styles.fieldContainer}>
                <Typography style={styles.label}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  Day Off:
                </Typography>
                <Typography style={styles.value}>
                  {profileInformation.remainingDayOff ? profileInformation.remainingDayOff : "-"}
                </Typography>
              </div>
            </div>
          </Card>
          {isChangePasswordButtonClicked ? 
          <Card style={styles.cardChangePassword} 
                sx={{backgroundColor: theme.palette.mode === "light" ? light.primary : dark.primary}}>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography variant="h6" style={styles.title} 
                          sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                Change Password
              </Typography>
            </Box>
            <div>
              <Divider style={styles.divider}/>
              <div>
                <Typography style={styles.inputFieldLabel}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  Old Password:
                </Typography>
                <Input type="password" onChange={(event) => handleOldPasswordChange(event)} style={styles.inputField}/>
              </div>
              <div>
                <Typography style={styles.inputFieldLabel}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  New Password:
                </Typography>
                <Input type="password" onChange={(event) => handleNewPasswordChange(event)} style={styles.inputField}/>
              </div>
              <div>
                <Typography style={styles.inputFieldLabel}
                            sx={{color: theme.palette.mode === "light" ? light.text : dark.text}}>
                  New Password Again:
                </Typography>
                <Input type="password" onChange={(event) => handleConfirmPasswordChange(event)} style={styles.inputField}/>
              </div>
              <Button style={styles.button} variant="outlined" color="primary" onClick={confirmButtonClicked}
                      sx={{backgroundColor: 
                        theme.palette.mode === "light" ? light.buttonBackground : dark.buttonBackground,
                        color: theme.palette.mode === "light" ? light.buttonIcon : dark.buttonIcon,
                        border : `1px solid ${
                          theme.palette.mode === "light"
                            ? light.border
                            : dark.border
                        }`,
                        '&:hover': {
                          backgroundColor: theme.palette.mode === "light" ? light.buttonBackgroundHover : dark.buttonBackgroundHover,
                          color: theme.palette.mode === "light" ? light.buttonIconHover : dark.buttonIconHover,
                          border: `1px solid ${
                            theme.palette.mode === "light"
                              ? light.borderHover
                              : dark.borderHover
                          }`,
                        },}}>
                Confirm
              </Button>
              {message ?
                error ? 
                <Alert severity="error" style={{ marginTop: '10px' }}>
                  {message}
                </Alert> :
                <Alert severity="success" style={{ marginTop: '10px' }}>
                  {message}
                </Alert>
              : <></>}
            </div>
          </Card>
          : <></>}
        </Box>
        <Box style={{display:"flex"}}>
        <Button style={styles.button} variant="outlined" color="primary" onClick={handleLeaveFormClick}
                sx={{backgroundColor: 
                  theme.palette.mode === "light" ? light.buttonBackground : dark.buttonBackground,
                  color: theme.palette.mode === "light" ? light.buttonIcon : dark.buttonIcon,
                  border : `1px solid ${
                    theme.palette.mode === "light"
                      ? light.border
                      : dark.border
                  }`,
                  '&:hover': {
                    backgroundColor: theme.palette.mode === "light" ? light.buttonBackgroundHover : dark.buttonBackgroundHover,
                    color: theme.palette.mode === "light" ? light.buttonIconHover : dark.buttonIconHover,
                    border: `1px solid ${
                      theme.palette.mode === "light"
                        ? light.borderHover
                        : dark.borderHover
                    }`,
                  },}}>
            Take a Day Off
          </Button>
          <Button style={styles.button} variant="outlined" color="primary" onClick={handlePasswordChangeClick}
                  sx={{backgroundColor: 
                    theme.palette.mode === "light" ? light.buttonBackground : dark.buttonBackground,
                    color: theme.palette.mode === "light" ? light.buttonIcon : dark.buttonIcon,
                    border : `1px solid ${
                      theme.palette.mode === "light"
                        ? light.border
                        : dark.border
                    }`,
                    '&:hover': {
                      backgroundColor: theme.palette.mode === "light" ? light.buttonBackgroundHover : dark.buttonBackgroundHover,
                      color: theme.palette.mode === "light" ? light.buttonIconHover : dark.buttonIconHover,
                      border: `1px solid ${
                        theme.palette.mode === "light"
                          ? light.borderHover
                          : dark.borderHover
                      }`,
                    },}}>
            Change Password
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export { ProfileInfo };
