import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import { format, parseISO } from "date-fns";
import IconButton from "@mui/material/IconButton"; // Import IconButton
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import {
  getUnreadNotificationCount,
  updateNotificationReadStatus,
} from "../../../apis/apis/index";
import { useData } from "context";

export const NotificationComponent = ({
  notification,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  getNotifications,
}) => {
  const { dispatchNotification } = useData();
  // Format timestamp
  const formattedTimestamp = format(parseISO(notification.timestamp), "PPPpp");

  const handleReadClick = async () => {
    await updateNotificationReadStatus(
      !notification.is_read,
      notification.id,
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate
    );
    getNotifications();
    getUnreadNotificationCount(token).then((res) => {
      dispatchNotification({ type: "SET_DATA", payload: { data: res.count } });
    });
  };

  return (
    <MDAlert
      color={!notification.is_read ? "primary" : "secondary"}
      dismissible
    >
      {notification.is_read ? (
        <IconButton onClick={handleReadClick} color="white" size="small">
          <MarkChatUnreadOutlinedIcon /> {/* Mark as Unread button */}
        </IconButton>
      ) : (
        <IconButton onClick={handleReadClick} color="white" size="small">
          <MarkChatReadOutlinedIcon /> {/* Mark as Read button */}
        </IconButton>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <MDTypography variant="body2" color="white">
            <b>Message: </b>
            {notification.message}
            <br />
            <small>
              <b> Date: </b>
              {formattedTimestamp}
            </small>
          </MDTypography>
        </div>
      </div>
    </MDAlert>
  );
};

export default { NotificationComponent };
