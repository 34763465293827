import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  InputLabel,
  Typography,
  Chip,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Done as DoneIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Sell as SellIcon,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import dayjs from "dayjs";
import Modal from "../../Modal/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  fetchDefaultProjectLabels,
  fetchCustomProjectLabels,
  fetchTaskLabels,
  updateTaskLabels,
} from "../../../../apis/apis/index";
import { useNavigate } from "react-router-dom";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { darken, lighten } from "@mui/system";
import ProjectLabelsSection from "../../../../layouts/workspace/ProjectLabels/ProjectLabels";

export default function CardDetails(props) {
  const colors = ["#61bd4f", "#f2d600", "#ff9f1a", "#eb5a46", "#c377e0"];
  const { employees, labels = [] } = props; // Defaulting labels to an empty array
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openLabelDialog, setOpenLabelDialog] = useState(false); // State to manage ProjectLabelsSection dialog
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const PRIORITIES = [
    { value: "HIGH", label: "High" },
    { value: "MEDIUM", label: "Medium" },
    { value: "LOW", label: "Low" },
  ];

  const TYPES = [
    { value: "DESIGN", label: "Design" },
    { value: "FEATURE", label: "Feature" },
    { value: "REVISION", label: "Revision" },
  ];

  const [values, setValues] = useState({
    ...props.card,
    assigner: props.card.assigner || "",
    assignee: props.card.assignee || "",
    priority: props.card.priority || "",
    type: props.card.type || "",
    actual_duration: props.card.actual_duration || 0,
    foreseen_duration: props.card.foreseen_duration || 0,
  });

  const [defaultLabels, setDefaultLabels] = useState([]); // State for default labels
  const [customLabels, setCustomLabels] = useState([]); // State for custom labels
  const [taskLabels, setTaskLabels] = useState([]); // State for task labels
  const [pendingLabels, setPendingLabels] = useState([]); // State for pending label changes

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const fetchLabels = async () => {
    const token = sessionStorage.getItem("token");
    const defaultData = await fetchDefaultProjectLabels(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate
    );
    setDefaultLabels(defaultData);

    if (props.projectInfo.id) {
      const customData = await fetchCustomProjectLabels(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate,
        props.projectInfo.id
      );
      setCustomLabels(customData);
    }
  };

  const getTaskLabels = async () => {
    const token = sessionStorage.getItem("token");
    const taskData = await fetchTaskLabels(
      token,
      props.card.id,
      setErrorMessage,
      handleOpenDialog,
      navigate
    );
    setTaskLabels(taskData.labels); // Correcting the state assignment
    setPendingLabels(taskData.labels); // Initialize pending labels with current task labels
    console.log("assigned labels", taskData.labels);
  };

  useEffect(() => {
    fetchLabels();
  }, [props.projectInfo.id]);

  useEffect(() => {
    getTaskLabels();
  }, [props.card.id]);

  const handleLabelsDialogClose = () => {
    fetchLabels(); // Re-fetch labels when the dialog is closed
    setOpenLabelDialog(false);
  };

  const [input, setInput] = useState(false);
  const [text, setText] = useState(values.title);
  const [labelShow, setLabelShow] = useState(false);

  const [description, setDescription] = useState("");
  const [assignee, setAssignee] = useState(0);
  const [priority, setPriority] = useState("Medium");
  const [type, setType] = useState("Design");
  const [start_date, setStartDate] = useState(new Date());
  const [estimated_end_date, setEndDate] = useState(new Date());
  const [title, setTitle] = useState("");

  const parseDate = (date) => {
    const dateArray = date.split("T");
    const dateArray2 = dateArray[0].split("-");
    return dateArray2;
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return currentDate.toLocaleDateString(undefined, options);
  };

  const addTask = (value) => {
    // Add task logic
  };

  const removeTask = (id) => {
    const remaningTask = values.task.filter((item) => item.id !== id);
    setValues({ ...values, task: remaningTask });
  };

  const deleteAllTask = () => {
    // Delete all tasks logic
  };

  const updateTask = (id) => {
    // Update task logic
  };

  const updateTitle = (e) => {
    setValues({ ...values, name: e.target.value });
  };

  const updateDescription = (e) => {
    setValues({ ...values, description: e.target.value || "" });
  };

  const calculatePercent = () => {
    // Calculate percent logic
    return 0;
  };

  const removeTag = (id) => {
    // Remove tag logic
  };

  const addTag = (value, color) => {
    // Add tag logic
  };

  const handelClickListner = (e) => {
    // Handle click listener logic
  };

  useEffect(() => {
    document.addEventListener("keypress", handelClickListner);
    return () => {
      document.removeEventListener("keypress", handelClickListner);
    };
  });

  useEffect(() => {
    if (props.updateCard) props.updateCard(props.bid, values.id, values);
  }, [values]);

  const [chosenEmployee, setChosenEmployee] = useState();

  const handleChange = (e) => {
    setValues({
      ...values,
      assignee: e.target.value,
    });
  };

  const handlePriorityChange = (e) => {
    setValues({
      ...values,
      priority: e.target.value,
    });
  };

  const handleTypeChange = (e) => {
    setValues({
      ...values,
      type: e.target.value,
    });
  };

  const handleStartDateChange = (newDate) => {
    const formattedDate = dayjs(newDate).format("YYYY-MM-DDT00:00:00.000Z");
    setValues({
      ...values,
      start_date: formattedDate,
    });
  };

  const handleEstimatedEndDateChange = (newDate) => {
    const formattedDate = dayjs(newDate).format("YYYY-MM-DDT00:00:00.000Z");
    setValues({
      ...values,
      estimated_end_date: formattedDate,
    });
  };

  const handleIncreaseRevision = () => {
    setValues((prevValues) => ({
      ...prevValues,
      revision_count: prevValues.revision_count + 1,
    }));
  };

  const handleDecreaseRevision = () => {
    if (values.revision_count > 0) {
      setValues((prevValues) => ({
        ...prevValues,
        revision_count: prevValues.revision_count - 1,
      }));
    }
  };

  const handleActualDurationChange = (e) => {
    setValues({
      ...values,
      actual_duration: e.target.value,
    });
  };

  const handleForeseenDurationChange = (e) => {
    setValues({
      ...values,
      foreseen_duration: e.target.value,
    });
  };

  // Manage dialog state
  const [open, setOpen] = useState(true); // Set initial state to true to display the modal

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
    props.onClose(); // Ensure the modal closes
  };

  const updateLabels = (updatedLabels) => {
    setValues({
      ...values,
      labels: updatedLabels,
    });
  };

  const handleSave = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await updateTaskLabels(
        token,
        props.card.id,
        pendingLabels.map((label) => label.id),
        setErrorMessage,
        handleOpenDialog,
        navigate
      );
      setTaskLabels(response.labels); // Update task labels with the response from the backend
      setSnackbarMessage("Labels updated successfully!");
      setSnackbarSeverity("success");
      props.setLabelsUpdated(true); // Set the labelsUpdated state to true
    } catch (error) {
      setSnackbarMessage("Failed to update labels.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleToggleLabel = (labelId) => {
    const isAssigned = pendingLabels.some((label) => label.id === labelId);
    if (isAssigned) {
      setPendingLabels(pendingLabels.filter((label) => label.id !== labelId));
    } else {
      const newLabel =
        defaultLabels.find((label) => label.id === labelId) ||
        customLabels.find((label) => label.id === labelId);
      setPendingLabels([...pendingLabels, newLabel]);
    }
  };

  // Function to determine if a color is dark
  const isColorDark = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance < 128;
  };

  // State for confirmation dialog
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirmed) => {
    setConfirmOpen(false);
    if (confirmed) {
      if (JSON.stringify(taskLabels) !== JSON.stringify(pendingLabels)) {
        await handleSave();
      }
      setOpen(false);
      if (props.removeCard) {
        props.removeCard(props.bid, props.id);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Modal onClose={props.onClose}>
      <div
        className="local__bootstrap"
        style={{
          borderRadius: "20px",
          backgroundColor:
            theme.palette.mode === "dark" ? dark.primary : light.primary,
          border: `1px solid ${
            theme.palette.mode === "light" ? light.border : dark.border
          }`,
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            maxWidth: "900px", // Updated width
            position: "relative",
            borderRadius: "1.5rem",
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
          }}
        >
          <div className="row pb-3" style={{ flex: 1 }}>
            <div className="col-8">
              <div
                className="container"
                style={{ position: "relative", borderRadius: "1.5rem" }}
              >
                <div className="row">
                  <div className="d-flex align-items-center align-middle">
                    <TextField
                      variant="outlined"
                      label="Title"
                      value={values.name || ""}
                      sx={{ width: "100%" }}
                      onChange={updateTitle}
                      InputLabelProps={{
                        style: {
                          color:
                            theme.palette.mode === "dark"
                              ? dark.text
                              : light.text,
                        },
                      }}
                      InputProps={{
                        style: {
                          color:
                            theme.palette.mode === "light"
                              ? dark.text
                              : light.text,
                        },
                        inputProps: {
                          style: {
                            color:
                              theme.palette.mode === "dark"
                                ? dark.text
                                : light.text,
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="d-flex align-items-center align-middle">
                    <TextField
                      id="outlined-multiline-static"
                      label="Description"
                      multiline
                      rows={4}
                      value={values.description || ""}
                      onChange={updateDescription}
                      sx={{ width: "inherit" }}
                      InputLabelProps={{
                        style: {
                          color:
                            theme.palette.mode === "dark"
                              ? dark.text
                              : light.text,
                        },
                      }}
                      InputProps={{
                        style: {
                          color:
                            theme.palette.mode === "light"
                              ? dark.text
                              : light.text,
                        },
                        inputProps: {
                          style: {
                            color:
                              theme.palette.mode === "dark"
                                ? dark.text
                                : light.text,
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-6 pr-2">
                    <div
                      className="d-flex align-items-center justify-content-start align-middle"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{
                            "& .MuiInputLabel-root": {
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            },
                            "& .MuiInputBase-input": {
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            },
                          }}
                          label="Start Date"
                          format="DD-MM-YYYY"
                          value={
                            values.start_date
                              ? dayjs(parseDate(values.start_date))
                              : null
                          }
                          onChange={handleStartDateChange}
                          textField={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      className="d-flex align-items-center justify-content-end align-middle"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{
                            "& .MuiInputLabel-root": {
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            },
                            "& .MuiInputBase-input": {
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            },
                          }}
                          label="End Date"
                          format="DD-MM-YYYY"
                          value={
                            values.estimated_end_date
                              ? dayjs(parseDate(values.estimated_end_date))
                              : null
                          }
                          onChange={handleEstimatedEndDateChange}
                          textField={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="row pt-4">
                  <div className="col-6 pr-2">
                    <div className="d-flex align-items-center justify-content-start align-middle">
                      <Tooltip title="Only task owner can change the foreseen duration">
                        <TextField
                          sx={{
                            width: "100%",
                            "& .MuiInputLabel-root": {
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            },
                            "& .MuiInputBase-input": {
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            },
                          }}
                          label="Foreseen Duration"
                          type="number"
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: 160,
                          }}
                          value={
                            values.foreseen_duration
                              ? values.foreseen_duration
                              : 0
                          }
                          onChange={handleForeseenDurationChange}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center justify-content-end align-middle">
                      <Tooltip title="Only task assignee can change the actual duration">
                        <TextField
                          sx={{
                            width: "100%",
                            "& .MuiInputLabel-root": {
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            },
                            "& .MuiInputBase-input": {
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                            },
                          }}
                          label="Actual Duration"
                          type="number"
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: 160,
                          }}
                          value={
                            values.actual_duration ? values.actual_duration : 0
                          }
                          onChange={handleActualDurationChange}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="container" style={{ position: "relative" }}>
                <div className="row-12">
                  <div
                    className="d-flex align-items-center align-middle"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? light.text
                              : dark.text,
                        }}
                      >
                        Assignee
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.assignee || ""}
                        label="Assignee"
                        onChange={handleChange}
                        sx={{
                          minHeight: "40px",
                          justifyContent: "center",
                          "& .MuiInputLabel-root": {
                            color:
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text,
                          },
                          "& .MuiInputBase-input": {
                            color:
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text,
                          },
                        }}
                      >
                        {employees.map((employee) => (
                          <MenuItem key={employee.id} value={employee.id}>
                            {employee.first_name + " " + employee.last_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    className="d-flex align-items-center align-middle"
                    style={{
                      marginBottom: "30px",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? light.text
                              : dark.text,
                        }}
                      >
                        Priority
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.priority || ""}
                        label="Priority"
                        onChange={handlePriorityChange}
                        sx={{
                          minHeight: "40px",
                          justifyContent: "center",
                          "& .MuiInputLabel-root": {
                            color:
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text,
                          },
                          "& .MuiInputBase-input": {
                            color:
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text,
                          },
                        }}
                      >
                        {PRIORITIES.map((priority) => (
                          <MenuItem key={priority.value} value={priority.value}>
                            {priority.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    className="d-flex align-items-center align-middle"
                    style={{
                      marginBottom: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? light.text
                              : dark.text,
                        }}
                      >
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.type || ""}
                        label="type"
                        onChange={handleTypeChange}
                        sx={{
                          minHeight: "40px",
                          justifyContent: "center",
                          "& .MuiInputLabel-root": {
                            color:
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text,
                          },
                          "& .MuiInputBase-input": {
                            color:
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text,
                          },
                        }}
                      >
                        {TYPES.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    className="d-flex align-items-center align-middle"
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "2px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{
                          color:
                            theme.palette.mode === "dark"
                              ? dark.text
                              : light.text,
                        }}
                      >
                        Revision Count:
                      </Typography>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={handleDecreaseRevision}
                        aria-label="decrease revision"
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? dark.text
                              : light.text,
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Chip
                        label={values.revision_count}
                        size="small"
                        color="primary"
                        sx={{
                          marginLeft: "8px",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? light.primary
                              : dark.primary,
                          color:
                            theme.palette.mode === "dark"
                              ? light.text
                              : dark.text,
                          borderRadius: "4px",
                          fontWeight: "bold",
                          minWidth: "28px",
                        }}
                      />
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={handleIncreaseRevision}
                        sx={{
                          color:
                            theme.palette.mode === "dark"
                              ? dark.text
                              : light.text,
                          marginLeft: "8px",
                        }}
                        aria-label="increase revision"
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </div>
                  {values.assigner && (
                    <div
                      style={{
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        style={{
                          marginRight: "8px",
                          color:
                            theme.palette.mode === "dark"
                              ? dark.text
                              : light.text,
                          marginLeft: "20px",
                        }}
                      >
                        Assigner:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{
                          marginRight: "8px",
                          color:
                            theme.palette.mode === "dark"
                              ? dark.text
                              : light.text,
                          marginLeft: "10px", // Adjust this value to move the Assigner label to the right
                        }}
                      >
                        {employees.map(
                          (employee) =>
                            values.assigner === employee.id && (
                              <Chip
                                key={employee.id}
                                label={
                                  employee.first_name + " " + employee.last_name
                                }
                                size="small"
                                color="primary"
                                sx={{
                                  backgroundColor: "rgba(00, 180, 240, 0.6)",
                                  borderRadius: "4px",
                                  fontWeight: "bold",
                                  minWidth: "28px",
                                  marginLeft: "8px",
                                }}
                              />
                            )
                        )}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row-12 d-flex">
              <div className="col-6 d-flex justify-content-start">
                <IconButton
                  sx={{
                    marginLeft: "5px",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#45A049",
                    },
                    "&:active": {
                      backgroundColor: "#357435",
                    },
                    borderRadius: "10%",
                    transition: "background-color 0.3s",
                    marginLeft: "15px",
                    marginTop: "30px",
                  }}
                  aria-label="done"
                  onClick={(e) => {
                    props.handleTaskViewOpen && props.handleTaskViewOpen(e);
                    handleSave(); // Use handleSave function
                  }}
                >
                  <DoneIcon style={{ color: "white" }} />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: "#999",
                    color: "white",
                    borderRadius: "10%",
                    transition: "background-color 0.3s, color 0.3s",
                    marginLeft: "5px",
                    "&:hover": {
                      backgroundColor: "#666",
                    },
                    "&:active": {
                      backgroundColor: "#444",
                    },
                    marginTop: "30px",
                  }}
                  aria-label="close"
                  onClick={handleClose} // Use handleClose function
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </div>
            </div>
          </div>
          <div
            className="col-4"
            style={{
              marginLeft: "20px",
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                marginTop: "15px",
                marginRight: "15px",
                marginLeft: "15px",
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
                borderRadius: "8px",
              }}
            >
              <Box sx={{ height: "20px", display: "flex" }}>
                <SellIcon
                  sx={{
                    marginTop: "14px",
                    marginLeft: "10px",
                    color:
                      theme.palette.mode === "dark" ? dark.text : light.text,
                  }}
                />
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === "dark" ? dark.text : light.text,
                    padding: "8px 8px 0 8px",
                    fontSize: "1rem",
                    marginTop: "5px",
                  }}
                >
                  Labels
                </Typography>
              </Box>
              <Box
                sx={{
                  overflowY: "auto",
                  height: "240px", // Increased height
                  padding: "8px",
                  marginTop: "25px",
                }}
              >
                <Grid container spacing={1}>
                  {defaultLabels.map((label) => {
                    const isAssigned = pendingLabels.some(
                      (taskLabel) => taskLabel.id === label.id
                    );
                    return (
                      <Grid item xs="auto" key={label.id}>
                        <Box
                          onClick={() => handleToggleLabel(label.id)}
                          sx={{
                            display: "flex",
                            maxWidth: "fit-content",
                            justifyContent: "flex-start",
                            border: `1px solid ${
                              theme.palette.mode === "light"
                                ? darken(label.color, 0.5)
                                : lighten(label.color, 0.5)
                            }`,
                            backgroundColor: isAssigned
                              ? label.color
                              : theme.palette.mode === "light"
                              ? lighten(label.color, 0.5)
                              : darken(label.color, 0.5),
                            borderRadius: "10px",
                            padding: "7px",
                            margin: "0px",
                            cursor: "pointer",
                            opacity: isAssigned ? 1 : 0.5,
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "0.9rem",
                              color:
                                theme.palette.mode === "light"
                                  ? isColorDark(label.color)
                                    ? lighten(label.color, 0.8)
                                    : darken(label.color, 0.8)
                                  : lighten(label.color, 0.8),
                            }}
                          >
                            {label.name}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                  {customLabels.map((label) => {
                    const isAssigned = pendingLabels.some(
                      (taskLabel) => taskLabel.id === label.id
                    );
                    return (
                      <Grid item xs="auto" key={label.id}>
                        <Box
                          onClick={() => handleToggleLabel(label.id)}
                          sx={{
                            display: "flex",
                            maxWidth: "fit-content",
                            justifyContent: "flex-start",
                            border: `1px solid ${
                              theme.palette.mode === "light"
                                ? darken(label.color, 0.5)
                                : lighten(label.color, 0.5)
                            }`,
                            backgroundColor: isAssigned
                              ? label.color
                              : theme.palette.mode === "light"
                              ? lighten(label.color, 0.5)
                              : darken(label.color, 0.5),
                            borderRadius: "10px",
                            padding: "7px",
                            margin: "0px",
                            cursor: "pointer",
                            opacity: isAssigned ? 1 : 0.5,
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "0.9rem",
                              color:
                                theme.palette.mode === "light"
                                  ? isColorDark(label.color)
                                    ? lighten(label.color, 0.8)
                                    : darken(label.color, 0.8)
                                  : lighten(label.color, 0.8),
                            }}
                          >
                            {label.name}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "4px 8px",
                  height: "10px",
                  cursor: "pointer",
                  marginTop: "25px",
                }}
                onClick={() => setOpenLabelDialog(true)}
              >
                <Box
                  sx={{
                    marginBottom: "30px",
                    display: "flex",
                    marginTop: "5px",
                  }}
                >
                  <AppRegistrationIcon
                    style={{
                      color:
                        theme.palette.mode === "light"
                          ? light.buttonIcon
                          : dark.buttonIcon,
                      fontSize: "16px",
                      marginRight: "8px",
                    }}
                  />
                  <Typography
                    sx={{
                      marginTop: "2px",
                      fontSize: "12px",
                      color:
                        theme.palette.mode === "dark" ? dark.text : light.text,
                    }}
                  >
                    Edit Labels of {props.projectInfo.abbr}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <div>
              <IconButton
                onClick={handleConfirmOpen} // Open confirmation dialog
                aria-label="delete"
                sx={{
                  backgroundColor: "#FF5722",
                  marginTop: "50px",
                  marginLeft: "230px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#E64A19",
                  },
                  "&:active": {
                    backgroundColor: "#D84315",
                  },
                  borderRadius: "10%",
                  transition: "background-color 0.3s",
                }}
              >
                <DeleteIcon style={{ color: "white" }} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmOpen}
        onClose={() => handleConfirmClose(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle
          id="confirm-dialog-title"
          sx={{
            color: theme.palette.mode === "dark" ? dark.text : light.text,
          }}
        >
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <Typography
            id="confirm-dialog-description"
            sx={{
              color: theme.palette.mode === "dark" ? dark.text : light.text,
            }}
          >
            Are you sure you want to delete this task?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmClose(false)}
            sx={{
              color: theme.palette.mode === "dark" ? dark.text : light.text,
            }}
          >
            No
          </Button>
          <Button
            onClick={() => handleConfirmClose(true)}
            sx={{
              color: theme.palette.mode === "dark" ? dark.text : light.text,
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <ProjectLabelsSection
        open={openLabelDialog}
        onClose={handleLabelsDialogClose}
        projectName={props.projectInfo.abbr}
        projectId={props.projectInfo.id}
      />

      {/* Snackbar for alerts */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Modal>
  );
}
