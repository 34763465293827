import {
    ScheduleComponent,
    Day,
    Week,
    WorkWeek,
    Month,
    Agenda,
    Inject,
    ViewDirective,
    ViewsDirective, TimelineViews,

} from "@syncfusion/ej2-react-schedule";
import './index.css';
import React, {useEffect, useState, createRef, useRef} from "react";
import axios from "../../axios/index";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";
import { DropDownTree } from "@syncfusion/ej2/dropdowns";
import {DateTimePickerComponent, TimePickerComponent} from "@syncfusion/ej2-react-calendars";

import {Box, Card, CardContent, CardHeader, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import DatePicker from '@mui/lab/DatePicker';
import TextField from "@mui/material/TextField";
import { handleTokenExpiration } from "../../axios/tokenUtils";
import { Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from "@mui/material/";
import {useNavigate} from "react-router-dom";
import { Background } from "devextreme-react/vector-map";
import { a } from "@react-spring/web";






function refineDoorCardLogs(jsonData) {
    const doorCardLogs = jsonData.sortedAttendances;
    const modifiedData = [];

    for (let index = 0; index < doorCardLogs.length; index++) {
        const dateTimes = doorCardLogs[index];

        const processedDateTimes = processDateTimes(dateTimes);

        // Create a new object with "StartTime" and "EndTime" keys
        const renamedObject = {
            StartTime: processedDateTimes[0],
            EndTime: processedDateTimes[1],
            description: "Door Card",
            projectName: "Kartlı Geçiş"
        };

        modifiedData.push(renamedObject);
    }

    return modifiedData;
}  
function processDateTimes(dateTimes) {
if (dateTimes.length === 1) {
    // Case 1: Only one value
    // Add 5 minutes to the date and time and create a new value
    const originalDateTime = new Date(dateTimes[0]);
    const newDateTime = new Date(originalDateTime.getTime() + 5 * 60 * 1000);
    return [dateTimes[0], newDateTime.toISOString()];
} else if (dateTimes.length >= 2) {
    // Case 2: More than 2 values
    // Return the first and last value
    return [dateTimes[0], dateTimes[dateTimes.length - 1]];
} else {
    // Case 3: Exactly two values
    // Return the same values
    return dateTimes;
}
}

const TimerCreate = () => {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false); // State for dialog open/close
    const [errorMessage, setErrorMessage] = useState(null);
    const handleOpenDialog = () => {
        setOpenDialog(true);
      };
      
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [projects, setProjects] = useState([]);
    const [isProjectsDataFetched, setIsProjectsDataFetched] = useState(false);
    const [isUserSlotsDataFetched, setIsUserSlotsDataFetched] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [enteredDescription, setEnteredDescription] = useState('');


    const [doorCardData, setDoorCardData] = useState([]);
    const [data, setData] = useState([]);


    useEffect(() => {
    }, [enteredDescription]);


    const token = sessionStorage.getItem("token");
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const [dialogTimeOpen, setDialogTimeOpen] = useState(false);
    const handleTimeDialogClose= () => {
        setDialogTimeOpen(false);
    };

    const handleErrorDialogClose = () => {
        setErrorDialogOpen(false);
    };

    const getUserSlots = async () => {
        try
        {
            const res = await axios
            .get("api/project/get-user-slots", {
                headers: {
                    Authorization: token,
                },
            })
                if (res && res.data) {
                    setIsUserSlotsDataFetched(true);
                    setData([]);
                    for (let i = 0; i < res.data.length; i++) {
                        setData((oldData) => [
                            ...oldData,
                            {
                                Id: res.data[i].id,
                                IsBlock: res.data[i].IsBlock,
                                Subject: res.data[i].projectWorkPackage,
                                StartTime: res.data[i].startDate,
                                EndTime: res.data[i].endDate,
                                description: res.data[i].description,
                                totalhour: res.data[i].totalhour,
                            },
                        ]);
                }
                }
        }
        catch(error)
        {
            handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
        }
    }

    const getDoorTime = async () => {
        try
        {
            const res = await axios
            .get("api/users/get-door-logs-by-request", {
                headers: {
                    Authorization: token,
                },
            })
            if (res && res.data) 
            {
                const modifiedCardDoorLog = refineDoorCardLogs(res.data)
                //console.log("modified Card Door Log: ", modifiedCardDoorLog)
                setDoorCardData(modifiedCardDoorLog)
            }
        }
        catch(error)
        {
            handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
        }
    }
    


    const getWorkPackages = async () => {
        try
        {
            const res = await axios
            .get("api/users/get-employee-project-work-package", {
                headers: {
                    Authorization: token,
                },
                params: {
                    is_only_active : true,
                  }
            })
                setIsProjectsDataFetched(true);
                if (res && res.data) {
                    
                    setProjects(res.data);
                }

        }
        catch(error)
        {
            handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
        }
    }

    useEffect(() => {
        getUserSlots();
        getDoorTime();
        getWorkPackages();
    }, []);

    const saveTimeSlot = async (timeSlot) => {
        try
        {
            const res = await axios
            .post("api/project/submit-slot", timeSlot, {
                headers: {
                    Authorization: token,
                },
            })
                if (res && res.data) {
                    getUserSlots();
            }
        }
        catch(error)
        {
            handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
        }
    };

    const updateTimeSlot = async (updatedTimeSlot) => {
        try
        {
            const res =  await axios
            .post("api/project/update-slot", updatedTimeSlot, {
                headers: {
                    Authorization: token,
                },
            })
                if (res && res.data) {
                    
                    getUserSlots();
                }
        }
        catch(error)
        {
            handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
        }
    };


    function addDays(date, days) 
    {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
    }

    const copyTimeSlot = async (props) => {
        const copyTimeSlot =
        {
            endDate: props.EndTime,
            startDate: props.StartTime,
            related_workpackage_id: getProjectIdByName(props.Subject).slice(0, -1),
            description: props.description,
        };
        
        copyTimeSlot.startDate = addDays(props.StartTime, 1)
        copyTimeSlot.endDate = addDays(props.EndTime, 1)
        const isSlotAvailable = scheduleRef.current.isSlotAvailable(
            copyTimeSlot.startDate,
            copyTimeSlot.endDate
        );
        
        if (isSlotAvailable) {
            saveTimeSlot(copyTimeSlot);
        }
    }


    const deleteTimeSlot = async (deleteTimeSlot) => {
        try
        {
            const res = await axios
            .post("api/project/delete-slot", deleteTimeSlot, {
                headers: {
                    Authorization: token,
                },
            })
            if (res && res.data) 
            {
                    getUserSlots();
            }
        }
        catch(error)
        {
            handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
        }
    };

    const onActionBegin = (args) => {
        if (args.data) {
            let data;
            if (args.requestType === "eventCreate")
            {
                data = args.data[0];
                const related_workpackage_id = getProjectIdByName(data.Subject).slice(0, -1);

                const timeSlot =
                {
                    id: data.Id,
                    startDate: data.StartTime,
                    endDate: data.EndTime,
                    related_workpackage_id: related_workpackage_id,
                    description: data.Description,
                };

                if (!data.Description || data.Description.trim() === "") {
                    args.cancel = true;
                    setErrorDialogOpen(true);
                }
                else if (data.StartTime > data.EndTime) {
                    args.cancel = true;
                    setDialogTimeOpen(true);
                }
                else {
                    const isSlotAvailable = scheduleRef.current.isSlotAvailable(
                        timeSlot.startDate,
                        timeSlot.endDate
                    );

                    if (isSlotAvailable) {
                        saveTimeSlot(timeSlot);
                    }
                    else {
                        args.cancel = true;
                        setDialogOpen(true);
                    }
                }
            }
            else if (args.requestType === "eventRemove")
            {
                data = args.data[0];
                const deletedTimeSlot =
                    {
                        id: data.Id,
                    };
                deleteTimeSlot(deletedTimeSlot);
            }
            else if (args.requestType === "eventChange")
            {
                data = args.data;
                const updatedTimeSlot =
                    {
                        id: data.Id,
                        startDate: data.StartTime,
                        endDate: data.EndTime ,
                        related_workpackage: getProjectIdByName(data.Subject).slice(0, -1),
                        description: data.Description ,
                    };
                if (!data.Description || data.Description.trim() === "") {
                    args.cancel = true;
                    setErrorDialogOpen(true);
                }
                else if (data.StartTime > data.EndTime) {
                    args.cancel = true;
                    setDialogTimeOpen(true);
                }
                else {
                    const isSlotAvailable = scheduleRef.current.isSlotAvailable(
                        updatedTimeSlot.startDate,
                        updatedTimeSlot.endDate
                    );

                    if (isSlotAvailable) {
                        updateTimeSlot(updatedTimeSlot);
                    } else {
                        args.cancel = true;
                        setDialogOpen(true);
                    }
                }
            }
        }
    };

    const dayDemo = new Date()
    useEffect(() => {
        
    }, []);

    const popUpHeader = (props) => {
        return (
            <div>
                {props.elementType === "cell" ? (
                    <div className="e-cell-header">
                        <div className="e-header-icon-wrapper">
                            <button className="e-close" title="Close"></button>
                        </div>
                    </div>
                ) : (
                    <Card>
                        <Card>
                            <CardHeader
                                title={getProjectNameById(props.Subject)}
                            />
                        </Card>
                    </Card>
                )}
            </div>
        );
    };


    const [openTreeDialog, setOpenTreeDialog] = React.useState(false);

    const handleTreeClose = () => {
        setOpenTreeDialog(false);
    };

    const popUpContent = (props) => {
        let fields = { dataSource: projects, value: 'id', text: 'name', child: 'work_packages' };
        const dropdownTreeRef = React.createRef();

        const onChange = (args) => {
            const selectedNode = dropdownTreeRef.current.treeObj.selectedNodes;
            if(selectedNode.length > 0) {
                const selectedNodeFull = dropdownTreeRef.current.treeObj.getNode(selectedNode[0]);
                
                if(selectedNodeFull.parentID === null) {
                    
                    dropdownTreeRef.current.treeObj.expandAll([selectedNode[0]]);
                    dropdownTreeRef.current.value = null;
                    dropdownTreeRef.current.hidePopup();
                    alert('Please expand the project to select a work package');
                    // setOpenTreeDialog(true); // Open the error dialog
                    setTimeout(() => {
                        dropdownTreeRef.current.showPopup();
                    }, 0);

                    requestAnimationFrame(() => {
                        dropdownTreeRef.current.showPopup();
                    });
                }
            }
        }
        return (
            <div>
                {props.elementType === "cell" ? (
                    <div className="e-cell-content">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <DropDownTreeComponent
                                    id="Project"
                                    ref={dropdownTreeRef}
                                    placeholder="Çalıştığın Proje"
                                    data-name="Subject"
                                    className="e-field"
                                    style={{ width: "100%" }}
                                    enabled="false"
                                    expandOn={true}
                                    fields={fields}
                                    showDropDownIcon="false"
                                    ensureVisible={true}
                                    change={onChange}
                                ></DropDownTreeComponent>
                            </Grid>
                            <Grid item xs={12}>
                                <textarea
                                    required
                                    id="Description"
                                    className="e-field e-input"
                                    name="Description"
                                    data-name="Description"
                                    placeholder="Bugün Rekrom için ne yaptın?"
                                    rows={3}
                                    cols={50}
                                    style={{
                                        width: "100%",
                                        height: "60px !important",
                                        resize: "vertical",
                                    }}
                                ></textarea>
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        <Card
                            style={{
                                width: "100%",
                                height: "30px !important",
                                resize: "vertical",
                            }}
                        >
                            <CardHeader
                                subheader={`Total Hour: ${
                                    ((props.EndTime - props.StartTime) / 3600000).toFixed(1)
                                }`}
                            />
                            <CardContent>
                                <Typography variant="body1" component="p">
                                    Description: {props.description
                                    ? props.description
                                    : "No description provided."}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Button onClick={() => copyTimeSlot(props)}>Copy This Slot</Button>
                    </Box>
                )}
            </div>
        );
    };

    // const [slotId, setSlotId] = useState();

    function editorTemplate(props) {
        let slotId = 0

        if (props.Id) {
            slotId =  props.Id
        }

        const result = data.filter(
            (slot) => {
                return slot.Id === slotId
        })

        if(result[0]) {
            let subject = result[0].Subject
            subject = subject.replaceAll(':', '-')
            for(let i = 0; i < projects.length; i++) {
                let el = projects[i]
                for(let j = 0; j < el.work_packages.length; j++) {
                    let el2 = el.work_packages[j]
                    if(el2.name === subject) {
                        slotId = el2.id
                        break
                    }
                }
            }
        }
        let fields = { dataSource: projects, value: 'id', text: 'name', child: 'work_packages' };
        const dropdownTreeRef = React.createRef();

        const onChange = (args) => {
            const selectedNode = dropdownTreeRef.current.treeObj.selectedNodes;
            if(selectedNode.length > 0) {
                const selectedNodeFull = dropdownTreeRef.current.treeObj.getNode(selectedNode[0]);
                
                if(selectedNodeFull.parentID === null) {
                    dropdownTreeRef.current.treeObj.expandAll([selectedNode[0]]);
                    dropdownTreeRef.current.value = null;
                    dropdownTreeRef.current.hidePopup();
                    alert('Please expand the project to select a work package');
                    // setOpenTreeDialog(true); // Open the error dialog
                    setTimeout(() => {
                        dropdownTreeRef.current.showPopup();
                    }, 0);
                }
            }
        }


        return props !== undefined ? (
            <table className="schedule-dialog" style={{width: "100%"}} cellPadding={5}>
                <tbody>
                <tr>
                    <td className="e-textlabel">Project</td>
                    <td colSpan={4}>
                        <DropDownTreeComponent
                            id="Project"
                            ref={dropdownTreeRef}
                            placeholder="Çalıştığın Proje"
                            data-name="Subject"
                            className="e-field"
                            style={{ width: "100%" }}
                            enabled="false"
                            expandOn={true}
                            fields={fields}
                            showDropDownIcon="false"
                            ensureVisible={true}
                            change={onChange}
                            value={(()=> {
                                return [slotId.toString()]
                            })()}
                        ></DropDownTreeComponent>
                    </td>
                </tr>
                <tr>
                    <td className="e-textlabel">Start Time</td>
                    <td colSpan={4}>
                        <DateTimePickerComponent
                            id="StartTime"
                            format="dd/MM/yy hh:mm a"
                            data-name="StartTime"
                            value={new Date(props.startTime || props.StartTime)}
                            className="e-field"
                        ></DateTimePickerComponent>
                    </td>
                </tr>
                <tr>
                    <td className="e-textlabel">End Time</td>
                    <DateTimePickerComponent
                        id="EndTime"
                        format="dd/MM/yy hh:mm a"
                        data-name="EndTime"
                        value={new Date(props.endTime || props.EndTime)}
                        className="e-field"
                    ></DateTimePickerComponent>
                </tr>
                <tr>
                    <td className="e-textlabel">Description</td>
                        <td colSpan={4}><textarea
              id="Description"
              className="e-field e-input"
              name="Description"
              data-name="Description"
              placeholder="Bugün Rekrom için ne yaptın?"
              rows={3}
              cols={50}
              style={{
                  width: "100%",
                  height: "60px !important",
                  resize: "vertical",
                                }}>{props.description}</textarea>
                    </td>
                </tr>
                </tbody>
            </table>
        ) : (
            <div></div>
        );
    }

    const getProjectNameById = (id) => {
        if (typeof id === "number") {
            const project = projects.find((project) => project.id === id);
            return project ? project.name : '';
        }
        else return id
    };


    const getProjectIdByName = (combinedName) => 
    {
        if (typeof combinedName === "string") {
            const [projectName, workPackageName] = combinedName.split(' - ');
            const project = projects.find((project) => project.name === projectName.trim());
            let workPackage = {}
            if (project) {
                project.work_packages.forEach(wp => {
                    if (wp.name === combinedName) {
                        workPackage = wp
                    }
                })
                return workPackage ? workPackage.id : '';
            }
            return '';
        }
        else return combinedName;
    };

    const eventTemplate = (props) => {
        const projectName = getProjectNameById(props.Subject);
        return (
            <div className="custom-event">
                <div className="custom-event-subject">{projectName ? projectName : "Kartlı Geçiş" }</div>
                <div className="custom-event-time">
                    {
                        new Intl.DateTimeFormat(navigator.language, {
                            hour: "2-digit",
                            minute: "2-digit",
                        }).format(props.StartTime)
                    } - {
                    new Intl.DateTimeFormat(navigator.language, {
                        hour: "2-digit",
                        minute: "2-digit",
                    }).format(props.EndTime)
                }
                </div>
            </div>
        );
    };

    function getDateHeaderDay(value) {
        return new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(value);
    }

    function getDateHeaderDate(value) {
        return new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(value);
    }

    const DateHeaderTemplate = (props) => {
        const { date } = props;
        let workloadHours = 0;

        const targetDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().substring(0, 10);
        for (let i = 0; i < data.length; i++)
        {
            let datadate = new Date(data[i].StartTime);
            // Compare formatted strings instead of Date objects
            let formattedDataDate = datadate.toISOString().substring(0,10);
            if (formattedDataDate === targetDate)
            {
                workloadHours += data[i].totalhour;
            }
        }
        return (
            <div>
                <div>
                    {getDateHeaderDate(date)} - {getDateHeaderDay(date)}
                </div>
                <div className="date-text">Total: {workloadHours.toFixed(1)} hours</div>
            </div>
        );
    };

    const dataBound = () => {
        if (scheduleRef && scheduleRef.current) {
            const currentHour = new Date().getHours();
            const formattedHour = currentHour < 10 ? `0${currentHour}:00` : `${currentHour}:00`;
            scheduleRef.current.scrollTo(formattedHour);
        }
    };

    const scheduleRef = useRef(null);

    const onNavigate = (args) => {
        setSelectedDate(args.currentDate);
    };

    const [selectedDate, setSelectedDate] = useState(new Date()); // Set the selected date to 10th July 2023
    //console.log("data", data)
    //console.log("doorCardData", doorCardData)

    const onEventRendered = (args) => {
        applyCategoryColor(args, scheduleRef.current.currentView);
    }

    const applyCategoryColor = (args, currentView) => {
        let categoryColor = args.data.CategoryColor;
        let width = args.data.width; 
        //console.log("args", args)
        if(args.data.description === "Door Card") 
        {
            categoryColor = "#FFAD5A"
            width = "50%"
        }
        args.element.style.backgroundColor = categoryColor;
        args.element.style.width = width; 

    }
    
    return (
        <>
            {/* Your other JSX components */}
            <div>
                <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">Error</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                    OK
                    </Button>
                </DialogActions>
                </Dialog>
            </div>
            <title>Scheduler</title>
            {isProjectsDataFetched && isUserSlotsDataFetched ? (
                <>
                    <ScheduleComponent 
                        ref={scheduleRef}
                        width="100%"
                        height="85vh"
                        selectedDate={selectedDate}
                        currentView="Week"
                        navigating={onNavigate.bind(this)}
                        actionBegin={onActionBegin.bind(this)}
                        dataBound={dataBound.bind(this)}
                        startHour='00:00'
                        endHour='24:00'
                        minDate={new Date(2019,2,8)}
                        firstDayOfWeek={1}
                        showWeekNumber={true}
                        dateHeaderTemplate={DateHeaderTemplate.bind(this)}
                        editorTemplate={editorTemplate.bind(this)}
                        eventTemplate={eventTemplate.bind(this)}
                        eventSettings={{
                            enableTooltip: true,
                            template: eventTemplate,
                            dataSource: data.concat(doorCardData),
                            // dataSource: doorCardData,
                            fields: {
                                id: "Id",
                                subject: { name: "Subject", title: "whattt" },
                                startTime: "StartTime",
                                endTime: "EndTime",
                                description: { name: "description", title: "Açıklama" },
                                workpackage: { name: "Subject", title: "Work Package" },
                            },
                        }}
                        
                        eventRendered={onEventRendered}
                        
                        workDays={[1, 2, 3, 4, 5]}
                        workHours={{
                            start: '07:00',
                            end: '23:00',
                            highlight: true,
                        }}
                        workingHour={{
                            start: '08:00',
                            end: '18:00',
                            isWorking: false
                        }}
                        quickInfoTemplates={{
                            header: popUpHeader.bind(this),
                            content: popUpContent.bind(this),
                        }}
                        showTimeIndicator={true}
                        quickInfoOnSelectionEnd={true}
                        allowKeyboardInteraction={true}
                    >
                        <ViewsDirective>
                            <ViewDirective option="Day" />
                            <ViewDirective option="Week" />
                            <ViewDirective option="WorkWeek" />
                            <ViewDirective option="Month" />
                            <ViewDirective option="Agenda" />
                        </ViewsDirective>
                        <Inject services={[Day, Week, WorkWeek, Month, Agenda, TimelineViews]} />
                    </ScheduleComponent>
                    <Dialog open={errorDialogOpen} onClose={handleErrorDialogClose}>
                        <DialogTitle>{"Error"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please provide a description for the event.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleErrorDialogClose} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={dialogOpen} onClose={handleDialogClose}>
                        <DialogTitle>{"Time Slot Unavailable"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                The selected time slot is already occupied. Please choose another time.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={dialogTimeOpen} onClose={handleTimeDialogClose}>
                        <DialogTitle>{"Invalid time interval"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                start time cannot be later than end time
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleTimeDialogClose} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (<div>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="50vh"
                    >
                    <CircularProgress/>
                    </Box>
                </div>)
            }
        </>
    );
};

export default TimerCreate;