import {
	CardHeader,
	Alert,
	AlertTitle,
	Box,
	Button,
	Card,
	CardContent,
	Container,
	Divider,
	Grid,
	MenuItem,
	TextField,
	Typography,
  } from '@mui/material';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import jwt_decode from 'jwt-decode';
import {  useEffect, useState } from 'react';
import * as Yup from 'yup';
import axios from '../../../axios/index';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { InfoBox } from '../../../components/rekrom/InfoBox/index';
import { handleTokenExpiration } from '../../../axios/tokenUtils';

const paid_type = [
	{
		value: 1,
		label: 'Ücretli',
	},
	{
		value: 2,
		label: 'Ücretsiz',
	},
];

const location_types = [
	{
		value: 1,
		label: 'Şehir İçi',
	},
	{
		value: 2,
		label: 'Şehir Dışı',
	},
];

const excuse_types = [
	{
		value: 1,
		label: 'Yıllık İzin',
	},
	{
		value: 2,
		label: 'Sıhhi İzin',
	},
];

const DayOffCreate = () => {
const navigate = useNavigate();

const [openDialog, setOpenDialog] = useState(false); // State for dialog open/close
	
	
const [errorMessage, setErrorMessage] = useState(null);
	
	const handleOpenDialog = () => {
		setOpenDialog(true);
	  };
	
	  const handleCloseDialog = () => {
		setOpenDialog(false);
	  };

	const location = useLocation();
	const profileInformation = location.state && location.state.profileInformation;

	let token = sessionStorage.getItem('token');

	const decode = jwt_decode(token);

	const today = new Date();
	const date = format(today, 'yyyy-MM-dd');
	const futureDate = today.getDate() + 1;
	today.setDate(futureDate);
	const newDate = format(today, 'yyyy-MM-dd');

	const [startDate, setStartDate] = useState(date);
	const [endDate, setEndDate] = useState(newDate);
	const [dateRange, setDateRange] = useState(newDate);
	const [back2WorkDate, setBack2WorkDate] = useState(newDate);

	const employee = {
		id: profileInformation.id,
		name: profileInformation.firstName + ' ' + profileInformation.lastName,
	};

	const formik = useFormik({
		initialValues: {
			requested_by: '',
			description: 'Aşağıdaki tarihlerde ve sürede izin kullanmak istiyorum. Gereğini arz ederim.',
			day_off_start: date,
			day_off_end: newDate,
			paid_type: 1,
			location_type: 1,
			excuse_type: 1,
			date_range: 0,
			back_2_work_date: newDate,
			timeStamp: new Date(),
		},
		validationSchema: Yup.object({
			requested_by: Yup.number(),
			description: Yup.string().required('Day off description is required'),
			day_off_start: Yup.date()
				.min(date, 'Day off start date should be later than today')
				.max(endDate, 'Day off start date should be earlier than end date')
				.required('Day off start date is required'),
			day_off_end: Yup.date()
				.min(startDate, 'Day off start date should be later than start date')
				.required('Day off end date is required'),
			date_range: Yup.number()
			.required('Day off range is required')
			.min(0, 'Day off range should be greater than 0'),
			back_2_work_date: Yup.date()
				.min(endDate, 'Back to work date should be later than day off end date')
				.required('Back to work date is required'),
			timeStamp: Yup.date(),
		}),
		onSubmit: async (values) => {
			formik.values.requested_by = employee.id;
			try
			{
				const res = await axios
				.post('api/users/request-leave-form', values, {
					headers: {
						Authorization: token,
					},
				})

				if (res && res.data) {
					//console.log("res.data", res.data);
					setErrorMessage("succesfully created")
					handleOpenDialog();
					navigate("/profile") 
				}
			}
			catch(error)
			{
				handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate)

			}

				
		},
	});

	const adjustStartDate = (e) => {
		setStartDate(e.target.value);
	};

	const adjustEndDate = (e) => {
		setEndDate(e.target.value);
	};

	const adjustDateRange = (e) => {
		setDateRange(e.target.value);
	}

	const adjustBack2WorkDate = (e) => {
		setBack2WorkDate(e.target.value);
	}

	return (
		<>
			<InfoBox errorMessage={errorMessage} openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
			<form onSubmit={formik.handleSubmit}>
			<Box mt={2}>
			<Button
				component={Link} // Use the Link component
				to="/profile" // Replace with the actual URL of the user profile page
				variant="outlined"
				color="primary"
			>
				Back to Profile
			</Button>
			</Box>
				<title>Create Day Off Form</title>
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						py: 8,
					}}
				>
					<Container maxWidth="lg">
						<Card>
							<CardHeader title="Day Off Information" />
							<Divider />
							<Grid
								container
								justifyContent="center"
								direction="row"
								spacing={3}
							>
								<Grid item md={6} xs={12}>
									<TextField
										error={Boolean(
											formik.touched.requested_by && formik.errors.requested_by
										)}
										fullWidth
										helperText={
											formik.touched.requested_by && formik.errors.requested_by
										}
										label="Employee Name"
										name="requested_by"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={employee.name}
										disabled
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										error={Boolean(
											formik.touched.description && formik.errors.description
										)}
										fullWidth
										helperText={
											formik.touched.description && formik.errors.description
										}
										label="Day off Description"
										name="description"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.description}
										variant="outlined"
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										error={Boolean(
											formik.touched.day_off_start &&
												formik.errors.day_off_start
										)}
										helperText={
											formik.touched.day_off_start &&
											formik.errors.day_off_start
										}
										fullWidth
										label="Day off Start Date"
										name="day_off_start"
										onBlur={formik.handleBlur}
										onChange={(e) => {
											formik.handleChange(e);
											adjustStartDate(e);
										}}
										type="date"
										value={formik.values.day_off_start}
										variant="outlined"
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										error={Boolean(
											formik.touched.day_off_end && formik.errors.day_off_end
										)}
										helperText={
											formik.touched.day_off_end && formik.errors.day_off_end
										}
										fullWidth
										label="Day off End Date"
										name="day_off_end"
										onBlur={formik.handleBlur}
										onChange={(e) => {
											formik.handleChange(e);
											adjustEndDate(e);
										}}
										type="date"
										value={formik.values.day_off_end}
										variant="outlined"
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										error={Boolean(
											formik.touched.day_off_range && formik.errors.day_off_range
										)}
										helperText={
											formik.touched.day_off_range && formik.errors.day_off_range
										}
										fullWidth
										label="Day off Range(Days)"
										name="day_off_range"
										onBlur={formik.handleBlur}
										onChange={(e) => {
											formik.handleChange(e);
											adjustDateRange(e);
										}}
										type="number"
										value={formik.values.day_off_range}
										variant="outlined"
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										error={Boolean(
											formik.touched.back_2_work_date && formik.errors.back_2_work_date
										)}
										helperText={
											formik.touched.back_2_work_date && formik.errors.back_2_work_date
										}
										fullWidth
										label="Back to Work Date"
										name="back_2_work_date"
										onBlur={formik.handleBlur}
										onChange={(e) => {
											formik.handleChange(e);
											adjustBack2WorkDate(e);
										}}
										type="date"
										value={formik.values.back_2_work_date}
										variant="outlined"
									/>
								</Grid>
								<Grid item md={6} xs={12}>
								<TextField
									select
									fullWidth
									label="Day off Type"
									name="paid_type"
									value={formik.values.paid_type}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									variant="outlined"
									>
									{paid_type.map((paid_type) => (
										<MenuItem key={paid_type.value} value={paid_type.value}>
										{paid_type.label}
										</MenuItem>
									))}
									</TextField>
								</Grid>
								<Grid item md={6} xs={12}>
								<TextField
									select
									fullWidth
									label="Day off Location"
									name="location_type"
									value={formik.values.location_type}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									variant="outlined"
									>
									{location_types.map((location_type) => (
										<MenuItem key={location_type.value} value={location_type.value}>
										{location_type.label}
										</MenuItem>
									))}
								</TextField>
								</Grid>
								<Grid item md={6} xs={12}>
								<TextField
								select
								fullWidth
								label="Day off**"
								name="excuse_type"
								value={formik.values.excuse_type}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								variant="outlined"
								>
								{excuse_types.map((excuse_type) => (
									<MenuItem key={excuse_type.value} value={excuse_type.value}>
									{excuse_type.label}
									</MenuItem>
								))}
								</TextField>
								</Grid>
							</Grid>
							{/* Submit Button */}
							<Box mt={3} display="flex" justifyContent="center">
							<Button
								color="primary"
								size="large"
								variant="outlined"
								type="submit"
							>
								Submit Form
							</Button>
							</Box>
						</Card>
					</Container>
				</Box>
			</form>
		</>
	);
};

export default DayOffCreate;
