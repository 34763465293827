import { useState, useEffect } from "react";
import React from "react";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

// Material Dashboard 2 React routes
import routes from "routes";
import RequireAuth from "RequireAuth";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password/cover";
import Error from "layouts/404/";
// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import { DataProvider } from "context";

// Images
import brandWhite from "assets/images/rekrom-logo-sq.jpg";
import brandDark from "assets/images/rekrom-logo-sq.jpg";


// Notes component
import Notes from "components/Notes";
// Themes
import getDesignTokens from "theme";

import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";


import "./App.css";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();


  const { main: light } = baseColors;
  const { main: dark } = darkColors;


  const token = sessionStorage.getItem("token");
  const is_staff = sessionStorage.getItem("is_staff");
  const is_superuser = sessionStorage.getItem("is_superuser");

  const [isSideNavFixed, setSideNavFixed] = useState(false);
  const changeSidenavFixed = () => {
    //TODO PIN LOGIC
    setSideNavFixed(!isSideNavFixed);
    if (isSideNavFixed) {
      setOnMouseEnter(false);
    }
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (!isSideNavFixed) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (!isSideNavFixed) {
      if (onMouseEnter) {
        setMiniSidenav(dispatch, true);
        setOnMouseEnter(false);
      }
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const [mode, setMode] = useState("light");
  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);


  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };


  const getRoutes = (routes) =>
    routes.map((route) => {
      if (route.route && route.route.includes("admin")) {
        return (
          <Route
            exact
            path={route.route}
            element={
              <RequireAuth>
                <DashboardLayout>
                  <DashboardNavbar toggleColorMode={() => toggleColorMode()} />
                  {route.component}
                </DashboardLayout>
              </RequireAuth>
            }
            key={route.key}
          />
        );
      } else if (route.route && route.route.includes("admin")) {
        return null;
      } else if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              <RequireAuth>
                <DashboardLayout>

                  <DashboardNavbar toggleColorMode={() => toggleColorMode()} />
                  {route.component}
                </DashboardLayout>
              </RequireAuth>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  function getFilteredRoutes(routes) {
    const filteredRoutes = [];
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].is_staff_required === true) {
        if (is_staff === "true" || is_superuser === "true") {
          filteredRoutes.push(routes[i]);
        }
      } else {
        filteredRoutes.push(routes[i]);
      }
    }
    return filteredRoutes;
  }
  const filteredRoutes = getFilteredRoutes(routes);

  return (

    <DataProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            is_superuser={is_superuser}
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Rekrom Optoelektronik"
            routes={filteredRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            isSideNavFixed={isSideNavFixed}
            changeSidenavFixed={changeSidenavFixed}
          />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {!token ? (
          <Route
            exact
            path="/"
            element={<Navigate to="/authentication/sign-in" />}
          />
        ) : (
          <Route exact path="/" element={<Navigate to="/dashboard" />} />
        )}
        <Route exact path="/authentication/sign-in" element={<SignIn />} />
        <Route exact path="/authentication/sign-up" element={<SignUp />} />
        <Route
          exact
          path="/authentication/reset-password"
          element={<ResetPassword />}
        />
        <Route exact path="/*" element={<Error />} />
        {getRoutes(filteredRoutes)}
      </Routes>
      {token && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            width: isExpanded ? "30%" : "12%",
            height: isExpanded ? "85%" : "5%",
            zIndex: 1000,
            transition: "width 0.3s, height 0.3s",
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : "#6e6e73"
            }`,
          }}
        >
          <div
            onClick={toggleExpand}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              backgroundColor:
                theme.palette.mode === "dark" ? dark.primary : light.primary,
              cursor: "pointer",
            }}
          >
            {!isExpanded && (
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                  marginLeft: "20px",
                  marginTop: "-10px", // Adjust margin to move text up
                  fontWeight: "bold",
                }}
              >
                My Notes
              </Typography>
            )}
            <IconButton
              style={{
                marginTop: "-9px", // Adjust margin to move icon up
                marginLeft: isExpanded ? "-10px" : "0px", // Move ExpandLessIcon to the left
              }}
            >
              {isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </div>
          {isExpanded && <Notes />}
        </div>
      )}
    </ThemeProvider>
    </DataProvider>

  );
}
