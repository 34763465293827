import React from "react";
import "./Modal.css";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

const Modal = (props) => {
  const theme = useTheme();
  const { primary: light } = baseColors;
  const { primary: dark } = darkColors;

  return React.createElement(
    "div",
    {
      className: "custom__modal",
      onClick: () => props.onClose(false),
      style: {
        backgroundColor:
          theme.palette.mode === "light" ? light.primary : dark.primary,
      },
    },
    React.createElement(
      "div",
      {
        className: "modal__content mw-75 p-3",
        onClick: (event) => event.stopPropagation(),
        style: {
          backgroundColor:
            theme.palette.mode === "light" ? light.primary : dark.primary,
        },
      },
      props.children
    )
  );
};

export default Modal;
