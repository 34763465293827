import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import {
  Box,
  IconButton,
  Typography,
  Popover,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import { Favorite, Info as InfoIcon } from "@mui/icons-material";
import axios from "../../axios";


import { handleTokenExpiration } from "../../axios/tokenUtils";
import Board from "../../components/Kanban/Board/Board";
import {
  getFavoriteWorkSpaces,
  toggleFavoriteWorkSpace,
  fetchWorkSpaceInformation,
  fetchAllProjects,
} from "../../apis/apis";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";

const Kanban = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const location = useLocation();
  const { highlightedTaskId, projectId } = location.state || {
    taskId: null,
    projectId: null,
  };

  const [projects, setProjects] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { workspaceId } = useParams();
  const [incomingData, setIncomingData] = useState([]);
  const [favoriteWorkspaces, setFavoriteWorkspaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [workspaceInfo, setWorkspaceInfo] = useState({});
  const [projectInfo, setProjectInfo] = useState({});
  const token = sessionStorage.getItem("token");
  const currentUser = jwtDecode(token);

  const AXIOS_HEADERS = {
    headers: {
      Authorization: token,
    },
  };

  const [isFetchedFavoriteWorkspaces, setIsFetchedFavoriteWorkspaces] =
    useState(false);

  const fetchFavoriteWorkspaces = () => {
    setLoading(true);
    setError(null);
    getFavoriteWorkSpaces(token, setErrorMessage, handleOpenDialog, navigate)
      .then((favoriteWorkspacesData) => {
        setFavoriteWorkspaces(favoriteWorkspacesData);
        setIsFetchedFavoriteWorkspaces(false);
        for (let i = 0; i < favoriteWorkspacesData.length; i++) {
          if (
            favoriteWorkspacesData[i].related_work_package_id == workspaceId
          ) {
            setIsFetchedFavoriteWorkspaces(true);
            break;
          }
        }
      })
      .catch((error) => {
        setError(error.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFavoriteWorkspaces();
  }, []);

  const fetchWorkspaceData = async () => {
    try {
      const response = await axios.get(
        `api/project/get-tasks-by-work-space/${workspaceId}`,
        AXIOS_HEADERS
      );
      if (response && response.data) {
        console.log("Workspace Data:", response.data);
        setIncomingData(response.data);
      }
    } catch (error) {
      handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
  };

  const getAllProjects = async () => {
    try {
      const response = await fetchAllProjects(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate
      );
      console.log("Projects:", response);
      if (response) {
        setProjects(response);
        findRelatedProject(response, workspaceInfo.related_work_package_id);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const findRelatedProject = (projects, workPackageId) => {
    for (let project of projects) {
      for (let workPackage of project.work_packages) {
        if (workPackage.id === workPackageId) {
          console.log("Related Project:", project);
          setProjectInfo(project);
          console.log("Project Info:", projectInfo);
          return;
        }
      }
    }
  };

  useEffect(() => {
    getAllProjects();
  }, [workspaceInfo.related_work_package_id]);

  const [employees, setEmployees] = useState([]);
  const fetchWorkspaceEmployees = async () => {
    try {
      const response = await axios.get(
        `api/project/get-work-package-assigned-employees/${workspaceId}`,
        AXIOS_HEADERS
      );
      if (response && response.data) {
        setEmployees(response.data);
      }
    } catch (error) {
      handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
  };

  useEffect(() => {
    fetchWorkspaceData();
    fetchWorkspaceEmployees();
  }, []);

  const fetchWorkspaceInfo = async () => {
    try {
      const data = await fetchWorkSpaceInformation(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate,
        workspaceId
      );
      setWorkspaceInfo(data);
      console.log("Workspace info:", data);
    } catch (error) {
      console.error("Error fetching workspace info:", error);
    }
  };

  useEffect(() => {
    fetchWorkspaceInfo();
  }, []);

  const [newCard, setNewCard] = useState({});
  useEffect(() => {
    const createKanbanCard = async () => {
      try {
        if (newCard.name !== undefined) {
          await axios.post(
            `api/project/create-kanban-card/${workspaceId}`,
            newCard,
            AXIOS_HEADERS
          );
          fetchWorkspaceData();
        }
      } catch (error) {
        handleTokenExpiration(
          error,
          setErrorMessage,
          handleOpenDialog,
          navigate
        );
      }
    };
    if (newCard.name !== undefined) {
      createKanbanCard();
    }
  }, [newCard.name]);

  const [newTask, setNewTask] = useState({});
  const [isTaskAdded, setIsTaskAdded] = useState(false);
  const [cardId, setCardId] = useState("");

  const handleAddTask = (title, tid, orderIndex) => {
    if (
      tid === incomingData.kanban_cards[3]?.id &&
      workspaceInfo.owner_username !== currentUser.username
    ) {
      setSnackbarMessage(
        "You do not have permission to add tasks to this card!"
      );
      setSnackbarOpen(true);
      return;
    }

    setIsTaskAdded(!isTaskAdded);
    setNewTask({
      name: title,
      order_index: orderIndex + 1,
    });
    setCardId(tid);
  };

  useEffect(() => {
    const createTask = async () => {
      try {
        if (newTask.name !== undefined) {
          await axios.post(
            `api/project/create-task/${cardId}`,
            newTask,
            AXIOS_HEADERS
          );
          fetchWorkspaceData();
        }
      } catch (error) {
        handleTokenExpiration(
          error,
          setErrorMessage,
          handleOpenDialog,
          navigate
        );
      }
    };
    if (newTask.name !== undefined) {
      createTask();
    }
  }, [isTaskAdded, newTask]);

  const [isTaskDeleted, setIsTaskDeleted] = useState(false);
  const [deletedTaskId, setDeletedTaskId] = useState(0);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (!isTaskDeleted) {
      return;
    }
    const deleteTask = async () => {
      try {
        await axios.delete(
          `api/project/delete-task/${deletedTaskId}`,
          AXIOS_HEADERS
        );
        fetchWorkspaceData();
        setIsTaskDeleted(false);
      } catch (error) {
        handleTokenExpiration(
          error,
          setErrorMessage,
          handleOpenDialog,
          navigate
        );
      }
    };
    deleteTask();
  }, [isTaskDeleted, deletedTaskId]);

  const addToFavorites = async () => {
    try {
      await toggleFavoriteWorkSpace(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate,
        workspaceId
      );
      fetchFavoriteWorkspaces();
    } catch (error) {
      handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
  };

  const setName = (title, bid) => {};

  const dragCardInBoard = (source, destination) => {
    const tempData = JSON.parse(JSON.stringify(incomingData));

    const destinationBoard = tempData.find(
      (board) => board.id.toString() === destination.droppableId
    );
    const sourceBoard = tempData.find(
      (board) => board.id.toString() === source.droppableId
    );

    const [movedCard] = sourceBoard.card.splice(source.index, 1);
    destinationBoard.card.splice(destination.index, 0, movedCard);

    return tempData;
  };

  const updateTaskOrder = async (taskId, orderIndex, cardId) => {
    try {
      const data = {
        order_index: orderIndex,
        related_kanban_card: cardId,
      };
      await axios.patch(
        `api/project/update-task/${taskId}`,
        data,
        AXIOS_HEADERS
      );
    } catch (error) {
      console.log("api call error", error);
      handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
    fetchWorkspaceData();
  };

  const updateTaskInDetail = async (value, taskId) => {
    try {
      const data = {
        ...value,
      };
      const response = await axios.patch(
        `/api/project/update-task/${taskId}`,
        data,
        AXIOS_HEADERS
      );
      console.log("Task update response:", response.data);
    } catch (error) {
      handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
    fetchWorkspaceData();
  };

  const updateTaskIndices = async (workspace) => {
    try {
      const data = {
        tasks: workspace,
      };
      await axios.patch(`api/project/update-task-indices`, data, AXIOS_HEADERS);
    } catch (error) {
      handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
    fetchWorkspaceData();
  };

  const removeCard = (boardId, cardId) => {
    setIsTaskDeleted(true);
    setDeletedTaskId(cardId);
  };

  const onDragEnd = async (result) => {
    const { source, destination, type } = result;
    if (!destination) return;

    const isOwner = workspaceInfo.owner_username === currentUser.username;

    const involvesFourthCard =
      source.droppableId === incomingData.kanban_cards[3]?.id.toString() ||
      destination.droppableId === incomingData.kanban_cards[3]?.id.toString();

    if (involvesFourthCard && !isOwner) {
      setSnackbarMessage("You do not have permission to do that!");
      setSnackbarOpen(true);
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (type === "BOARD") {
      const newKanbanCards = Array.from(incomingData.kanban_cards);
      const [reorderedBoard] = newKanbanCards.splice(source.index, 1);
      newKanbanCards.splice(destination.index, 0, reorderedBoard);
      setIncomingData({
        ...incomingData,
        kanban_cards: newKanbanCards,
      });
    } else {
      const newKanbanCards = [...incomingData.kanban_cards];
      const sourceBoardIndex = newKanbanCards.findIndex(
        (board) => board.id.toString() === source.droppableId
      );
      const sourceBoard = newKanbanCards[sourceBoardIndex];
      const [movedTask] = sourceBoard.tasks.splice(source.index, 1);
      if (source.droppableId === destination.droppableId) {
        sourceBoard.tasks.splice(destination.index, 0, movedTask);
        sourceBoard.tasks.forEach((task, index) => {
          task.order_index = index;
        });
        await updateTaskOrder(
          movedTask.id,
          destination.index,
          destination.droppableId
        );
        await updateTaskIndices(sourceBoard.tasks);
      } else {
        const destBoardIndex = newKanbanCards.findIndex(
          (board) => board.id.toString() === destination.droppableId
        );
        const destBoard = newKanbanCards[destBoardIndex];
        destBoard.tasks.splice(destination.index, 0, movedTask);
        sourceBoard.tasks.forEach((task, index) => {
          task.order_index = index;
        });
        destBoard.tasks.forEach((task, index) => {
          task.order_index = index;
        });
        await updateTaskOrder(
          movedTask.id,
          destination.index,
          destination.droppableId
        );
        await updateTaskIndices(sourceBoard.tasks);
      }
      setIncomingData({
        ...incomingData,
        kanban_cards: newKanbanCards,
      });
    }
  };

  const handleUpdateTask = async (value, taskId) => {
    if (checkUpdateTask(value)) {
      await updateTaskInDetail(value, taskId);
    } else {
      setErrorMessage("Error");
      handleOpenDialog();
    }
  };

  const checkUpdateTask = (value) => {
    if (
      true ||
      (value.name != null &&
        value.description != null &&
        value.start_date != null &&
        value.end_date != null &&
        value.estimated_time != null &&
        value.assigned_to != null)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [infoAnchorEl, setInfoAnchorEl] = useState(null);
  const formattedDate = workspaceInfo.created_at
    ? dayjs(workspaceInfo.created_at).format("DD/MM/YYYY")
    : "N/A";

  const handleInfoClick = (event) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoAnchorEl(null);
  };

  const openInfo = Boolean(infoAnchorEl);
  const id = openInfo ? "simple-popover" : undefined;

  const borderColors = ["#ADD8E6", "#FFD700", "#32CD32", "#8A2BE2"];

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              marginLeft: "20px",
              fontWeight: "600",
              color: theme.palette.mode === "light" ? light.text : dark.text,
              display: "inline-block",
            }}
          >
            {incomingData.work_space_name}
          </Typography>
          <IconButton
            onClick={handleInfoClick}
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,

              marginLeft: "2px",
            }}
          >
            <InfoIcon />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            aria-label="add to favorites"
            sx={{
              color: isFetchedFavoriteWorkspaces ? "red" : "gray",
            }}
            onClick={addToFavorites}
          >
            <Favorite />
          </IconButton>
        </Box>
      </Box>

      <Popover
        id={id}
        open={openInfo}
        anchorEl={infoAnchorEl}
        onClose={handleInfoClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography fontSize={17}>
            Work Package: {workspaceInfo.related_work_package_name}
          </Typography>
          <Typography fontSize={17}>
            Owner: {workspaceInfo.owner_name}
          </Typography>
          <Typography fontSize={17}>Creation Date: {formattedDate}</Typography>
        </Box>
      </Popover>

      <DragDropContext onDragEnd={onDragEnd}>
        <Box
          sx={{
            height: "71vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
            borderRadius: "10px",
            transition: "all 350ms",
            overflowX: "auto",
          }}
        >
          <Box sx={{ flex: 1, width: "100%" }}>
            <Box
              sx={{
                marginTop: "20px",
                minWidth: "fit-content",
                display: "flex",
                gap: "30px",
                paddingInline: "2rem",
                color: "var(--text-color)",
              }}
            >
              <Droppable
                droppableId="all-boards"
                direction="horizontal"
                type="BOARD"
              >
                {(provided) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {incomingData &&
                      incomingData.kanban_cards &&
                      incomingData.kanban_cards.map((item, index) => (
                        <Box key={item.id}>
                          <Board
                           
                            employees={employees}
                            id={item.id}
                            name={item.name}
                            card={item.tasks}
                            setName={setName}
                            addNewTask={handleAddTask}
                            removeCard={removeCard}
                            handleUpdateTask={handleUpdateTask}
                            highlightedTaskId={highlightedTaskId}
                            backgroundColor={
                              theme.palette.mode === "light"
                                ? light.secondary
                                : dark.tertiary
                            }
                            textColor={
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text
                            }
                            cardBackgroundColor={
                              theme.palette.mode === "light"
                                ? light.primary
                                : dark.primary
                            }
                            borderColor={
                              borderColors[index] || "rgba(0, 0, 0, 0.125)"
                            }
                            projectInfo={projectInfo}
                          />
                        </Box>
                      ))}
                  </Box>
                )}
              </Droppable>
            </Box>
          </Box>
        </Box>
      </DragDropContext>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Kanban;
