import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {useEffect, useState} from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

function getStyles(emp, selectedEmployees, theme) {
    return {
        fontWeight:
            selectedEmployees.find(employee => employee.id === emp.id)
                ? theme.typography.fontWeightMedium
                : theme.typography.fontWeightRegular,
    };
}

export default function MultipleSelectDropdown({setSelectedEmployeesParent, employees}) {
    const theme = useTheme();

    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        //console.log('SELECTED EMPLOYEES')
        //console.log(value)
        const selected = typeof value === 'string' ? value.split(',') : value;
        setSelectedEmployees(selected);
        setSelectedEmployeesParent(selected)
    };

    useEffect(() => {
        //console.log("SELECTED EMPLOYEES USE EFFECT")
        //console.log(selectedEmployees)
    }, [selectedEmployees]);


    return (
        <div>
            <FormControl sx={{ m: 2, width: '100%'}}>
                <InputLabel id="demo-multiple-chip-label">Employees</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedEmployees}
                    onChange={handleChange}
                    sx={{
                        minHeight: '45px'
                    }}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {selected.map((emp) => (
                                <Chip key={emp.id} label={emp.name} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {employees.map((emp) => (
                        <MenuItem
                            key={emp.id}
                            value={emp}
                            style={getStyles(emp, selectedEmployees, theme)}
                        >
                            {emp.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}