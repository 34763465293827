const styles = {
    themeColors: {
        primary: "#457b9d",
        textSecondary: "rgba(0, 0, 0, 0.54)",
    },
    
    commonStyles: {
        fontFamily: "Arial, Helvetica, sans-serif",
    },

    card: { 
        boxShadow: `0 0 15px #457b9d`,
        width: "100%",
    },

    title: {
        fontFamily: "Arial, Helvetica, sans-serif",
        fontVariant: "large",
        fontWeight: "900",
        color: "#457b9d",
        marginTop: 1,
    },

    subtitle: {
        fontFamily: "Arial, Helvetica, sans-serif",
        color: "rgba(0, 0, 0, 0.54)",
        marginTop: 1,
    },

    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },

    inputContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },

    input: {
        "& .MuiInputBase-input": {
          color: "#000",
        },
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2e2e2e",
            borderWidth: "2px",
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "secondary.main",
              borderWidth: "3px",
            },
          },
        },
        "& .MuiInputLabel-outlined": {
          color: "#2e2e2e",
          fontWeight: "bold",
          "&.Mui-focused": {
            color: "secondary.main",
            fontWeight: "bold",
          },
        },
        width: "60%",
      },

    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginBottom: 1,
    },

    button: {
        width: "auto",
        padding: "6px 20px",
        borderRadius: "15px",
        color: "#fff",
        backgroundColor: "#457b9d",
    },

    messageBox: {
        marginTop: 1,
        marginBottom: 3,
    },
};

export default styles;