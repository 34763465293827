import SignIn from "./layouts/authentication/sign-in";
import { Alert, AlertTitle } from "@mui/material";
import { useState, useEffect } from "react";

const RequireAuth = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState("You need to login first!");

    useEffect(() => {
        const localToken = localStorage.getItem("localToken");
        if (localToken !== null) {
            sessionStorage.setItem("token", localToken);
        }
    }, []);

    const token = sessionStorage.getItem("token");

    if (!token) {
        return (
            <>
                {errorMessage &&
                    <Alert
                        severity="error"
                        onClose={() => {
                            setErrorMessage("");
                        }}
                    >
                        <AlertTitle>Error</AlertTitle>
                        {errorMessage}
                    </Alert>
                }
                <SignIn />
            </>
        );
    }
    return children;
};

export default RequireAuth;
