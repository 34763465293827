import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Popover,
  IconButton,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import { IoAddOutline, IoChevronDown, IoChevronUp } from "react-icons/io5";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import {
  fetchProjectsWorkPackagesWorkSpaces,
  toggleFavoriteWorkSpace,
  fetchTopWorkSpaces,
} from "../../../../apis/apis/index";
import { Favorite } from "@mui/icons-material";

const FavoriteWorkSpaces = ({
  favoritedWorkspaceError,
  expandedProject,
  setExpandedProject,
  handleProjectClick,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
}) => {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const [anchorEl, setAnchorEl] = useState(null);
  const [projectsData, setProjectsData] = useState([]);
  const [favoriteWorkSpaces, setFavoriteWorkSpaces] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [revertAction, setRevertAction] = useState(null);

  console.log("Favorite workspaces:", favoriteWorkSpaces);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const favorites = await fetchTopWorkSpaces(
          token,
          setErrorMessage,
          handleOpenDialog,
          navigate
        );
        setFavoriteWorkSpaces(favorites);
        console.log("burası:", favorites);
      } catch (error) {
        console.error("Error fetching favorite workspaces:", error);
      }
    };

    fetchFavorites();
  }, []);

  const handlePopoverOpen = async (event) => {
    setAnchorEl(event.currentTarget);
    try {
      const data = await fetchProjectsWorkPackagesWorkSpaces(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate
      );
      setProjectsData(data);
      console.log("Projects data:", projectsData);
    } catch (error) {
      console.error("Error fetching projects data:", error);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const isValidPercentage = (percentage) => {
    return percentage && typeof percentage.color === "string";
  };

  const getPercentageColor = (percentage) => {
    if (isValidPercentage(percentage)) {
      return percentage.color;
    }
    console.warn("Invalid percentage object", percentage);
    return "text";
  };

  const addToFavorites = async (workspaceId, closePopover = false) => {
    if (!workspaceId) {
      console.error("Invalid workspace ID:", workspaceId);
      setErrorMessage("Invalid workspace ID. Please try again.");
      handleOpenDialog();
      return;
    }

    try {
      console.log(`Adding workspace ID ${workspaceId} to favorites`);
      await toggleFavoriteWorkSpace(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate,
        workspaceId
      );
      // Update favorite workspaces list
      const updatedFavorites = await fetchTopWorkSpaces(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate
      );
      setFavoriteWorkSpaces(updatedFavorites);
      if (closePopover) {
        handlePopoverClose();
      }
      setSnackbarMessage("Workspace added to favorites.");
      setSnackbarOpen(true);
    } catch (error) {
      console.error(
        `Error adding workspace ID ${workspaceId} to favorites:`,
        error
      );
      setErrorMessage(
        "Failed to add workspace to favorites. Please try again."
      );
      handleOpenDialog();
    }
  };

  const removeFromFavorites = async (workspaceId) => {
    if (!workspaceId) {
      console.error("Invalid workspace ID:", workspaceId);
      setErrorMessage("Invalid workspace ID. Please try again.");
      handleOpenDialog();
      return;
    }

    try {
      console.log(`Removing workspace ID ${workspaceId} from favorites`);
      await toggleFavoriteWorkSpace(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate,
        workspaceId
      );
      // Update favorite workspaces list
      const updatedFavorites = await fetchTopWorkSpaces(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate
      );
      setFavoriteWorkSpaces(updatedFavorites);
      setSnackbarMessage("Workspace removed from favorites.");
      setRevertAction(() => () => addToFavorites(workspaceId));
      setSnackbarOpen(true);
    } catch (error) {
      console.error(
        `Error removing workspace ID ${workspaceId} from favorites:`,
        error
      );
      setErrorMessage(
        "Failed to remove workspace from favorites. Please try again."
      );
      handleOpenDialog();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setRevertAction(null);
  };

  const filterFavoriteWorkSpaces = (workspaces) => {
    const favoriteWorkspaceIds = new Set(
      favoriteWorkSpaces.map((workspace) => workspace.related_work_package)
    );
    return workspaces.filter(
      (workspace) => !favoriteWorkspaceIds.has(workspace.id)
    );
  };

  const isWorkspaceFavorite = (workspaceId) => {
    return favoriteWorkSpaces.some(
      (workspace) => workspace.related_work_package === workspaceId
    );
  };

  const handleInfoClick = (id) => {
    // Define the behavior for the info button click here
    console.log(`Info button clicked for workspace ID: ${id}`);
  };

  return (
    <Box
      xs={12}
      md={6}
      lg={3}
      sx={{
        backgroundColor:
          theme.palette.mode === "light" ? light.primary : dark.primary,
        marginBottom: "20px",
        padding: "10px",
        borderRadius: "10px",
        boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
        height: "340px",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            padding: "15px",
            paddingBottom: "40px",
            color: theme.palette.mode === "light" ? light.text : dark.text,
          }}
        >
          Favorite Work Spaces
        </Typography>

        {favoritedWorkspaceError && (
          <Typography
            variant="h4"
            sx={{
              fontWeight: "400",
              padding: "4px",
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            No favorited work spaces found
          </Typography>
        )}
      </Box>
      <Box mb={5}>
        <Grid container spacing={1}>
          {favoriteWorkSpaces.map((data) => {
            return (
              data.related_work_package && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  key={data.related_work_package.toString()}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? light.primary
                          : dark.primary,
                    }}
                    padding={1}
                  >
                    <ComplexStatisticsCard
                      color="dark"
                      title={data.name}
                      id={data.related_work_package}
                      owner={data.owner}
                      createdAt={data.created_at}
                      relatedWorkPackageName={data.related_work_package_name}
                      key={data.related_work_package}
                      count={data.total_task_count}
                      percentage={data.percentage || {}}
                      isFavorite={true}
                      onFavoriteClick={(id) => removeFromFavorites(id)}
                      onInfoClick={handleInfoClick}
                    >
                      <Typography
                        component="span"
                        variant="button"
                        fontWeight="bold"
                        color={getPercentageColor(data?.percentage)}
                      >
                        {data?.percentage?.amount || ""}
                      </Typography>
                      &nbsp;{data?.percentage?.label || ""}
                    </ComplexStatisticsCard>
                  </MDBox>
                </Grid>
              )
            );
          })}
          {favoriteWorkSpaces.length < 4 && (
            <Grid item>
              <MDBox
                sx={{
                  borderRadius: "10%",
                  border: `1px solid ${
                    theme.palette.mode === "light" ? light.border : dark.border
                  }`,
                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIcon
                      : dark.buttonIcon,
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.tertiary
                      : dark.buttonBackground,
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.buttonBackgroundHover
                        : dark.buttonBackgroundHover,
                    cursor: "pointer",
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIconHover
                        : dark.buttonIconHover,
                    border: `1px solid ${
                      theme.palette.mode === "light"
                        ? light.border
                        : dark.border
                    }`,
                  },
                  width: "fit-content",
                  height: "fit-content",
                  display: "inline-block",
                  textAlign: "center",
                  lineHeight: "0",
                  padding: "0.25rem",
                  marginTop: "0.5rem",
                }}
                onClick={handlePopoverOpen}
              >
                <IoAddOutline size={30} />
              </MDBox>
              <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  sx: {
                    p: 2,
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.primary
                        : dark.primary,
                    borderRadius: 2,
                    boxShadow: 1,
                    width: "300px",
                    height: "400px",
                    overflow: "auto",
                    border: `1px solid ${
                      theme.palette.mode === "light"
                        ? light.border
                        : dark.border
                    }`,
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                  }}
                >
                  Projects
                </Typography>
                {projectsData &&
                  projectsData.map((project) => (
                    <Box key={project.id}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          mt: 1,
                          "&:hover": {
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? light.tertiary
                                : dark.tertiary,
                          },
                          pl: 1,
                          pr: 1,
                        }}
                        onClick={() => handleProjectClick(project.id)}
                      >
                        <Typography fontSize={12}>{project.name}</Typography>
                        <IconButton>
                          {expandedProject === project.id ? (
                            <IoChevronUp />
                          ) : (
                            <IoChevronDown />
                          )}
                        </IconButton>
                      </Box>
                      {expandedProject === project.id &&
                        project.work_packages && (
                          <Box sx={{ pl: 4 }}>
                            {filterFavoriteWorkSpaces(
                              project.work_packages
                            ).map((workPackage) => {
                              console.log("Work package data:", workPackage); // Log the full work package object
                              const workspaceId = workPackage.workspace
                                ? workPackage.workspace.related_work_package
                                : workPackage.related_work_package;
                              return (
                                <Box key={workPackage.id} sx={{ pl: 4 }}>
                                  {workPackage.workspace && (
                                    <Box key={workPackage.id} sx={{ pl: 4 }}>
                                      <Box sx={{ pl: 4 }}>
                                        <Typography
                                          sx={{
                                            cursor: "pointer",
                                            marginTop: "0.5rem",
                                            "&:hover": {
                                              color:
                                                theme.palette.mode === "light"
                                                  ? light.text
                                                  : dark.text,
                                            },
                                          }}
                                          fontSize={12}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            addToFavorites(workspaceId, true);
                                          }}
                                        >
                                          {workPackage.workspace.name}
                                        </Typography>
                                      </Box>
                                      {!workPackage.workspace && (
                                        <Box>
                                          <Typography
                                            sx={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              addToFavorites(workspaceId, true);
                                            }}
                                          ></Typography>
                                          <IconButton
                                            aria-label="add to favorites"
                                            sx={{
                                              color: isWorkspaceFavorite(
                                                workspaceId
                                              )
                                                ? "red"
                                                : "gray",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              addToFavorites(workspaceId, true);
                                            }}
                                          >
                                            <Favorite />
                                          </IconButton>
                                        </Box>
                                      )}
                                    </Box>
                                  )}
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                    </Box>
                  ))}
              </Popover>
            </Grid>
          )}
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          action={
            revertAction && (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  revertAction();
                  handleSnackbarClose();
                }}
              >
                UNDO
              </Button>
            )
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FavoriteWorkSpaces;
