// @mui material components
import Grid from "@mui/material/Grid";

import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DateRangePicker } from "react-date-range";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // Import the styles
import "react-date-range/dist/theme/default.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  TablePagination,
  TableSortLabel,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "../../axios";
import FilterDialog from "../../components/Report/Dialog";
import PerfectScrollbar from "react-perfect-scrollbar";
import { handleTokenExpiration } from "../../axios/tokenUtils";
import { useNavigate } from "react-router-dom";
import { InfoBox } from "components/rekrom/InfoBox";

function Report() {
  const [openDialog, setOpenDialog] = useState(false); // State for dialog open/close
  const [errorMessage, setErrorMessage] = useState(null);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const token = sessionStorage.getItem("token");
  
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeNames, setEmployeeNames] = useState([]);

  const now = new Date();

  const [state, setState] = useState([
    {
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [filterOption, setFilterOption] = useState("show-all-non-zero");


  // func to handle radiobutton selections
  const handleOptionSelect = (value) => {
    //console.log("Received in parent:", value);
    setFilterOption(value);
  };

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  
  const handleEmployeeSelect = (value) => {
    //console.log("CHECK PARENT COMPONENT")
    //console.log(value)
    setSelectedEmployees(value)
    //console.log(selectedEmployees)
  };

  const [selectedProjects, setSelectedProjects] = useState([]);
  
  const handleProjectSelect = (value) => {
    //console.log("CHECK PARENT COMPONENT")
    //console.log(value)
    setSelectedProjects(value)
  };

  const [searchButton, setSearchButton] = useState(false);

  const [projects, setProjects] = useState([]);
  
  const [responseStatus, setResponseStatus] = useState(400)
  
  const [isFetchedFilteredData, setNotFetchedYet] = useState(false);

  useEffect(() => {
    const selectDateRange = async () => {
      setNotFetchedYet(false);
      try {
        const res = await axios.post(
          "api/project/report-filter-by-date",
          {
            startDate: state[0].startDate,
            endDate: state[0].endDate,
            filterOptions: filterOption,
            selectedEmployees: selectedEmployees.map((emp) => emp.id),
            selectedProjects: selectedProjects.map((project) => project.id),
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res && res.data) {
          setNotFetchedYet(true);
          
          setEmployeeData([]);
          
          setEmployeeData(res.data.detail_list);
        }
      } catch (error) {
        handleTokenExpiration(
          error,
          setErrorMessage,
          handleOpenDialog,
          useNavigate
        );
      }
    };
    const fetchEmployeeNames = async () => {
      try {
        const res = await axios.get("api/users/get-active-employee-names", {
          headers: {
            Authorization: token,
          },
        });
        if (res && res.data) {
          const newEmployeeNames = res.data.map((employee) => ({
            name: employee.first_name + " " + employee.last_name,
            id: employee.id,
          }));
          setEmployeeNames((oldData) => [...oldData, ...newEmployeeNames]);
        }
      } catch (error) {
        handleTokenExpiration(
          error,
          setErrorMessage,
          handleOpenDialog,
          useNavigate
        );
      }
    };
    const fetchProjects = async () => {
      try {
        const res = await axios.get("api/project/get-all-projects-filtering", {
          headers: {
            Authorization: token,
          },
        });
        if (res && res.data) {
          setProjects(res.data);
        }
      } catch (error) {
        handleTokenExpiration(
          error,
          setErrorMessage,
          handleOpenDialog,
          useNavigate
        );
      }
    };
    fetchEmployeeNames();
    selectDateRange();
    fetchProjects().then(() => console.log('projects received'));
  }, [searchButton, selectedEmployees, filterOption]);

  const [expanded, setExpanded] = React.useState(false);

  const handleTimeIntervalClick = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleRowClick = (emp_id) => {
    //console.log(emp_id);  // You will be able to see the emp_id in your console.
    // your code here...
};

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    
    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }} onClick={() => handleRowClick(row.employee_id)}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.employee_full_name}
          </TableCell>
          <TableCell align="right">{row.totalHour}</TableCell>
          <TableCell align="right">{row.leaveHour}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Box>
                    <Typography variant="h6" gutterBottom component="div">
                      Detail
                    </Typography>
                  </Box>
                  
                </Box>
                <Table size="small" aria-label="purchases">
                  <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <TableCell>Project</TableCell>
                      <TableCell>Total Hour</TableCell>
                      <TableCell>Leave Hour</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.projects.map((detailRow) => (
                      <TableRow key={detailRow.name}>
                        <TableCell component="th" scope="row" resiz>
                          {detailRow.name}
                        </TableCell>
                        <TableCell>{detailRow.totalHour}</TableCell>
                        <TableCell>{detailRow.leaveHour}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleButtonClicked = () => {
    //console.log(state)
    setExpanded(!expanded);
    setSearchButton(!searchButton);
    setIsButtonClicked(true); // Set to true when button is clicked
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const CollapsibleTable = () => {
    return (
      <>
        <Card>
          <CardHeader title="Employee Report" />
          {/* Time Interval Picker */}
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <Box sx={{}}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleTimeIntervalClick("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Grid
                    container
                    spacing={2}
                    xs={12} md={12} lg={12}
                    direction="column"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center", // This centers the content horizontally
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        marginBottom: "-20px",
                      }}
                    >
                      <Typography sx={{ width: "100%", flexShrink: 0 }}>
                        Choose another time interval
                      </Typography>
                    </Grid>
                    <Grid item sx={{}}>
                      <Typography
                        sx={{
                          fontWeight: "300",
                        }}
                      >
                        ({state[0].startDate.toLocaleDateString()} -{" "} {state[0].endDate.toLocaleDateString()})
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <form>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <DateRangePicker
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={state}
                          label="username"
                          name="username"
                          variant="outlined"
                          onChange={(item) => setState([item.selection])}
                          value={state}
                          direction="horizontal"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          sx={{
                            color: "#fff",
                          }}
                          onClick={handleButtonClicked}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <FilterDialog
                filter={handleOptionSelect}
                filterEmployee={handleEmployeeSelect}
                filterEmployeesByProjects={handleProjectSelect}
                employees={employeeNames}
                projects={projects}
              />
            </Box>
          </Box>
          {/* Time Interval Picker */}
          <Divider />
          <CardContent>
            <PerfectScrollbar>
              <Box>
                <Table stickyHeader>
                  <TableHead sx={{
                      display: "table-header-group",
                      backgroundColor: "red !important",
                    }}>
                    <TableRow sx={{ backgroundColor: "white" }}>
                      <TableCell style={{ backgroundColor: "white" }} />
                      <TableCell style={{ backgroundColor: "white" }} component={"th"}>Employee</TableCell>
                      <TableCell style={{ backgroundColor: "white" }} align="right">Total Hours</TableCell>
                      <TableCell style={{ backgroundColor: "white" }} align="right">Leave Hours</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? employeeData.slice(page * rowsPerPage,page * rowsPerPage + rowsPerPage)
                      : employeeData
                    ).map((row) => (
                      <Row key={row.employee_id} row={row} />
                    ))}
                  </TableBody>
                  <TablePagination
                    rowsPerPageOptions={[3, 5, 10]}
                    component="div"
                    count={employeeData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Table>
              </Box>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <>
        <InfoBox errorMessage={errorMessage} openDialog={openDialog} handleCloseDialog={handleCloseDialog}/>        


        {isFetchedFilteredData ? (
          <>
            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                    width: '100%',
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            lg={12}
                            md={12}
                            xl={15}
                            xs={12}
                        >
                            <CollapsibleTable/>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
          </>
        ) : (<div>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="50vh"
            >
                <CircularProgress/>
            </Box>
        </div>)}
    </>
  );
}

export default Report;