import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/rekrom-background-v2.png";
import Box from "@mui/material/Box";
import { CircularProgress, Divider } from "@mui/material";
import styles from "./style.js";
import Button from "@mui/material/Button";
import { resetPassword } from "apis/apis/index.js";
import { useState } from "react";
import Alert from "@mui/material/Alert";

function Cover() {
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSendResetEmail = async () => {
    setError('');
    setMessage('');
    setLoading(true);

    try{
      const res = await resetPassword(email);

      setMessage(res['message']);
      setError('');
      setLoading(false);
    }
    catch(error){
      console.log(error.response.data['error']);
      setError(error.response.data['error']);
      setMessage('');
      setLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={styles.card}>
        <Box pt={2} textAlign="center">
          <MDTypography variant="h2" fontWeight="medium" sx={styles.title}>
            Reset Password
          </MDTypography>
          <MDTypography variant="body2" sx={styles.subtitle}>
            You will receive an e-mail in maximum 10-15 minutes.
          </MDTypography>
        </Box>
        <Divider />
        <MDBox component="form" role="form" sx={styles.form}>
          <MDBox mb={4} sx={styles.inputContainer}>
            <MDInput
              type="email"
              label="E-mail"
              variant="standard"
              InputProps={{
                style: { color: styles.themeColors.primary },
              }}
              sx={styles.input}
              onChange={(event) => setEmail(event.target.value)}
            />
          </MDBox>
          <MDBox sx={styles.buttonContainer}>
            <Button onClick={handleSendResetEmail} style={styles.button} disabled={loading || !email}>
              SEND RESET E-MAIL
            </Button>
            <MDBox sx={styles.loadingContainer}>
              {loading && <CircularProgress size={'2rem'} sx={{color:"#457b9d", marginLeft:"10px", marginTop:"5px"}}/>}
            </MDBox>
          </MDBox>
          <MDBox sx={styles.messageBox}>
            {message && (
              <Alert severity="success" style={{ marginTop: '10px' }}>
                {message}
              </Alert>
            )}
            {error && (
              <Alert severity="error" style={{ marginTop: '10px' }}>
                {error}
              </Alert>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;