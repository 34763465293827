import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import {
  fetchActivities,
  updateActivityStatus,
  getDateOfWorkPackages,
  createActivity,
  deleteActivity,
  updateActivity,
  fetchMilestones,
  createMilestone,
  deleteMilestone,
  updateMilestone,
} from "../../../../apis/apis/index";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import {
  useFetcher,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

const WorkPackageList = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const projects =
    location.state || JSON.parse(localStorage.getItem("projects")); // Load projects from localStorage if location.state is null
  const project = projects[parseInt(id)];
  const { workPackages, projectId, projectStartDate, projectEndDate } = props;
  const [expandedWorkPackages, setExpandedWorkPackages] = useState(new Set());
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [customData, setCustomData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newActivity, setNewActivity] = useState({
    name: "",
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    workPackageId: null,
    activityId: null,
    milestones: [],
  });
  const [milestoneDeleteConfirmation, setMilestoneDeleteConfirmation] =
    useState({
      open: false,
      activityId: null,
      milestoneId: null,
    });
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [activityData, setActivityData] = useState({});
  const [milestones, setMilestones] = useState([]);
  const [milestoneModalOpen, setMilestoneModalOpen] = useState(false);
  const [newMilestone, setNewMilestone] = useState({
    name: "",
    due_date: moment().format("YYYY-MM-DD"),
  });
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  useEffect(() => {
    fetchWorkPackagesAndActivities();
  }, [projectId, token]);

  const fetchWorkPackagesAndActivities = async () => {
    setLoading(true);
    try {
      const workPackages = await getDateOfWorkPackages(projectId, token);
      const workPackagesWithActivities = await Promise.all(
        workPackages.map(async (wp) => {
          const activities = await fetchActivities(projectId, wp.id, token);
          const activitiesWithMilestones = await Promise.all(
            activities.map(async (activity) => {
              const milestones = await fetchMilestones(activity.id, token);
              return { ...activity, milestones };
            })
          );
          return { ...wp, activities: activitiesWithMilestones };
        })
      );

      const chartData = formatChartData(workPackagesWithActivities);
      setCustomData(chartData);
      setActivityData(mapActivitiesToWorkPackages(workPackagesWithActivities));
    } catch (error) {
      setError("An error occurred while fetching work packages.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formatChartData = (workPackagesWithActivities) => {
    return workPackagesWithActivities.flatMap((wp) => {
      const wpData = {
        name: wp.name,
        data: [
          {
            x: wp.name,
            y: [
              new Date(wp.start_date).getTime(),
              new Date(wp.end_date).getTime(),
            ],
            fillColor: "red",
            meta: { type: "workPackage", name: wp.name },
          },
        ],
      };

      const activityData = wp.activities.map((activity) => ({
        name: wp.name,
        data: [
          {
            x: wp.name,
            y: [
              new Date(activity.start_date).getTime(),
              new Date(activity.end_date).getTime(),
            ],
            fillColor: "#a3a3a3",
            meta: { type: "activity", name: activity.name },
            goals: activity.milestones.map((milestone) => ({
              name: milestone.name,
              value: new Date(milestone.due_date).getTime(),
              strokeWidth: 10,
              strokeHeight: 0,
              strokeLineCap: "round",
              strokeColor: "#0000FF",
              label: {
                text: `${milestone.name}: ${moment(milestone.due_date).format(
                  "YYYY-MM-DD"
                )}`,
              },
            })),
          },
        ],
      }));
      setError(null);
      return [wpData, ...activityData];
    });
  };

  const mapActivitiesToWorkPackages = (workPackagesWithActivities) => {
    return workPackagesWithActivities.reduce((acc, wp) => {
      acc[wp.id] = wp.activities;
      return acc;
    }, {});
  };

  const validateActivityDates = (activity, workPackage) => {
    if (!activity.name || !activity.start_date || !activity.end_date) {
      return "Please fill in all fields.";
    }

    const startDate = moment(activity.start_date);
    const endDate = moment(activity.end_date);

    if (startDate.isAfter(endDate)) {
      return "Start date must be before end date.";
    }

    if (startDate.isBefore(moment(workPackage.start_date), "day")) {
      return "Start date must be on or after the work package start date.";
    }

    if (endDate.isAfter(moment(workPackage.end_date), "day")) {
      return "End date must be before the work package end date.";
    }

    if (
      startDate.year() < 2000 ||
      startDate.year() > 2100 ||
      endDate.year() < 2000 ||
      endDate.year() > 2100
    ) {
      return "Date must be between 2000 and 2100.";
    }

    return null;
  };

  const validateMilestoneDates = (milestones, activity) => {
    for (let milestone of milestones) {
      const dueDate = moment(milestone.due_date);
      if (
        dueDate.isBefore(moment(activity.start_date), "day") ||
        dueDate.isAfter(moment(activity.end_date), "day")
      ) {
        return `Milestone "${milestone.name}" due date must be within the activity start and end dates.`;
      }

      if (dueDate.year() < 2000 || dueDate.year() > 2100) {
        return "Date must be between 2000 and 2100.";
      }
    }
    return null;
  };

  const addActivity = async (workPackageId, activity) => {
    setError(null);

    const workPackages = await getDateOfWorkPackages(projectId, token);
    const selectedWorkPackage = workPackages.find(
      (wp) => wp.id === workPackageId
    );

    const validationError = validateActivityDates(
      activity,
      selectedWorkPackage
    );

    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      await createActivity(
        projectId,
        workPackageId,
        activity,
        token,
        setError,
        navigate
      );
      await fetchWorkPackagesAndActivities();
      setModalOpen(false);
      location.state = projects;
      localStorage.setItem("projects", JSON.stringify(projects));
    } catch (error) {
      setError("Failed to create activity. Please try again.");
    }
  };

  const handleActivityInputChange = (event) => {
    const { name, value } = event.target;
    if (editModalOpen) {
      setSelectedActivity((prev) => ({
        ...prev,
        [name]: value,
      }));
      setUnsavedChanges(true);
    } else {
      setNewActivity((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenEditModal = (workPackageId, activity) => {
    setSelectedActivity({
      ...activity,
      milestones: activity.milestones || [],
      workPackageId,
      start_date: moment(activity.start_date).format("YYYY-MM-DD"),
      end_date: moment(activity.end_date).format("YYYY-MM-DD"),
    });
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    if (unsavedChanges) {
      setConfirmationOpen(true);
    } else {
      setEditModalOpen(false);
      setSelectedActivity(null);
    }
  };

  const handleDeleteActivity = (workPackageId, activity) => {
    setDeleteConfirmation({
      open: true,
      workPackageId: workPackageId,
      activityId: activity.id,
      milestones: activity.milestones || [],
    });
  };

  const confirmDelete = async () => {
    setError(null);
    try {
      await deleteActivity(
        projectId,
        deleteConfirmation.workPackageId,
        deleteConfirmation.activityId,
        token,
        setError,
        navigate
      );
      await fetchWorkPackagesAndActivities();
      setDeleteConfirmation({
        open: false,
        workPackageId: null,
        activityId: null,
        milestones: [],
      });
    } catch (error) {
      setError("Failed to delete activity. Please try again.");
    }
  };

  const handleDeleteMilestone = (activityId, milestoneId) => {
    setMilestoneDeleteConfirmation({
      open: true,
      activityId: activityId,
      milestoneId: milestoneId,
    });
  };

  const confirmDeleteMilestone = async () => {
    setError(null);
    try {
      await deleteMilestone(
        milestoneDeleteConfirmation.activityId,
        milestoneDeleteConfirmation.milestoneId,
        token,
        setError,
        () => {},
        navigate
      );
      await fetchWorkPackagesAndActivities();
      const updatedMilestones = await fetchMilestones(
        selectedActivity.id,
        token
      );
      setSelectedActivity((prev) => ({
        ...prev,
        milestones: updatedMilestones,
      }));
      setMilestoneDeleteConfirmation({
        open: false,
        activityId: null,
        milestoneId: null,
      });
    } catch (error) {
      setError("Failed to delete milestone. Please try again.");
    }
  };

  const saveActivityChanges = async () => {
    setError(null);

    const workPackages = await getDateOfWorkPackages(projectId, token);
    const selectedWorkPackage = workPackages.find(
      (wp) => wp.id === selectedActivity.workPackageId
    );

    const validationError = validateActivityDates(
      selectedActivity,
      selectedWorkPackage
    );

    if (validationError) {
      setError(validationError);
      return;
    }

    const milestoneValidationError = validateMilestoneDates(
      selectedActivity.milestones,
      selectedActivity
    );

    if (milestoneValidationError) {
      setError(milestoneValidationError);
      return;
    }

    try {
      await updateActivity(
        projectId,
        selectedActivity.workPackageId,
        selectedActivity.id,
        token,
        setError,
        () => {},
        navigate,
        {
          name: selectedActivity.name,
          start_date: moment(selectedActivity.start_date).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          end_date: moment(selectedActivity.end_date).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
        }
      );
      await Promise.all(
        selectedActivity.milestones.map((milestone) =>
          updateMilestone(
            selectedActivity.id,
            milestone.id,
            milestone,
            token,
            setError,
            () => {},
            navigate
          )
        )
      );
      await fetchWorkPackagesAndActivities();
      setEditModalOpen(false);
    } catch (error) {
      setError("Failed to save changes. Please try again.");
    }
  };

  const toggleWorkPackage = (workPackageId) => {
    setExpandedWorkPackages((prevState) => {
      const newExpanded = new Set(prevState);
      if (newExpanded.has(workPackageId)) {
        newExpanded.delete(workPackageId);
      } else {
        newExpanded.add(workPackageId);
      }
      return newExpanded;
    });
  };

  const completedActivityCount = (workPackageId) => {
    return activityData[workPackageId]?.filter(
      (activity) => activity.status === 1
    ).length;
  };

  const totalActivityCount = (workPackageId) => {
    return activityData[workPackageId]?.length;
  };

  const handleMilestoneInputChange = (event, milestoneIndex) => {
    const { name, value } = event.target;
    setSelectedActivity((prev) => {
      const updatedMilestones = prev.milestones.map((milestone, index) =>
        index === milestoneIndex ? { ...milestone, [name]: value } : milestone
      );
      return { ...prev, milestones: updatedMilestones };
    });
    setUnsavedChanges(true);
  };

  const handleAddMilestone = () => {
    setMilestoneModalOpen(true);
  };

  const handleSaveMilestone = async () => {
    if (!newMilestone.name || !newMilestone.due_date) {
      setError("Please fill in all fields.");
      return;
    }

    const dueDate = moment(newMilestone.due_date);
    if (dueDate.year() < 2000 || dueDate.year() > 2100) {
      setError("Date must be between 2000 and 2100.");
      return;
    }

    try {
      await createMilestone(
        selectedActivity.id,
        newMilestone,
        token,
        setError,
        () => {},
        navigate
      );
      const updatedMilestones = await fetchMilestones(
        selectedActivity.id,
        token
      );
      setSelectedActivity((prev) => ({
        ...prev,
        milestones: updatedMilestones,
      }));
      setMilestoneModalOpen(false);
      setNewMilestone({ name: "", due_date: moment().format("YYYY-MM-DD") });
      setUnsavedChanges(false);
    } catch (error) {
      setError("Failed to add milestone. Please try again.");
    }
  };

  const handleCloseMilestoneModal = () => {
    setMilestoneModalOpen(false);
    setError(null);
  };

  const handleConfirmCloseEditModal = async (saveChanges) => {
    if (saveChanges) {
      await saveActivityChanges();
    }
    setEditModalOpen(false);
    setSelectedActivity(null);
    setUnsavedChanges(false);
    setConfirmationOpen(false);
    setError(null);
  };

  const changeActivityStatus = async (workPackageId, activityId, status) => {
    setError(null);
    let parsedStatus = parseInt(status, 10);
    try {
      await updateActivityStatus(
        projectId,
        workPackageId,
        activityId,
        token,
        setError,
        () => {},
        navigate,
        parsedStatus
      );
      await fetchWorkPackagesAndActivities();
    } catch (error) {
      setError("Failed to update activity status. Please try again.");
    }
  };

  const chartOptions = {
    chart: {
      height: 350,
      type: "rangeBar",
      zoom: { enabled: false },
      pan: { enabled: false },
      selection: { enabled: false },
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        horizontal: true,
        barHeight: "7px",
        rangeBarGroupPadding: 0,
        rangeBarGroupRows: false,
        borderColor: "#000",
      },
    },
    stroke: {
      show: false,
      width: 0.5, // Border width
      border: "1px solid #000",
    },
    dataLabels: {
      enabled: false,
      formatter: (val) => `${moment(val[1]).diff(moment(val[0]), "days")} days`,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.25,
        gradientToColors: false,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    xaxis: {
      type: "datetime",
      min: new Date(projectStartDate).getTime(),
      max: new Date(projectEndDate).getTime(),
    },
    legend: { show: false },
    grid: {
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: true } },
    },
    annotations: { xaxis: [{ x: new Date().getTime(), borderColor: "red" }] },
    animation: {
      enabled: true,
      easing: "linear",
      speed: 100,
      animateGradually: { enabled: true, delay: 550 },
      dynamicAnimation: { enabled: true, speed: 150 },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        const startDate = moment(data.y[0]).format("YYYY-MM-DD");
        const endDate = moment(data.y[1]).format("YYYY-MM-DD");
        let milestoneText = "";
        if (data.goals && data.goals.length > 0) {
          milestoneText = data.goals
            .map((goal) => {
              const dueDate = moment(goal.value).format("YYYY-MM-DD");
              return `<div><br><strong>Milestone:</strong> ${goal.name}</div>
              <div><strong>Due Date:</strong> ${dueDate}</div>`;
            })
            .join("");
        }

        const displayName =
          data.meta.type === "workPackage"
            ? `${data.meta.name}`
            : `${data.meta.name}`;

        return `<div class="apexcharts-tooltip-rangebar">
                  <div><strong>${displayName}</strong></div>
                  <div><strong>Start Date:</strong> ${startDate}</div>
                  <div><strong>End Date:</strong> ${endDate}</div>
                  <div>${milestoneText}</div>`;
      },
    },
    markers: { hover: { sizeOffset: 0 } },
  };

  const chartSeries = customData;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Card sx={{ height: "600px", overflowY: "auto" }}>
        <PerfectScrollbar>
          <Table sx={{ margin: 0, padding: 0 }}>
            <TableHead
              sx={{
                display: "table-header-group",
                position: "sticky",
                top: 0,
                backgroundColor:
                  theme.palette.mode === "light"
                    ? light.primary
                    : dark.buttonBackground,
                zIndex: 1,
              }}
            >
              <TableRow>
                <TableCell />
                <TableCell align="left">Work Package</TableCell>
                <TableCell align="left">Lead</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Management</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {workPackages.map((workPackage, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            toggleWorkPackage(workPackage.workPackageId)
                          }
                        >
                          {expandedWorkPackages.has(
                            workPackage.workPackageId
                          ) ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">
                        {workPackage.workPackageName}
                      </TableCell>
                      <TableCell align="left">
                        {workPackage.workPackageLead}
                      </TableCell>
                      <TableCell align="left">
                        {totalActivityCount(workPackage.workPackageId) === 0
                          ? "N/A"
                          : `${completedActivityCount(
                              workPackage.workPackageId
                            )} / ${totalActivityCount(
                              workPackage.workPackageId
                            )}`}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="View">
                          <IconButton
                            onClick={() => {
                              navigate(
                                `/projects/viewproject/viewworkpackage/${
                                  index + 1
                                }`,
                                { state: workPackages }
                              );
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Collapse
                          in={expandedWorkPackages.has(
                            workPackage.workPackageId
                          )}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Table>
                              <TableBody>
                                {activityData[workPackage.workPackageId]?.map(
                                  (activity) => (
                                    <TableRow key={activity.id}>
                                      <TableCell align="right">
                                        {activity.name}
                                      </TableCell>
                                      <TableCell align="center">
                                        <Select
                                          value={activity.status}
                                          onChange={(e) =>
                                            changeActivityStatus(
                                              workPackage.workPackageId,
                                              activity.id,
                                              e.target.value
                                            )
                                          }
                                          sx={{
                                            background:
                                              activity.status === 0
                                                ? "red"
                                                : activity.status === 1
                                                ? "green"
                                                : "transparent",
                                            borderRadius: "10px",
                                            minWidth: "100px",
                                            height: "30px",
                                            padding: "8px",
                                            "& .MuiSelect-select": {
                                              color: "white !important",
                                            },
                                            "& .MuiSelect-icon": {
                                              color: "white !important",
                                            },
                                          }}
                                        >
                                          <MenuItem value={0}>Ongoing</MenuItem>
                                          <MenuItem value={1}>Done</MenuItem>
                                        </Select>
                                      </TableCell>
                                      <TableCell>
                                        <Tooltip title="Edit">
                                          <IconButton
                                            onClick={() =>
                                              handleOpenEditModal(
                                                workPackage.workPackageId,
                                                activity
                                              )
                                            }
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                          <IconButton
                                            onClick={() =>
                                              handleDeleteActivity(
                                                workPackage.workPackageId,
                                                activity
                                              )
                                            }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                            <Box
                              sx={{
                                backgroundColor: "#f5f5f5",
                                width: "155px",
                                borderRadius: "5px",
                                border: "1px solid #e0e0e0",
                              }}
                            >
                              <Button
                                onClick={handleOpenModal}
                                startIcon={<AddIcon />}
                                color="primary"
                              >
                                Add Activity
                              </Button>
                            </Box>
                            <Modal
                              open={modalOpen}
                              onClose={handleCloseModal}
                              aria-labelledby="new-activity-modal-title"
                            >
                              <Box
                                sx={{
                                  borderRadius: "5px",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: 400,
                                  bgcolor: "background.paper",
                                  boxShadow: 24,
                                  p: 4,
                                  pb: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Activity Name"
                                  variant="outlined"
                                  name="name"
                                  value={newActivity.name}
                                  onChange={handleActivityInputChange}
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  fullWidth
                                  label="Start Date"
                                  type="date"
                                  name="start_date"
                                  value={newActivity.start_date}
                                  onChange={handleActivityInputChange}
                                  InputLabelProps={{ shrink: true }}
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  fullWidth
                                  label="End Date"
                                  type="date"
                                  name="end_date"
                                  value={newActivity.end_date}
                                  onChange={handleActivityInputChange}
                                  InputLabelProps={{ shrink: true }}
                                  sx={{ mb: 2 }}
                                />
                                {error && (
                                  <Typography color="error" sx={{ mb: 2 }}>
                                    {error}
                                  </Typography>
                                )}
                                <Button
                                  sx={{
                                    width: "100px",
                                    alignSelf: "center",
                                  }}
                                  color="primary"
                                  onClick={() =>
                                    addActivity(
                                      workPackage.workPackageId,
                                      newActivity
                                    )
                                  }
                                >
                                  Submit
                                </Button>
                              </Box>
                            </Modal>
                            <Modal
                              open={deleteConfirmation.open}
                              onClose={() =>
                                setDeleteConfirmation({
                                  open: false,
                                  workPackageId: null,
                                  activityId: null,
                                  milestones: [],
                                })
                              }
                            >
                              <Box
                                sx={{
                                  borderRadius: "5px",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: 400,
                                  bgcolor: "background.paper",
                                  boxShadow: 24,
                                  p: 4,
                                  pb: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography variant="h6">
                                  Are you sure you want to delete this activity?
                                </Typography>
                                {deleteConfirmation.milestones.length > 0 && (
                                  <Typography variant="subtitle1">
                                    This activity has the following Milestones:
                                    <ul>
                                      {deleteConfirmation.milestones.map(
                                        (milestone) => (
                                          <li key={milestone.id}>
                                            {milestone.name}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </Typography>
                                )}

                                <Box sx={{ mt: 2 }}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={confirmDelete}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    sx={{ ml: 2 }}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() =>
                                      setDeleteConfirmation({
                                        open: false,
                                        workPackageId: null,
                                        activityId: null,
                                        milestones: [],
                                      })
                                    }
                                  >
                                    No
                                  </Button>
                                </Box>
                              </Box>
                            </Modal>
                            <Modal
                              open={editModalOpen}
                              onClose={handleCloseEditModal}
                            >
                              <Box
                                sx={{
                                  borderRadius: "5px",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: 400,
                                  bgcolor: "background.paper",
                                  boxShadow: 24,
                                  p: 4,
                                  pb: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Activity Name"
                                  variant="outlined"
                                  name="name"
                                  value={selectedActivity?.name || ""}
                                  onChange={handleActivityInputChange}
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  fullWidth
                                  label="Start Date"
                                  type="date"
                                  name="start_date"
                                  value={selectedActivity?.start_date || ""}
                                  onChange={handleActivityInputChange}
                                  InputLabelProps={{ shrink: true }}
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  fullWidth
                                  label="End Date"
                                  type="date"
                                  name="end_date"
                                  value={selectedActivity?.end_date || ""}
                                  onChange={handleActivityInputChange}
                                  InputLabelProps={{ shrink: true }}
                                  sx={{ mb: 2 }}
                                />
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    <Typography>Milestones</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {selectedActivity?.milestones?.map(
                                      (milestone, index) => (
                                        <Box
                                          key={index}
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            mb: 2,
                                          }}
                                        >
                                          <TextField
                                            fullWidth
                                            label="Milestone Name"
                                            variant="outlined"
                                            name="name"
                                            value={milestone.name}
                                            onChange={(e) =>
                                              handleMilestoneInputChange(
                                                e,
                                                index
                                              )
                                            }
                                            sx={{ mr: 2 }}
                                          />
                                          <TextField
                                            fullWidth
                                            label="Due Date"
                                            type="date"
                                            name="due_date"
                                            value={moment(
                                              milestone.due_date
                                            ).format("YYYY-MM-DD")}
                                            onChange={(e) =>
                                              handleMilestoneInputChange(
                                                e,
                                                index
                                              )
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            sx={{ mr: 2 }}
                                          />
                                          <Tooltip title="Delete">
                                            <IconButton
                                              onClick={() =>
                                                handleDeleteMilestone(
                                                  selectedActivity.id,
                                                  milestone.id
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      )
                                    )}
                                    <Button
                                      startIcon={<AddIcon />}
                                      color="primary"
                                      onClick={handleAddMilestone}
                                    >
                                      Add Milestone
                                    </Button>
                                  </AccordionDetails>
                                </Accordion>
                                {error && (
                                  <Typography color="error" sx={{ mb: 2 }}>
                                    {error}
                                  </Typography>
                                )}
                                <Button
                                  sx={{
                                    width: "100px",
                                    alignSelf: "center",
                                    mt: 2,
                                  }}
                                  color="primary"
                                  onClick={saveActivityChanges}
                                >
                                  Save Changes
                                </Button>
                              </Box>
                            </Modal>
                            <Modal
                              open={milestoneModalOpen}
                              onClose={handleCloseMilestoneModal}
                              aria-labelledby="add-milestone-modal-title"
                            >
                              <Box
                                sx={{
                                  borderRadius: "5px",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: 400,
                                  bgcolor: "background.paper",
                                  boxShadow: 24,
                                  p: 4,
                                  pb: 2,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <TextField
                                  fullWidth
                                  label="Milestone Name"
                                  variant="outlined"
                                  name="name"
                                  value={newMilestone.name}
                                  onChange={(e) =>
                                    setNewMilestone({
                                      ...newMilestone,
                                      name: e.target.value,
                                    })
                                  }
                                  sx={{ mb: 2 }}
                                />
                                <TextField
                                  fullWidth
                                  label="Due Date"
                                  type="date"
                                  name="due_date"
                                  value={newMilestone.due_date}
                                  onChange={(e) =>
                                    setNewMilestone({
                                      ...newMilestone,
                                      due_date: e.target.value,
                                    })
                                  }
                                  InputLabelProps={{ shrink: true }}
                                  sx={{ mb: 2 }}
                                />
                                {error && (
                                  <Typography color="error" sx={{ mb: 2 }}>
                                    {error}
                                  </Typography>
                                )}
                                <Button
                                  sx={{
                                    width: "100px",
                                    alignSelf: "center",
                                  }}
                                  color="primary"
                                  onClick={handleSaveMilestone}
                                >
                                  Create
                                </Button>
                              </Box>
                            </Modal>
                            <Dialog
                              open={confirmationOpen}
                              onClose={() => setConfirmationOpen(false)}
                            >
                              <DialogTitle>Unsaved Changes</DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  Do you want to save your changes?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() =>
                                    handleConfirmCloseEditModal(true)
                                  }
                                  color="primary"
                                >
                                  Yes
                                </Button>
                                <Button
                                  onClick={() =>
                                    handleConfirmCloseEditModal(false)
                                  }
                                  color="secondary"
                                >
                                  No
                                </Button>
                              </DialogActions>
                            </Dialog>
                            <Dialog
                              open={milestoneDeleteConfirmation.open}
                              onClose={() =>
                                setMilestoneDeleteConfirmation({
                                  open: false,
                                  activityId: null,
                                  milestoneId: null,
                                })
                              }
                            >
                              <DialogTitle>Delete Milestone</DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  Are you sure you want to delete this
                                  milestone?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={confirmDeleteMilestone}
                                  color="primary"
                                >
                                  Yes
                                </Button>
                                <Button
                                  onClick={() =>
                                    setMilestoneDeleteConfirmation({
                                      open: false,
                                      activityId: null,
                                      milestoneId: null,
                                    })
                                  }
                                  color="secondary"
                                >
                                  No
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </PerfectScrollbar>
      </Card>
      <Card
        sx={{
          height: "600px",
          overflowY: "auto",
          width: "1245px",
          borderRadius: "2px",
          marginTop: "20px",
        }}
      >
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="rangeBar"
          height={workPackages.length * 150 + 100}
          width={1240}
        />
      </Card>
    </Box>
  );
};

export { WorkPackageList };
