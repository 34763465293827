import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Card,
  CardContent,
  Avatar,
  Button,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Popover,
  Grid,
} from "@mui/material";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useTheme } from "@mui/material/styles";
import { Search, Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonIcon from "@mui/icons-material/Person";
import {
  fetchSurveys,
  deleteSurvey,
  publishSurvey,
  validatePublisherGroup,
  fetchEmployees,
  fetchEmployeesWhoSubmitted,
  endSurvey,
} from "../../apis/apis/index";
import ViewSurvey from "./viewSurvey";
import EditSurvey from "./editSurvey";
import Answers from "./answers";
import Summary from "./summary";
import jwtDecode from "jwt-decode";

const Surveys = ({ onBack }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [surveys, setSurveys] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedSurveyToDelete, setSelectedSurveyToDelete] = useState(null);
  const [openEndDialog, setOpenEndDialog] = useState(false);
  const [selectedSurveyToEnd, setSelectedSurveyToEnd] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const token = sessionStorage.getItem("token");
  const currentUser = jwtDecode(token);
  const [isCEOOffice, setIsCEOOffice] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [selectedSurveyTitle, setSelectedSurveyTitle] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [surveyToEdit, setSurveyToEdit] = useState(null);
  const [viewingAnswers, setViewingAnswers] = useState(false);
  const [viewingSummary, setViewingSummary] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeesSubmitted, setEmployeesSubmitted] = useState([]);
  const [employeesNotSubmitted, setEmployeesNotSubmitted] = useState([]);
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [filterBySubmission, setFilterBySubmission] = useState("all");
  const [sortByDate, setSortByDate] = useState("newest");
  const [answers, setAnswers] = useState({});
  const surveysPerPage = 5;



  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const theme = useTheme();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    const validateGroup = async () => {
      const res = await validatePublisherGroup(
        token,
        setErrorMessage,
        handleOpenDialog,
        () => {}
      );
      setIsCEOOffice(res && res.detail.includes("CEO Office"));
    };

    validateGroup();
    fetchSurveysData();
  }, [token]);

  const fetchSurveysData = async () => {
    try {
      const response = await fetchSurveys(
        token,
        setErrorMessage,
        handleOpenDialog
      );
      setSurveys(response);
      console.log(response);
      setFilteredSurveys(response);
      console.log("bura", surveys);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    let filtered = surveys.filter((survey) =>
      survey?.title?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (filterBySubmission === "submitted") {
      filtered = filtered.filter((survey) => survey.has_submitted);
    } else if (filterBySubmission === "not-submitted") {
      filtered = filtered.filter((survey) => !survey.has_submitted);
    }

    filtered.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return sortByDate === "newest" ? dateB - dateA : dateA - dateB;
    });

    setFilteredSurveys(filtered);
    setCurrentPage(1);
  }, [searchQuery, surveys, filterBySubmission, sortByDate]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleViewSurvey = (survey) => {
    setSelectedSurvey(survey.id);

    const resetAnswers = {};
    survey.questions.forEach((question, index) => {
      switch (question.type) {
        case 1: // Single Choice
          resetAnswers[index] = { text: "", options: [] };
          break;
        case 2: // Multiple Choice
          resetAnswers[index] = { options: [] };
          break;
        case 3: // Rating
          resetAnswers[index] = { numeric: null };
          break;
        case 4: // Text
          resetAnswers[index] = { text: "" };
          break;
        case 5: // File Upload
          resetAnswers[index] = { file: null };
          break;
        default:
          resetAnswers[index] = {};
          break;
      }
    });

    setAnswers(resetAnswers);
  };

  const handleViewAnswers = (surveyId) => {
    setSelectedSurvey(surveyId);
    setViewingAnswers(true);
  };

  const handleViewSummary = (surveyId, surveyTitle) => {
    setSelectedSurvey(surveyId);
    setSelectedSurveyTitle(surveyTitle);
    setViewingSummary(true);
  };

  const handleEmployeePopoverOpen = async (event, surveyId) => {
    setAnchorEl(event.currentTarget);
    setLoadingEmployees(true);

    try {
      const allEmployees = await fetchEmployees(token);
      const submittedEmployees = await fetchEmployeesWhoSubmitted(
        token,
        surveyId
      );

      const submittedIds = new Set(
        submittedEmployees.map((emp) => emp.respondent.id)
      );

      const notSubmittedEmployees = allEmployees.filter(
        (employee) => !submittedIds.has(employee.id) && employee.is_active
      );

      setEmployeesSubmitted(submittedEmployees);
      setEmployeesNotSubmitted(notSubmittedEmployees);
    } catch (error) {
      setErrorMessage("Failed to fetch employees.");
    } finally {
      setLoadingEmployees(false);
    }
  };

  const handleEmployeePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const handleDeleteSurvey = async (surveyId) => {
    try {
      await deleteSurvey(token, surveyId);
      fetchSurveysData();
      setOpenDeleteDialog(false);
      setSnackbarMessage("Survey deleted successfully.");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage("Failed to delete survey.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handlePublish = async (surveyId) => {
    try {
      await publishSurvey(token, surveyId);
      fetchSurveysData();
      setSnackbarMessage("Survey published successfully.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setErrorMessage(error.message);
      setSnackbarMessage("Failed to publish survey.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleEndSurvey = async (surveyId) => {
    try {
      await endSurvey(token, surveyId);
      fetchSurveysData();
      setSnackbarMessage("Survey ended successfully.");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage("Failed to end the survey.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      handleCloseEndDialog();
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleOpenDeleteDialog = (surveyId) => {
    setSelectedSurveyToDelete(surveyId);
    setOpenDeleteDialog(true);
  };

  const handleOpenEndDialog = (surveyId) => {
    setSelectedSurveyToEnd(surveyId);
    setOpenEndDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedSurveyToDelete(null);
  };

  const handleCloseEndDialog = () => {
    setOpenEndDialog(false);
    setSelectedSurveyToEnd(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleEditSurvey = (survey) => {
    setSurveyToEdit(survey);
    setIsEditing(true);
  };

  const handleSurveyUpdated = () => {
    fetchSurveysData();
    setIsEditing(false);
  };

  const startIndex = (currentPage - 1) * surveysPerPage;
  const currentSurveys = filteredSurveys.slice(
    startIndex,
    startIndex + surveysPerPage
  );

  const onBackFromSurvey = (shouldRefetch) => {
    if (shouldRefetch) {
      fetchSurveysData();
    }
    setSelectedSurvey(null);
    setViewingAnswers(false);
    setViewingSummary(false);
  };

  return (
    <Box sx={{ p: 2, height: "600px", overflowY: "auto" }}>
      {isEditing ? (
        <EditSurvey
          survey={surveyToEdit}
          onCancel={() => setIsEditing(false)}
          onSurveyUpdated={handleSurveyUpdated}
        />
      ) : viewingAnswers ? (
        <Answers
          surveyId={selectedSurvey}
          onBack={() => onBackFromSurvey(false)}
        />
      ) : viewingSummary ? (
        <Summary
          surveyId={selectedSurvey}
          surveyTitle={selectedSurveyTitle}
          onBack={() => onBackFromSurvey(false)}
        />
      ) : selectedSurvey ? (
        <ViewSurvey
          surveyId={selectedSurvey}
          onBack={onBackFromSurvey}
          initialAnswers={answers}
        />
      ) : (
        <>
          <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            <TextField
              variant="outlined"
              placeholder="Search a survey by title..."
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputBase-input": {
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                },
              }}
            />
            <FormControl
              variant="outlined"
              sx={{
                ml: 1,
                minWidth: 120,
                height: 40,
              }}
            >
              <InputLabel>Submission</InputLabel>
              <Select
                value={filterBySubmission}
                onChange={(e) => setFilterBySubmission(e.target.value)}
                label="Submission"
                sx={{
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  "& .MuiInputBase-input": {
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                    height: "auto",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 14px",
                  },
                }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="submitted">Submitted</MenuItem>
                <MenuItem value="not-submitted">Not Submitted</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              sx={{
                ml: 1,
                minWidth: 130,
                height: 40,
              }}
            >
              <InputLabel>Sort by Creation</InputLabel>
              <Select
                value={sortByDate}
                onChange={(e) => setSortByDate(e.target.value)}
                label="Sort by Creation"
                sx={{
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  "& .MuiInputBase-input": {
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                    height: "auto",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                  },
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 14px",
                  },
                }}
              >
                <MenuItem value="newest">Newest to Oldest</MenuItem>
                <MenuItem value="oldest">Oldest to Newest</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {currentSurveys.map((survey) => (
            <Card
              key={survey.id}
              sx={{
                mb: 2,
                backgroundColor:
                  theme.palette.mode === "light" ? light.primary : dark.primary,
                color: theme.palette.mode === "light" ? light.text : dark.text,
                borderRadius: "12px",
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
                minHeight: "250px",
                maxHeight: "400px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardContent
                sx={{
                  flex: "1 1 auto",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    "& .MuiAvatar-root": {
                      width: "50px",
                      height: "50px",
                    },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    {survey.publisher && (
                      <>
                        <Avatar
                          sx={{ mt: 1 }}
                          src={survey.publisher.profile_image}
                          alt={survey.publisher.first_name}
                        />
                        <Box ml={2} flexGrow={1} mt={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {survey.publisher.first_name}{" "}
                            {survey.publisher.last_name}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "auto",
                      mt: 1,
                      maxWidth: "50px",
                    }}
                  >
                    {survey.has_submitted && (
                      <CheckCircleIcon
                        sx={{
                          color: "white",
                          width: "22px",
                          height: "22px",
                          mb: 1,
                        }}
                      />
                    )}
                    {isCEOOffice && (
                      <>
                        {!survey.is_anonymous && (
                          <IconButton
                            onClick={(e) =>
                              handleEmployeePopoverOpen(e, survey.id)
                            }
                            sx={{
                              color:
                                theme.palette.mode === "light"
                                  ? light.buttonIcon
                                  : dark.buttonIcon,
                              width: "22px",
                              height: "22px",
                              "&:hover": {
                                color:
                                  theme.palette.mode === "light"
                                    ? light.buttonIconHover
                                    : "#000000",
                                borderRadius: "20%",
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? light.buttonBackgroundHover
                                    : dark.buttonBackgroundHover,
                              },
                              mb: 1,
                            }}
                          >
                            <PersonIcon />
                          </IconButton>
                        )}
                        {!survey.is_published && (
                          <IconButton
                            onClick={() => handleEditSurvey(survey)}
                            sx={{
                              color:
                                theme.palette.mode === "light"
                                  ? light.buttonIcon
                                  : dark.buttonIcon,
                              width: "22px",
                              height: "22px",
                              "&:hover": {
                                color:
                                  theme.palette.mode === "light"
                                    ? light.buttonIconHover
                                    : "#000000",
                                borderRadius: "20%",
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? light.buttonBackgroundHover
                                    : dark.buttonBackgroundHover,
                              },
                              mb: 1,
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() => handleOpenDeleteDialog(survey.id)}
                          sx={{
                            color:
                              theme.palette.mode === "light"
                                ? light.buttonIcon
                                : dark.buttonIcon,
                            width: "22px",
                            height: "22px",
                            "&:hover": {
                              color:
                                theme.palette.mode === "light"
                                  ? light.buttonIconHover
                                  : "#000000",
                              borderRadius: "20%",
                              backgroundColor:
                                theme.palette.mode === "light"
                                  ? light.buttonBackgroundHover
                                  : dark.buttonBackgroundHover,
                            },
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Box>
                <Typography
                  variant="h5"
                  ml={1}
                  mt={2}
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {survey.title}
                </Typography>
                <Typography
                  variant="body2"
                  mt={1}
                  ml={1}
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  mb={2}
                >
                  {survey.description}
                </Typography>
                <Box flexGrow={1} />
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Box display="flex" gap={1}>
                    <Button
                      onClick={() => handleViewSurvey(survey)}
                      sx={{
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? light.buttonBackground
                            : dark.buttonBackground,
                        "&:hover": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? light.buttonBackgroundHover
                              : dark.buttonBackgroundHover,
                          color:
                            theme.palette.mode === "light"
                              ? dark.text
                              : light.text,
                        },
                        border: `1px solid ${
                          theme.palette.mode === "light"
                            ? light.border
                            : dark.border
                        }`,
                        color:
                          theme.palette.mode === "light"
                            ? light.text
                            : dark.text,
                        maxWidth: "80px",
                      }}
                    >
                      View
                    </Button>
                    {isCEOOffice && (
                      <>
                        <Button
                          onClick={() => handleViewAnswers(survey.id)}
                          sx={{
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? light.buttonBackground
                                : dark.buttonBackground,
                            "&:hover": {
                              backgroundColor:
                                theme.palette.mode === "light"
                                  ? light.buttonBackgroundHover
                                  : dark.buttonBackgroundHover,
                              color:
                                theme.palette.mode === "light"
                                  ? dark.text
                                  : light.text,
                            },
                            border: `1px solid ${
                              theme.palette.mode === "light"
                                ? light.border
                                : dark.border
                            }`,
                            color:
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text,
                            maxWidth: "80px",
                          }}
                        >
                          Answers
                        </Button>
                        <Button
                          onClick={() =>
                            handleViewSummary(survey.id, survey.title)
                          }
                          sx={{
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? light.buttonBackground
                                : dark.buttonBackground,
                            "&:hover": {
                              backgroundColor:
                                theme.palette.mode === "light"
                                  ? light.buttonBackgroundHover
                                  : dark.buttonBackgroundHover,
                              color:
                                theme.palette.mode === "light"
                                  ? dark.text
                                  : light.text,
                            },
                            border: `1px solid ${
                              theme.palette.mode === "light"
                                ? light.border
                                : dark.border
                            }`,
                            color:
                              theme.palette.mode === "light"
                                ? light.text
                                : dark.text,
                            maxWidth: "80px",
                          }}
                        >
                          Summary
                        </Button>
                      </>
                    )}
                    {!survey.is_published && (
                      <Button
                        onClick={() => handlePublish(survey.id)}
                        sx={{
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? light.buttonBackground
                              : dark.buttonBackground,
                          "&:hover": {
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? light.buttonBackgroundHover
                                : dark.buttonBackgroundHover,
                            color:
                              theme.palette.mode === "light"
                                ? dark.text
                                : light.text,
                          },
                          border: `1px solid ${
                            theme.palette.mode === "light"
                              ? light.border
                              : dark.border
                          }`,
                          color:
                            theme.palette.mode === "light"
                              ? light.text
                              : dark.text,
                          maxWidth: "80px",
                        }}
                      >
                        Publish
                      </Button>
                    )}
                    {survey.is_published && isCEOOffice && (
                      <Button
                        onClick={() => handleOpenEndDialog(survey.id)}
                        sx={{
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? light.buttonBackground
                              : dark.buttonBackground,
                          "&:hover": {
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? light.buttonBackgroundHover
                                : dark.buttonBackgroundHover,
                            color:
                              theme.palette.mode === "light"
                                ? dark.text
                                : light.text,
                          },
                          border: `1px solid ${
                            theme.palette.mode === "light"
                              ? light.border
                              : dark.border
                          }`,
                          color:
                            theme.palette.mode === "light"
                              ? light.text
                              : dark.text,
                          maxWidth: "80px",
                        }}
                      >
                        End
                      </Button>
                    )}
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? light.text
                            : dark.text,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {new Date(survey.created_at).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ))}
          <Pagination
            count={Math.ceil(filteredSurveys.length / surveysPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            sx={{ mt: 2, display: "flex", justifyContent: "center" }}
          />
        </>
      )}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            color: theme.palette.mode === "light" ? light.text : dark.text,
          }}
        >
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            Are you sure you want to delete this survey?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteSurvey(selectedSurveyToDelete)}
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEndDialog}
        onClose={handleCloseEndDialog}
        aria-labelledby="alert-dialog-title-end"
        aria-describedby="alert-dialog-description-end"
      >
        <DialogTitle
          id="alert-dialog-title-end"
          sx={{
            color: theme.palette.mode === "light" ? light.text : dark.text,
          }}
        >
          {"End Survey Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description-end"
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            Are you sure you want to end this survey?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEndDialog}
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleEndSurvey(selectedSurveyToEnd)}
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            End
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleEmployeePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={2} width={400}>
          {loadingEmployees ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                  }}
                  variant="h6"
                >
                  Submitted
                </Typography>
                <List>
                  {employeesSubmitted.map((employee) => (
                    <ListItem key={employee.id}>
                      <ListItemText
                        primaryTypographyProps={{ variant: "body2" }}
                        primary={`${employee.respondent.first_name} ${employee.respondent.last_name}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                  }}
                  variant="h6"
                >
                  Not Submitted
                </Typography>
                <List>
                  {employeesNotSubmitted.map((employee) => (
                    <ListItem key={employee.id}>
                      <ListItemText
                        primaryTypographyProps={{ variant: "body2" }}
                        primary={`${employee.first_name} ${employee.last_name}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default Surveys;
