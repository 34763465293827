import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { getFeedbackTypes, submitFeedback } from "apis/apis/index";
import { useNavigate } from "react-router-dom";

const UserFeedback = () => {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [feedbackType, setFeedbackType] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [subjectError, setSubjectError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token");
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    const fetchFeedbackTypes = async () => {
      try {
        const types = await getFeedbackTypes(
          token,
          setErrorMessage,
          handleOpenDialog,
          navigate
        );
        setFeedbackTypes(types);
      } catch (error) {
        console.error("Failed to fetch feedback types:", error);
      }
    };

    fetchFeedbackTypes();
  }, []);

  useEffect(() => {
    if (feedbackType && subject && description) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [feedbackType, subject, description]);

  const handleFeedbackTypeChange = (e) => {
    const selectedType = e.target.value;
    setFeedbackType(selectedType);

    if (!selectedType) {
      setSubject("");
      setDescription("");
    }
  };

  const handleSubjectChange = (e) => {
    const value = e.target.value;
    setSubject(value);

    if (value.length > 50) {
      setSubjectError("Subject cannot be more than 50 characters.");
    } else {
      setSubjectError("");
    }
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);

    if (value.length > 500) {
      setDescriptionError("Description cannot be more than 500 characters.");
    } else {
      setDescriptionError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const feedbackData = { feedback_type: feedbackType, subject, description };

    if (!token) {
      setSnackbarMessage(
        "Error: No authentication token found. Please log in."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    console.log("Feedback data: ", feedbackData);

    try {
      const response = await submitFeedback(
        token,
        feedbackData,
        setErrorMessage,
        handleOpenDialog,
        navigate
      );

      if (response && response.message) {
        setSnackbarMessage("Thank you for your feedback! 😊");
        setSnackbarSeverity("success");

        setFeedbackType("");
        setSubject("");
        setDescription("");
      } else {
        setSnackbarMessage("Error submitting feedback. Please try again.");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("Error submitting feedback. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor:
          theme.palette.mode === "light" ? light.primary : dark.primary,
        marginLeft: 4,
        marginRight: 4,
        borderRadius: 2,
        border: `1px solid ${
          theme.palette.mode === "light" ? light.border : dark.border
        }`,
        padding: 4,
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: theme.palette.mode === "light" ? light.text : dark.text,
          }}
        >
          We need your help!
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={{
            color: theme.palette.mode === "light" ? light.text : dark.text,
          }}
        >
          Rekrom Optoelektronik aims to address the shortcomings of our ERP
          system and provide new features that align with your needs as users.{" "}
          <br />
          You can provide feedback about the system's current features and share
          any new ideas you think would meet your needs using the panel below.
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                margin="normal"
                SelectProps={{
                  native: true,
                }}
                value={feedbackType}
                onChange={handleFeedbackTypeChange}
                sx={{
                  width: "25%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: `1px solid ${
                        theme.palette.mode === "light"
                          ? light.border
                          : dark.border
                      }`,
                    },
                  },
                  "& .MuiInputBase-input": {
                    color:
                      theme.palette.mode === "light" ? light.text2 : dark.text2,
                  },
                }}
              >
                <option value="">Select a feedback type</option>
                {feedbackTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.display_name}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Subject"
                margin="normal"
                variant="outlined"
                value={subject}
                onChange={handleSubjectChange}
                error={!!subjectError}
                helperText={subjectError || `${subject.length}/50 characters`}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: `1px solid ${
                        theme.palette.mode === "light"
                          ? light.border
                          : dark.border
                      }`,
                    },
                  },
                  "& .MuiInputBase-input": {
                    color:
                      theme.palette.mode === "light" ? light.text2 : dark.text2,
                  },
                }}
                disabled={!feedbackType}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={4}
                margin="normal"
                variant="outlined"
                value={description}
                onChange={handleDescriptionChange}
                error={!!descriptionError}
                helperText={
                  descriptionError || `${description.length}/500 characters`
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: `1px solid ${
                        theme.palette.mode === "light"
                          ? light.border
                          : dark.border
                      }`,
                    },
                  },
                  "& .MuiInputBase-input": {
                    color:
                      theme.palette.mode === "light" ? light.text2 : dark.text2,
                  },
                }}
                disabled={!subject}
              />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              sx={{
                marginTop: 4,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: dark.text,
                }}
                disabled={isSubmitDisabled}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default UserFeedback;
