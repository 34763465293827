import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import axios from "../../axios";
import Typography from "@mui/material/Typography";
import MDTypography from "../../components/MDTypography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SellIcon from "@mui/icons-material/Sell";
import "./index.css";
import { animated, useSpring } from "@react-spring/web";
import { useScroll } from "@use-gesture/react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { handleTokenExpiration } from "../../axios/tokenUtils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Box,
} from "@mui/material/";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import {
  getFavoriteWorkSpaces,
  toggleFavoriteWorkSpace,
  fetchWorkSpaceInformation,
} from "../../apis/apis";
import jwtDecode from "jwt-decode";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import LabelsSection from "./ProjectLabels/ProjectLabels";

const WorkSpace = () => {
  const navigate = useNavigate();
  const { workspaceId } = useParams(); // Define workspaceId
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [labelsSectionOpen, setLabelsSectionOpen] = useState(false); // State for the LabelsSection
  const [currentProjectName, setCurrentProjectName] = useState(""); // State to hold the current project name
  const [currentProjectId, setCurrentProjectId] = useState(""); // State to hold the current project ID
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenLabelsSection = (projectName, projectId) => {
    setCurrentProjectName(projectName);
    setCurrentProjectId(projectId);
    setLabelsSectionOpen(true);
  };

  const handleCloseLabelsSection = () => {
    setLabelsSectionOpen(false);
  };

  const [workSpaceData, setWorkSpaceData] = useState([]);
  const token = sessionStorage.getItem("token");
  const currentUser = jwtDecode(token);
  const [currentExpandedPanel, setCurrentExpandedPanel] = useState(null);

  const [style, set] = useSpring(() => ({
    transform: "perspective(500px) rotateY(0deg)",
  }));

  const bind = useScroll((event) => {
    if (currentExpandedPanel !== null) {
      set({
        transform: `perspective(500px) rotateY(${clamp(event.delta[0])}deg)`,
      });
    }
  });

  const getWorkSpaces = async () => {
    try {
      const res = await axios.get(
        "api/project/fetch-user-top-work-spaces-by-project",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res && res.data) {
        setWorkSpaceData(res.data);
        console.log("burası", res.data);
      }
    } catch (error) {
      handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
  };

  useEffect(() => {
    getWorkSpaces();
  }, []);

  const [expanded, setExpanded] = useState(
    [...Array(5)].reduce((acc, _, index) => {
      acc[`panel${index + 1}`] = true;
      return acc;
    }, {})
  );

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setCurrentExpandedPanel(panel);
    } else {
      setCurrentExpandedPanel(null);
    }
    setExpanded((prev) => ({ ...prev, [panel]: isExpanded }));
  };

  const clamp = (value, clampAt) => {
    if (value > 0) {
      return value > clampAt ? clampAt : value;
    } else {
      return value < -clampAt ? -clampAt : value;
    }
  };

  useEffect(() => {
    set({
      transform: "perspective(500px) rotateY(360deg)",
      config: { duration: 400 },
    });
  }, []);

  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const [favoriteWorkspaces, setFavoriteWorkspaces] = useState([]);
  const [isFetchedFavoriteWorkspaces, setIsFetchedFavoriteWorkspaces] =
    useState(false);

  const fetchFavoriteWorkspaces = () => {
    getFavoriteWorkSpaces(token, setErrorMessage, handleOpenDialog, navigate)
      .then((favoriteWorkspacesData) => {
        setFavoriteWorkspaces(favoriteWorkspacesData);
        setIsFetchedFavoriteWorkspaces(false);
        for (let i = 0; i < favoriteWorkspacesData.length; i++) {
          if (
            favoriteWorkspacesData[i].related_work_package_id == workspaceId
          ) {
            setIsFetchedFavoriteWorkspaces(true);
            break;
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message || "An error occurred"); // Define setErrorMessage
      });
  };

  useEffect(() => {
    fetchFavoriteWorkspaces();
  }, []);

  const addToFavorites = async (id) => {
    try {
      await toggleFavoriteWorkSpace(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate,
        id
      );
      fetchFavoriteWorkspaces();
    } catch (error) {
      handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    }
  };

  const handleFavoriteClick = (id) => {
    addToFavorites(id);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  return (
    <>
      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Error</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div style={{ padding: "20px", paddingLeft: "5px" }}>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
            backgroundColor: 
              theme.palette.mode === "light" ? light.primary : dark.primary,
            
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Project"
            inputProps={{ "aria-label": "search google maps" }}
            onChange={handleSearchChange}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      {workSpaceData
        .filter(
          (workSpace) =>
            searchValue.length === 0 ||
            workSpace.abbr.toLowerCase().includes(searchValue.toLowerCase()) ||
            workSpace.name.toLowerCase().includes(searchValue.toLowerCase())
        )
        .map((workSpace, index) => (
          <Accordion
            key={index}
            sx={{
              borderRadius: "15px",
              padding: "10px",
              marginBottom: "10px",
              backgroundImage: "url(your-image-url)",
              "&:before": {
                backgroundColor: "none",
                opacity: 0,
              },
              backgroundColor: 
              theme.palette.mode === "light" ? light.primary : dark.primary,
            }}
            expanded={expanded[`panel${index + 1}`]}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ExpandMoreIcon />
                </div>
              }
              aria-controls={`panel${index + 1}bh-content`}
              id={`panel${index + 1}bh-header`}
              sx={{ backgroundColor: "transparent" }}
            >
              <Typography sx={{ color: "text.secondary" }}>
                {workSpace.name} ({workSpace.abbr})
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "auto",
                  border: `1px solid ${
                    theme.palette.mode === "light" ? light.border : dark.border
                  }`,
                  borderRadius: "5px",
                  padding: "5px",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light" ? light.hover : dark.hover,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation(); // Stop event propagation
                  handleOpenLabelsSection(workSpace.name, workSpace.id);
                }}
              >
                <SellIcon
                  sx={{
                    color:
                      theme.palette.mode === "dark" ? dark.text : light.text,

                    "&:hover": {
                      color:
                        theme.palette.mode === "dark" ? dark.text : light.text,
                    },
                  }}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: "transparent",
                padding: "10px",
                minHeight: "250px",
              }}
            >
              <div
                className={`container-2 ${
                  currentExpandedPanel === `panel${index + 1}`
                    ? "active-accordion"
                    : ""
                }`}
                {...(currentExpandedPanel === `panel${index + 1}`
                  ? bind()
                  : {})}
              >
                {workSpace.work_packages.map((wp) =>
                  wp && wp.work_space ? (
                    <animated.div
                      className="card-2"
                      style={{ ...style }}
                      key={wp.id}
                    >
                      <ComplexStatisticsCard
                        color="dark"
                        title={wp.work_space.name}
                        id={wp.id.toString()} // Ensure id is a string
                        key={wp.id}
                        count={wp.work_space.total_task_count}
                        isFavorite={favoriteWorkspaces?.some(
                          (fav) => fav.related_work_package_id === wp.id
                        )}
                        onFavoriteClick={() => handleFavoriteClick(wp.id)}
                        percentage={{
                          color: wp.percentage
                            ? wp.percentage.color
                            : "default",
                          amount: wp.percentage ? wp.percentage.amount : "0%",
                          label: wp.percentage
                            ? wp.percentage.label
                            : "No data available",
                        }}
                      ></ComplexStatisticsCard>
                    </animated.div>
                  ) : null
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <LabelsSection
        open={labelsSectionOpen}
        onClose={handleCloseLabelsSection}
        projectName={currentProjectName}
        projectId={currentProjectId}
      />
    </>
  );
};

export default WorkSpace;
