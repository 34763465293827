import { InfoBox } from "components/rekrom/InfoBox";
import { Container, Typography, Paper, Tab, Tabs } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

import DayOffForms from "./DayOffForms";
import PurchasingForms from "./PurchasingForms";

import { useState } from "react";

const Forms = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const theme = useTheme();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  return (
    <div style={{marginTop:"10px"}}>
      <InfoBox
        errorMessage={errorMessage}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <Container maxWidth="md" sx={{backgroundColor: theme.palette.mode === 'light' ? light.primary : dark.primary, padding:'10px'}}>
        <Typography variant="h4" component="div" gutterBottom sx={{color: theme.palette.mode === 'light' ? light.text : dark.text}}>
          Forms
        </Typography>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          TabIndicatorProps={{ sx: { backgroundColor: theme.palette.mode === 'light' ? light.hover : dark.hover } }}
          centered
          sx={{
            marginBottom: "10px",
            backgroundColor: theme.palette.mode === 'light' ? light.secondary : dark.secondary,
          }}
        >
          <Tab label="Day Off Forms" />
          <Tab label="Purchasing Forms" />
        </Tabs>

        {selectedTab === 0 &&
          <Paper sx={{ backgroundColor: theme.palette.mode === 'light' ? light.secondary : dark.secondary, padding: '10px', height:"850px"}}>
            <DayOffForms />
          </Paper>
        }
        {selectedTab === 1 &&
          <Paper sx={{ backgroundColor: theme.palette.mode === 'light' ? light.secondary : dark.secondary, padding: '10px'}}>
            <PurchasingForms />
          </Paper>
        }
      </Container>
    </div>
  )
};

export default Forms;
