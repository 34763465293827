import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Container, Typography } from '@mui/material';
import rekromBack from 'assets/images/rekrom-background-v2.png';
import errorImg from 'assets/images/undraw_page_not_found_su7k.svg';
import BasicLayout from 'layouts/404/BasicLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Error = () => {
	const navigate = useNavigate();

	const goDash = () => {
		navigate('/');
	};

	return (
		<>
			<BasicLayout image={rekromBack}>
				<title>404 | Material Kit</title>
				<Box
					component="main"
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexGrow: 1,
						minHeight: '100%',
						minWidth: '100%',
					}}
				>
					<Container maxWidth="lg">
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Typography
								align="center"
								color="textPrimary"
								variant="h1"
								sx={{ mt: 6 }}
							>
								404: The page you are looking for isn’t here
							</Typography>
							<Typography align="center" color="textPrimary" variant="body2">
								You either tried some shady route or you came here by mistake.
								Whichever it is, try using the navigation
							</Typography>
							<Box sx={{ textAlign: 'center' }}>
								<img
									alt="Under development"
									src={errorImg}
									style={{
										marginTop: 50,
										display: 'inline-block',
										maxWidth: '100%',
										width: 560,
									}}
								/>
							</Box>
							<Button
								component="a"
								startIcon={<ArrowBackIcon fontSize="small" />}
								onClick={goDash}
								sx={{ mt: 3, mb: 8 }}
								variant="contained"
							>
								Go back to dashboard
							</Button>
						</Box>
					</Container>
				</Box>
			</BasicLayout>
		</>
	);
};

export default Error;
