import { Container, Typography, Grid, CircularProgress, Box, Pagination, Autocomplete, TextField } from "@mui/material";

import { InfoBox } from "../../../components/rekrom/InfoBox";
import DayOffFormsList from "./DayOffFormsList";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getDayOffForms, getEmployeesFullName} from "../../../apis/apis/index";

import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors"

import filteringOptions from "./filters";
const { dayOffStatus, dayOffExcuseTypes, dayOffSortOptions } = filteringOptions;

const Reset_Page = 1;

export default function DayOffForms() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  // Example data for day off requests (you should replace this with your actual data)
  const [dayOffRequests, setDayOffRequests] = useState(null);
  const [employees, setEmployees] = useState([]);

  // State for controlling the visibility of the view details dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedExcuseType, setSelectedExcuseType] = useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState("Newest to Oldest");

  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const theme = useTheme();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchDayOffForms = () => {
    setLoading(true);
    // Replace getChangelog with your actual API function to fetch changelog data
    getDayOffForms(
      token, 
      setErrorMessage, 
      handleOpenDialog, 
      navigate, 
      page, 
      selectedEmployee && employees ? employees.find(employee => employee.fullName === selectedEmployee)?.id : null,
      selectedStatus ? dayOffStatus.find(stat => stat.status === selectedStatus)?.id : null, 
      selectedExcuseType ? dayOffExcuseTypes.find(type => type.type === selectedExcuseType)?.id : null, 
      selectedSortOption
    )
    .then((fetchedDayOffForms) => {
      if(fetchDayOffForms){
        setDayOffRequests(fetchedDayOffForms.day_off_data);
        setPageCount(fetchedDayOffForms.total_page_count);
      }
    })
    .catch((error) => {
      setErrorMessage(error.message || 'An error occured')
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const fetchEmployeesFullName = () => {
    getEmployeesFullName(token, setErrorMessage, handleOpenDialog, navigate)
      .then((fetchedFullNames) => {
        setEmployees(fetchedFullNames.map(employee => ({
          id: employee.id,
          fullName: employee.employee_name
        })));
      })
      .catch((error) => {
        setErrorMessage("An error occured")
      })
  }

  useEffect(() => {
    if(token){
      fetchDayOffForms();
    }
  },[token, page, selectedEmployee, selectedStatus, selectedExcuseType, selectedSortOption]);

  useEffect(() => {
    fetchEmployeesFullName();
  }, []);

  const handleFilterChange = (filter, value) => {
    setPage(Reset_Page);

    if(filter == "requested_by"){
      setSelectedEmployee(value);
    }
    else if(filter == "status"){
      setSelectedStatus(value);
    }
    else if(filter == "excuse_type"){
      setSelectedExcuseType(value);
    }
    else if(filter == "sort_option"){
      setSelectedSortOption(value);
    }
    else{
      setSelectedEmployee(null);
      setSelectedStatus(null);
      setSelectedExcuseType(null);
      setSelectedSortOption(null);
      setErrorMessage("Filter options error");
    }
  }

  return (
    <Container>
      {errorMessage &&
        <InfoBox errorMessage={errorMessage} openDialog={openDialog} handleCloseDialog={handleCloseDialog}/>
      }
      
      <Typography  variant="h5" gutterBottom sx={{color: theme.palette.mode === 'light' ? light.text : dark.text, height:35, display:"flex"}}>
        Day Off Forms
        {loading ? 
          <CircularProgress size={25} sx={{color:"#686868", marginLeft:"10px"}}/> 
        :
          null
        }
      </Typography>
      
      <Grid container sx={{marginBottom:"20px"}}>
        <Grid item>
          <Box display="flex" alignItems="center" gap="10px">
            <Autocomplete
              disablePortal
              options={employees.map(employee => employee.fullName)}
              sx={{width:"200px", '.MuiAutocomplete-input': { color: theme.palette.mode === 'light' ? light.text : dark.text }}}
              renderInput={(params) => <TextField {...params} label="Requested By" sx={{ height: "40px", input: { height: "10px" }}}/>}
              onChange={(event, newValue) => {handleFilterChange("requested_by", newValue)}}
            />

            <Autocomplete
              disablePortal
              options={dayOffStatus.map(stat => stat.status)}
              sx={{width:"200px", '.MuiAutocomplete-input': { color: theme.palette.mode === 'light' ? light.text : dark.text }}}
              renderInput={(params) => <TextField {...params} label="Status" sx={{ height: "40px", input: { height: "10px" } }}/>}
              onChange={(event, newValue) => {handleFilterChange("status", newValue)}}
            />

            <Autocomplete
              disablePortal
              options={dayOffExcuseTypes.map(type => type.type)}
              sx={{width:"200px", '.MuiAutocomplete-input': { color: theme.palette.mode === 'light' ? light.text : dark.text } }}
              renderInput={(params) => <TextField {...params} label="Excuse Type" sx={{ height: "40px", input: { height: "10px" } }}/>}
              onChange={(event, newValue) => {handleFilterChange("excuse_type", newValue)}}
            />
          </Box>
        </Grid>
        <Grid item xs={false} style={{ flexGrow: 1 }} />
        <Grid item>
          <Autocomplete
            freeSolo
            options={dayOffSortOptions}
            sx={{width:"200px", '.MuiAutocomplete-input': { color: theme.palette.mode === 'light' ? light.text : dark.text }}}
            renderInput={(params) => <TextField {...params} label="Sort By Creation" sx={{ height: "40px", input: { height: "10px" } }}/>}
            onChange={(event, newValue) => {handleFilterChange("sort_option", newValue)}}
            value={selectedSortOption}
          />
        </Grid>
      </Grid>
        
      <Grid container spacing={2}>
          <DayOffFormsList 
            dayOffRequests={dayOffRequests}
            onFormUpdate={fetchDayOffForms} 
            onLoading={setLoading} 
            onError={handleOpenDialog} 
            setErrorMessage={setErrorMessage}
          />
      </Grid>

      <Box display="flex" justifyContent="center" marginTop="20px">
        <Pagination 
          count={pageCount} 
          page={page} 
          onChange={(event, value) => {setPage(value)}}
        />
      </Box>
    </Container>
  );
};