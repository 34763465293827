import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import { NotificationComponent } from "./notification";
import { popups } from "./popups";
import { fetchNotifications } from "../../apis/apis/index";
import { getHierarchyStructure } from "../../apis/apis/index";
import { axisLabelClick } from "@syncfusion/ej2/charts";

import axios from "../../axios/index";

import {
  getUnreadNotificationCount,
  updateNotificationReadStatus,
  markAllAsReadNotifications,
} from "../../apis/apis/index";
import { useData } from "context";


function Notifications() {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [notifications, setNotifications] = useState([]);
  const [isNotificationsFetched, setIsNotificationsFetched] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const token = sessionStorage.getItem("token");
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { dispatchNotification } = useData();


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const getNotifications = () => {
    //console.log("getNotifications called")
    setLoading(true);
    setError(null);
    // Replace getChangelog with your actual API function to fetch changelog data
    fetchNotifications(token, setErrorMessage, handleOpenDialog, useNavigate)
      .then((fetchedNotifications) => {
        setNotifications(fetchedNotifications);
      })
      .catch((error) => {
        setError(error.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    //console.log("useEffect")
    if (token) {
      //console.log("inside if")
      getNotifications();
    }
  }, [token]);

  const markAllAsRead = async () => {
    await markAllAsReadNotifications(token);
    getNotifications();
    getUnreadNotificationCount(token).then((res) => {
      dispatchNotification({ type: "SET_DATA", payload: { data: res.count } });
    });

  };

  return (
    <DashboardLayout>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              {/* Header Section */}
              <MDBox p={2} display="flex" alignItems="center" justifyContent="space-between">
                <MDTypography variant="h5">Notifications</MDTypography>
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={markAllAsRead}
                >
                  Mark All As Read
                </MDButton>
              </MDBox>
              {/* Notifications List */}
              <MDBox p={2}>
                <Grid container spacing={2}>
                  {notifications &&
                    notifications.map((notification, index) => (
                      <Grid item xs={12} key={notification.id || index}>
                        <NotificationComponent
                          notification={notification}
                          token={token}
                          setErrorMessage={setErrorMessage}
                          handleOpenDialog={handleOpenDialog}
                          navigate={useNavigate}
                          getNotifications={getNotifications}
                        />
                      </Grid>
                    ))}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
  
}

export default Notifications;
