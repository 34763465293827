// Board.js
import React from "react";
import Card from "../Card/Card";
import "./Board.css";
import Editable from "../Editable/Editable";
import { Droppable } from "react-beautiful-dnd";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

export default function Board(props) {
  const newOrderIndex = props.card ? props.card.length : 0;
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  return (
    <div
      className="board"
      style={{
        backgroundColor: props.backgroundColor || "#f4f5f7",
        borderColor: props.borderColor || "rgba(0, 0, 0, 0.125)",
        minWidth: "300px",
        flex: "1 1 auto",
      }}
    >
      <div className="board__top">
        <div>
          <p
            style={{
              marginLeft: "12px",
              marginTop: "12px",
              color: theme.palette.mode === "dark" ? dark.text : light.text,
            }}
            className="board__title"
          >
            {props?.name || "Name of Board"}
            <span
              style={{
                fontSize: "12px",
                borderRadius: "5px",
                padding: "3px",
                border: "2px solid lightgrey",
                backgroundColor: "white",
                marginLeft: "10px",
                color: "black",
                minWidth: "30px",
                textAlign: "center",
                display: "inline-block",
                verticalAlign: "middle",
                position: "relative",
                top: "-2px",
              }}
            >
              {props.card?.length}
            </span>
          </p>
        </div>
      </div>
      <Droppable droppableId={props.id.toString()}>
        {(provided) => (
          <div
            className="board__cards"
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ overflowY: "auto" }}
          >
            {props.card?.map((items, index) => (
              <Card
                employees={props.employees}
                bid={props.id}
                id={items.id}
                index={index}
                key={items.id}
                title={items.name}
                tags={items.labels || []} // Pass labels here
                updateCard={props.updateCard}
                removeCard={props.removeCard}
                updateTask={props.handleUpdateTask}
                card={items}
                highlightedTaskId={props.highlightedTaskId}
                cardBackgroundColor={
                  theme.palette.mode === "dark" ? dark.primary : light.primary
                }
                projectInfo={props.projectInfo}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div className="board__footer">
        <Editable
          name={null}
          btnName="Add Task"
          placeholder="Enter task title..."
          onSubmit={(value) => props.addNewTask(value, props.id, newOrderIndex)}
        />
      </div>
    </div>
  );
}
