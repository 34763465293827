import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IoAddOutline } from "react-icons/io5";
import {
  fetchProducts,
  fetchLabs,
  fetchCategories,
  fetchCategorySets,
  deleteProduct,
} from "../../../apis/apis/index";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useTheme } from "@mui/material/styles";
import ProductDialog from "../ProductDialog/index";
import FilterSection from "../FilterSection/index";

export default function InventoryTable() {
  const token = sessionStorage.getItem("token");
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isNewProduct, setIsNewProduct] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [productsData, setProductsData] = useState([]);
  const [labs, setLabs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categorySets, setCategorySets] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpenDialog = (product = null) => {
    if (product && product.details) {
      const {
        lab_id,
        lab_name,
        category_set_id,
        category_set_name,
        category_id,
        category_name,
      } = product.details;

      setSelectedProduct({
        ...product,
        lab: { id: lab_id, name: lab_name },
        category_set: { id: category_set_id, name: category_set_name },
        category: { id: category_id, name: category_name },
      });
    } else {
      setSelectedProduct({
        name: "",
        quantity: "",
        image: null,
        description: "",
        lab: { id: "", name: "" },
        category_set: { id: "", name: "" },
        category: { id: "", name: "" },
      });
    }

    setIsNewProduct(product === null);
    setOpenDialog(true);
  };

  const handleDialogClose = async () => {
    setOpenDialog(false);
    setSelectedProduct(null);
    await fetchProductsDataOnly();
  };

  const fetchProductsDataOnly = async () => {
    try {
      const productsData = await fetchProducts(
        token,
        {},
        setErrorMessage,
        handleOpenDialog
      );

      const updatedProducts = (productsData || []).map((product) => {
        return {
          ...product,
          laboratory: product.details?.lab_name || "Unknown",
          categorySet: product.details?.category_set_name || "Unknown",
          category: product.details?.category_name || "Unknown",
          missingDetails: getMissingDetails(product.details),
        };
      });

      setProductsData(updatedProducts);
      setRows(updatedProducts);
    } catch (error) {
      setError(error);
      setRows([]);
    }
  };

  const loadInitialData = async () => {
    try {
      const [productsData, labsData, categoriesData, categorySetsData] =
        await Promise.all([
          fetchProducts(token, {}, setErrorMessage, handleOpenDialog),
          fetchLabs(token, setErrorMessage, handleOpenDialog),
          fetchCategories(token, setErrorMessage, handleOpenDialog),
          fetchCategorySets(token, setErrorMessage, handleOpenDialog),
        ]);

      const updatedProducts = (productsData || []).map((product) => {
        return {
          ...product,
          laboratory: product.details?.lab_name || "Unknown",
          categorySet: product.details?.category_set_name || "Unknown",
          category: product.details?.category_name || "Unknown",
          missingDetails: getMissingDetails(product.details),
        };
      });

      setProductsData(updatedProducts);
      setLabs(labsData || []);
      setCategories(categoriesData || []);
      setCategorySets(categorySetsData || []);
      setRows(updatedProducts);
    } catch (error) {
      setError(error);
      setRows([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const applyFilters = (
    filteredLabs,
    filteredCategorySets,
    filteredCategories
  ) => {
    const filteredProducts = (productsData || []).filter((product) => {
      const labMatch =
        filteredLabs.length === 0 ||
        filteredLabs.includes(product.details.lab_name);

      const categorySetMatch =
        filteredCategorySets.length === 0 ||
        filteredCategorySets.includes(product.details.category_set_name);

      const categoryMatch =
        filteredCategories.length === 0 ||
        filteredCategories.includes(product.details.category_name);

      return labMatch && categorySetMatch && categoryMatch;
    });

    setRows(filteredProducts);
  };

  const handleDeleteDialogOpen = (product) => {
    setProductToDelete(product);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = async () => {
    setOpenDeleteDialog(false);
    setProductToDelete(null);
  };

  const handleDeleteProduct = async () => {
    try {
      await deleteProduct(
        token,
        setErrorMessage,
        handleDialogClose,
        productToDelete.id,
        showSnackbar
      );
      await fetchProductsDataOnly();
      handleDeleteDialogClose();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const getMissingDetails = (details) => {
    const missingDetails = [];
    if (!details?.lab_id) missingDetails.push("Lab");
    if (!details?.category_set_id) missingDetails.push("Category Set");
    if (!details?.category_id) missingDetails.push("Category");
    return missingDetails;
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ height: "80vh", marginTop: "1rem", borderRadius: "8px" }}
    >
      <Box flexGrow={1} display="flex" sx={{ overflow: "hidden" }}>
        <FilterSection
          products={productsData}
          labs={labs}
          categorySets={categorySets}
          categories={categories}
          applyFilters={applyFilters}
          showSnackbar={showSnackbar}
          setErrorMessage={setErrorMessage}
          handleOpenDialog={handleOpenDialog}
          refreshData={loadInitialData}
        />

        <Box
          flexGrow={0.7}
          padding="1rem"
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
            borderRadius: "8px",
            marginLeft: "0.5rem",
            height: "100%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box sx={{ height: "88%", overflowY: "auto", overflowX: "auto" }}>
            <DataGrid
              rows={rows}
              columns={[
                {
                  field: "name",
                  headerName: "Name",
                  width: 250,
                  sortable: true,
                  filterable: false,
                  align: "center",
                  headerAlign: "center",
                  flex: 1,
                },
                {
                  field: "quantity",
                  headerName: "Quantity",
                  width: 100,
                  sortable: true,
                  filterable: false,
                  align: "center",
                  headerAlign: "center",
                  flex: 0.4,
                },
                {
                  field: "missingDetails",
                  headerName: "Details",
                  width: 100,
                  sortable: false,
                  filterable: false,
                  align: "center",
                  headerAlign: "center",
                  flex: 0.5,
                  renderCell: (params) => {
                    const missingDetails = params.row.missingDetails || [];
                    return (
                      missingDetails.length > 0 && (
                        <Tooltip
                          title={
                            <span>
                              {" "}
                              <strong>Missing:</strong>
                              <br />
                              {missingDetails.map((detail, index) => (
                                <span key={index}>
                                  {detail}
                                  <br />
                                </span>
                              ))}
                            </span>
                          }
                          arrow
                        >
                          <WarningAmberIcon
                            sx={{
                              fontSize: "inherit",
                              width: "20px",
                              height: "20px",
                              color:
                                theme.palette.mode === "light"
                                  ? "#ba9400"
                                  : "#f5d03b",
                            }}
                          />
                        </Tooltip>
                      )
                    );
                  },
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  width: 170,
                  sortable: false,
                  filterable: false,
                  align: "center",
                  headerAlign: "center",
                  flex: 0.5,
                  renderCell: (params) => (
                    <Box display="flex" justifyContent="center" width="100%">
                      <IconButton
                        sx={{ padding: "4px" }}
                        onClick={() => handleOpenDialog(params.row)}
                      >
                        <VisibilityIcon />
                      </IconButton>

                      <IconButton
                        sx={{
                          padding: "4px",
                          color:
                            theme.palette.mode === "light"
                              ? light.error
                              : dark.error,
                        }}
                        onClick={() => handleDeleteDialogOpen(params.row)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ),
                },
              ]}
              autoHeight={false}
              hideFooter
              disableColumnMenu={true}
              sx={{
                height: "100%",
                width: "100%",
                maxWidth: "100%",
                ".MuiDataGrid-cell": {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
                ".MuiDataGrid-columnHeader": {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor:
                theme.palette.mode === "dark" ? dark.primary : light.primary,
              padding: "1rem",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                borderRadius: "4px",
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
                color:
                  theme.palette.mode === "light"
                    ? light.buttonIcon
                    : dark.buttonIcon,
                backgroundColor:
                  theme.palette.mode === "light"
                    ? light.buttonBackground
                    : dark.buttonBackground,
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.buttonBackgroundHover
                      : dark.buttonBackgroundHover,
                  cursor: "pointer",
                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIconHover
                      : dark.buttonIconHover,
                },
                width: "fit-content",
                height: "fit-content",
                display: "inline-block",
                textAlign: "center",
                lineHeight: "0",
                padding: "0.25rem",
              }}
              onClick={() => handleOpenDialog()}
            >
              <span style={{ fontSize: "30px" }}>
                <IoAddOutline />
              </span>
            </Box>
            <Box
              sx={{
                marginRight: "1rem",
                fontSize: "1rem",
                textAlign: "center",
                color: theme.palette.mode === "dark" ? dark.text2 : light.text,
              }}
            >
              Number of products:
              <strong
                style={{
                  color:
                    theme.palette.mode === "dark" ? dark.text1 : light.text,
                }}
              >
                &nbsp;{rows.length}
              </strong>
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="product-details-dialog"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          id="product-details-dialog"
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
            color: theme.palette.mode === "dark" ? dark.text : light.text,
          }}
        >
          {isNewProduct
            ? "Add New Product"
            : `Details of ${selectedProduct?.name || "Product"}`}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
          }}
        >
          <ProductDialog
            open={openDialog}
            onClose={handleDialogClose}
            product={selectedProduct}
            labs={labs}
            categorySets={categorySets}
            categories={categories}
            isNew={isNewProduct}
            setErrorMessage={setErrorMessage}
            showSnackbar={showSnackbar}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-confirmation-dialog"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          id="delete-confirmation-dialog"
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
            color: theme.palette.mode === "dark" ? dark.text : light.text,
          }}
        >
          Confirm Delete
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
          }}
        >
          <DialogContentText
            sx={{
              color: theme.palette.mode === "dark" ? dark.text : light.text,
            }}
          >
            Are you sure you want to delete{" "}
            <strong>{productToDelete?.name}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
          }}
        >
          <Button
            onClick={handleDeleteProduct}
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? dark.primary : light.primary,
              color: theme.palette.mode === "dark" ? dark.text : light.text,
              border: `1px solid ${
                theme.palette.mode === "dark" ? dark.border : light.border
              }`,
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? dark.secondary
                    : light.secondary,
              },
            }}
          >
            Delete
          </Button>
          <Button
            onClick={handleDeleteDialogClose}
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? dark.primary : light.primary,
              color: theme.palette.mode === "dark" ? dark.text : light.text,
              border: `1px solid ${
                theme.palette.mode === "dark" ? dark.border : light.border
              }`,
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? dark.secondary
                    : light.secondary,
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
