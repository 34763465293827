import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Box,
  TextField,
  Divider,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  fetchCustomProjectLabels,
  createProjectLabel,
  editProjectLabel,
  deleteProjectLabel,
} from "../../../apis/apis/index";
import { useTheme } from "@mui/material/styles";
import { lighten, darken } from "@mui/system";
import RefreshIcon from "@mui/icons-material/Refresh";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

const ProjectLabelsSection = ({ open, onClose, projectName, projectId }) => {
  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newLabelName, setNewLabelName] = useState("");
  const [newLabelColor, setNewLabelColor] = useState("#000000");
  const [colorValid, setColorValid] = useState(true);
  const [editingLabel, setEditingLabel] = useState(null);
  const [labelToDelete, setLabelToDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showNewLabelFields, setShowNewLabelFields] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const token = sessionStorage.getItem("token");
  const [errorMessage, setErrorMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  useEffect(() => {
    if (theme.palette.mode === "dark") {
      setNewLabelColor("#ffffff");
    } else {
      setNewLabelColor("#000000");
    }
  }, [theme.palette.mode]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const fetchLabels = () => {
    setLoading(true);
    fetchCustomProjectLabels(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate,
      projectId
    )
      .then((data) => {
        setLabels(data || []);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      fetchLabels();
    }
  }, [open, projectId, token]);

  const handleCreateLabel = () => {
    if (!newLabelName || !newLabelColor) {
      setErrorMessage("Label name and color are required.");
      handleOpenDialog();
      return;
    }

    const newLabel = { name: newLabelName, color: newLabelColor };
    createProjectLabel(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate,
      projectId,
      newLabel
    )
      .then(() => {
        setNewLabelName("");
        setNewLabelColor(theme.palette.mode === "dark" ? "#ffffff" : "#000000");
        setSnackbar({
          open: true,
          message: "Label created successfully!",
          severity: "success",
        });
        setShowNewLabelFields(false);
        fetchLabels();
      })
      .catch((err) => {
        setError(err);
        setSnackbar({
          open: true,
          message: "Failed to create label!",
          severity: "error",
        });
      });
  };

  const handleUpdateLabel = () => {
    if (!newLabelName || !newLabelColor) {
      setErrorMessage("Label name and color are required.");
      handleOpenDialog();
      return;
    }

    const updatedLabel = {
      name: newLabelName,
      color: newLabelColor,
    };
    editProjectLabel(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate,
      projectId,
      editingLabel.id,
      updatedLabel
    )
      .then(() => {
        setEditingLabel(null);
        setNewLabelName("");
        setNewLabelColor(theme.palette.mode === "dark" ? "#ffffff" : "#000000");
        setSnackbar({
          open: true,
          message: "Label updated successfully!",
          severity: "success",
        });
        fetchLabels();
      })
      .catch((err) => {
        setError(err);
        setSnackbar({
          open: true,
          message: "Failed to update label!",
          severity: "error",
        });
      });
  };

  const handleClose = () => {
    onClose();
    setNewLabelName("");
    setNewLabelColor(theme.palette.mode === "dark" ? "#ffffff" : "#000000");
    setLabels([]);
    setError(null);
    setEditingLabel(null);
    setShowNewLabelFields(false);
  };

  const generateRandomColor = () => {
    let randomColor;
    do {
      randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    } while (randomColor === newLabelColor);
    setNewLabelColor(randomColor);
    setColorValid(true);
  };

  const validateColor = (color) => {
    const isValid =
      /^#([0-9A-F]{3}){1,2}$/i.test(color) ||
      /^rgb(a?)\((\d+,\s?){2}\d+(,\s?(\d+\.?\d*)?)?\)$/i.test(color) ||
      /^hsl(a?)\((\d+,\s?){2}(\d+\.?\d*%?)(,\s?(\d+\.?\d*)?)?\)$/i.test(
        color
      ) ||
      /^color\(.+\)$/i.test(color);
    setColorValid(isValid);
    return isValid;
  };

  const handleDeleteLabel = (label) => {
    setLabelToDelete(label);
    setConfirmDelete(true);
  };

  const confirmDeleteLabel = () => {
    deleteProjectLabel(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate,
      projectId,
      labelToDelete.id
    )
      .then(() => {
        setLabelToDelete(null);
        setConfirmDelete(false);
        setSnackbar({
          open: true,
          message: "Label deleted successfully!",
          severity: "success",
        });
        fetchLabels();
      })
      .catch((err) => {
        setError(err);
        setSnackbar({
          open: true,
          message: "Failed to delete label!",
          severity: "error",
        });
      });
  };

  const handleCloseConfirmDelete = () => {
    setLabelToDelete(null);
    setConfirmDelete(false);
  };

  const handleNewLabelClick = () => {
    setShowNewLabelFields(!showNewLabelFields);
    setEditingLabel(null);
    setNewLabelName("");
    setNewLabelColor(theme.palette.mode === "dark" ? "#ffffff" : "#000000");
    setColorValid(true);
  };

  const handleEditLabel = (label) => {
    if (editingLabel && editingLabel.id === label.id) {
      setEditingLabel(null);
      setNewLabelName("");
      setNewLabelColor(theme.palette.mode === "dark" ? "#ffffff" : "#000000");
      setColorValid(true);
    } else {
      setShowNewLabelFields(false);
      setEditingLabel(label);
      setNewLabelName(label.name);
      setNewLabelColor(label.color);
      setColorValid(true);
    }
  };

  const handleSaveLabel = () => {
    if (editingLabel) {
      handleUpdateLabel();
    } else {
      handleCreateLabel();
    }
  };

  const handleCancelEdit = () => {
    setEditingLabel(null);
    setNewLabelName("");
    setNewLabelColor(theme.palette.mode === "dark" ? "#ffffff" : "#000000");
    setColorValid(true);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
            color: theme.palette.mode === "dark" ? dark.text : light.text,
          }}
        >
          Custom Labels of {projectName}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">Error fetching labels</Typography>
          ) : (
            <>
              {labels.length === 0 ? (
                <Typography>No labels yet.</Typography>
              ) : (
                <Box
                  sx={{
                    maxHeight: "50vh",
                    overflowY: "auto",
                    marginTop: "15px",
                  }}
                >
                  {labels
                    .slice()
                    .reverse()
                    .map((label) => (
                      <Grid
                        container
                        key={label.id}
                        spacing={0}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid
                          item
                          xs="auto"
                          style={{
                            display: "flex",
                            maxWidth: "fit-content",
                            justifyContent: "flex-start",
                            border: `1px solid ${
                              theme.palette.mode === "light"
                                ? darken(label.color, 0.5)
                                : lighten(label.color, 0.5)
                            }`,
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? lighten(label.color, 0.5)
                                : darken(label.color, 0.5),
                            borderRadius: "10px",
                            padding: "7px",
                            margin: "5px",
                          }}
                        >
                          <Typography
                            noWrap
                            style={{
                              fontSize: "0.9rem",
                              color:
                                theme.palette.mode === "light"
                                  ? darken(label.color, 0.5)
                                  : lighten(label.color, 0.5),
                            }}
                          >
                            {label.name}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Box display="flex" justifyContent="flex-end" pr={2}>
                            <Button
                              sx={{
                                padding: "0",
                                backgroundColor:
                                  theme.palette.mode === "dark"
                                    ? dark.buttonBackground
                                    : light.buttonBackground,
                                color:
                                  theme.palette.mode === "dark"
                                    ? dark.buttonIcon
                                    : light.buttonIcon,
                                border: `1px solid ${
                                  theme.palette.mode === "dark"
                                    ? dark.border
                                    : light.border
                                }`,
                                "&:hover": {
                                  backgroundColor:
                                    theme.palette.mode === "dark"
                                      ? dark.buttonBackgroundHover
                                      : light.buttonBackgroundHover,
                                  color:
                                    theme.palette.mode === "dark"
                                      ? dark.buttonIconHover
                                      : light.buttonIconHover,
                                  borderColor:
                                    theme.palette.mode === "dark"
                                      ? dark.borderHover
                                      : light.borderHover,
                                },
                                maxWidth: "fit-content",
                                fontSize: "0.6rem",
                              }}
                              onClick={() => handleEditLabel(label)}
                            >
                              {editingLabel && editingLabel.id === label.id
                                ? "Cancel"
                                : "Edit"}
                            </Button>
                            {!(
                              editingLabel && editingLabel.id === label.id
                            ) && (
                              <Button
                                sx={{
                                  padding: "0",
                                  backgroundColor:
                                    theme.palette.mode === "dark"
                                      ? dark.buttonBackground
                                      : light.buttonBackground,
                                  color:
                                    theme.palette.mode === "dark"
                                      ? dark.buttonIcon
                                      : light.buttonIcon,
                                  border: `1px solid ${
                                    theme.palette.mode === "dark"
                                      ? dark.border
                                      : light.border
                                  }`,
                                  "&:hover": {
                                    backgroundColor: "#e32d2d",
                                    color: "#ffffff",
                                    borderColor:
                                      theme.palette.mode === "dark"
                                        ? dark.borderHover
                                        : light.borderHover,
                                  },
                                  marginLeft: "5px",
                                  maxWidth: "fit-content",
                                  fontSize: "0.6rem",
                                }}
                                onClick={() => handleDeleteLabel(label)}
                              >
                                Delete
                              </Button>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                </Box>
              )}
              <Divider
                sx={{
                  my: 2,
                  borderColor: "transparent",
                  backgroundImage: `linear-gradient(to right, transparent, ${
                    theme.palette.mode === "light"
                      ? darken(light.primary, 0.2)
                      : lighten(dark.primary, 0.2)
                  }, transparent)`,
                }}
              />
              {(showNewLabelFields || editingLabel) && (
                <Box
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? dark.primary
                        : light.primary,
                    paddingBottom: "1rem",
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid
                      item
                      xs="auto"
                      style={{
                        display: "flex",
                        maxWidth: "fit-content",
                        justifyContent: "flex-start",
                        border: `1px solid ${
                          theme.palette.mode === "light"
                            ? darken(colorValid ? newLabelColor : "#000", 0.5)
                            : lighten(colorValid ? newLabelColor : "#fff", 0.5)
                        }`,
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? lighten(colorValid ? newLabelColor : "#000", 0.5)
                            : darken(colorValid ? newLabelColor : "#fff", 0.5),
                        borderRadius: "10px",
                        padding: "7px",
                        margin: "5px",
                        marginLeft: "22px",
                        marginBottom: "15px",
                      }}
                    >
                      <Typography
                        noWrap
                        style={{
                          fontSize: "0.9rem",
                          color:
                            theme.palette.mode === "light"
                              ? darken(colorValid ? newLabelColor : "#000", 0.5)
                              : lighten(
                                  colorValid ? newLabelColor : "#fff",
                                  0.5
                                ),
                        }}
                      >
                        {newLabelName || "Label Preview"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        marginRight: "10px",
                      }}
                    >
                      <TextField
                        label="Label Name"
                        variant="outlined"
                        value={newLabelName}
                        onChange={(e) => setNewLabelName(e.target.value)}
                        inputProps={{ maxLength: 16 }}
                        InputProps={{
                          sx: {
                            height: 40,
                            "& .MuiInputBase-input": {
                              color:
                                theme.palette.mode === "dark"
                                  ? dark.text
                                  : light.text,
                            },
                          },
                        }}
                        fullWidth
                        sx={{ flexGrow: 1 }}
                      />
                    </Grid>
                    <Grid item xs={4} display="flex">
                      <IconButton
                        onClick={generateRandomColor}
                        sx={{
                          border: `1px solid ${
                            theme.palette.mode === "light"
                              ? darken(colorValid ? newLabelColor : "#000", 0.5)
                              : lighten(
                                  colorValid ? newLabelColor : "#fff",
                                  0.5
                                )
                          }`,
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? lighten(
                                  colorValid ? newLabelColor : "#000",
                                  0.5
                                )
                              : darken(
                                  colorValid ? newLabelColor : "#fff",
                                  0.5
                                ),
                          color:
                            theme.palette.mode === "light"
                              ? darken(colorValid ? newLabelColor : "#000", 0.5)
                              : lighten(
                                  colorValid ? newLabelColor : "#fff",
                                  0.5
                                ),
                          "&:hover": {
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? lighten(
                                    colorValid ? newLabelColor : "#000",
                                    0.5
                                  )
                                : darken(
                                    colorValid ? newLabelColor : "#fff",
                                    0.5
                                  ),
                            color:
                              theme.palette.mode === "light"
                                ? darken(
                                    colorValid ? newLabelColor : "#000",
                                    0.5
                                  )
                                : lighten(
                                    colorValid ? newLabelColor : "#fff",
                                    0.5
                                  ),
                          },
                          marginLeft: "40px",
                          marginRight: "5px",
                        }}
                      >
                        <RefreshIcon />
                      </IconButton>
                      <TextField
                        label="Color"
                        variant="outlined"
                        fullWidth
                        value={newLabelColor}
                        onChange={(e) => {
                          const color = e.target.value;
                          setNewLabelColor(color);
                          validateColor(color);
                        }}
                        inputProps={{ maxLength: 7 }}
                        InputProps={{
                          sx: {
                            height: 40,
                            "& .MuiInputBase-input": {
                              color:
                                theme.palette.mode === "dark"
                                  ? dark.text
                                  : light.text,
                            },
                            borderColor: colorValid ? "default" : "red",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: colorValid ? "default" : "red",
                            },
                            "&:hover fieldset": {
                              borderColor: colorValid ? "default" : "red",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: colorValid ? "default" : "red",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        onClick={handleSaveLabel}
                        sx={{
                          padding: "0",
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? dark.buttonBackground
                              : light.buttonBackground,
                          color:
                            theme.palette.mode === "dark"
                              ? dark.buttonIcon
                              : light.buttonIcon,
                          border: `1px solid ${
                            theme.palette.mode === "dark"
                              ? dark.border
                              : light.border
                          }`,
                          "&:hover": {
                            backgroundColor:
                              theme.palette.mode === "dark"
                                ? dark.buttonBackgroundHover
                                : light.buttonBackgroundHover,
                            color:
                              theme.palette.mode === "dark"
                                ? dark.buttonIconHover
                                : light.buttonIconHover,
                            borderColor:
                              theme.palette.mode === "dark"
                                ? dark.borderHover
                                : light.borderHover,
                          },
                          maxWidth: "fit-content",
                          fontSize: "0.6rem",
                          marginLeft: "90px",
                        }}
                        disabled={!newLabelName || !colorValid}
                      >
                        {editingLabel ? "Save" : "Create"}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
          }}
        >
          <Button
            onClick={handleNewLabelClick}
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? dark.buttonBackground
                  : light.buttonBackground,
              color:
                theme.palette.mode === "dark"
                  ? dark.buttonIcon
                  : light.buttonIcon,
              border: `1px solid ${
                theme.palette.mode === "dark" ? dark.border : light.border
              }`,
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? dark.buttonBackgroundHover
                    : light.buttonBackgroundHover,
                color:
                  theme.palette.mode === "dark"
                    ? dark.buttonIconHover
                    : light.buttonIconHover,
                borderColor:
                  theme.palette.mode === "dark"
                    ? dark.borderHover
                    : light.borderHover,
              },
              maxWidth: "fit-content",
              marginRight: "auto", // Align to the left
            }}
          >
            New Label
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? dark.buttonBackground
                  : light.buttonBackground,
              color:
                theme.palette.mode === "dark"
                  ? dark.buttonIcon
                  : light.buttonIcon,
              border: `1px solid ${
                theme.palette.mode === "dark" ? dark.border : light.border
              }`,
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? dark.buttonBackgroundHover
                    : light.buttonBackgroundHover,
                color:
                  theme.palette.mode === "dark"
                    ? dark.buttonIconHover
                    : light.buttonIconHover,
                borderColor:
                  theme.palette.mode === "dark"
                    ? dark.borderHover
                    : light.borderHover,
              },
              maxWidth: "fit-content",
              marginRight: "15px",
            }}
          >
            Close
          </Button>
        </DialogActions>
        <Dialog open={confirmDelete} onClose={handleCloseConfirmDelete}>
          <DialogTitle
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? dark.primary : light.primary,
              color: theme.palette.mode === "dark" ? dark.text : light.text,
            }}
          >
            Confirm Delete
          </DialogTitle>
          <DialogContent
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? dark.primary : light.primary,
            }}
          >
            <Typography
              sx={{
                color: theme.palette.mode === "dark" ? dark.text : light.text,
              }}
            >
              Are you sure you want to delete this label?
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? dark.primary : light.primary,
            }}
          >
            <Button
              onClick={handleCloseConfirmDelete}
              sx={{
                color: theme.palette.mode === "dark" ? dark.text : light.text,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={confirmDeleteLabel}
              sx={{
                color: theme.palette.mode === "dark" ? dark.text : light.text,
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProjectLabelsSection;
