import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import SurveyCreator from "./createSurvey";
import Surveys from "./surveys";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useTheme } from "@mui/material/styles";
import { validatePublisherGroup } from "../../apis/apis/index";
import jwtDecode from "jwt-decode";

const Survey = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isMemberOfCeoOffice, setIsMemberOfCeoOffice] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = sessionStorage.getItem("token");
  const currentUser = jwtDecode(token);

  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const checkUserGroup = async () => {
      try {
        const response = await validatePublisherGroup(
          token,
          setErrorMessage,
          () => {},
          () => {}
        );
        if (response.detail === "Publisher belongs to 'CEO Office' group.") {
          setIsMemberOfCeoOffice(true);
        }
      } catch (error) {
        console.error("Error validating publisher group:", error);
      }
    };

    checkUserGroup();
  }, [token]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "20px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor:
            theme.palette.mode === "light" ? light.primary : dark.primary,
          width: "800px",
          borderRadius: "12px",
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Tabs
            sx={{
              backgroundColor:
                theme.palette.mode === "light"
                  ? light.secondary
                  : dark.secondary,
            }}
            value={selectedTab}
            onChange={handleChange}
            centered
          >
            {isMemberOfCeoOffice && (
              <Tab
                label="Create Survey"
                sx={{
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                }}
              />
            )}
            <Tab
              label="Surveys"
              sx={{
                color: theme.palette.mode === "light" ? light.text : dark.text,
              }}
            />
          </Tabs>
          {selectedTab === 0 && isMemberOfCeoOffice && (
            <Box sx={{ padding: 2 }}>
              <SurveyCreator />
            </Box>
          )}
          {selectedTab === (isMemberOfCeoOffice ? 1 : 0) && (
            <Box sx={{ padding: 2 }}>
              <Surveys
                onBack={() => setSelectedTab(isMemberOfCeoOffice ? 1 : 0)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Survey;
