import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MultiSelectDropdown from "./MultiSelectDropdown";
import Grid from "@mui/material/Grid";
import OptionsToSelect from "./OptionsToSelect";
import {styled} from "@mui/system";
import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import MultipleSelectDropdownProject from "./MultiSelectDropdownProject";


const StyledOptions = styled(OptionsToSelect)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    marginLeft: '2rem'
    // other styles
});



const FilterDialog = ({filterEmployeesByProjects, filterEmployee, filter, employees, projects}) => {

    const [open, setOpen] = React.useState(false)

    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const [selectedProjects, setSelectedProjects] = useState([]);

    const [selectedOption, setSelectedOption] = useState();

    const handleSelectedOption = (value) => {
        setSelectedOption(value)
    }

    const handleEmployeeSelection = (value) => {
        //console.log('FILTER DIALOG CALLED')
        //console.log(value)
        setSelectedEmployees(value)
    }

    const handleProjectSelection = (value) => {
        setSelectedProjects(value)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        filter(selectedOption)
        filterEmployeesByProjects(selectedProjects)
        filterEmployee(selectedEmployees);
    };

    return (
        <Box>
            <Button
                variant="contained"
                startIcon={<FilterAltIcon/>}
                onClick={handleClickOpen}
                sx={{
                    color: '#fff'
                }}
            >
                Filter
            </Button>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        sx: {
                            display: 'flex',
                            flexWrap: 'wrap',
                            overflow: 'auto',
                            minWidth: '600px',  // Increase this value as necessary
                            minHeight: '400px',  // Adjust these values as necessary
                        },
                    }}
                >
                    <DialogTitle>Filter</DialogTitle>
                    <DialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',  // Adjust these values as necessary
                            height: '100%',  // Adjust these values as necessary
                            overflow: 'auto',  // If you still want to allow scrolling within the DialogContent
                        }}
                    >
                        <DialogContentText>
                            Choose one or more filtering
                        </DialogContentText>
                        <Grid
                            container
                            spacing={2} // use grid spacing for consistent gaps
                        >
                            <Grid
                                item
                                xs={6}
                            >
                                <Box p={1}>  {/* Add padding as required */}
                                    <MultiSelectDropdown
                                        setSelectedEmployeesParent={handleEmployeeSelection}
                                        employees={employees}/>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <Box p={1}> {/* Add padding as required */}
                                    <MultipleSelectDropdownProject
                                        projects={projects}
                                        setSelectedProjectsParent={handleProjectSelection}
                                    />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <Box p={1}> {/* Add padding as required */}
                                    <StyledOptions
                                        selectedValue={selectedOption}
                                        // onSelect={filter}
                                        onFilter={handleSelectedOption}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleClose}>Done</Button>
                    </DialogActions>
                </Dialog>
            </Box>

        </Box>
    );

}

export default FilterDialog;