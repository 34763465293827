export function handleTokenExpiration(
  error,
  setErrorMessage,
  handleOpenDialog,
  navigate
) {
  if (error.response && error.response.status === 401) {
    const responseBody = error.response.data;
    if (responseBody && responseBody.detail === "Token has expired.") {
      setErrorMessage("Your session has expired.");
      handleOpenDialog();
      sessionStorage.clear();
      localStorage.clear();
      sessionStorage.removeItem("token");
      localStorage.removeItem("localToken");
      localStorage.removeItem("token");
      localStorage.setItem("logoutFlag", Date.now().toString());
      setTimeout(() => {
        navigate("/authentication/sign-in");
      }, 5000);
    } else {
       // check the error response data error if it is a json or a string

      if (typeof error.response.data.error === 'object') 
      {
        setErrorMessage(error.response.data.error[Object.keys(error.response.data.error)[0]]);
      }
      else 
      {
        setErrorMessage(error.response.data.error);
      }
      handleOpenDialog();
    }
  } else {
    setErrorMessage(error.response.data.error);
    handleOpenDialog();
  }
}