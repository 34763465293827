// react-router-dom components
import { Link, useNavigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

import { Check } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Step,
	StepConnector,
	stepConnectorClasses,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// Authentication layout components
import rekromBack from 'assets/images/rekrom-background-v2.png';
// Images
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import axios from '../../../axios';
import BasicLayout from '../components/BasicLayout';
import { FormConfirm } from './components/form-confirm';
import { FormFirst } from './components/form-first';
import { FormMail } from './components/form-mail';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: 'calc(-50% + 16px)',
		right: 'calc(50% + 16px)',
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#784af4',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: '#784af4',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderTopWidth: 3,
		borderRadius: 1,
	},
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .QontoStepIcon-completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .QontoStepIcon-circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

const Cover = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [stepCompleted, setStepCompleted] = useState([false, false, false]);

	const [result, setResult] = useState();
	const [isMailConfirmed, setIsMailConfirmed] = useState(false);
	const [isCodeComplete, setIsCodeComplete] = useState(false);
	const [isConfirmationComplete, setConfirmationComplete] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
	const [infoMessage, setInfoMessage] = useState("")

	const navigate = useNavigate();
	const goLogin = () => {
		navigate('/authentication/sign-in');
	};
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			userName: '', 
			email: '',
			password: '',
			passwordAgain: '',
		},
		validationSchema: Yup.object({
			firstName: Yup.string().max(20).required('First name is required'),
			lastName: Yup.string().max(20).required('Last name is required'),
			userName: Yup
				.string()
				.max(16, "Username cannot exceed 16 characters")
				.required("Username is required"),
			email: Yup.string()
				.email('Invalid email')
				.max(255)
				.required('A rekrom email is required'),
			password: Yup.string().max(255).required('Password is required'),
			passwordAgain: Yup.string().max(255).required('Password is required'),
		}),
		onSubmit: async (values) => {
			let newValues = {
				first_name: formik.values.firstName,
				last_name: formik.values.lastName,
				username: formik.values.userName,
				password: formik.values.password,
				password_again: formik.values.passwordAgain,
				email: formik.values.email,
			}
			//console.log(newValues);
			await axios.post("/api/auth/userRegistration", newValues)
                .then((res) => {
                    if (res && res.data) {
                        //console.log(res.data)
						setSuccessMessage("Successfully Registered")
						setErrorMessage("")
						setInfoMessage("")
                        goLogin();
                    }
                })
                .catch((err) => {
                    if (err && err.response) {
						//console.log(err.response)
						setErrorMessage(err.response.data.error)
						setSuccessMessage("")
						setInfoMessage("")
						setActiveStep(0)
                    }
                })
		},
	});

	function QontoStepIcon(props) {
		const { active, className, icon } = props;
		return (
			<QontoStepIconRoot ownerState={{ active }} className={className}>
				{stepCompleted[icon - 1] ? (
					<Check className="QontoStepIcon-completedIcon" />
				) : (
					<div className="QontoStepIcon-circle" />
				)}
			</QontoStepIconRoot>
		);
	}

	const sendMail = async () => {
		let values = {
			email : formik.values.email
		};
		//console.log(values)
		await axios.post('/api/users/send-verification-code', values)
			.then((res) => {
				if (res && res.data) {
					//console.log(res.data)
					setInfoMessage(res.data.success)
				}
			})
			.catch((err) => {
				if (err && err.response) {
					//console.log(err.response)
					if (err) {
						setErrorMessage(err.response.data.error)
						setSuccessMessage("")
						setInfoMessage("")
						setActiveStep(0)
					}
				}
			})
	}

	const confirmMail = async () => {
		let values = {
			email: formik.values.email,
			verification_code: result
		}
		//console.log(values)
		await axios
			.post('/api/users/verify-code', values)
			.then((res) => {
				if (res && res.data) {
					//console.log(res.data);
					if (res.data.success) {
						setSuccessMessage(res.data.success)
						setErrorMessage("")
						setInfoMessage("")
						setIsMailConfirmed(true);
					}
				}
			})
			.catch((err) => {
				if (err) {
					setErrorMessage(err.response.data.error)
					setSuccessMessage("")
					setInfoMessage("")
				}
			});
	};

	const nextStepHandler = () => {
		stepCompleted[activeStep] = true;
		setActiveStep(activeStep + 1);
	};

	const previousStepHandler = () => {
		setActiveStep(activeStep - 1);
		stepCompleted[activeStep - 1] = false;
	};

	const checkCodeComplete = () => {
		if (result > 10000) {
			setIsCodeComplete(true);
		} else if (result <= 99999){
			setIsCodeComplete(false);
		}
		//console.log(isCodeComplete);
	};

	const handleOnAuthChange = (res) => {
		setResult(res);
		checkCodeComplete();
		//console.log(res);
	};

	const steps = [
		'Personal Information',
		'Mail Verification',
		'Confirmation'
	];

	return (
		<>
		
			{ errorMessage &&
				<Alert
					severity="error"
					onClose={() => {
						setErrorMessage("")
					}}
				> 
					<AlertTitle>Error</AlertTitle>
					{errorMessage}
				</Alert>
			}
			{ successMessage &&
				<Alert
					severity="success"
					onClose={() => {
						setSuccessMessage("")
					}}
				> 
					<AlertTitle>Success</AlertTitle>
					{successMessage}
				</Alert>
			}
			{ infoMessage &&
				<Alert
					severity="info"
					onClose={() => {
						setInfoMessage("")
					}}
				> 
					<AlertTitle>Info</AlertTitle>
					{infoMessage}
				</Alert>
			}
		<BasicLayout image={rekromBack}>
			<form onSubmit={formik.handleSubmit}>
				<Card>
					<Box pt={2} textAlign="center" m={1}>
						<Typography
							variant="h2"
							fontWeight="medium"
							color="white"
							mt={1}
							style={{
								fontFamily: 'Arial, Helvetica, sans-serif',
								fontVariant: 'large',
								fontWeight: '600',
								color: '#457b9d',
							}}
						>
							Welcome to Rekrom
						</Typography>
					</Box>
					<Stepper nonLinear alternativeLabel connector={<QontoConnector />}>
						{steps.map((label, index) => (
							<Step key={index}>
								<StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<Grid container>
						{formik.isSubmitting ? (
							<Container align="center" sx={{ mt: 10 }}>
								<CircularProgress />
							</Container>
						) : (
							<Grid item lg={12} md={12} xs={24}>
								<FormFirst
									hidden={activeStep !== 0}
									formik={formik}
									editable={true}
								/>
								<FormMail
									hidden={activeStep !== 1}
									editable={true}
									result={result}
									formik={formik}
									handleOnAuthChange={() => handleOnAuthChange}
									confirmMail={confirmMail}
									isCodeComplete={isCodeComplete}
								/>
								<FormConfirm
									hidden={activeStep !== 2}
									setConfirmationComplete={setConfirmationComplete}

								/>
								<Box sx={{ display: 'flex', flexDirection: 'row', pt: 0 }}>
									{activeStep > 0 && (
										<Button onClick={previousStepHandler}>{'< Back'}</Button>
									)}
									<Box sx={{ flex: '1 1 auto' }}>
										{activeStep === 0 && (
											<Button
												onClick={() => {
													nextStepHandler()
													sendMail()
												}}
												disabled={
													(activeStep !== 0 ||
														(!formik.values.email ||
															!formik.values.firstName ||
															!formik.values.lastName ||
															!formik.values.password ||
															!formik.values.passwordAgain))
												}
											>
												{'Next >'}
											</Button>
										)}
										{activeStep === 1 &&
											<Button
												onClick={() => {
													nextStepHandler()
												}}
												disabled={(activeStep !== 1 || !isMailConfirmed)}
											>
												{'Next >'}
											</Button>
										}
										{activeStep === steps.length - 1 && (
											<Button
												type="submit"
												disabled={activeStep !== steps.length - 1 || !isConfirmationComplete}
											>
												{'Finish'}
											</Button>
										)}
									</Box>
								</Box>
								<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: 2, pt: 1 }}>
									<Typography variant="body2" color="text">
										Already have an account? Click
										<Link to={'/authentication/sign-in'}>{' here '}</Link>
										to sign in.									
									</Typography>
								</Box>
							</Grid>
						)}
					</Grid>
				</Card>
			</form>
		</BasicLayout>
		</>
	);
};

export default Cover;
