import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ClearIcon from "@mui/icons-material/Clear";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useTheme } from "@mui/material/styles";
import { createProduct, updateProduct } from "apis/apis";

export default function ProductDialog({
  open,
  onClose,
  product,
  labs = [],
  categorySets = [],
  categories = [],
  onSave,
  isNew = false,
  setErrorMessage,
  showSnackbar,
}) {
  const [image, setImage] = useState({ file: null, previewUrl: "" });
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [description, setDescription] = useState("");
  const [laboratory, setLaboratory] = useState("");
  const [categorySet, setCategorySet] = useState("");
  const [category, setCategory] = useState("");
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  const token = sessionStorage.getItem("token");
  const [showOptions, setShowOptions] = useState(false);
  const [categorySetsFiltered, setCategorySetsFiltered] = useState([]);
  const [categoriesFiltered, setCategoriesFiltered] = useState([]);
  const [isCategorySetEnabled, setIsCategorySetEnabled] = useState(false);
  const [isCategoryEnabled, setIsCategoryEnabled] = useState(false);
  const [isImageRemoved, setIsImageRemoved] = useState(false);

  useEffect(() => {
    if (isNew) {
      resetFields();
    } else if (product) {
      const imageUrl = product?.image
        ? `${process.env.REACT_APP_BASE_URL || "http://localhost:8000"}${
            product.image
          }`
        : "";

      setImage({ file: null, previewUrl: imageUrl });
      setName(product?.name || "");
      setQuantity(product?.quantity || "");
      setDescription(product?.description || "");

      setLaboratory(product.details?.lab_name || "");
      setCategorySet(product.details?.category_set_name || "");
      setCategory(product.details?.category_name || "");

      const filteredSets = categorySets.filter(
        (set) => set.lab === product.details?.lab_id
      );
      setCategorySetsFiltered(filteredSets);
      setIsCategorySetEnabled(filteredSets.length > 0);

      const filteredCategories = categories.filter(
        (cat) => cat.category_set === product.details?.category_set_id
      );
      setCategoriesFiltered(filteredCategories);
      setIsCategoryEnabled(filteredCategories.length > 0);
    }
  }, [isNew, product, categories, categorySets, labs]);

  const tooltipMessage = "Name and Quantity are required to save changes";

  const handleImageDownload = async () => {
    try {
      if (image && image.previewUrl) {
        const response = await fetch(image.previewUrl);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        const imageName = image.previewUrl.split("/").pop();
        link.href = blobUrl;
        link.download = imageName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      } else {
        console.warn("No image to download");
      }
    } catch (error) {
      console.error("Failed to download image", error);
    }
  };

  const handleQuantityChange = (e) => {
    const { value } = e.target;
    if (value === "" || /^[1-9]\d*$/.test(value)) {
      setQuantity(value);
    }
  };

  const resetFields = () => {
    setImage({ file: null, previewUrl: "" });
    setName("");
    setQuantity("");
    setDescription("");
    setLaboratory("");
    setCategorySet("");
    setCategory("");
    setIsCategorySetEnabled(false);
    setIsCategoryEnabled(false);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage({ file, previewUrl: imageUrl });
    }
  };

  const handleImageRemove = () => {
    setImage({ file: null, previewUrl: "" });
    setIsImageRemoved(true);
  };

  const handleLaboratoryChange = (e) => {
    const labValue = e.target.value;
    setLaboratory(labValue);

    if (!labValue) {
      setCategorySet("");
      setCategory("");
      setIsCategorySetEnabled(false);
      setIsCategoryEnabled(false);
      setCategorySetsFiltered([]);
      setCategoriesFiltered([]);
    } else {
      const selectedLab = labs.find((lab) => lab.name === labValue);

      const filteredCategorySets = categorySets.filter(
        (set) => set.lab === selectedLab.id
      );

      setCategorySet("");
      setCategory("");
      setCategorySetsFiltered(filteredCategorySets);
      setIsCategorySetEnabled(true);
      setIsCategoryEnabled(false);
      setCategoriesFiltered([]);
    }
  };

  const handleCategorySetChange = (e) => {
    const setValue = e.target.value;
    setCategorySet(setValue);

    setCategory("");
    setIsCategoryEnabled(false);
    setCategoriesFiltered([]);

    if (!setValue) {
      setIsCategoryEnabled(false);
    } else {
      const selectedCategorySet = categorySets.find(
        (set) => set.name === setValue
      );

      const filteredCategories = categories.filter(
        (cat) => cat.category_set === selectedCategorySet.id
      );

      setCategoriesFiltered(filteredCategories);
      setIsCategoryEnabled(true);
    }
  };

  const handleCategoryChange = (e) => {
    const categoryValue = e.target.value;
    setCategory(categoryValue);
  };

  const resetCategorySetAndCategory = () => {
    setCategorySet("");
    setCategory("");
    setIsCategorySetEnabled(false);
    setIsCategoryEnabled(false);
    setCategorySetsFiltered([]);
    setCategoriesFiltered([]);
  };

  const resetCategoryAndCategories = () => {
    setCategory("");
    setIsCategoryEnabled(false);
    setCategoriesFiltered([]);
  };

  const isFormValid = () => {
    return name.trim() !== "" && String(quantity).trim() !== "";
  };

  const handleSaveClick = async () => {
    if (isFormValid()) {
      const selectedLab = labs.find((lab) => lab.name === laboratory);
      const selectedCategorySet = categorySets.find(
        (set) => set.name === categorySet
      );
      const selectedCategory = categories.find((cat) => cat.name === category);

      const formData = new FormData();
      formData.append("name", name);
      formData.append("quantity", quantity);
      formData.append("description", description);

      if (selectedLab) formData.append("lab_id", selectedLab.id);
      if (selectedCategorySet)
        formData.append("category_set_id", selectedCategorySet.id);
      if (selectedCategory) formData.append("category_id", selectedCategory.id);

      if (image.file) {
        formData.append("image", image.file);
      } else if (isImageRemoved) {
        formData.append("image", "");
      }

      try {
        if (isNew) {
          await createProduct(
            token,
            setErrorMessage,
            onClose,
            formData,
            showSnackbar
          );
        } else {
          await updateProduct(
            token,
            setErrorMessage,
            onClose,
            product.id,
            formData,
            showSnackbar
          );
        }
        onClose();
      } catch (error) {
        console.error("Error saving product:", error);
      }
    }
  };

  return (
    <Box sx={{ padding: "16px", borderRadius: "8px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Product name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{
              "& .MuiInputBase-input": {
                color:
                  theme.palette.mode === "light" ? light.text2 : dark.text2,
              },
              borderRadius: "6px",
            }}
          />
        </Grid>

        <Grid item xs={12} md={2.5}>
          <TextField
            fullWidth
            variant="outlined"
            label="Quantity"
            value={quantity}
            onChange={handleQuantityChange}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            sx={{
              "& .MuiInputBase-input": {
                color:
                  theme.palette.mode === "light" ? light.text2 : dark.text2,
              },
              borderRadius: "6px",
            }}
          />
        </Grid>

        <Grid item xs={12} md={2.5}>
          <FormControl fullWidth>
            <InputLabel>Laboratory</InputLabel>
            <Select
              label="Laboratory"
              value={laboratory}
              onChange={handleLaboratoryChange}
              sx={{
                "& .MuiInputBase-input": {
                  color:
                    theme.palette.mode === "light" ? light.text2 : dark.text2,
                },
                height: "44px",
                borderRadius: "6px",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {labs.map((lab) => (
                <MenuItem key={lab.id} value={lab.name}>
                  {lab.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth disabled={!isCategorySetEnabled}>
            <InputLabel>Category Set</InputLabel>
            <Select
              label="Category Set"
              value={categorySet}
              onChange={handleCategorySetChange}
              sx={{
                "& .MuiInputBase-input": {
                  color:
                    theme.palette.mode === "light" ? light.text2 : dark.text2,
                },
                height: "44px",
                borderRadius: "6px",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {categorySetsFiltered.map((set) => (
                <MenuItem key={set.id} value={set.name}>
                  {set.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth disabled={!isCategoryEnabled}>
            <InputLabel>Category</InputLabel>
            <Select
              label="Category"
              value={category}
              onChange={handleCategoryChange}
              sx={{
                "& .MuiInputBase-input": {
                  color:
                    theme.palette.mode === "light" ? light.text2 : dark.text2,
                },
                height: "44px",
                borderRadius: "6px",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {categoriesFiltered.map((category) => (
                <MenuItem key={category.id} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={7}>
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            multiline
            rows={8}
            sx={{
              "& .MuiInputBase-input": {
                color:
                  theme.palette.mode === "light" ? light.text2 : dark.text2,
              },
              width: "100%",
              minHeight: "150px",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          alignItems="center"
          justifyContent="center"
          onClick={() => setShowOptions((prev) => !prev)}
          sx={{ position: "relative" }}
        >
          <Box
            sx={{
              width: "100%",
              height: "185px",
              backgroundColor: "#e0e0e0",
              borderRadius: "8px",
              backgroundImage: image.previewUrl
                ? `url(${image.previewUrl})`
                : "none",
              backgroundSize: "cover",
              backgroundPosition: "center",
              cursor: "pointer",
            }}
          >
            <input
              id="image-upload"
              type="file"
              accept=".jpg,.jpeg,.png,.gif"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            {!image.previewUrl && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <AddPhotoAlternateIcon fontSize="large" />
              </Box>
            )}
            {image.previewUrl && (
              <IconButton
                size="small"
                sx={{
                  position: "absolute",
                  right: "0px",
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.buttonBackground
                      : dark.buttonBackground,
                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIcon
                      : dark.buttonIcon,
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.buttonBackgroundHover
                        : dark.buttonBackgroundHover,
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIconHover
                        : dark.buttonIconHover,
                  },
                }}
                onClick={handleImageRemove}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </Box>

          {showOptions && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "8px",
                width: "100%",
                position: "absolute",
                bottom: "-20px",
                left: "4%",
                padding: "0 8px",
                boxSizing: "border-box",
              }}
            >
              <Button
                sx={{
                  flex: "1",
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.buttonBackground
                      : dark.buttonBackground,
                  border: `1px solid ${
                    theme.palette.mode === "light" ? light.border : dark.border
                  }`,
                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIcon
                      : dark.buttonIcon,
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.buttonBackgroundHover
                        : dark.buttonBackgroundHover,
                    border: `1px solid ${
                      theme.palette.mode === "light"
                        ? light.borderHover
                        : dark.borderHover
                    }`,
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIconHover
                        : dark.buttonIconHover,
                  },
                  textAlign: "center",
                }}
                onClick={() => document.getElementById("image-upload").click()}
              >
                Upload
              </Button>
              {image.previewUrl && (
                <Button
                  sx={{
                    flex: "1",
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.buttonBackground
                        : dark.buttonBackground,
                    border: `1px solid ${
                      theme.palette.mode === "light"
                        ? light.border
                        : dark.border
                    }`,
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIcon
                        : dark.buttonIcon,
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? light.buttonBackgroundHover
                          : dark.buttonBackgroundHover,
                      border: `1px solid ${
                        theme.palette.mode === "light"
                          ? light.borderHover
                          : dark.borderHover
                      }`,
                      color:
                        theme.palette.mode === "light"
                          ? light.buttonIconHover
                          : dark.buttonIconHover,
                    },
                    textAlign: "center",
                  }}
                  onClick={handleImageDownload}
                >
                  Download
                </Button>
              )}
            </Box>
          )}
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "32px",
        }}
      >
        <Tooltip title={!isFormValid() ? tooltipMessage : ""} placement="top">
          <span>
            <Button
              onClick={handleSaveClick}
              disabled={!isFormValid()}
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? dark.primary : light.primary,
                color: theme.palette.mode === "dark" ? dark.text : light.text,
                border: `1px solid ${
                  theme.palette.mode === "dark" ? dark.border : light.border
                }`,
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? dark.secondary
                      : light.secondary,
                },
              }}
            >
              {isNew ? "Add Product" : "Save Changes"}
            </Button>
          </span>
        </Tooltip>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
            color: theme.palette.mode === "dark" ? dark.text : light.text,
            border: `1px solid ${
              theme.palette.mode === "dark" ? dark.border : light.border
            }`,
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "dark"
                  ? dark.secondary
                  : light.secondary,
            },
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}
