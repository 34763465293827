import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Divider,
    Grid,
    IconButton,
    Collapse,
    TextField
} from "@mui/material"
import { useState, useEffect } from "react";
import {ArrowDropDown} from "@mui/icons-material";
import {ArrowDropUp} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { PermissionTable } from "components/Permission/table/PermissionTable";
import { useFormik } from "formik";
import axios from "../../../axios"
import * as Yup from "yup"

const UpdateEmployee = (props) => {

    const token = sessionStorage.getItem("token")
    const navigate = useNavigate();
    const location = useLocation();
    const employee = location.state.employee;
    const permissions = location.state.permissions;
    //console.log(permissions)
    //console.log(employee)
    const [nonGivenPermissions, setNonGivenPermissions] = useState(permissions.filter((permission) => !employee.user_permissions.includes(permission.id)).sort((a,b) => a.id > b.id ? 1 : -1));
    const [givenPermissions, setGivenPermissions] = useState(permissions.filter((permission) => employee.user_permissions.includes(permission.id)).sort((a,b) => a.id > b.id ? 1 : -1));

    const formik = useFormik({
        initialValues: {
            first_name: employee.first_name,
            last_name: employee.last_name,
            date_of_birth: employee.date_of_birth,
            username: employee.username,
            user_permissions: givenPermissions.map((perm) => (perm.id)),
            email: employee.email,
            phone_number: employee.phone_number,
            address: employee.address,
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required(),
            last_name: Yup.string().required(),
            date_of_birth: Yup.date().required(),
            username: Yup.string().required(),
            email: Yup.string().email().required(),
            phone_number: Yup.string().required(),
            address: Yup.string().required(),
        }),
        onSubmit: async (values) => {
            //console.log(values);
            await axios.patch(`/api/users/employee-update/${employee.id}`, values ,{
                headers: {
                    "Authorization": token
                }
            })
                .then((res) => {
                    if (res && res.data) {
                        //console.log(res)
                        navigate("/tables")
                    }
                })
                .catch((err) => {
                    if (err && err.response) {
                        //console.log("Error:", err)
                    }
                })
        }
    });

    const assignPermission = (id) => {
        let permId = parseInt(id);
        let copyG = [...givenPermissions]
        copyG.push(nonGivenPermissions.find((perm) => perm.id === id))
        setGivenPermissions(copyG)

        let copyN = [...nonGivenPermissions]
        copyN = copyN.filter((perm) => perm.id !== id)
        setNonGivenPermissions(copyN)
    }
    
    return(
        <>
            <form onSubmit={formik.handleSubmit}>
                <title>Update Employee</title>
                <Card>
                    <CardHeader
                        title={employee.id + "-) " + employee.first_name + " " + employee.last_name}
                    />
                    <Divider/>
                    <CardContent>
                        <Grid
                            container
                            justifyContent="center"
                            direction="row"
                            spacing={3}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(
                                        formik.touched.first_name && formik.errors.first_name
                                    )}
                                    fullWidth
                                    helperText={
                                        formik.touched.first_name && formik.errors.first_name
                                    }
                                    label="First Name"
                                    name="first_name"
                                    required
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.first_name}
                                    variant="outlined"
                                />
                                <TextField
								    style={{ marginTop: 10 }}
                                    error={Boolean(
                                        formik.touched.last_name && formik.errors.last_name
                                    )}
                                    fullWidth
                                    helperText={
                                        formik.touched.last_name && formik.errors.last_name
                                    }
                                    label="Last Name"
                                    name="last_name"
                                    required
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.last_name}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(
                                        formik.touched.date_of_birth && formik.errors.date_of_birth
                                    )}
                                    fullWidth
                                    helperText={
                                        formik.touched.date_of_birth && formik.errors.date_of_birth
                                    }
                                    label="Date of Birth"
                                    name="date_of_birth"
                                    required
                                    type="date"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.date_of_birth}
                                    variant="outlined"
                                />
                                <TextField
								    style={{ marginTop: 10 }}
                                    error={Boolean(
                                        formik.touched.username && formik.errors.username
                                    )}
                                    fullWidth
                                    helperText={
                                        formik.touched.username && formik.errors.username
                                    }
                                    label="Username"
                                    name="username"
                                    required
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.username}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(
                                        formik.touched.email && formik.errors.email
                                    )}
                                    fullWidth
                                    helperText={
                                        formik.touched.email && formik.errors.email
                                    }
                                    label="Email"
                                    name="email"
                                    required
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    variant="outlined"
                                />
                                <TextField
								    style={{ marginTop: 10 }}
                                    error={Boolean(
                                        formik.touched.phone_number && formik.errors.phone_number
                                    )}
                                    fullWidth
                                    helperText={
                                        formik.touched.phone_number && formik.errors.phone_number
                                    }
                                    label="Phone Number"
                                    name="phone_number"
                                    required
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.phone_number}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    error={Boolean(
                                        formik.touched.address && formik.errors.address
                                    )}
                                    fullWidth
                                    helperText={
                                        formik.touched.address && formik.errors.address
                                    }
                                    label="Address"
                                    name="address"
                                    required
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.address}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider/>
                    <CardContent>
                        <Grid
                            container
                            justifyContent="center"
                            direction="row"
                            spacing={3}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <PermissionTable
                                    index={props.index}
                                    nonGivenPermissions={nonGivenPermissions}
                                    assignPermission={(id) => assignPermission(id)}
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <PermissionTable
                                    index={props.index}
                                    givenPermissions={givenPermissions}
                                    unassignEmployee={(e, index) => props.unassignEmployee(e, index)}
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="center" pt={2}>
                            <Button 
                                type='submit'
                            >
                                Update
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </form>
        </>
    );
}

export default UpdateEmployee