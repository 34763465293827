import React, { useEffect, useState, useCallback } from "react";
import { Draggable } from "react-beautiful-dnd";
import Modal from "../Modal/Modal";
import "./Card.css";
import CardDetails from "./CardDetails/CardDetails";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { fetchTaskLabels } from "../../../apis/apis/index";
import { Chip, Box, Typography } from "@mui/material";
import { darken, lighten } from "@mui/system";
import { useNavigate } from "react-router-dom";

const Card = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [isCardDetailsRendered, setIsCardDetailsRendered] = useState(false);
  const [taskLabels, setTaskLabels] = useState([]);
  const [labelsUpdated, setLabelsUpdated] = useState(false); // Add state for tracking label updates
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const navigate = useNavigate();

  const handleTaskViewOpen = () => {
    setModalShow(false);
    setIsCardDetailsRendered(true); // Reset the flag
  };

  const shouldRenderCardDetails =
    modalShow ||
    (props.highlightedTaskId === props.id && !isCardDetailsRendered);

  const fetchLabels = useCallback(async () => {
    const token = sessionStorage.getItem("token");
    const labelsData = await fetchTaskLabels(
      token,
      props.id,
      setErrorMessage,
      handleOpenDialog,
      navigate
    );
    console.log("Fetched Labels: ", labelsData.labels); // Debug log
    setTaskLabels(labelsData.labels); // Assuming the labels are in labelsData.labels
  }, [navigate, props.id]);

  useEffect(() => {
    fetchLabels();
  }, [fetchLabels, props.id]);

  // UseEffect to re-fetch labels when labelsUpdated state changes
  useEffect(() => {
    if (labelsUpdated) {
      fetchLabels();
      setLabelsUpdated(false);
    }
  }, [labelsUpdated, fetchLabels]);

  return (
    <Draggable
      key={props.id.toString()}
      draggableId={props.id.toString()}
      index={props.index}
    >
      {(provided) => (
        <>
          {shouldRenderCardDetails && (
            <CardDetails
              projectId={props.projectId}
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? dark.primary : light.primary,
              }}
              employees={props.employees}
              updateCard={props.updateCard}
              onClose={setModalShow}
              title={props.title}
              card={props.card}
              bid={props.bid}
              id={props.id}
              handleTaskViewOpen={handleTaskViewOpen}
              removeCard={props.removeCard}
              handleUpdateTask={props.updateTask}
              projectInfo={props.projectInfo} // Pass projectInfo here
              setLabelsUpdated={setLabelsUpdated} // Pass the setLabelsUpdated function
            />
          )}

          <div
            className="custom__card"
            onClick={() => {
              setModalShow(true);
            }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
              ...provided.draggableProps.style,
              backgroundColor: props.cardBackgroundColor || "#fff", // Set initial background color
              border:
                theme.palette.mode === "light"
                  ? `1px solid ${light.border}`
                  : "none",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                theme.palette.mode === "dark" ? dark.secondary : light.hover; // Change to hover color
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor =
                theme.palette.mode === "dark" ? dark.primary : light.primary; // Change back to initial color
            }}
          >
            <div className="card__text">
              <p
                style={{
                  marginLeft: "8px",
                  marginTop: "4px",
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                }}
              >
                {props.title}
              </p>
            </div>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                marginTop: "8px",
              }}
            >
              {taskLabels.map((label) => {
                const color = label.color || "#ccc"; // Default color if undefined
                return (
                  <Box
                    key={label.id}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: `1px solid ${
                        theme.palette.mode === "light"
                          ? darken(color, 0.5)
                          : lighten(color, 0.5)
                      }`,
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? lighten(color, 0.5)
                          : darken(color, 0.5),
                      borderRadius: "10px",
                      padding: "4px 8px",
                    }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        fontSize: "0.9rem",
                        color:
                          theme.palette.mode === "light"
                            ? darken(color, 0.5)
                            : lighten(color, 0.5),
                      }}
                    >
                      {label.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            {provided.placeholder}
          </div>
        </>
      )}
    </Draggable>
  );
};

export default Card;