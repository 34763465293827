
const styles = {
    container: {
      padding: "24px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "100%",
    },
  
    card: {
      position: "relative",
      padding: "24px",
      borderRadius: "8px",
      maxWidth: "100%",
      flex:"1",
    },
  
    cardDivided: {
      position: "relative",
      padding: "24px",
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
      maxWidth: "70%",
      flex:"1",
    },
  
    cardChangePassword: {
      position: "relative",
      padding: "24px",
      marginLeft: "10px",
      border: "none",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
      maxWidth: "30%",
      flex:"1",
    },
  
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "16px",
    },
  
    divider: {
      borderWidth: "2px",
      opacity: 1,
      margin: "16px 0", 
    },
  
    fieldContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "12px", 
      padding: "0 8px", 
      justifyContent: "space-between",
    },
  
    label: {
      flex: "0 0 auto",
      fontWeight: "600",
      width: "40%",
    },
  
    value: {
      flex: "1",
      textAlign: "left",
    },
  
    button: {
      marginTop: "24px",
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: "0.5px",
      borderRadius: "20px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      textTransform: "none",
      marginLeft: "10px",
    },
  
    inputFieldLabel: {
      flex: "0 0 auto",
      fontWeight: "600",
      width: "60%",
    },
  
    inputField: {
      backgroundColor: "#f0f0f0",
      border: "1px solid #ccc",
      borderRadius: "4px",
      padding: "8px 12px",
      marginBottom: "10px",
      width: "calc(100% - 24px)",
      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    },
};

export default styles;