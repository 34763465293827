import React, { useEffect } from 'react';
import Tree from 'react-d3-tree';
import './custom-tree.css'
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

function CompanyHierarchy({ treeData }) {
      
    return (
        <>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                            <MDTypography variant="h6" color="white">
                                Organization Structure
                            </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>

                                <div id="treeWrapper">
                                    <Tree
                                        rootNodeClassName='node__root'
                                        branchNodeClassName='node__branch'
                                        leafNodeClassName='node__leaf'
                                        orientation='vertical'
                                        enableLegacyTransitions={true}
                                        translate={{ x: 750, y: 100 }}
                                        nodeSize={{ x: 250, y: 250 }}
                                        pathFunc={'step'}
                                        initialDepth={1}
                                        separation={{ siblings: 2, nonSiblings: 2 }}
                                        centeringTransitionDuration={10}
                                        data={treeData}
                                    />
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </>
      );
    };

export { CompanyHierarchy };