const filteringOptions = {
    dayOffStatus: [
        {status: "Pending", id: 1},
        {status: "Approved", id: 2},
        {status: "Rejected", id: 3},
        {status: "Withdrawn", id: 4}
    ],

    dayOffExcuseTypes: [
        {type: "Annual", id: 1},
        {type: "Health", id: 2}
    ],

    dayOffSortOptions: [
        "Newest to Oldest",
        "Oldest to Newest",
    ]
};

export default filteringOptions;
