import { forwardRef } from "react";
import PropTypes from "prop-types";
import MDInputRoot from "components/MDInput/MDInputRoot";

const MDInput = forwardRef(({ error, success, disabled, ...rest }, ref) => {
  const { key, ...otherProps } = rest;
  return (
    <MDInputRoot
      ref={ref}
      key={key}
      ownerState={{ error, success, disabled }}
      {...otherProps}
    />
  );
});

MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDInput;
