import { Typography, Button, Grid, Card, CardContent, CardActions, Divider, Box } from "@mui/material";

import { SeverityPill } from "components/SeverityPill";

import FormDetails from "../FormDetails";
import DayOffConfirmationDialog from "../DayOffConfirmationDialog";

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { updateDayOffForms } from "../../../../apis/apis/index";

import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors"

import dayOffStatusColors from "./colors";

export default function DayOffFormsList({dayOffRequests, onFormUpdate, onLoading, onError, setErrorMessage}){
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  const [confirmationAction, setConfirmationAction] = useState("");
  
  const [selectedDayOffForm, setSelectedDayOffForm] = useState(null);

  const rejectionReason = useRef();

  const viewDetail = useRef();
  const confirmationDialog = useRef();

  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const theme = useTheme();

  const updateDayOffFormRequest  = (dayOffId, data) => {
    updateDayOffForms(token, setErrorMessage, onError, navigate, data, dayOffId)
    .then(
    )
    .catch((error) => {
        setErrorMessage(error.message || "An error occurred");
    })
    .finally(() => {
        onLoading(false);
        onFormUpdate();
    });
  };

  const handleViewDayOffRequest = (form) => 
  {
    setSelectedDayOffForm(form);
    viewDetail.current.open();
  };
    
  const handleOpenConfirmation = (action, form) => 
  {
    setConfirmationAction(action);
    setSelectedDayOffForm(form);
    confirmationDialog.current.open();
  };
    
  const handleConfirmAction = () => {
    if (confirmationAction === "approve") {
      updateDayOffFormRequest(selectedDayOffForm.id, {status: 2})
      onFormUpdate();
    } else if (confirmationAction === "reject") {
      updateDayOffFormRequest(selectedDayOffForm.id, {status: 3, rejection_reason: rejectionReason.current.value});
      onFormUpdate();
    }
    
    confirmationDialog.current.close();
  };

  const createDetailItem = (label, value) => {
    return(
      <Box>
        <Typography variant="h6" sx={{color: theme.palette.mode === 'light' ? light.text : dark.text}}>
          {label}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" sx={{color: theme.palette.mode === 'light' ? light.text : dark.text, textAlign:"center"}}>
          {value}
        </Typography>
      </Box>
    );
  }

  return(
    <>
      <FormDetails ref={viewDetail} dayOffForm={selectedDayOffForm}></FormDetails>
      <DayOffConfirmationDialog ref={confirmationDialog} formType={confirmationAction} rejectionReasonRef={rejectionReason} onConfirm={handleConfirmAction}></DayOffConfirmationDialog>
      {dayOffRequests &&
        dayOffRequests.map((request => {
          return(
            <Grid item xs={12} sm={6} md={4} key={request.id}>
                <Card sx={{backgroundColor: theme.palette.mode === 'light' ? light.tertiary : dark.tertiary}}>
                  <CardContent marginBottom={"0px"}>
                    <Grid sx={{display:"flex", justifyContent:"space-between", marginBottom:"20px"}}>
                      <Typography variant="h5" sx={{color: theme.palette.mode === 'light' ? light.text : dark.text, padding:"5px"}}>
                          {request.requested_by.employee_name}
                      </Typography>
                      <SeverityPill sx={{backgroundColor:dayOffStatusColors[request.status], color:light.primary, width:"85px"}}>
                          {request.status}
                      </SeverityPill>
                    </Grid>
                    <Box sx={{display:"flex", justifyContent:"space-between", padding:"5px"}}>
                      {createDetailItem("Start Date", request.day_off_start)}
                      {createDetailItem("End Date", request.day_off_end)}
                      {createDetailItem("Day Off", request.day_off_range)}
                    </Box>
                  </CardContent>

                  <Divider sx={{margin:"1px"}}/>
                  
                  <CardActions sx={{ display: "flex", justifyContent: "space-between", paddingRight:"20px", paddingLeft:"25px"}}>
                    <div style={{justifyContent: "space-between", display: "flex"}}>
                      <Button variant="outlined" 
                        disabled={request.status === "APPROVED" || request.status === "WITHDRAW"} 
                        onClick={() => handleOpenConfirmation("approve", request)} 
                        sx={{marginRight:"4px"}}
                      >
                        Approve
                      </Button>
                      <Button variant="outlined" 
                        disabled={request.status === "REJECTED" || request.status === "WITHDRAW"} 
                        onClick={() => handleOpenConfirmation("reject", request)}
                      >
                        Reject
                      </Button>
                    </div>
                    <Button variant="outlined" onClick={() => handleViewDayOffRequest(request)}>
                      Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
        )}))
      }
    </>
  );
}