import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Paper,
  Divider,
} from "@mui/material";
import { DataGrid, GridToolbar, GridPagination } from "@mui/x-data-grid";

const PAGE_SIZE = 5;

const DayoffLister = ({ dayoffs }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const modifiedDayoffs = dayoffs.map((dayoff, index) => ({
    ...dayoff,
    order: index + 1, // Increment the ID
  }));

  return (
    <>
    <Box>
      <Paper elevation={3} sx={{ padding: 2, maxWidth: "100%" }}>
        <Card sx={{ position: "relative" }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Dayoffs ({modifiedDayoffs.length} total)
            </Typography>
            <Divider sx={{ marginY: 2 }} />
              <DataGrid
                rows={modifiedDayoffs}
                columns={[
                  { field: "order", headerName: "ID", flex: 1 },
                //   { field: "send_to", headerName: "Send To", flex: 2 },
                  { field: "day_off_start", headerName: "Day Off Start", flex: 3 },
                  { field: "day_off_end", headerName: "Day Off End", flex:3 },
                  { field: "day_off_range", headerName: "Range", flex: 2 },
                  { field: "rejection_reason", headerName: "Rejection Reason", flex: 2 },
                  { field: "status", headerName: "status", flex: 2 },
                //   { field: "paid_type", headerName: "Paid Type", flex: 3 },
                //   { field: "location_type", headerName: "Location Type", flex: 3 },
                //   { field: "excuse_type", headerName: "Excuse Type", flex: 3 },
                ]}
                autoHeight
                page={currentPage}
                pageSize={PAGE_SIZE}
                components={{
                  Toolbar: GridToolbar,
                  Pagination: GridPagination,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                onPageChange={(params) => {
                  handlePageChange(params.page);
                }}
                pageSizeOptions={[PAGE_SIZE]}
              />
          </CardContent>
        </Card>
      </Paper>
    </Box>
    </>

  );
};

export { DayoffLister };
