import{Dialog, DialogContent, TextField, DialogTitle, DialogActions, Button, Typography} from "@mui/material";

import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

const DayOffConfirmationDialog = forwardRef(function DayOffConfirmationDialog({formType, rejectionReasonRef, onConfirm}, ref){
    const [isOpen, setIsOpen] = useState(false);
    const dialog = useRef();

    const { main: light } = baseColors;
    const { main: dark } = darkColors;
  
    const theme = useTheme();

    useImperativeHandle(ref, () =>{
        return{
            open: () => setIsOpen(true),
            close: () => setIsOpen(false)
        };
    });

    return(
        <Dialog ref={dialog} open={isOpen} sx={{width:"550px", margin:"auto"}}>
            <DialogTitle variant="h3" sx={{color: theme.palette.mode === 'light' ? light.text : dark.text}}>
                {formType === 'reject' ? 'Confirm Rejection' : 'Confirm Action'}
            </DialogTitle>
            <DialogContent>
                <Typography sx={{color: theme.palette.mode === 'light' ? light.text : dark.text}}>
                    {formType === 'reject' ? 'Are you sure you want to reject this request?' : 'Are you sure you want to approve this request?'}
                </Typography>
                {formType === 'reject' && 
                    <TextField 
                        inputRef={rejectionReasonRef} 
                        sx={{marginTop:"20px", '& .MuiInputBase-input': {color: theme.palette.mode === 'light' ? light.text : dark.text}}} 
                        label="Reason for Rejection" 
                        variant="outlined" 
                        fullWidth
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onConfirm}>
                    Confirm
                </Button>
                <Button variant="outlined" onClick={() => setIsOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default DayOffConfirmationDialog;