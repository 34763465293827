import{Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography, Divider, Box} from "@mui/material";

import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

const FormDetails = forwardRef(function FormDetails({dayOffForm}, ref){
    const dialog = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const { main: light } = baseColors;
    const { main: dark } = darkColors;
  
    const theme = useTheme();

    useImperativeHandle(ref, () =>{
        return{
            open: () => setIsOpen(true),
            close: () => setIsOpen(false),
        };
    });

    const createDetailItem = (label, value) => {
        return(
            <Box>
                <Typography variant="h6" sx={{color: theme.palette.mode === 'light' ? light.text : dark.text}}>
                    {label}:
                </Typography>
                <Typography variant="body3" color="textSecondary" sx={{textAlign:"center"}}>
                    {value}
                </Typography>
            </Box>
        );
    }

    return(
        <Dialog ref={dialog} open={isOpen} sx={{width:"550px", margin:"auto"}}>
            {dayOffForm &&
                <>
                    <DialogTitle sx={{color: theme.palette.mode === 'light' ? light.text : dark.text}}>
                        {dayOffForm.requested_by.employee_name} Day Off Form Details
                    </DialogTitle>

                    <DialogContent>
                        <Typography variant="h5" sx={{color: theme.palette.mode === 'light' ? light.text : dark.text}}>
                            Description
                        </Typography>
                        <Typography variant="body3" color="textSecondary">
                            {dayOffForm.description ? dayOffForm.description : "Unfortunately, no description found for this day off form."}
                        </Typography>

                        <Box sx={{display:"grid", gridTemplateColumns: "repeat(3, 1fr)", gap:"15px", marginTop:"15px"}}>
                            {createDetailItem("Paid Type", dayOffForm.paid_type)}
                            {createDetailItem("Location Type", dayOffForm.location_type)}
                            {createDetailItem("Excuse Type", dayOffForm.excuse_type)}
                            {createDetailItem("Start Date", dayOffForm.day_off_start)}
                            {createDetailItem("End Date", dayOffForm.day_off_end)}
                            {createDetailItem("Day Off", dayOffForm.day_off_range)}
                        </Box>

                        {dayOffForm.status === 'REJECTED' &&
                            <>
                            <Divider/>
                            <Typography variant="h5" sx={{color: theme.palette.mode === 'light' ? light.text : dark.text}}>
                                Rejection Reason
                            </Typography>
                            <Typography variant="body3" color="textSecondary">
                                {dayOffForm.rejection_reason}
                            </Typography>
                            </>
                        }
                    </DialogContent>

                    <DialogActions>
                        <Button variant="outlined" onClick={() => {setIsOpen(false)}}>
                            Close
                        </Button>
                    </DialogActions>
                </>
            }
        </Dialog>
    );
})

export default FormDetails;