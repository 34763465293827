import {
	Box,
	Button,
	Card,
	CardHeader,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';

const EmployeeList = (props) => {
	const { employees } = props;

	const navigate = useNavigate();
	const goProjectList = () => {
		navigate(`/projects`);
	};

	return (
		<Card>
			<CardHeader title="Work Package List" />
			<PerfectScrollbar>
				<Box>
					<Table>
						<TableHead sx={{ display: 'table-header-group' }}>
							<TableRow>
								<TableCell>Employee ID</TableCell>
								<TableCell align="right">Employee Name</TableCell>
								<TableCell align="right">Employee Surname</TableCell>
								<TableCell align="right">Employee Work Time (hours)</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{employees.map((employee, index) => (
								<TableRow
									key={index}
									sx={{
										border: 0,
									}}
								>
									<TableCell>{employee.id}</TableCell>
									<TableCell align="right">
										{employee.employeeFirstName}
									</TableCell>
									<TableCell align="right">
										{employee.employeeLastName}
									</TableCell>
									<TableCell align="right">
										{employee.employeeWorkTime}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			</PerfectScrollbar>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					p: 2,
				}}
			>
				<Button
					color="primary"
					size="small"
					variant="contained"
					onClick={goProjectList}
				>
					Go to Project List
				</Button>
			</Box>
		</Card>
	);
};

export { EmployeeList };
