const formatDate = (isoDateString) => {
  const date = new Date(isoDateString);

  // Check if date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Format the date as DD/MM/YYYY or MM/DD/YYYY (e.g., 09/10/2023 or 10/09/2023)
  return `${day}/${month}/${year}`;
};

export default formatDate;
