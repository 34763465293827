import {
    Card,
    CardHeader,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Box,
    Button,
} from "@mui/material"
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";

export const PermissionTable = (props) => {  

    const {nonGivenPermissions, givenPermissions} = props;
    
    const navigate = useNavigate();

    return (
        <Card>
            { nonGivenPermissions &&
                <CardHeader title="Non-Given Permission" />
            }
            { givenPermissions &&
                <CardHeader title="Given Permissions" />
            }
            <PerfectScrollbar>
                <Box>
                    <Table>
                        <TableHead sx={{ display: "table-header-group" }}>
                            <TableRow>
                                <TableCell>Permission ID</TableCell>
                                <TableCell>Permission Name</TableCell>
                                <TableCell>Content Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {nonGivenPermissions && nonGivenPermissions.map((permission, index) => (
                                <TableRow
                                    key={index}
                                >
                                    <TableCell>{permission.id}</TableCell>
                                    <TableCell>{permission.name}</TableCell>
                                    <TableCell>{permission.content_type.app_label}</TableCell>
                                    <TableCell>
                                        <Button
                                            id={index}
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                props.assignPermission(permission.id)
                                                //handleAssign(e)
                                            }}
                                        >
                                            {"->"}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {givenPermissions && givenPermissions.map((permission, index) => (
                                <TableRow
                                    key={index}
                                >
                                    <TableCell>{permission.id}</TableCell>
                                    <TableCell>{permission.name}</TableCell>
                                    <TableCell>{permission.content_type.app_label}</TableCell>
                                    <TableCell>
                                        <Button
                                            id={index}
                                            color="primary"
                                            size="small"
                                            onClick={(e) => {
                                                props.unassignEmployee(e, props.index)
                                            }}
                                        >
                                            {"<-"}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
        </Card>
    );
}