import React, { useState, useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  Tabs,
  Tab,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useTheme } from "@mui/material/styles";
import { IoAddOutline } from "react-icons/io5";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  createLab,
  createCategorySet,
  createCategory,
  deleteLab,
  deleteCategorySet,
  deleteCategory,
} from "apis/apis";

export default function FilterSection({
  labs,
  categorySets,
  categories,
  products,
  applyFilters,
  setErrorMessage,
  handleOpenDialog,
  showSnackbar,
  refreshData,
}) {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const token = sessionStorage.getItem("token");

  const [selectedLabs, setSelectedLabs] = useState([]);
  const [selectedCategorySets, setSelectedCategorySets] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredCategorySets, setFilteredCategorySets] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const [showLabInput, setShowLabInput] = useState(false);
  const [newLab, setNewLab] = useState("");
  const [showCategorySetInput, setShowCategorySetInput] = useState(false);
  const [newCategorySet, setNewCategorySet] = useState("");
  const [showCategoryInput, setShowCategoryInput] = useState(false);
  const [newCategory, setNewCategory] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [associatedItems, setAssociatedItems] = useState({
    categorySets: [],
    categories: [],
    products: [],
  });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedLabs([]);
    setSelectedCategorySets([]);
    setSelectedCategories([]);
    setFilteredCategorySets([]);
    setFilteredCategories([]);
    setShowLabInput(false);
    setNewLab("");
    setShowCategorySetInput(false);
    setNewCategorySet("");
    setShowCategoryInput(false);
    setNewCategory("");
  };

  const handleFilterChange = (type, value) => {
    if (type === "labs") {
      const newSelectedLabs = selectedLabs.includes(value)
        ? selectedLabs.filter((v) => v !== value)
        : [...selectedLabs, value];
      setSelectedLabs(newSelectedLabs);

      if (newSelectedLabs.length !== 1) {
        setShowCategorySetInput(false);
        setShowCategoryInput(false);
        setSelectedCategorySets([]);
        setSelectedCategories([]);
      }
    } else if (type === "categorySets") {
      const newSelectedCategorySets = selectedCategorySets.includes(value)
        ? selectedCategorySets.filter((v) => v !== value)
        : [...selectedCategorySets, value];
      setSelectedCategorySets(newSelectedCategorySets);

      if (newSelectedCategorySets.length !== 1) {
        setShowCategoryInput(false);
        setSelectedCategories([]);
      }
    } else if (type === "categories") {
      setSelectedCategories((prev) =>
        prev.includes(value)
          ? prev.filter((v) => v !== value)
          : [...prev, value]
      );
    }
  };

  useEffect(() => {
    if (selectedLabs.length > 0) {
      setFilteredCategorySets(
        categorySets.filter((set) =>
          selectedLabs.includes(labs.find((lab) => lab.id === set.lab)?.name)
        )
      );
    } else {
      setFilteredCategorySets([]);
    }
  }, [selectedLabs, categorySets, labs]);

  useEffect(() => {
    if (selectedCategorySets.length > 0) {
      setFilteredCategories(
        categories.filter((cat) =>
          selectedCategorySets.includes(
            categorySets.find((set) => set.id === cat.category_set)?.name
          )
        )
      );
    } else {
      setFilteredCategories([]);
    }
  }, [selectedCategorySets, categories, categorySets]);

  const handleApplyFilters = () => {
    applyFilters(selectedLabs, selectedCategorySets, selectedCategories);
  };

  const handleDeleteClick = (type, id, name) => {
    setDeleteTarget({ type, id, name });
    let associated = { categorySets: [], categories: [], products: [] };

    if (type === "laboratory") {
      associated.categorySets = categorySets
        .filter((set) => set.lab === id)
        .map((set) => set.name);
      associated.categories = categories
        .filter(
          (cat) =>
            categorySets.find((set) => set.id === cat.category_set)?.lab === id
        )
        .map((cat) => cat.name);
      associated.products = products
        .filter((product) => product.details?.lab_id === id)
        .map((product) => product.name);
    } else if (type === "category set") {
      associated.categories = categories
        .filter((cat) => cat.category_set === id)
        .map((cat) => cat.name);
      associated.products = products
        .filter((product) => product.details?.category_set_id === id)
        .map((product) => product.name);
    } else if (type === "category") {
      associated.products = products
        .filter((product) => product.details?.category_id === id)
        .map((product) => product.name);
    }

    setAssociatedItems(associated);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteTarget) {
      try {
        switch (deleteTarget.type) {
          case "laboratory":
            await deleteLab(
              token,
              setErrorMessage,
              handleOpenDialog,
              deleteTarget.id,
              showSnackbar
            );
            break;
          case "category set":
            await deleteCategorySet(
              token,
              setErrorMessage,
              handleOpenDialog,
              deleteTarget.id
            );
            break;
          case "category":
            await deleteCategory(
              token,
              setErrorMessage,
              handleOpenDialog,
              deleteTarget.id
            );
            break;
          default:
            break;
        }
        await refreshData();
        showSnackbar("Item deleted successfully!", "success");
      } catch (error) {
        showSnackbar("Failed to delete item.", "error");
      }
    }
    setOpenDialog(false);
  };

  const handleAddLab = async () => {
    if (newLab.trim() === "") return;
    try {
      await createLab(token, setErrorMessage, handleOpenDialog, {
        name: newLab,
      });
      showSnackbar("Lab created successfully!", "success");
      setShowLabInput(false);
      setNewLab("");
      await refreshData();
    } catch (error) {
      showSnackbar("Failed to create lab.", "error");
    }
  };

  const handleAddCategorySet = async () => {
    if (newCategorySet.trim() === "" || selectedLabs.length !== 1) return;

    const selectedLabId = labs.find((lab) => lab.name === selectedLabs[0])?.id;

    if (!selectedLabId) {
      showSnackbar("Please select a valid laboratory.", "error");
      return;
    }

    try {
      await createCategorySet(token, setErrorMessage, handleOpenDialog, {
        name: newCategorySet,
        lab: selectedLabId,
      });
      showSnackbar("Category Set created successfully!", "success");
      setShowCategorySetInput(false);
      setNewCategorySet("");
      await refreshData();
    } catch (error) {
      showSnackbar("Failed to create category set.", "error");
    }
  };

  const handleAddCategory = async () => {
    if (newCategory.trim() === "" || selectedCategorySets.length !== 1) return;

    const selectedCategorySetId = categorySets.find(
      (set) => set.name === selectedCategorySets[0]
    )?.id;

    const selectedLabId = categorySets.find(
      (set) => set.id === selectedCategorySetId
    )?.lab;

    if (!selectedCategorySetId || !selectedLabId) {
      showSnackbar("Please select valid category set and laboratory.", "error");
      return;
    }

    try {
      await createCategory(token, setErrorMessage, handleOpenDialog, {
        name: newCategory,
        category_set: selectedCategorySetId,
        lab: selectedLabId,
      });
      showSnackbar("Category created successfully!", "success");
      setShowCategoryInput(false);
      setNewCategory("");
      await refreshData();
    } catch (error) {
      showSnackbar("Failed to create category.", "error");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showLabInput &&
        !event.target.closest("#new-lab-input") &&
        newLab.trim() !== ""
      ) {
        handleAddLab();
      }
      if (
        showCategorySetInput &&
        !event.target.closest("#new-category-set-input") &&
        newCategorySet.trim() !== ""
      ) {
        handleAddCategorySet();
      }
      if (
        showCategoryInput &&
        !event.target.closest("#new-category-input") &&
        newCategory.trim() !== ""
      ) {
        handleAddCategory();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [
    showLabInput,
    showCategorySetInput,
    showCategoryInput,
    newLab,
    newCategorySet,
    newCategory,
  ]);

  return (
    <Box
      flexGrow={0.3}
      minWidth="350px"
      padding="0rem"
      height="100%"
      sx={{
        position: "relative",
        backgroundColor:
          theme.palette.mode === "dark" ? dark.primary : light.primary,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <Box sx={{ padding: "0.2rem" }}>
        <Tabs
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? light.secondary : dark.secondary,
            borderRadius: "10px",
          }}
          onChange={handleTabChange}
          centered
          value={selectedTab}
        >
          <Tab icon={<VisibilityIcon />} />
          <Tab icon={<EditIcon />} />
        </Tabs>
      </Box>

      {/* Filter section */}
      <Box
        sx={{
          padding: "1rem",
          flexGrow: 1,
          overflowY: "auto",
          marginBottom: "4rem",
        }}
      >
        {/* Laboratories Section */}
        <Box marginBottom="2rem">
          <h3
            style={{
              color: theme.palette.mode === "dark" ? dark.text : light.text,
              fontWeight: "bold",
              fontSize: "1rem",
              marginLeft: "0.5rem",
            }}
          >
            Laboratories
            {selectedTab === 1 && (
              <Box
                sx={{
                  borderRadius: "4px",

                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIcon
                      : dark.buttonIcon,
                  backgroundColor:
                    "transparent",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.buttonBackgroundHover
                        : dark.buttonBackgroundHover,
                    cursor: "pointer",
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIconHover
                        : dark.buttonIconHover,
                  },
                  width: "fit-content",
                  height: "fit-content",
                  display: "inline-block",
                  textAlign: "center",
                  lineHeight: "0",
                  marginLeft: "0.5rem",
                }}
                onClick={() => setShowLabInput(true)}
              >
                <span style={{ fontSize: "15px" }}>
                  <IoAddOutline />
                </span>
              </Box>
            )}
          </h3>
          {labs.map((lab) => (
            <Box key={lab.id} display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLabs.includes(lab.name)}
                    onChange={() => handleFilterChange("labs", lab.name)}
                    sx={{
                      marginLeft: "0.7rem",
                      color:
                        theme.palette.mode === "dark" ? dark.text : light.text,
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      color:
                        theme.palette.mode === "dark" ? dark.text : light.text,
                      fontWeight: "lighter",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {lab.name}
                  </span>
                }
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              />
              {selectedTab === 1 && (
                <IconButton
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.error : dark.error,
                    marginLeft: "auto",
                    fontSize: "1.2rem",
                  }}
                  onClick={() =>
                    handleDeleteClick("laboratory", lab.id, lab.name)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
          {showLabInput && (
            <Box
              id="new-lab-input"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox disabled />
              <TextField
                autoFocus
                value={newLab}
                onChange={(e) => setNewLab(e.target.value)}
                onBlur={handleAddLab}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleAddLab();
                }}
                placeholder="Enter new laboratory name"
                size="small"
                variant="standard"
                inputProps={{ maxLength: 20 }}
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    color: theme.palette.mode === "light" ? "#000" : dark.text,
                  },
                  "& .MuiInputBase-input": {
                    color: theme.palette.mode === "light" ? "#000" : dark.text,
                  },
                  marginLeft: "0.3rem",
                }}
              />
            </Box>
          )}
        </Box>

        {/* Category Sets Section */}
        <Box marginBottom="2rem">
          <h3
            style={{
              color: theme.palette.mode === "dark" ? dark.text : light.text,
              fontWeight: "bold",
              fontSize: "1rem",
              marginLeft: "0.5rem",
            }}
          >
            Category Sets
            {selectedTab === 1 && selectedLabs.length === 1 && (
              <Box
                sx={{
                  borderRadius: "4px",

                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIcon
                      : dark.buttonIcon,
                  backgroundColor:
                    "transparent",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.buttonBackgroundHover
                        : dark.buttonBackgroundHover,
                    cursor: "pointer",
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIconHover
                        : dark.buttonIconHover,
                  },
                  width: "fit-content",
                  height: "fit-content",
                  display: "inline-block",
                  textAlign: "center",
                  lineHeight: "0",
                  marginLeft: "0.5rem",
                }}
                onClick={() => setShowCategorySetInput(true)}
              >
                <span style={{ fontSize: "15px" }}>
                  <IoAddOutline />
                </span>
              </Box>
            )}
          </h3>
          {filteredCategorySets.map((set) => (
            <Box key={set.id} display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCategorySets.includes(set.name)}
                    onChange={() =>
                      handleFilterChange("categorySets", set.name)
                    }
                    sx={{
                      marginLeft: "0.7rem",
                      color:
                        theme.palette.mode === "dark" ? dark.text : light.text,
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      color:
                        theme.palette.mode === "dark" ? dark.text : light.text,
                      fontWeight: "lighter",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {set.name}
                  </span>
                }
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              />
              {selectedTab === 1 && (
                <IconButton
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.error : dark.error,
                    marginLeft: "auto",
                    fontSize: "1.2rem",
                  }}
                  onClick={() =>
                    handleDeleteClick("category set", set.id, set.name)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
          {showCategorySetInput && (
            <Box
              id="new-category-set-input"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox disabled />
              <TextField
                autoFocus
                value={newCategorySet}
                onChange={(e) => setNewCategorySet(e.target.value)}
                onBlur={handleAddCategorySet}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleAddCategorySet();
                }}
                placeholder="Enter new category set name"
                size="small"
                variant="standard"
                inputProps={{ maxLength: 20 }}
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    color: theme.palette.mode === "light" ? "#000" : dark.text,
                  },
                  "& .MuiInputBase-input": {
                    color: theme.palette.mode === "light" ? "#000" : dark.text,
                  },
                  marginLeft: "0.3rem",
                }}
              />
            </Box>
          )}
        </Box>

        {/* Categories Section */}
        <Box marginBottom="2rem">
          <h3
            style={{
              color: theme.palette.mode === "dark" ? dark.text : light.text,
              fontWeight: "bold",
              fontSize: "1rem",
              marginLeft: "0.5rem",
            }}
          >
            Categories
            {selectedTab === 1 &&
              selectedLabs.length === 1 &&
              selectedCategorySets.length === 1 && (
                <Box
                  sx={{
                    borderRadius: "4px",

                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIcon
                        : dark.buttonIcon,
                    backgroundColor:
                      "transparent",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? light.buttonBackgroundHover
                          : dark.buttonBackgroundHover,
                      cursor: "pointer",
                      color:
                        theme.palette.mode === "light"
                          ? light.buttonIconHover
                          : dark.buttonIconHover,
                    },
                    width: "fit-content",
                    height: "fit-content",
                    display: "inline-block",
                    textAlign: "center",
                    lineHeight: "0",
                    marginLeft: "0.5rem",
                  }}
                  onClick={() => setShowCategoryInput(true)}
                >
                  <span style={{ fontSize: "15px" }}>
                    <IoAddOutline />
                  </span>
                </Box>
              )}
          </h3>
          {filteredCategories.map((cat) => (
            <Box key={cat.id} display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCategories.includes(cat.name)}
                    onChange={() => handleFilterChange("categories", cat.name)}
                    sx={{
                      marginLeft: "0.7rem",
                      color:
                        theme.palette.mode === "dark" ? dark.text : light.text,
                    }}
                  />
                }
                label={
                  <span
                    style={{
                      color:
                        theme.palette.mode === "dark" ? dark.text : light.text,
                      fontWeight: "lighter",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {cat.name}
                  </span>
                }
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              />
              {selectedTab === 1 && (
                <IconButton
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.error : dark.error,

                    marginLeft: "auto",
                    fontSize: "1.2rem",
                  }}
                  onClick={() =>
                    handleDeleteClick("category", cat.id, cat.name)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
          {showCategoryInput && (
            <Box
              id="new-category-input"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox disabled />
              <TextField
                autoFocus
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                onBlur={handleAddCategory}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleAddCategory();
                }}
                placeholder="Enter new category name"
                size="small"
                variant="standard"
                inputProps={{ maxLength: 20 }}
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    color: theme.palette.mode === "light" ? "#000" : dark.text,
                  },
                  "& .MuiInputBase-input": {
                    color: theme.palette.mode === "light" ? "#000" : dark.text,
                  },
                  marginLeft: "0.3rem",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor:
            theme.palette.mode === "dark" ? dark.primary : light.primary,
          padding: "1rem",
          borderRadius: "8px",
        }}
      >
        {selectedTab === 0 && (
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            sx={{
              width: "100%",
              height: "40px",
              fontSize: "1rem",
              backgroundColor:
                theme.palette.mode === "dark" ? dark.primary : light.primary,
              color: theme.palette.mode === "dark" ? dark.text : light.text,
              borderRadius: "4px",
              border: `1px solid ${
                theme.palette.mode === "dark" ? dark.border : light.border
              }`,
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? dark.secondary
                    : light.secondary,
              },
            }}
          >
            Apply Filters
          </Button>
        )}
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
            color: theme.palette.mode === "dark" ? dark.text : light.text,
          }}
        >
          Delete Confirmation
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
            color: theme.palette.mode === "dark" ? dark.text : light.text,
          }}
        >
          <DialogContentText
            sx={{
              color: theme.palette.mode === "dark" ? dark.text : light.text,
            }}
          >
            {associatedItems.categorySets.length > 0 ||
            associatedItems.categories.length > 0 ||
            associatedItems.products.length > 0 ? (
              <strong>
                Deleting {deleteTarget?.name} will also delete the following
                associated items:
              </strong>
            ) : (
              <strong>Are you sure you want to delete this item?</strong>
            )}
          </DialogContentText>
          {associatedItems.categorySets.length > 0 && (
            <Box>
              <strong>
                <br />
                Category Sets:
              </strong>
              {associatedItems.categorySets.map((set, index) => (
                <div key={index}>- {set}</div>
              ))}
            </Box>
          )}
          {associatedItems.categories.length > 0 && (
            <Box>
              <strong>
                <br />
                Categories:
              </strong>
              {associatedItems.categories.map((category, index) => (
                <div key={index}>- {category}</div>
              ))}
            </Box>
          )}
          {associatedItems.products.length > 0 && (
            <Box>
              <strong>
                <br />
                Products with associations:
              </strong>
              {associatedItems.products.map((product, index) => (
                <div key={index}>- {product}</div>
              ))}
            </Box>
          )}
          {(associatedItems.categorySets.length > 0 ||
            associatedItems.categories.length > 0 ||
            associatedItems.products.length > 0) && (
            <DialogContentText
              sx={{
                color: theme.palette.mode === "dark" ? dark.text : light.text,
              }}
            >
              <br /> If you proceed, the relations with these products will be
              lost.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor:
              theme.palette.mode === "dark" ? dark.primary : light.primary,
            color: theme.palette.mode === "dark" ? dark.text : light.text,
          }}
        >
          <Button
            onClick={() => setOpenDialog(false)}
            sx={{
              color: theme.palette.mode === "dark" ? dark.text : light.text,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            autoFocus
            sx={{
              color: theme.palette.mode === "dark" ? dark.text : light.text,
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
