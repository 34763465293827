import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  List,
  ListItem,
  Box,
  Divider,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import baseColors from "../../../assets/theme/base/colors";
import darkColors from "../../../assets/theme-dark/base/colors";
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { lighten, darken } from "@mui/system";
import {
  getChangelog,
  getChangelogTypes,
  submitChangelog,
  deleteChangelog,
  updateChangelog,
} from "../../../apis/apis";

const Changelog = ({ isMemberOfERPDevTeam }) => {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const [errorMessage, setErrorMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [changelogToDelete, setChangelogToDelete] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [editingChangelog, setEditingChangelog] = useState(null);
  const [newChangelog, setNewChangelog] = useState({
    version: "",
    changes: [{ type: "", description: "" }],
  });
  const [changelogTypes, setChangelogTypes] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleOpenDeleteDialog = (changelogId) => {
    setChangelogToDelete(changelogId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setChangelogToDelete(null);
  };

  const fetchChangelog = () => {
    setLoading(true);
    setError(null);

    getChangelog(token, setErrorMessage, handleOpenDialog, navigate)
      .then((changelogData) => {
        setData(changelogData);
      })
      .catch((error) => {
        setError(error.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchChangelogTypes = () => {
    getChangelogTypes(token, setErrorMessage, handleOpenDialog, navigate)
      .then((types) => setChangelogTypes(types))
      .catch((error) => {
        setError(error.message || "An error occurred");
      });
  };

  useEffect(() => {
    if (token) {
      fetchChangelog();
      fetchChangelogTypes();
    }
  }, [token]);

  const semanticVersionPattern = /^\d+\.\d+\.\d+$/;

  const handleVersionChange = (e) => {
    const inputVersion = e.target.value;
    const isDuplicateVersion = data.some(
      (entry) =>
        entry.version === inputVersion && entry.id !== editingChangelog?.id
    );

    if (!semanticVersionPattern.test(inputVersion)) {
      setError(
        "Invalid version format. Please use MAJOR.MINOR.PATCH (e.g., 1.0.0)."
      );
    } else if (isDuplicateVersion) {
      setError("Version already exists. Please use a unique version.");
    } else {
      setError(null);
    }

    setNewChangelog((prev) => ({
      ...prev,
      version: inputVersion,
    }));
  };

  const handleNewChange = () => {
    setNewChangelog((prev) => ({
      ...prev,
      changes: [...prev.changes, { type: "", description: "" }],
    }));
  };

  const handleRemoveChange = (index) => {
    setNewChangelog((prev) => ({
      ...prev,
      changes: prev.changes.filter((_, i) => i !== index),
    }));
  };

  const validateChangelog = () => {
    const hasValidVersion = semanticVersionPattern.test(newChangelog.version);
    const hasAtLeastOneChange = newChangelog.changes.length > 0;
    const areAllFieldsFilled = newChangelog.changes.every(
      (change) => change.type && change.description
    );
    const isDuplicateVersion = data.some(
      (entry) =>
        entry.version === newChangelog.version &&
        entry.id !== editingChangelog?.id
    );

    setIsValid(
      hasValidVersion &&
        hasAtLeastOneChange &&
        areAllFieldsFilled &&
        !isDuplicateVersion
    );
  };

  useEffect(() => {
    validateChangelog();
  }, [newChangelog, data]);

  const handleSave = async () => {
    if (!isValid) return;

    const changelogData = {
      id: editingChangelog?.id,
      version: newChangelog.version,
      module: newChangelog.module || "ERP",
      changes: newChangelog.changes.map((change) => ({
        change_type: change.type,
        description: change.description,
      })),
    };

    try {
      if (editingChangelog) {
        const response = await updateChangelog(
          token,
          changelogData,
          setErrorMessage,
          handleOpenDialog,
          navigate
        );
        if (response) {
          setSnackbarMessage("Changelog updated successfully.");
          setSnackbarSeverity("success");
        } else {
          setSnackbarMessage("Error updating changelog. Please try again.");
          setSnackbarSeverity("error");
        }
      } else {
        const response = await submitChangelog(
          token,
          changelogData,
          setErrorMessage,
          handleOpenDialog,
          navigate
        );
        if (response) {
          setSnackbarMessage("Changelog created successfully.");
          setSnackbarSeverity("success");
        } else {
          setSnackbarMessage("Error creating changelog. Please try again.");
          setSnackbarSeverity("error");
        }
      }

      setSnackbarOpen(true);
      setIsCreating(false);
      setEditingChangelog(null);
      setNewChangelog({
        version: "",
        module: "ERP",
        changes: [{ type: "", description: "" }],
      });
      fetchChangelog();
    } catch (error) {
      setSnackbarMessage("Error processing changelog. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Error processing changelog:", error);
    }
  };

  const handleDeleteChangelog = async () => {
    if (!changelogToDelete) return;

    try {
      const response = await deleteChangelog(
        changelogToDelete,
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate
      );
      if (response) {
        setSnackbarMessage("Changelog deleted successfully.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        handleCloseDeleteDialog();
        fetchChangelog();
      }
    } catch (error) {
      setSnackbarMessage("Error deleting changelog. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      console.error("Error deleting changelog:", error);
    }
  };

  const handleEditChangelog = (entry) => {
    setIsCreating(true);
    setEditingChangelog(entry);
    setNewChangelog({
      version: entry.version,
      changes: entry.changes.map((change) => ({
        type: change.change_type,
        description: change.description,
      })),
    });
  };

  const handleCancel = () => {
    setIsCreating(false);
    setEditingChangelog(null);
    setNewChangelog({ version: "", changes: [{ type: "", description: "" }] });
  };

  const getColorStyles = (color) => {
    if (!color)
      return { color: "#333", borderColor: "#333", backgroundColor: "#E0E0E0" };

    const textColor =
      theme.palette.mode === "light" ? darken(color, 0.3) : lighten(color, 0.1);
    const borderColor =
      theme.palette.mode === "light" ? darken(color, 0.3) : lighten(color, 0.1);
    const backgroundColor =
      theme.palette.mode === "light" ? lighten(color, 0.5) : darken(color, 0.5);

    return {
      color: textColor,
      borderColor: borderColor,
      backgroundColor: backgroundColor,
    };
  };

  return (
    <Container maxWidth="md">
      {isMemberOfERPDevTeam && !isCreating && (
        <Button
          onClick={() => setIsCreating(true)}
          variant="contained"
          color="primary"
          sx={{
            marginBottom: "1.5rem",
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
            color: theme.palette.mode === "light" ? light.text : dark.text,
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : dark.border
            }`,
            "&:hover": {
              color: theme.palette.mode === "light" ? light.text : dark.text,
              backgroundColor:
                theme.palette.mode === "light" ? light.hover : dark.hover,
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : dark.border
              }`,
            },
          }}
        >
          New Changelog
        </Button>
      )}

      {isCreating && (
        <Box
          sx={{
            p: 4,
            mb: 2,
            borderRadius: 2,
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : dark.border
            }`,
            position: "relative",
          }}
        >
          <Typography
            variant="h5"
            mb={2}
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            {new Date().toISOString().split("T")[0]}
          </Typography>
          <TextField
            fullWidth
            label="Version (Semantic)"
            value={newChangelog.version}
            onChange={handleVersionChange}
            error={!!error}
            helperText={error}
            sx={{
              width: "20rem",
              marginBottom: "1rem",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: `1px solid ${
                    theme.palette.mode === "light" ? light.border : dark.border
                  }`,
                },
              },
              "& .MuiInputBase-input": {
                color:
                  theme.palette.mode === "light" ? light.text2 : dark.text2,
              },
            }}
          />
          <Divider />
          {newChangelog.changes.map((change, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}
            >
              <FormControl sx={{ minWidth: "10rem" }}>
                <InputLabel
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.text2 : dark.text2,
                    paddingBottom: "0.5rem",
                  }}
                >
                  Change Type
                </InputLabel>
                <Select
                  value={change.type}
                  onChange={(e) =>
                    setNewChangelog((prev) => {
                      const changes = [...prev.changes];
                      changes[index].type = e.target.value;
                      return { ...prev, changes };
                    })
                  }
                  label="Change Type"
                  sx={{
                    height: "2.7rem",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: `1px solid ${
                          theme.palette.mode === "light"
                            ? light.border
                            : dark.border
                        }`,
                      },
                    },
                    "& .MuiInputBase-input": {
                      color:
                        theme.palette.mode === "light"
                          ? light.text
                          : dark.text2,
                    },
                  }}
                >
                  {changelogTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                value={change.description}
                onChange={(e) =>
                  setNewChangelog((prev) => {
                    const changes = [...prev.changes];
                    changes[index].description = e.target.value;
                    return { ...prev, changes };
                  })
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: `1px solid ${
                        theme.palette.mode === "light"
                          ? light.border
                          : dark.border
                      }`,
                    },
                  },
                  "& .MuiInputBase-input": {
                    color:
                      theme.palette.mode === "light" ? light.text2 : dark.text2,
                  },
                }}
                placeholder="Description"
              />
              <IconButton onClick={() => handleRemoveChange(index)}>
                <CloseIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            onClick={handleNewChange}
            sx={{
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
              color: theme.palette.mode === "light" ? light.text : dark.text,
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : dark.border
              }`,
              "&:hover": {
                color: theme.palette.mode === "light" ? light.text : dark.text,
                backgroundColor:
                  theme.palette.mode === "light" ? light.hover : dark.hover,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
              },
            }}
          >
            + New Change
          </Button>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "flex-end", mt: 2 }}
          >
            <Tooltip
              title={
                !isValid
                  ? "You need to fill all the fields without errors and at least one change is required."
                  : ""
              }
              arrow
            >
              <span>
                <Button
                  onClick={handleSave}
                  disabled={!isValid}
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.primary
                        : dark.primary,
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                    border: `1px solid ${
                      theme.palette.mode === "light"
                        ? light.border
                        : dark.border
                    }`,
                    "&:hover": {
                      color:
                        theme.palette.mode === "light" ? light.text : dark.text,
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? light.hover
                          : dark.hover,
                      border: `1px solid ${
                        theme.palette.mode === "light"
                          ? light.border
                          : dark.border
                      }`,
                    },
                  }}
                >
                  Save
                </Button>
              </span>
            </Tooltip>
            <Button
              onClick={handleCancel}
              sx={{
                backgroundColor:
                  theme.palette.mode === "light" ? light.primary : dark.primary,
                color: theme.palette.mode === "light" ? light.text : dark.text,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
                "&:hover": {
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                  backgroundColor:
                    theme.palette.mode === "light" ? light.hover : dark.hover,
                  border: `1px solid ${
                    theme.palette.mode === "light" ? light.border : dark.border
                  }`,
                },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}

      {loading ? (
        <CircularProgress
          sx={{ display: "block", margin: "auto" }}
          color="primary"
        />
      ) : (
        data.length > 0 && (
          <List>
            {data.map((entry) => (
              <Box
                key={entry.id}
                sx={{
                  borderRadius: 2,
                  p: 4,
                  mb: 2,
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.primary
                      : dark.primary,
                  border: `1px solid ${
                    theme.palette.mode === "light" ? light.border : dark.border
                  }`,
                  position: "relative",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                  }}
                >
                  {entry.created_at.split("T")[0]}
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    color:
                      theme.palette.mode === "light" ? light.text : dark.text,
                  }}
                >
                  Version {entry.version}
                </Typography>
                <Divider />
                <List>
                  {entry.changes.map((change) => (
                    <ListItem
                      key={change.id}
                      disableGutters
                      sx={{
                        marginBottom: "2rem",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={1}>
                          <Box
                            sx={{
                              display: "inline-block",
                              borderRadius: "4px",
                              fontSize: "14px",
                              fontWeight: "bold",
                              p: "4px 8px",
                              color: getColorStyles(change.color).color,
                              border: `2px solid ${
                                getColorStyles(change.color).borderColor
                              }`,
                              backgroundColor: getColorStyles(change.color)
                                .backgroundColor,
                              minWidth: "70px",
                              textAlign: "center",
                            }}
                          >
                            {change.change_type}
                          </Box>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography
                            sx={{
                              color:
                                theme.palette.mode === "light"
                                  ? light.text
                                  : dark.text,
                              fontSize: "1rem",
                              marginTop: "0.3rem",
                              marginLeft: "1rem",
                            }}
                          >
                            {change.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>

                {isMemberOfERPDevTeam && (
                  <Box
                    sx={{
                      position: "absolute",
                      right: "16px",
                      top: "16px",
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    <IconButton
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? light.warning
                            : dark.warning,
                      }}
                      onClick={() => handleEditChangelog(entry)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? light.error
                            : dark.error,
                      }}
                      onClick={() => handleOpenDeleteDialog(entry.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            ))}
          </List>
        )
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle
          id="delete-dialog-title"
          sx={{
            color: theme.palette.mode === "light" ? light.text : dark.text,
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
            fontSize: "1.2rem",
          }}
        >
          Are you sure you want to delete this changelog?
        </DialogTitle>
        <DialogActions
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
          }}
        >
          <Button
            onClick={handleCloseDeleteDialog}
            color="primary"
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : dark.border
              }`,
              "&:hover": {
                color: theme.palette.mode === "light" ? light.text : dark.text,
                backgroundColor:
                  theme.palette.mode === "light" ? light.hover : dark.hover,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteChangelog}
            color="primary"
            autoFocus
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
              backgroundColor:
                theme.palette.mode === "light" ? light.primary : dark.primary,
              border: `1px solid ${
                theme.palette.mode === "light" ? light.border : dark.border
              }`,
              "&:hover": {
                color: theme.palette.mode === "light" ? light.text : dark.text,
                backgroundColor:
                  theme.palette.mode === "light" ? light.hover : dark.hover,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Changelog;
