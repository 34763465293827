import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { IoAddOutline } from "react-icons/io5";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Typography,
  Menu,
  MenuItem,
  AppBar,
  Tabs,
  Tab,
  Radio,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import {
  fetchNotes,
  fetchNotesByDate,
  addUserNote,
  updateUserNote,
  deleteUserNote,
} from "apis/apis";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

function Notes() {
  const [isThereAnyNote, setIsThereAnyNote] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const token = sessionStorage.getItem("token");
  const [navigate, setNavigate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // Load saved states from local storage or set default values
  const loadState = (key, defaultValue, isDate = false) => {
    const savedState = localStorage.getItem(key);
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      return isDate ? dayjs(parsedState) : parsedState;
    }
    return defaultValue;
  };

  const [selectedFilter, setSelectedFilter] = useState(
    loadState("selectedFilter", ["byDate"])
  );
  const [selectedDate, setSelectedDate] = useState(
    loadState("selectedDate", dayjs(), true) // Ensure default is a Day.js object
  );

  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const theme = useTheme();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const [notePanelWidth, setNotePanelWidth] = useState("103%");
  const otherComponentRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        const narrowWidth = width > 400 ? "70%" : "103%";
        setNotePanelWidth(narrowWidth);
      }
    });

    if (otherComponentRef.current) {
      resizeObserver.observe(otherComponentRef.current);
    }

    return () => {
      if (otherComponentRef.current) {
        resizeObserver.unobserve(otherComponentRef.current);
      }
    };
  }, []);

  const Note = ({ note, toggleDone }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const removeNote = () => {
      setOpenConfirmationDialog(true);
    };

    const handleDeleteConfirmed = () => {
      setOpenConfirmationDialog(false);
      deleteNote(note.id);
    };

    const handleDeleteCanceled = () => {
      setOpenConfirmationDialog(false);
    };

    return (
      <Card
        sx={{
          boxShadow:
            theme.palette.mode === "light" ? light.shadow : dark.shadow,
          variant: "outlined",
          width: notePanelWidth - "3%",
          height: "130",
          backgroundColor:
            theme.palette.mode === "light" ? light.primary : dark.primary,
          "&:hover": {
            backgroundColor:
              theme.palette.mode === "light" ? light.hover : dark.hover,
          },
          overflowY: "hidden",
          overflowX: "hidden",
          margin: "5px",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardContent
          sx={{
            display: "flex",
            maxHeight: "100px",
          }}
        >
          <Box
            onClick={toggleDone}
            style={{
              color:
                theme.palette.mode === "light"
                  ? light.buttonIcon
                  : dark.buttonIcon,

              display: "flex",
              marginTop: "8px",
              marginRight: "10px",
              cursor: "pointer",
            }}
          >
            {note.completion ? <CheckBoxIcon /> : <CheckBoxOutlineIcon />}
          </Box>

          <Typography
            sx={{
              textDecoration: note.completion ? "line-through" : "none",
              color: note.completion ? "grey" : "inherit",
              fontSize: "17px",
              marginTop: "3px",
              paddingLeft: "10px",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {note.title}
          </Typography>
        </CardContent>
        <Typography
          sx={{
            color:
              note.execution_date &&
              dayjs(note.execution_date).isBefore(
                dayjs().format("DD MMMM YYYY")
              )
                ? "#fc766d"
                : theme.palette.mode === "light"
                ? light.text
                : dark.text,
            fontSize: "12px",
            marginLeft: "10px",
            position: "absolute",
            bottom: "2px",
          }}
        >
          {note.execution_date
            ? dayjs(note.execution_date).format("DD MMMM YYYY")
            : "No date"}
        </Typography>

        <Box display="flex" justifyContent="flex-end" p={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginLeft: "auto",
              marginRight: "0px",
              marginTop: "-20px",
              visibility: isHovered ? "visible" : "hidden",
            }}
          >
            <Icon
              sx={{
                color:
                  theme.palette.mode === "light"
                    ? light.buttonIcon
                    : dark.buttonIcon,
                cursor: "pointer",
                top: "51px",
                right: "5px",
                width: "22px",
                height: "22px",
                fontSize: "15px",
                "&:hover": {
                  color:
                    theme.palette.mode === "light"
                      ? light.buttonIconHover
                      : "#000000",
                  borderRadius: "20%",
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.buttonBackgroundHover
                      : dark.buttonBackgroundHover,
                },
              }}
              onClick={removeNote}
            >
              delete
            </Icon>
          </Box>
        </Box>
        <Dialog
          open={openConfirmationDialog}
          onClose={handleDeleteCanceled}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            {"Confirm Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                color: theme.palette.mode === "light" ? light.text : dark.text,
              }}
            >
              Are you sure you want to delete this note?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDeleteCanceled}
              sx={{
                color: theme.palette.mode === "light" ? light.text : dark.text,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteConfirmed}
              sx={{
                color: theme.palette.mode === "light" ? light.text : dark.text,
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  };

  const [notes, setNotes] = useState([false]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const getUserNotes = () => {
    setLoading(true);
    setError(null);

    if (selectedFilter.includes("byDate")) {
      fetchNotesByDate(
        token,
        setErrorMessage,
        handleOpenDialog,
        navigate,
        selectedDate
      )
        .then((data) => {
          let filteredNotes = data.filter((note) =>
            dayjs(note.execution_date).isSame(dayjs(selectedDate), "day")
          );

          if (selectedFilter.includes("done")) {
            if (selectedFilter.includes("undone")) {
              filteredNotes = filteredNotes;
            } else {
              filteredNotes = filteredNotes.filter((note) => note.completion);
            }
          } else if (selectedFilter.includes("undone")) {
            if (selectedFilter.includes("done")) {
              filteredNotes = filteredNotes;
            } else {
              filteredNotes = filteredNotes.filter((note) => !note.completion);
            }
          } else {
            filteredNotes = filteredNotes;
          }

          if (filteredNotes.length > 0) {
            setIsThereAnyNote(true);
          } else {
            setIsThereAnyNote(false);
          }
          setSelectedDate(selectedDate);
          setNotes(filteredNotes);
        })
        .catch((error) => {
          setError(error.message || "An error occurred");
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (selectedFilter.includes("all")) {
      fetchNotes(token, setErrorMessage, handleOpenDialog, navigate)
        .then((data) => {
          let filteredNotes = data;

          if (selectedFilter.includes("done")) {
            if (selectedFilter.includes("undone")) {
              if (selectedFilter.includes("overdue")) {
                filteredNotes = data;
              } else {
                filteredNotes = data;
              }
            } else if (selectedFilter.includes("overdue")) {
              filteredNotes = data.filter(
                (note) =>
                  note.completion &&
                  dayjs(note.execution_date).isBefore(
                    dayjs().format("DD MMMM YYYY")
                  )
              );
            } else {
              filteredNotes = data.filter((note) => note.completion);
            }
          } else if (selectedFilter.includes("undone")) {
            if (selectedFilter.includes("done")) {
              if (selectedFilter.includes("overdue")) {
                filteredNotes = data;
              } else {
                filteredNotes = data;
              }
            } else if (selectedFilter.includes("overdue")) {
              if (selectedFilter.includes("done")) {
                filteredNotes = data;
              } else {
                filteredNotes = data.filter(
                  (note) =>
                    !note.completion &&
                    dayjs(note.execution_date).isBefore(
                      dayjs().format("DD MMMM YYYY")
                    )
                );
              }
            } else {
              filteredNotes = data.filter((note) => !note.completion);
            }
          } else if (selectedFilter.includes("overdue")) {
            if (selectedFilter.includes("done")) {
              if (selectedFilter.includes("undone")) {
                filteredNotes = data;
              } else {
                filteredNotes = data.filter(
                  (note) =>
                    !note.completion &&
                    dayjs(note.execution_date).isBefore(
                      dayjs().format("DD MMMM YYYY")
                    )
                );
              }
            } else if (selectedFilter.includes("undone")) {
              if (selectedFilter.includes("done")) {
                filteredNotes = data;
              } else {
                filteredNotes = data.filter((note) => !note.completion);
                filteredNotes = data.filter((note) =>
                  dayjs(note.execution_date).isBefore(
                    dayjs().format("DD MMMM YYYY")
                  )
                );
              }
            } else {
              filteredNotes = data.filter((note) =>
                dayjs(note.execution_date).isBefore(
                  dayjs().format("DD MMMM YYYY")
                )
              );
            }
          }

          if (filteredNotes.length > 0) {
            setIsThereAnyNote(true);
          } else {
            setIsThereAnyNote(false);
          }
          setNotes(filteredNotes);
        })
        .catch((error) => {
          setError(error.message || "An error occurred");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Save states to local storage whenever they change
  useEffect(() => {
    localStorage.setItem("selectedFilter", JSON.stringify(selectedFilter));
  }, [selectedFilter]);

  useEffect(() => {
    localStorage.setItem("selectedDate", JSON.stringify(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    getUserNotes();
  }, [selectedDate, selectedFilter]);

  const handleTabChange = (event, newValue) => {
    setSelectedFilter([newValue]);
  };

  const updateNote = (id, done) => {
    setLoading(true);
    setError(null);

    updateUserNote(token, setErrorMessage, handleOpenDialog, navigate, id, done)
      .then((data) => {
        getUserNotes();
      })
      .catch((error) => {
        setError(error.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
        setErrorMessage(null);
      });
  };

  const addNote = () => {
    setLoading(true);
    setError(null);

    if (dayjs(selectedDate).isBefore(dayjs().startOf("day"))) {
      setErrorMessage("You cannot enter a note for a past date.");
    }

    addUserNote(
      token,
      setErrorMessage,
      handleOpenDialog,
      navigate,
      inputValue,
      selectedDate
    )
      .then((data) => {
        getUserNotes();
        setInputValue("");
      })
      .catch((error) => {
        setError(error.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteNote = (id) => {
    setLoading(true);
    setError(null);

    deleteUserNote(token, setErrorMessage, handleOpenDialog, navigate, id)
      .then((data) => {
        getUserNotes();
      })
      .catch((error) => {
        setError(error.message || "An error occurred");
      })
      .finally(() => {
        setLoading(false);
        setErrorMessage(null);
      });
  };

  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleSelectFilter = (filter) => {
    const isSelected = selectedFilter.includes(filter);
    let updatedFilters;

    if (isSelected) {
      updatedFilters = selectedFilter.filter((f) => f !== filter);
    } else {
      updatedFilters = [...selectedFilter, filter];
    }

    setSelectedFilter(updatedFilters);
  };

  const today = new Date();

  return (
    <Card
      sx={{
        boxShadow: theme.palette.mode === "light" ? light.shadow : dark.shadow,
        width: notePanelWidth,
        height: "680px",
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor:
          theme.palette.mode === "light" ? light.secondary : dark.secondary,
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor:
            theme.palette.mode === "light" ? light.primary : dark.primary,
        }}
      >
        <MDBox
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? light.primary : dark.primary,
          }}
        >
          <MDBox borderBottom={1} borderColor="grey.400">
            <MDBox
              lineHeight={1}
              sx={{ textAlign: "center" }}
              pb={3}
              pl={3}
              pr={3}
            >
              <MDTypography
                variant="h2"
                sx={{
                  color:
                    theme.palette.mode === "light"
                      ? dark.secondary
                      : light.secondary,
                }}
              >
                My Notes
              </MDTypography>
            </MDBox>

            <MDBox p={1}>
              <MDBox display="flex" alignItems="center" p={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Select Date"
                    defaultValue={today}
                    value={selectedDate}
                    onChange={(newDate) => {
                      setSelectedDate(dayjs(newDate));
                    }}
                    textField={(params) => <TextField {...params} />}
                    sx={{
                      width: "40%",
                      "& .MuiInputLabel-root": {
                        color:
                          theme.palette.mode === "light"
                            ? light.text
                            : dark.text,
                      },
                      "& .MuiInputBase-input": {
                        color:
                          theme.palette.mode === "light"
                            ? light.text
                            : dark.text,
                      },
                    }}
                  />
                </LocalizationProvider>

                <Tabs
                  value={selectedFilter[0]}
                  onChange={handleTabChange}
                  sx={{
                    width: "43.5%",
                    marginLeft: "5px",
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? "#e8e8e8"
                        : dark.secondary,
                    border: `1px solid ${
                      theme.palette.mode === "light"
                        ? light.border
                        : dark.border
                    }`,
                    borderRadius: "5px",
                  }}
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: "#fff",
                      boxShadow: "0px 0px 0px 0px",
                    },
                  }}
                >
                  <Tab
                    label="By Date"
                    value="byDate"
                    sx={{
                      marginRight: "5px",
                    }}
                  />

                  <Tab
                    label="All"
                    value="all"
                    style={{
                      color:
                        theme.palette.mode === "light" ? light.text : dark.text,
                    }}
                  />
                </Tabs>

                <FilterListIcon
                  onClick={handleOpenMenu}
                  sx={{
                    marginLeft: "23px",

                    fontSize: "30px",
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIcon
                        : dark.buttonIcon,
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.primary
                        : dark.buttonBackground,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  fontSize="medium"
                ></FilterListIcon>

                <Menu
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleCloseMenu}
                  sx={{
                    "& .MuiMenu-list": {
                      borderRadius: "5px",

                      "& .MuiMenuItem-root": {
                        color:
                          theme.palette.mode === "light" ? light.text : "#000",
                      },
                    },
                  }}
                >
                  <MenuItem onClick={() => handleSelectFilter("done")}>
                    <Radio
                      checked={selectedFilter.includes("done")}
                      onChange={() => handleSelectFilter("done")}
                      style={{
                        color: "#000",
                        "&.Mui-checked": { color: "#000" },
                      }}
                    />
                    Done
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectFilter("undone")}>
                    <Radio
                      checked={selectedFilter.includes("undone")}
                      onChange={() => handleSelectFilter("undone")}
                    />
                    Undone
                  </MenuItem>
                  {!selectedFilter.includes("byDate") && (
                    <MenuItem onClick={() => handleSelectFilter("overdue")}>
                      <Radio
                        checked={selectedFilter.includes("overdue")}
                        onChange={() => handleSelectFilter("overdue")}
                      />
                      Overdue
                    </MenuItem>
                  )}
                </Menu>
              </MDBox>

              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
              >
                <TextField
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  label="Enter a note"
                  variant="outlined"
                  sx={{
                    width: "85%",
                    "& .MuiInputLabel-root": {
                      color:
                        theme.palette.mode === "light" ? light.text : dark.text,
                    },
                    "& .MuiInputBase-input": {
                      color:
                        theme.palette.mode === "light" ? light.text : dark.text,
                    },
                  }}
                  error={errorMessage !== null}
                  helperText={
                    errorMessage && (
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? light.error
                              : dark.error,
                          fontSize: "12px",
                        }}
                      >
                        {errorMessage}
                      </Typography>
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addNote();
                    }
                  }}
                />
                <Box
                  onClick={addNote}
                  sx={{
                    borderRadius: "10%",
                    border: `1px solid ${
                      theme.palette.mode === "light"
                        ? light.border
                        : dark.border
                    }`,
                    color:
                      theme.palette.mode === "light"
                        ? light.buttonIcon
                        : dark.buttonIcon,
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? light.primary
                        : dark.buttonBackground,
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? light.buttonBackgroundHover
                          : dark.buttonBackgroundHover,
                      cursor: "pointer",
                      color:
                        theme.palette.mode === "light"
                          ? light.buttonIconHover
                          : dark.buttonIconHover,
                    },
                    width: "fit-content",
                    height: "fit-content",
                    display: "inline-block",
                    textAlign: "center",
                    lineHeight: "0",
                    padding: "0.25rem",
                  }}
                >
                  <span style={{ fontSize: "32px" }}>
                    <IoAddOutline />
                  </span>
                </Box>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </div>

      <MDBox>
        {isThereAnyNote ? (
          notes?.map((note, key) => (
            <Note
              key={note.id}
              note={note}
              toggleDone={() => updateNote(note.id, note.completion)}
              removeNote={() => deleteNote(key)}
            />
          ))
        ) : (
          <Typography
            variant="body1"
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "180px",
              color: theme.palette.mode === "light" ? light.text : dark.text,
            }}
          >
            No tasks for this date yet
          </Typography>
        )}
      </MDBox>
    </Card>
  );
}

export default Notes;
